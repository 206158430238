var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":"create-contract","title":"Nuevo Contrato","size":_vm.ui_size == 'sm' ? 'md' : 'lg',"body-class":"p-0","title-class":{
			'fs-11' : _vm.ui_size == 'sm',
			'fs-13' : _vm.ui_size == 'md',
			'fs-15' : _vm.ui_size == 'lg',
		},"header-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"header-text-variant":_vm.ui_theme == 'dark' ? 'light' : '',"body-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"body-text-variant":_vm.ui_theme == 'dark' ? 'light' : '',"footer-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"footer-text-variant":_vm.ui_theme == 'dark' ? 'light' : ''},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ close }){return [_c('b-button',{attrs:{"variant":"outline-secondary","disabled":_vm.loading,"size":_vm.ui_size},on:{"click":function($event){return close()}}},[_vm._v("Cancelar")]),_c('b-button',{attrs:{"variant":"success","disabled":_vm.loading||!_vm.val_full,"size":_vm.ui_size},on:{"click":function($event){return _vm.create()}}},[_vm._v("CREAR")])]}}])},[_c('b-overlay',{staticClass:"p-4",attrs:{"show":_vm.loading,"rounded":"sm","variant":_vm.ui_theme == 'dark' ? 'dark': 'light'},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center py-5"},[_c('b-spinner',{attrs:{"type":"grow","variant":"primary"}})],1)]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"sm":"8"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Seleccione Cliente ")]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.clients.loading,"state":_vm.val_client,"size":_vm.ui_size},on:{"change":function($event){return _vm.reloadClientContacts()}},model:{value:(_vm.contract.client_id),callback:function ($$v) {_vm.$set(_vm.contract, "client_id", $$v)},expression:"contract.client_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Presione aquí")]),_vm._l((_vm.clients.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Cliente no existe: ")]},proxy:true}])},[_c('b-button',{attrs:{"block":"","size":_vm.ui_size,"variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-success'},on:{"click":function($event){return _vm.$bvModal.show('create-client')}}},[_c('i',{staticClass:"fas fa-plus mr-1"}),_c('span',[_vm._v("Nuevo Cliente")])])],1)],1),_c('b-col',{attrs:{"sm":"8"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
							'text-muted' : _vm.contract.client_id == null,
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Seleccione Contacto ")]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.contract.client_id == null || _vm.clients.loading || _vm.client_contacts.loading,"state":_vm.val_contact,"size":_vm.ui_size},model:{value:(_vm.contract.contact_id),callback:function ($$v) {_vm.$set(_vm.contract, "contact_id", $$v)},expression:"contract.contact_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[(_vm.contract.client_id != null)?_c('span',[_vm._v("Presione aquí")]):_c('span',[_vm._v("Seleccione primero un cliente")])]),_vm._l((_vm.client_contacts.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name)+" "+_vm._s(item.lastname))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
							'text-muted' : _vm.contract.client_id == null,
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Contacto no existe: ")]},proxy:true}])},[_c('b-button',{attrs:{"block":"","disabled":_vm.contract.client_id == null,"size":_vm.ui_size,"variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-success'},on:{"click":function($event){return _vm.$bvModal.show('create-client-contact')}}},[_c('i',{staticClass:"fas fa-plus mr-1"}),_c('span',[_vm._v("Nuevo Contacto")])])],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Fecha de Inicio de Contrato ")]},proxy:true}])},[_c('b-form-datepicker',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"date-format-options":{ year: 'numeric', month: 'long', day: 'numeric' },"max":_vm.contract.end_date,"placeholder":"Presione aquí","state":_vm.val_start,"size":_vm.ui_size},model:{value:(_vm.contract.start_date),callback:function ($$v) {_vm.$set(_vm.contract, "start_date", $$v)},expression:"contract.start_date"}}),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Fecha de Término de Contrato ")]},proxy:true}])},[_c('b-form-datepicker',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"date-format-options":{ year: 'numeric', month: 'long', day: 'numeric' },"min":_vm.contract.start_date,"placeholder":"Presione aquí","state":_vm.val_end,"size":_vm.ui_size},model:{value:(_vm.contract.end_date),callback:function ($$v) {_vm.$set(_vm.contract, "end_date", $$v)},expression:"contract.end_date"}}),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Título / Nombre del Contrato ")]},proxy:true}])},[_c('b-input',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"state":_vm.val_title,"size":_vm.ui_size},model:{value:(_vm.contract.title),callback:function ($$v) {_vm.$set(_vm.contract, "title", $$v)},expression:"contract.title"}}),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1)],1),_c('b-alert',{staticClass:"mb-0",class:{
					'fs-09' : _vm.ui_size == 'sm',
					'fs-10' : _vm.ui_size == 'md',
					'fs-12' : _vm.ui_size == 'lg',
				},attrs:{"show":"","variant":_vm.val_full ? 'success' : 'danger'}},[_c('p',{staticClass:"mb-0 text-size-10"},[_c('i',{staticClass:"fas fa-exclamation-circle mr-2"}),_c('span',[_vm._v(_vm._s(_vm.val_full ? 'Para continuar, presione el botón CREAR.' : 'Complete los campos requeridos para continuar.'))])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }