var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":"create-user","title":"Nuevo Usuario","size":_vm.ui_size == 'sm' ? 'lg' : 'lg',"body-class":"p-0","title-class":{
			'fs-11' : _vm.ui_size == 'sm',
			'fs-13' : _vm.ui_size == 'md',
			'fs-15' : _vm.ui_size == 'lg',
		},"header-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"header-text-variant":_vm.ui_theme == 'dark' ? 'light' : '',"body-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"body-text-variant":_vm.ui_theme == 'dark' ? 'light' : '',"footer-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"footer-text-variant":_vm.ui_theme == 'dark' ? 'light' : ''},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ close }){return [_c('b-button',{attrs:{"variant":"outline-secondary","disabled":_vm.loading,"size":_vm.ui_size},on:{"click":function($event){return close()}}},[_vm._v("Cancelar")]),_c('b-button',{attrs:{"variant":"success","disabled":_vm.loading||!_vm.val_full,"size":_vm.ui_size},on:{"click":function($event){return _vm.create()}}},[_vm._v("CREAR")])]}}])},[_c('b-overlay',{staticClass:"p-4",attrs:{"show":_vm.loading,"rounded":"sm","variant":_vm.ui_theme == 'dark' ? 'dark': 'light'},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center py-5"},[_c('b-spinner',{attrs:{"type":"grow","variant":"primary"}})],1)]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" RUT ")]},proxy:true}])},[_c('b-input',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"formatter":_vm.cleanIdentifier,"maxlength":9,"state":_vm.val_vatin,"size":_vm.ui_size},model:{value:(_vm.user.vatin),callback:function ($$v) {_vm.$set(_vm.user, "vatin", $$v)},expression:"user.vatin"}}),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nombre ")]},proxy:true}])},[_c('b-input',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"state":_vm.val_name,"size":_vm.ui_size},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Apellido ")]},proxy:true}])},[_c('b-input',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"state":_vm.val_lastname,"size":_vm.ui_size},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}}),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" E-mail ")]},proxy:true}])},[_c('b-input',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"state":_vm.val_email,"size":_vm.ui_size},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Celular ")]},proxy:true}])},[_c('b-input-group',{attrs:{"prepend":"+569","size":_vm.ui_size}},[_c('b-input',{class:{
									'bg-primary text-white' : _vm.ui_theme == 'dark',
								},attrs:{"maxlength":8,"formatter":_vm.onlyNumbers,"state":_vm.val_phone},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Perfil de Acceso")]),_c('b-button',{staticClass:"ml-2",attrs:{"size":"xs","variant":"light"},on:{"click":function($event){return _vm.$bvModal.show('helper-access-profile')}}},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fas fa-circle-question mr-1",attrs:{"title":"Más Información"}}),_c('span',[_vm._v("Más información")])])]},proxy:true}])},[_c('b-form-radio-group',{model:{value:(_vm.user.profile_id),callback:function ($$v) {_vm.$set(_vm.user, "profile_id", $$v)},expression:"user.profile_id"}},[_c('b-card',{class:{
									'py-2 px-2 mb-2' : true,
									'text-danger border-danger' : _vm.user.profile_id == null,
									'text-success border-success' : _vm.user.profile_id == 1,
									'text-secondary border-secondary' : _vm.user.profile_id > 1,
								},attrs:{"no-body":""}},[_c('b-form-radio',{attrs:{"value":1},on:{"input":function($event){_vm.user.client_id = null}}},[_c('p',{staticClass:"mb-0 fw-bold"},[_c('i',{staticClass:"fas fa-shield-check mr-1"}),_c('span',[_vm._v("Administración")])]),_c('p',{staticClass:"mb-0"},[_vm._v("Esta cuenta podrá acceder a los módulos comerciales.")])])],1),_c('b-card',{class:{
									'py-2 px-2 mb-2' : true,
									'text-danger border-danger' : _vm.user.profile_id == null,
									'text-success border-success' : _vm.user.profile_id == 2,
									'text-secondary border-secondary' : _vm.user.profile_id == 1 || _vm.user.profile_id == 3,
								},attrs:{"no-body":""}},[_c('b-form-radio',{attrs:{"value":2},on:{"input":function($event){_vm.user.client_id = null}}},[_c('p',{staticClass:"mb-0 fw-bold"},[_c('i',{staticClass:"fas fa-helmet-safety mr-1"}),_c('span',[_vm._v("Técnico")])]),_c('p',{staticClass:"mb-0"},[_vm._v("Esta cuenta podrá administrar Equipos y Órdenes de Trabajo de cualquier cliente.")])]),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1),_c('b-card',{class:{
									'py-2 px-2 mb-2' : true,
									'text-danger border-danger' : _vm.user.profile_id == null || (_vm.user.profile_id == 3 && _vm.user.client_id == null),
									'text-success border-success' : _vm.user.profile_id == 3,
									'text-secondary border-secondary' : _vm.user.profile_id < 3,
								},attrs:{"no-body":""}},[_c('b-form-radio',{attrs:{"value":3}},[_c('p',{staticClass:"mb-0 fw-bold"},[_c('i',{staticClass:"fas fa-buildings mr-1"}),_c('span',[_vm._v("Cliente")])]),_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v("Esta cuenta sólo podrá ver los Equipos y Órdenes de Trabajo del cliente que seleccione.")]),(_vm.user.profile_id == 3)?_c('b-form-group',{class:{
												'mb-0 mt-2' : true,
												'fs-09 pb-0' : _vm.ui_size == 'sm',
												'fs-10 pb-1' : _vm.ui_size == 'md',
												'fs-12 pb-2' : _vm.ui_size == 'lg',
											},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('b-row',[_c('b-col',[_vm._v(" Seleccione Cliente ")]),_c('b-col',{attrs:{"sm":"auto"}},[_c('b-button',{staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-dark' : 'outline-dark'},on:{"click":function($event){return _vm.$bvModal.show('create-client')}}},[_c('i',{staticClass:"fas fa-plus mr-1"}),_c('span',[_vm._v("Nuevo Cliente")])])],1)],1)]},proxy:true}],null,false,3267512843)},[_c('b-select',{class:{
													'bg-primary text-white' : _vm.ui_theme == 'dark',
												},attrs:{"disabled":_vm.clients.loading,"state":_vm.val_client,"size":_vm.ui_size},model:{value:(_vm.user.client_id),callback:function ($$v) {_vm.$set(_vm.user, "client_id", $$v)},expression:"user.client_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Presione aquí")]),_vm._l((_vm.clients.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1):_vm._e()],1)]),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.val_profile}},[_vm._v("Requerido")]),_c('b-form-valid-feedback',{attrs:{"state":_vm.val_profile}},[_vm._v("Correcto")])],1)],1)],1),_c('b-alert',{staticClass:"mb-0",class:{
					'fs-09' : _vm.ui_size == 'sm',
					'fs-10' : _vm.ui_size == 'md',
					'fs-12' : _vm.ui_size == 'lg',
				},attrs:{"show":"","variant":_vm.val_full ? 'success' : 'danger'}},[_c('p',{staticClass:"mb-0 text-size-10"},[_c('i',{staticClass:"fas fa-exclamation-circle mr-2"}),_c('span',[_vm._v(_vm._s(_vm.val_full ? 'Para continuar, presione el botón CREAR.' : 'Complete los campos requeridos para continuar.'))])])])],1)],1),_c('b-modal',{attrs:{"id":"helper-access-profile","hide-header":"","size":"md","body-class":"px-4 py-4"},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ close }){return [_c('b-button',{attrs:{"block":"","variant":"outline-info","disabled":_vm.loading,"size":_vm.ui_size},on:{"click":function($event){return close()}}},[_vm._v("Cerrar")])]}}])},[_c('div',[_c('p',{staticClass:"mb-2"},[_c('i',{staticClass:"fas fa-circle-question fs-30 text-info"})]),_c('p',{staticClass:"mb-2 fs-14 fw-bold"},[_vm._v("Perfil de Acceso")]),_c('p',{staticClass:"mb-0 fs-12 text-dark"},[_vm._v("Determina qué información almacenada en el sistema puede ver y cambiar una persona.")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }