<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="link-defontana-purchase-order-item"
			title="Vincular Ítem a Orden de Compra"
			:size="ui_size == 'sm' ? 'xl' : 'xl'"
			body-class="p-0"
			:title-class="{
				'fs-11' : ui_size == 'sm',
				'fs-13' : ui_size == 'md',
				'fs-15' : ui_size == 'lg',
			}"
			:header-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:header-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:body-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:body-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:footer-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:footer-text-variant="ui_theme == 'dark' ? 'light' : ''"
		>

			<b-row class="px-4 py-3">
				<b-col sm="12">
					<b-form-group
						label-class="fw-bold"
						:class="{
							'fs-09 pb-0' : ui_size == 'sm',
							'fs-10 pb-1' : ui_size == 'md',
							'fs-12 pb-2' : ui_size == 'lg',
						}"
						class="mb-0"
					>
						<template #label>
							<b-row no-gutters>
								<b-col>
									<span>Orden de Compra</span>
								</b-col>
							</b-row>
						</template>
						<b-select
							v-model="purchase_order_id"
							:state="val_purchase_order"
							:disabled="orders.loading"
							:size="ui_size"
							:class="{
								'bg-primary text-white' : ui_theme == 'dark',
							}"
						>
							<b-select-option :value="null" disabled>Presione aquí</b-select-option>
							<b-select-option v-for="(item, i) in orders.data" :key="i" :value="i" class="text-uppercase">{{ item.number }} -- {{ item.providerName }}</b-select-option>
						</b-select>
						<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
						<b-form-valid-feedback>Correcto</b-form-valid-feedback>
					</b-form-group>
				</b-col>
			</b-row>
			
			<hr v-if="purchase_order_id != null" class="m-0">

			<b-row class="px-4 py-4" v-if="purchase_order_id != null">

				<b-col sm="12">
					<b-list-group>

						<b-list-group-item class="py-2 pl-3 pr-2">
							<b-row no-gutters>
								<b-col sm="3">
									<strong>N° OC</strong>
								</b-col>
								<b-col>
									<div class="mb-0 text-uppercase">
										<p class="mb-0">
											<span>{{ orders.data[purchase_order_id].number }}</span>
										</p>
									</div>
								</b-col>
							</b-row>
						</b-list-group-item>

						<b-list-group-item class="py-2 pl-3 pr-2">
							<b-row no-gutters>
								<b-col sm="3">
									<strong>Proveedor</strong>
								</b-col>
								<b-col>
									<div class="mb-0 text-uppercase">
										<p class="mb-0">
											<span>{{ orders.data[purchase_order_id].providerName }}</span>
										</p>
										<p class="mb-0">
											<span class="text-muted fs-09">RUT {{ orders.data[purchase_order_id].providerLegalCode }}</span>
										</p>
									</div>
								</b-col>
							</b-row>
						</b-list-group-item>

						<b-list-group-item class="py-2 pl-3 pr-2">
							<b-row no-gutters>
								<b-col sm="3">
									<strong>Emisión</strong>
								</b-col>
								<b-col>
									<div class="mb-0 text-uppercase">
										<p class="mb-0">
											<span>{{ orders.data[purchase_order_id].emissionDate }}</span>
										</p>
									</div>
								</b-col>
							</b-row>
						</b-list-group-item>

						<b-list-group-item class="py-2 pl-3 pr-2">
							<b-row no-gutters>
								<b-col sm="3">
									<strong>Estado</strong>
								</b-col>
								<b-col>
									<div class="mb-0 text-uppercase">
										<p class="mb-0">
											<span>{{ orders.data[purchase_order_id].status }}</span>
										</p>
									</div>
								</b-col>
							</b-row>
						</b-list-group-item>

						<b-list-group-item class="py-2 pl-3 pr-2">
							<b-row no-gutters>
								<b-col sm="3">
									<strong>Monto Neto</strong>
								</b-col>
								<b-col>
									<div class="mb-0 text-uppercase">
										<p class="mb-0">
											<span class="text-muted mr-1">$</span>
											<span>{{ parseCurrency(orders.data[purchase_order_id].amountTotal, true, false) }}</span>
										</p>
									</div>
								</b-col>
							</b-row>
						</b-list-group-item>

					</b-list-group>
				</b-col>

			</b-row>

			<b-row class="px-4" v-if="purchase_order_id != null">
				<b-col>
					<b-alert show :variant="selected != null ? 'success' : 'danger'" class="fs-12">
						<b-row no-gutters>
							<b-col>
								<span v-if="selected == null">
									<i class="fas fa-arrow-down mr-2"></i>
									<span>Haga clic en un ítem del listado para seleccionarlo.</span>
								</span>
								<span v-else>
									<i class="fas fa-arrow-down mr-2"></i>
									<span>Línea {{ selected.line }} seleccionada.</span>
								</span>
							</b-col>
							<b-col sm="auto" v-if="selected != null">
								<b-button variant="outline-light" class="py-0" @click="disabledButton()">
									<i class="fas fa-check mr-2"></i>
									<span>Confirmar Vinculación</span>
								</b-button>
							</b-col>
						</b-row>
					</b-alert>

					<b-table
						v-if="!orders.loading && orders.data.length && orders.data[purchase_order_id] && orders.data[purchase_order_id].items"
						:items="currentItems"
						:fields="[
							{ key: 'line', label: '#', sortable: false },
							{ key: 'meta', label: 'Ítem', sortable: false },
							{ key: 'productInventoryMeasureUnitId', label: 'U.M.', sortable: false },
							{ key: 'quantity', label: 'Cantidad', sortable: false },
							{ key: 'price', label: 'Precio', sortable: false },
							{ key: 'amountInputCurrency', label: 'Subtotal', sortable: false },
						]"
						show-empty
						:stacked="mobile"
						selected-variant="success"
						@row-clicked="onRowSelected"
						ref="selectablePurchaseOrderItem"
						hover
						selectable
						select-mode="single"
						bordered
						:small="ui_size == 'sm' || false"
						sort-icon-left
						:head-variant="ui_theme == 'light' ? 'light' : 'dark'"
						class="mt-0 mb-4 rounded"
						fixed
						:class="{
							'fs-09' : ui_size == 'sm',
							'fs-10' : ui_size == 'md',
							'fs-13' : ui_size == 'lg',
							'table-dark' : ui_theme == 'dark',
						}"
					>
						<template #table-colgroup="scope">
							<col
								v-for="field in scope.fields"
								:key="field.key"
								:style="{
									width: field.key === 'line' ? '5%' :
											field.key === 'meta' ? '50%' :
											field.key === 'productInventoryMeasureUnitId' ? '5%' :
											field.key === 'quantity' ? '10%' :
											field.key === 'price' ? '15%' : '15%'
								}"
							>
						</template>
						<template v-slot:empty>
							<div class="p-3">
								<span class="fs-12 text-danger">Agregue al menos un ítem a la cotización.</span>
							</div>
						</template>
						<template v-slot:cell(meta)="data">
							<div v-if="data.item.productDescription && data.item.productDescription.length">
								<p class="mb-0 text-uppercase">{{ data.item.productDescription }}</p>
								<p class="mb-0 fs-09 text-muted text-uppercase">{{ data.item.comment }}</p>
							</div>
							<div v-else>
								<span class="text-muted">Sin Descripción</span>
							</div>
						</template>
						<template v-slot:cell(quantity)="data">
							<div>
								{{ parseCurrency(data.value, true, false) }}
							</div>
						</template>
						<template v-slot:cell(price)="data">
							<div class="text-right">
								<span class="text-muted float-left">$</span>
								<span>{{ parseCurrency(data.value, true, false) }}</span>
							</div>
						</template>
						<template v-slot:cell(amountInputCurrency)="data">
							<div class="text-right">
								<span class="text-muted float-left">$</span>
								<span>{{ parseCurrency(data.value, true, false) }}</span>
							</div>
						</template>
					</b-table>
				</b-col>
			</b-row>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :size="ui_size">Cancelar</b-button>
			</template>

		</b-modal>
	</div>
</template>

<script>
import Helpers from '@/helpers';
export default {
	name: 'LinkDefontanaPurchaseOrderItem',
	components: {
	},
	data() {
		return {
			reloading: false,
			loading: false,
			items: [],
			purchase_order_id: null,
			query: {
				limit: 10,
				page: 1,
				sortDesc: true,
				sortBy: 'id',
				search: null,
				type: '',
			},
			selected: null,
			paginationOptions: [
				{ value: 5, text: '5 por página' },
				{ value: 10, text: '10 por página' },
				{ value: 15, text: '15 por página' },
				{ value: 20, text: '20 por página' },
				{ value: 50, text: '50 por página' },
				{ value: 100, text: '100 por página' },
				{ value: 9999, text: 'Mostrar todo' },
			],
		}
	},
	mounted() {
		this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
			if (modalId == 'link-defontana-purchase-order-item') { this.reset() }
		})
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'link-defontana-purchase-order-item') { this.reset() }
		})
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		orders() { return this.$store.state.helpers.defontana_purchase_orders },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		val_purchase_order() {
			return this.purchase_order_id != null ? true : false
		},
		currentItems() {
			return this.val_purchase_order ? this.orders.data[this.purchase_order_id].items : []
		},
	},
	methods: {
		reset() {
			this.purchase_order_id = null
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		load() {
			this.$store.dispatch('purchase_orders', this.query).then(() => { this.reloading = false })
		},
		reload() {
			this.reloading = true
			this.load()
		},
		search() {
			if (this.query.search == null) {
				this.query.search = ''
				//this.$refs.search.focus()
			}
			else if (this.query.search == '') {
				this.query.search = null
			}
			else if (this.query.search.length > 0) {
				this.reload()
			}
		},
		searchBlur() {
			if (this.query.search == '') {
				this.query.search = null
			}
		},
		searchClear() {
			if (this.query.search != null && this.query.search.length == 0) {
				this.query.page = 1
				this.reload()
			}
		},
		searchChanged(search) {
			this.query.search = search
		},
		perPageChanged(limit) {
			this.query.limit = limit
			this.reload()
		},
		paginationChanged(page) {
			this.query.page = page
			this.reload()
		},
		sortingChanged(ctx) {
			this.query.sortDesc = ctx.sortDesc
			this.query.sortBy = ctx.sortBy
			this.reload()
		},
		finish() {
			this.new_purchase_request.items = [...this.new_purchase_request.items, ...this.items];
			this.$bvModal.hide('link-defontana-purchase-order-item')
		},
		rowClass(item, type) {
			if (!item || type !== 'row') return
			if (item.id == 1) return 'table-success'
		},
		changeType() {
			this.query.sortDesc = false
			this.query.sortBy = 'code'
			this.reload()
		},
		parseCurrency(number, decimal, reverse) {
			return Helpers.parseCurrency(number, decimal, reverse)
		},
		onRowSelected(data) {
			if (this.selected != null) {
				if (this.selected == data) {
					this.selected = null
				} else {
					this.selected = data
				}
			} else {
				this.selected = data
			}
		},
		disabledButton() {
			this.toast(false, 'Esta función se encuentra en espera de validación.', 10000)
		}
	}
}
</script>