<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="view-defontana-sale"
			:size="ui_size == 'sm' ? 'xl' : 'xl'"
			body-class="p-0"
			:title-class="{
				'fs-11' : ui_size == 'sm',
				'fs-13' : ui_size == 'md',
				'fs-15' : ui_size == 'lg',
			}"
			:header-bg-variant="ui_theme == 'dark' ? 'dark' : 'dark'"
			:header-text-variant="ui_theme == 'dark' ? 'light' : 'light'"
			:body-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:body-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:footer-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:footer-text-variant="ui_theme == 'dark' ? 'light' : ''"
			header-class="p-2"
		>
			<template #modal-header>
				<div class="w-100">
					<b-row no-gutters>
						<b-col sm="auto">
							<b-spinner v-if="resource.loading" type="grow" small variant="light"></b-spinner>
							<div class="mb-0" v-else-if="resource.data && resource.data.firstFolio">
								
							</div>
						</b-col>
						<b-col class="text-right" v-if="!resource.loading">
							<b-button
								size="sm"
								variant="dark"
								@click="$bvModal.hide('view-defontana-sale')"
							>
								<i class="fas fa-times mr-1"></i>
								<span>Cerrar</span>
							</b-button>
						</b-col>
					</b-row>
				</div>
			</template>
		
			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-4" :variant="ui_theme == 'dark' ? 'dark': 'light'">

				<b-row class="mb-3">

					<b-col class="text-center">
						<b-img src="/img/electrofrio/logo_vertical_color.svg" class="w-50"></b-img>
					</b-col>
					<b-col>
						<p class="mb-0">ELECTROFRIO LTDA</p>
						<p class="mb-0">ORELLA 885-A</p>
						<p class="mb-0">57-2410077</p>
					</b-col>
					<b-col>
						<div style="border: 2px solid red" class="p-4 text-center">
							<b-skeleton v-if="resource.loading"></b-skeleton>
							<p class="mb-0" v-else>77.651.850-6</p>
							<b-skeleton v-if="resource.loading"></b-skeleton>
							<p class="mb-0" v-else-if="resource.data.document_type && resource.data.document_type.name">{{ resource.data.document_type.name }}</p>
							<b-skeleton v-if="resource.loading"></b-skeleton>
							<p class="mb-0" v-else>N° {{ resource.data.firstFolio }}</p>
						</div>
					</b-col>

				</b-row>

				<!-- CABECERA -->
				<b-card body-class="p-3" class="mb-3">
					<b-row>
						<b-col sm="6" class="mb-3">
							<p class="mb-1 fw-bold">Fecha</p>
							<b-skeleton v-if="resource.loading"></b-skeleton>
							<p class="mb-0" v-else>
								<span>{{ resource.data.emissionDate }}</span>
							</p>
						</b-col>
						<b-col sm="6" class="mb-3">
							<p class="mb-1 fw-bold">Vencimiento</p>
							<b-skeleton v-if="resource.loading"></b-skeleton>
							<p class="mb-0" v-else>
								<span>{{ resource.data.expirationDate }}</span>
							</p>
						</b-col>
						<b-col sm="6" class="mb-3">
							<p class="mb-1 fw-bold">RUT o Nombre</p>
							<b-skeleton v-if="resource.loading"></b-skeleton>
							<p class="mb-0" v-else>
								<span>{{ resource.data.client.name }}</span>
							</p>
						</b-col>
						<b-col sm="6" class="mb-3">
							<p class="mb-1 fw-bold">Condición de Pago</p>
							<b-skeleton v-if="resource.loading"></b-skeleton>
							<p class="mb-0" v-else>
								<span>{{ resource.data.payment_condition.name }}</span>
							</p>
						</b-col>
						<b-col sm="6" class="mb-3">
							<p class="mb-1 fw-bold">Dirección</p>
							<b-skeleton v-if="resource.loading"></b-skeleton>
							<p class="mb-0" v-else>
								<span>{{ resource.data.client.address }}</span>
							</p>
						</b-col>
						<b-col sm="6" class="mb-3">
							<p class="mb-1 fw-bold">Vendedor</p>
							<b-skeleton v-if="resource.loading"></b-skeleton>
							<p class="mb-0" v-else>
								<span>{{ resource.data.seller.name }} ({{ resource.data.seller.code }})</span>
							</p>
						</b-col>
					</b-row>
				</b-card>

				<!-- DOCUMENTOS ASOCIADOS -->
				<b-card no-body class="mb-3">
					<b-table
						:items="resource.data.attached_documents"
						:fields="fields.attached_documents"
						:busy="resource.loading"
						show-empty
						small
						class="mb-0"
					>
						<template v-slot:empty>
							<div class="p-1">
								<span class="text-muted" >No hay documentos asociados.</span>
							</div>
						</template>
						<template v-slot:table-busy>
							<div class="text-center my-1">
								<b-skeleton></b-skeleton>
								<b-skeleton></b-skeleton>
							</div>
						</template>
					</b-table>
				</b-card>

				<!-- DETAILS -->
				<b-card no-body class="mb-3">
					<b-table
						:items="resource.data.details"
						:fields="fields.details"
						:busy="resource.loading"
						show-empty
						small
						class="mb-0"
					>
						<template v-slot:empty>
							<div class="p-1">
								<span class="text-muted" >No hay documentos asociados.</span>
							</div>
						</template>
						<template v-slot:table-busy>
							<div class="text-center my-1">
								<b-skeleton></b-skeleton>
								<b-skeleton></b-skeleton>
							</div>
						</template>
						<template #cell(price)="data">
							<div class="text-right">
								<span class="text-dark">$ </span>
								<span>{{ parseNumber(data.value) }}</span>
							</div>
						</template>
						<template #cell(total)="data">
							<div class="text-right">
								<span class="text-dark">$ </span>
								<span>{{ parseNumber(data.value) }}</span>
							</div>
						</template>
					</b-table>
				</b-card>

				<!-- PIE -->
				<b-row>
					<b-col>
						<b-card body-class="p-3">
							<p class="mb-2 fw-bold">Comentario</p>
							<b-skeleton v-if="resource.loading"></b-skeleton>
							<p class="mb-0" v-else>
								<span>{{ resource.data.gloss }}</span>
							</p>
						</b-card>
					</b-col>
					<b-col>
						<b-list-group>
							<b-list-group-item class="p-3">
								<b-row>
									<b-col>
										SUBTOTAL
									</b-col>
									<b-col class="text-right">
										<b-skeleton v-if="resource.loading" class="mt-1"></b-skeleton>
										<div v-else>
											<span class="text-dark">$ </span>
											<span>{{ parseNumber(resource.data.affectableTotal) }}</span>
										</div>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item>
								<b-row>
									<b-col>
										AFECTO
									</b-col>
									<b-col class="text-right">
										<b-skeleton v-if="resource.loading" class="mt-1"></b-skeleton>
										<div v-else>
											<span class="text-dark">$ </span>
											<span>{{ parseNumber(resource.data.affectableTotal) }}</span>
										</div>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item>
								<b-row>
									<b-col>
										EXENTO
									</b-col>
									<b-col class="text-right">
										<b-skeleton v-if="resource.loading" class="mt-1"></b-skeleton>
										<div v-else>
											<span class="text-dark">$ </span>
											<span>{{ parseNumber(resource.data.exemptTotal) }}</span>
										</div>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item>
								<b-row>
									<b-col>
										IVA
									</b-col>
									<b-col class="text-right">
										<b-skeleton v-if="resource.loading" class="mt-1"></b-skeleton>
										<div v-else>
											<span class="text-dark">$ </span>
											<span>{{ parseNumber(resource.data.taxeValue) }}</span>
										</div>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item>
								<b-row>
									<b-col>
										BRUTO
									</b-col>
									<b-col class="text-right">
										<b-skeleton v-if="resource.loading" class="mt-1"></b-skeleton>
										<div v-else>
											<span class="text-dark">$ </span>
											<span>{{ parseNumber(resource.data.total) }}</span>
										</div>
									</b-col>
								</b-row>
							</b-list-group-item>
						</b-list-group>
					</b-col>
				</b-row>

				<!-- LOADING -->
				<template #overlay>
					<div class="text-center py-5">
						<b-spinner type="grow" variant="primary"></b-spinner>
					</div>
				</template>

			</b-overlay>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :disabled="loading" :size="ui_size">Cerrar</b-button>
			</template>

		</b-modal>
	</div>
</template>

<script>
import Helpers from '@/helpers';
export default {
	name: 'ViewDefontanaSale',
	components: {
	},
	data() {
		return {
			loading: false,
			fields: {
				attached_documents: [
					{ key: 'attachedDocumentName', label: 'DOCUMENTO ASOCIADO', sortable: false },
					{ key: 'attachedDocumentNumber', label: 'FOLIO', sortable: false },
					{ key: 'date', label: 'FECHA', sortable: false },
					{ key: 'gloss', label: 'COMENTARIO', sortable: false },
				],
				details: [
					{ key: 'code', label: 'COD', sortable: false },
					{ key: 'comment', label: 'PRODUCTO', sortable: false },
					{ key: 'count', label: 'CANT', sortable: false },
					{ key: 'price', label: 'PRECIO', sortable: false },
					{ key: 'total', label: 'TOTAL', sortable: false },
				]
			}
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'view-defontana-sale') { this.reset() }
		})
	},
	computed: {
		resource: {
			get() { return this.$store.getters.defontana_sale },
			set(data) { this.$store.commit('defontana_sale', data) }
		},
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
	},
	methods: {
		reset() {
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		onlyNumbers(data) {
			return Helpers.onlyNumbers(data)
		},
		parseNumber(number) {
			return Helpers.parseNumber(number)
		},
	}
}
</script>