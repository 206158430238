<template>
	<div class="mt-3">

		<SiteModuleHeader
			:showBack="true"
			:showHome="true"
			:showSize="true"
			:showTheme="true"
			:showFluid="true"
			:showTitle="true"
			@reload="reload"
			:reloading="reloading || resources.loading"
		></SiteModuleHeader>

		<CreateClient></CreateClient>

		<!-- TOOLBAR -->
		<b-row no-gutters>

			<!-- CREATE -->
			<b-col md="12" lg="auto" xl="auto">
				<SitePrimaryButton
					:loading="resources.loading"
					text="Nuevo"
					title="Presione para crear"
					icon="plus"
					@clicked="create()"
				></SitePrimaryButton>
			</b-col>

			<!-- SEARCH INPUT -->
			<b-col md="12" lg="auto" xl="auto" class="mr-2">
				<b-overlay
					:show="resources.loading"
					rounded
					opacity="0.6"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-input-group :size="ui_size">
						<b-input
							v-model="query.search"
							placeholder="Escriba aquí"
							@changed="searchChanged"
							@blur="searchBlur()"
							@keyup.delete="searchClear()"
							@keydown.enter="search()"
							v-bind:class="{
								'bg-white text-primary' : true,
								'border-primary' : search != null,
								'border-0' : search == null,
							}"
						></b-input>
						<template #append>
							<b-button
								title="Presione para buscar"
								:disabled="query.search == null || query.search.length == 0"
								:variant="query.search != null && query.search.length > 0 ? 'primary' : 'outline-primary'"
								@click="search()"
							>
								<i class="fas fa-search mr-1"></i>
								<span>Buscar</span>
							</b-button>
						</template>
					</b-input-group>
				</b-overlay>
			</b-col>

		</b-row>
		<!-- /TOOLBAR -->

		<!-- DATA -->
		<b-skeleton-wrapper :loading="resources.loading && !reloading">

			<!-- LOADING -->
			<template #loading>
				<b-card no-body>
					<b-skeleton-table
						animation="fade"
						:rows="10"
						:columns="7"
					></b-skeleton-table>
				</b-card>
			</template>

			<!-- TABLE :fields="visibleTableFields" -->
			<b-table
				:items="resources.data.data"
				:fields="visibleTableFields"
				:busy="reloading"
				:no-local-sorting="true"
				@sort-changed="sortingChanged"
				show-empty
				:stacked="mobile"
				hover
				bordered
				:small="ui_size == 'sm' || false"
				responsive
				sort-icon-left
				head-variant="light"
				class="mt-0 mb-0 rounded"
				:class="{
					'fs-09' : ui_size == 'sm',
					'fs-10' : ui_size == 'md',
					'fs-13' : ui_size == 'lg',
				}"
			>
				<template #empty>
					<p class="px-3 text-size-12 text-muted my-3">No se encontraron registros.</p>
				</template>
				<template #cell(enabled)="data">
					<div class="pl-2" :class="{ 'mt-1' : ui_size != 'sm'}">
						<i :class="{
							'fas fa-circle mr-1' : true,
							'text-success' : data.value == true,
							'text-danger' : data.value == false
						}"></i>
						<span>{{ data.value ? 'Activo' : 'Inactivo' }}</span>
					</div>
				</template>
				<template #cell(email)="data">
					<div :class="{ 'mt-1' : ui_size != 'sm'}">
						<span>{{ data.value }}</span>
						<div v-b-tooltip.hover.right title="Editar" class="d-inline-block">
							<b-overlay
								:show="loaders.email.active && loaders.email.id == data.item.id"
								rounded
								opacity="0.6"
								spinner-small
								spinner-variant="primary"
								class="d-inline-block ml-2"
							>
								<b-dropdown
									size="xs"
									variant="light"
									no-caret
									@show="copyEmail(data.item)"
								>
									<template #button-content>
										<i class="fas fa-pen text-size-08 text-silenced"></i>
									</template>
									<template v-slot:default="{ hide }">
										<div class="px-2 m-0">
											<b-input
												v-model="loaders.email.new"
												style="width: 250px;"
												v-on:keyup.enter="updateEmail(data.item); hide()"
											></b-input>
											<b-button
												:variant="loaders.email.new == data.item.email ? 'secondary' : 'success'"
												:disabled="loaders.email.new == data.item.email"
												block
												size="sm"
												class="mt-2"
												@click="updateEmail(data.item); hide()"
											>Guardar Cambios</b-button>
										</div>
									</template>
								</b-dropdown>
							</b-overlay>
						</div>
					</div>
				</template>
				<template #cell(name)="data">
					<div :class="{ 'mt-1' : ui_size != 'sm'}">
						<span>{{ data.value }}</span>
						<div v-b-tooltip.hover.right title="Editar" class="d-inline-block">
							<b-overlay
								:show="loaders.name.active && loaders.name.id == data.item.id"
								rounded
								opacity="0.6"
								spinner-small
								spinner-variant="primary"
								class="d-inline-block ml-2"
							>
								<b-dropdown
									size="xs"
									variant="light"
									no-caret
									@show="copyName(data.item)"
								>
									<template #button-content>
										<i class="fas fa-pen text-size-08 text-silenced"></i>
									</template>
									<template v-slot:default="{ hide }">
										<div class="px-2 m-0">
											<b-input
												v-model="loaders.name.new"
												style="width: 200px;"
												v-on:keyup.enter="updateName(data.item); hide()"
											></b-input>
											<b-button
												:variant="loaders.name.new == data.item.name ? 'secondary' : 'success'"
												:disabled="loaders.name.new == data.item.name"
												block
												size="sm"
												class="mt-2"
												@click="updateName(data.item); hide()"
											>Guardar Cambios</b-button>
										</div>
									</template>
								</b-dropdown>
							</b-overlay>
						</div>
					</div>
				</template>
				<template #cell()="data">
					<div :class="{ 'mt-1' : ui_size != 'sm'}">
						<span v-if="data.value">{{ data.value }}</span>
						<span v-else class="text-muted">&mdash;</span>
					</div>
				</template>
				<template #cell(privileges)="data">
					<b-button variant="primary" @click="manage(data)" block :size="ui_size == 'sm' ? 'xs' : ui_size == 'md' ? 'sm' : 'md'">
						<i class="fas fa-user-lock mr-1"></i>
						<span>Acceso</span>
					</b-button>
				</template>
			</b-table>

		</b-skeleton-wrapper>
		<!-- /DATA -->

		<CreateUser @update="reload"></CreateUser>
		<ManageUser @update="reload" :user="user"></ManageUser>

	</div>
</template>

<script>
//import Helpers from '@/helpers';
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
import SitePrimaryButton from '@/components/SitePrimaryButton.vue'
import CreateUser from '@/components/CreateUser.vue'
import ManageUser from '@/components/ManageUser.vue'
import CreateClient from '@/components/CreateClient.vue'

export default {
	name: 'UsersView',
	components: {
		SiteModuleHeader,
		SitePrimaryButton,
		CreateUser,
		ManageUser,
		CreateClient,
	},
	data() {
		return {
			reloading: false,
			query: {
				limit: 9999,
				page: 1,
				sortDesc: false,
				sortBy: 'name',
				search: null,
			},
			user: {},
			loaders: {
				name: {
					new: '',
					active: false,
					id: 0
				},
				email: {
					new: '',
					active: false,
					id: 0
				},
				password: {
					active: false,
					id: 0
				},
			},
			breadcrumb: {
				items: [
					{ text: 'Inicio', to: { name: 'Home' } },
					{ text: 'Sistema', active: true },
					{ text: 'Usuarios', active: true },
				],
			},
			filters: {
				sidebar: false,
			},
			paginationOptions: [
				{ value: 5, text: '5 por página' },
				{ value: 10, text: '10 por página' },
				{ value: 15, text: '15 por página' },
				{ value: 20, text: '20 por página' },
				{ value: 50, text: '50 por página' },
				{ value: 100, text: '100 por página' },
				{ value: 9999, text: 'Mostrar todo' },
			],
			tableFields: [
				{ key: 'name', label: 'Nombre', sortable: true, mobile: true },
				{ key: 'lastname', label: 'Apellido', sortable: true, mobile: true },
				{ key: 'enabled', label: 'Estado', sortable: true, mobile: true },
				{ key: 'privileges', label: 'Administrar', sortable: false, mobile: true },
				{ key: 'email', label: 'Correo Electrónico', sortable: true, mobile: true },
				{ key: 'last_access', label: 'Último Acceso', sortable: true, mobile: true },
			],
			current: {
				worker: {}
			}
		}
	},
	props: {
		/*
		name: {
			type: Object,
			required: false,
			default: {},
		}
		*/
	},
	created() {
		this.load()
		//window.addEventListener('event_name', this.function_name())
	},
	mounted() {
		this.$store.dispatch('helpers_profiles')
		//this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {})
	},
	destroyed() {
		//window.removeEventListener('event_name')
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		resources() { return this.$store.state.users },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
	},
	watch: {
		/*
		name(new_value, old_value) {
			this.current = new_value
			this.last = old_value
		}
		*/
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		load() {
			this.$store.dispatch('get_users', this.query).then(() => { this.reloading = false })
		},
		reload() {
			this.reloading = true
			this.load()
		},
		perPageChanged(limit) {
			this.query.limit = limit
			this.reload()
		},
		paginationChanged(page) {
			this.query.page = page
			this.reload()
		},
		sortingChanged(ctx) {
			this.query.sortDesc = ctx.sortDesc
			this.query.sortBy = ctx.sortBy
			this.reload()
		},
		search() {
			if (this.query.search == null) {
				this.query.search = ''
				//this.$refs.search.focus()
			}
			else if (this.query.search == '') {
				this.query.search = null
			}
			else if (this.query.search.length > 0) {
				this.reload()
			}
		},
		searchBlur() {
			if (this.query.search == '') {
				this.query.search = null
			}
		},
		searchClear() {
			if (this.query.search != null && this.query.search.length == 0) {
				this.query.page = 1
				this.reload()
			}
		},
		searchChanged(search) {
			this.query.search = search
		},
		create() {
			this.$store.dispatch('helpers_clients')
			this.$bvModal.show('create-user')
		},
        manage(user) {
			this.user = user
			this.$store.dispatch('get_privileges', user.item.id)
            this.$bvModal.show('manage-user')
        },
		copyName(resource) {
			this.loaders.name.new = resource.name
		},
		copyEmail(resource) {
			this.loaders.email.new = resource.email
		},
		updateName(resource) {
			this.loaders.name.active = true
			this.loaders.name.id = resource.id
			this.$api.put('users/'+resource.id+'/name', {
				name: this.loaders.name.new
			})
			.then(response => {
				for (let i = 0; i < this.resources.data.data.length; i++) {
					if (this.resources.data.data[i].id == resource.id) {
						this.resources.data.data[i].name = response.data.name
						this.resources.data.data[i].updated = response.data.updated
					}
				}
				this.toast(true, 'Se actualizó el nombre de '+response.data.name+'.')
				this.loaders.name = {
					new: '',
					active: false,
					id: 0,
				}
			})
			.catch(error => {
				this.toast(false, error.response.data.message, 10000)
				this.loaders.name = {
					new: '',
					active: false,
					id: 0,
				}
			})
		},
		updateEmail(resource) {
			this.loaders.email.active = true
			this.loaders.email.id = resource.id
			this.$api.put('users/'+resource.id+'/email', {
				email: this.loaders.email.new
			})
			.then(response => {
				for (let i = 0; i < this.resources.data.data.length; i++) {
					if (this.resources.data.data[i].id == resource.id) {
						this.resources.data.data[i].email = response.data.email
						this.resources.data.data[i].updated = response.data.updated
					}
				}
				this.toast(true, 'Se actualizó al e-mail de '+response.data.name+'.')
				this.loaders.email = {
					new: '',
					active: false,
					id: 0,
				}
			})
			.catch(error => {
				if (error.response.status === 409) {
					this.toast(false, 'El e-mail "'+this.loaders.email.new+'" ya se encuentra registrado.', 10000)
				}
				else {
					this.toast(false, error.response.data.message, 10000)
				}
				this.loaders.email = {
					new: '',
					active: false,
					id: 0,
				}
			})
		},
	}
}
</script>
