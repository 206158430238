<template>
	<div id="app" v-bind:class="{ 'body-dark' : ui_theme == 'dark' }">
		<SiteBackground></SiteBackground>
		<SiteNavbar></SiteNavbar>
		<SystemUpdateModal></SystemUpdateModal>
		<b-container
			:fluid="!fluid && session ? 'md' : '' "
			:class="session || this.$route.meta.multi == true ? 'px-4' : 'p-0 h-100'"
		>
			<router-view />
		</b-container>
		<SiteFooter v-if="session || this.$route.meta.multi == true"></SiteFooter>
	</div>
</template>

<script>
import io from 'socket.io-client'
import SiteBackground from '@/components/SiteBackground.vue'
import SiteNavbar from '@/components/SiteNavbar.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import SystemUpdateModal from '@/components/SystemUpdateModal.vue'
export default {
	name: 'IntranetV3',
	components: {
		SiteBackground,
		SiteNavbar,
		SiteFooter,
		SystemUpdateModal,
	},
	data: function() {
		return {
		}
	},
	created() {
		if (this.session) {
			this.$store.dispatch('session_check')
			.then(response => {
				if (response.data.account.profile.name == 'management') {
					//this.goTo('Home')
				}
				else if (response.data.account.profile.name == 'technician') {
					//this.goTo('HomeTechnichian')
				}
				else if (response.data.account.profile.name == 'client') {
					//this.goTo('HomeClient')
				}
			})
		}
		if (this.websocket.token != null) {
			var priv = io(this.websocketURL + '/priv', { auth: { token: this.websocket.token } });
			priv.on('connect', () => {
				this.websocket.online = true;
			})
			priv.on('event', data => {
				var number = data.object && data.object.number ? data.object.number : data.object.folio
				var user = data.user != null ? data.user.name +' '+ data.user.lastname : 'el sistema desde Defontana'
				this.toast(
					true,
					'Nuevo Evento',
					data.channel.noun_singular +' N°'+ number +' '+ data.type.verb_singular +' por '+ user +'.',
					5000
				)
			})
			priv.on('disconnect', () => {
				this.websocket.online = false;
			})
		}
		window.addEventListener('resize',
			this.debounce(() => {
				this.handleResize()
			}, 250)
		)
		this.handleResize()
		this.$store.dispatch('get_build_frontend')
		.then(response => {
			if (response.data > this.local.build) this.$bvModal.show('system-update-modal')
		})
	},
	destroyed() {
		window.removeEventListener('resize', this.handleResize)
	},
	mounted() {
	},
	computed: {
		websocket: {
			get() { return this.$store.getters.websocket; },
			set(data) { this.$store.commit('websocket', data); }
		},
		websocketURL() { return process.env.VUE_APP_WEBSOCKET },
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		getters() { return this.$store.getters },
		fluid: {
			get() { return this.$store.getters.fluid },
			set(data) { this.$store.commit('fluid', data) }
		},
		access: {
			get() { return this.$store.getters.access },
			set(data) { this.$store.commit('access', data) }
		},
		///////////////////////////////////////////////////////////////////////
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
		handleResize() {
			this.$store.commit('set_screen_size_width', window.innerWidth)
			this.$store.commit('set_screen_size_height', window.innerHeight)
		},
		debounce(callback, wait) {
			let timerId;
			return (...args) => {
				clearTimeout(timerId);
				timerId = setTimeout(() => {
					callback(...args);
				}, wait);
			};
		},
		toast(success, title, message, delay) {
			this.$bvToast.toast(message, {
				title: title,
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
	}
}
</script>
