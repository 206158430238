<template>
	<div class="mt-3">

		<SiteModuleHeader
			:showBack="true"
			:showHome="true"
			:showSize="true"
			:showTheme="true"
			:showFluid="true"
			:showTitle="true"
			@reload="reload"
			:reloading="reloading || resources.loading"
		></SiteModuleHeader>

		<!-- TOOLBAR -->
		<b-row no-gutters class="mb-2">

			<!-- SEARCH -->
			<b-col md="sm" lg="auto" xl="auto">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Buscar</span>
					<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
				</p>
				<b-row no-gutters>

					<!-- SEARCH INPUT -->
					<b-col sm="auto">
						<b-overlay
							:show="resources.loading"
							rounded
							opacity="0.6"
						>
							<template #overlay>
								<span>&nbsp;</span>
							</template>
							<b-input
								:size="ui_size"
								v-model="query.search"
								placeholder="Escriba aquí"
							></b-input>
						</b-overlay>
					</b-col>
				</b-row>
			</b-col>

			<!-- SPACER -->
			<b-col>
			</b-col>
		</b-row>


		<!-- DATA -->
		<b-skeleton-wrapper :loading="resources.loading && !reloading">

			<!-- LOADING -->
			<template #loading>
				<b-card no-body>
					<b-skeleton-table
						animation="fade"
						:rows="10"
						:columns="7"
					></b-skeleton-table>
				</b-card>
			</template>

			<b-table
				:items="resources.data.data"
				:filter="query.search"
				:fields="fields"
				hover
				bordered
				rounded


				:busy="resources.loading"
				show-empty
				:stacked="mobile"
				:small="ui_size == 'sm' || false"
				responsive
				sort-icon-left
				:head-variant="ui_theme == 'light' ? 'light' : 'dark'"
				:table-variant="ui_theme == 'light' ? 'light' : 'dark'"
				class="mt-0 mb-0 rounded"
				:class="{
					'fs-09' : ui_size == 'sm',
					'fs-10' : ui_size == 'md',
					'fs-13' : ui_size == 'lg',
					'table-dark' : ui_theme == 'dark',
				}"




			>
				<template #cell(billable)="data">
					<div class="text-center">
						<span v-if="data.value">Sí</span>
						<span v-else>No</span>
					</div>
				</template>
				<template #cell(level)="data">
					<div class="text-center">
						<span v-if="data.value">{{ data.value }}</span>
					</div>
				</template>
			</b-table>

		</b-skeleton-wrapper>

	</div>
</template>

<script>
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
export default {
	name: 'CostCentersView',
	components: {
		SiteModuleHeader,
	},
	data: function() {
		return {
			reloading: false,
			query: {
				limit: 9999,
				page: 1,
				sortDesc: false,
				sortBy: 'code',
				search: null,
			},
			fields: [
				{ key: 'level', label: 'Nivel', sortable: true },
				{ key: 'billable', label: 'Imputable', sortable: true },
				{ key: 'format_code', label: 'Código', sortable: true },
				{ key: 'description', label: 'Nombre', sortable: true },
			]
		}
	},
	created() {
		this.load()
	},
	mounted() {
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		getters() { return this.$store.getters },
		resources() { return this.$store.state.defontana_cost_centers },
		//////-----------------------------------------------------------
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
		load() {
			this.$store.dispatch('defontana_cost_centers', this.query).then(() => { this.reloading = false })
		},
		reload() {
			this.reloading = true
			this.load()
		},
		perPageChanged(limit) {
			this.query.limit = limit
			this.reload()
		},
		paginationChanged(page) {
			this.query.page = page
			this.reload()
		},
		sortingChanged(ctx) {
			this.query.sortDesc = ctx.sortDesc
			this.query.sortBy = ctx.sortBy
			this.reload()
		},
		expandGroup(level, index) {
			if (level == 1) {
				this.collapses.level1[index] = true
			} else {
				return false
			}
		}
	}
}
</script>
