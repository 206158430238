var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('SiteModuleHeader',{attrs:{"showBack":true,"showHome":true,"showSize":true,"showTheme":true,"showFluid":true,"showTitle":true,"reloading":_vm.reloading || _vm.resources.loading},on:{"reload":_vm.reload}}),_c('b-row',{staticClass:"mb-2",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"md":"sm","lg":"auto","xl":"auto"}},[_c('p',{staticClass:"mb-2 text-dark",class:{
					'fs-09' : _vm.ui_size == 'sm',
					'fs-10' : _vm.ui_size == 'md',
					'fs-13' : _vm.ui_size == 'lg',
				}},[_c('span',[_vm._v("Buscar")]),_c('i',{staticClass:"fas fa-arrow-turn-down ml-2 text-muted"})]),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"sm":"auto"}},[_c('b-overlay',{attrs:{"show":_vm.resources.loading,"rounded":"","opacity":"0.6"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('span',[_vm._v(" ")])]},proxy:true}])},[_c('b-input',{attrs:{"size":_vm.ui_size,"placeholder":"Escriba aquí"},model:{value:(_vm.query.search),callback:function ($$v) {_vm.$set(_vm.query, "search", $$v)},expression:"query.search"}})],1)],1)],1)],1),_c('b-col')],1),_c('b-skeleton-wrapper',{attrs:{"loading":_vm.resources.loading && !_vm.reloading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-card',{attrs:{"no-body":""}},[_c('b-skeleton-table',{attrs:{"animation":"fade","rows":10,"columns":7}})],1)]},proxy:true}])},[_c('b-table',{staticClass:"mt-0 mb-0 rounded",class:{
				'fs-09' : _vm.ui_size == 'sm',
				'fs-10' : _vm.ui_size == 'md',
				'fs-13' : _vm.ui_size == 'lg',
				'table-dark' : _vm.ui_theme == 'dark',
			},attrs:{"items":_vm.resources.data.data,"filter":_vm.query.search,"fields":_vm.fields,"hover":"","bordered":"","rounded":"","busy":_vm.resources.loading,"show-empty":"","stacked":_vm.mobile,"small":_vm.ui_size == 'sm' || false,"responsive":"","sort-icon-left":"","head-variant":_vm.ui_theme == 'light' ? 'light' : 'dark',"table-variant":_vm.ui_theme == 'light' ? 'light' : 'dark'},scopedSlots:_vm._u([{key:"cell(billable)",fn:function(data){return [_c('div',{staticClass:"text-center"},[(data.value)?_c('span',[_vm._v("Sí")]):_c('span',[_vm._v("No")])])]}},{key:"cell(level)",fn:function(data){return [_c('div',{staticClass:"text-center"},[(data.value)?_c('span',[_vm._v(_vm._s(data.value))]):_vm._e()])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }