var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":"create-model","title":"Nuevo Modelo","size":_vm.ui_size == 'sm' ? 'lg' : 'lg',"body-class":"p-0","title-class":{
			'fs-11' : _vm.ui_size == 'sm',
			'fs-13' : _vm.ui_size == 'md',
			'fs-15' : _vm.ui_size == 'lg',
		},"header-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"header-text-variant":_vm.ui_theme == 'dark' ? 'light' : '',"body-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"body-text-variant":_vm.ui_theme == 'dark' ? 'light' : '',"footer-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"footer-text-variant":_vm.ui_theme == 'dark' ? 'light' : ''},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ close }){return [_c('b-button',{attrs:{"variant":"outline-secondary","disabled":_vm.loading,"size":_vm.ui_size},on:{"click":function($event){return close()}}},[_vm._v("Cancelar")]),_c('b-button',{attrs:{"variant":"success","disabled":_vm.loading||!_vm.val_full,"size":_vm.ui_size},on:{"click":function($event){return _vm.create()}}},[_vm._v("CREAR")])]}}])},[_c('b-overlay',{staticClass:"p-4",attrs:{"show":_vm.loading,"rounded":"sm","variant":_vm.ui_theme == 'dark' ? 'dark': 'light'},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center py-5"},[_c('b-spinner',{attrs:{"type":"grow","variant":"primary"}})],1)]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('b-row',[_c('b-col',[_vm._v(" Marca ")]),_c('b-col',{attrs:{"sm":"auto"}},[_c('b-button',{staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-success'},on:{"click":function($event){return _vm.$bvModal.show('create-brand')}}},[_c('i',{staticClass:"fas fa-plus mr-1"}),_c('span',[_vm._v("Nueva Marca")])])],1)],1)]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.brands.loading,"state":_vm.val_brand,"size":_vm.ui_size},on:{"change":function($event){return _vm.reloadBrandModels()}},model:{value:(_vm.model.brand_id),callback:function ($$v) {_vm.$set(_vm.model, "brand_id", $$v)},expression:"model.brand_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Presione aquí")]),_vm._l((_vm.brands.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('b-row',[_c('b-col',[_vm._v(" Tipo ")])],1)]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.model_types.loading,"state":_vm.val_type,"size":_vm.ui_size},model:{value:(_vm.model.type_id),callback:function ($$v) {_vm.$set(_vm.model, "type_id", $$v)},expression:"model.type_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Presione aquí")]),_vm._l((_vm.model_types.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nombre ")]},proxy:true}])},[_c('b-input',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"state":_vm.val_part_number,"size":_vm.ui_size},model:{value:(_vm.model.part_number),callback:function ($$v) {_vm.$set(_vm.model, "part_number", $$v)},expression:"model.part_number"}}),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{staticClass:"mb-4",attrs:{"sm":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-table',{staticClass:"mb-0",attrs:{"items":_vm.brand_models.data,"fields":_vm.fields,"per-page":5,"small":""}})],1)],1)],1),_c('b-alert',{staticClass:"mb-0",class:{
					'fs-09' : _vm.ui_size == 'sm',
					'fs-10' : _vm.ui_size == 'md',
					'fs-12' : _vm.ui_size == 'lg',
				},attrs:{"show":"","variant":_vm.val_full ? 'success' : 'danger'}},[_c('p',{staticClass:"mb-0 text-size-10"},[_c('i',{staticClass:"fas fa-exclamation-circle mr-2"}),_c('span',[_vm._v(_vm._s(_vm.val_full ? 'Para continuar, presione el botón CREAR.' : 'Complete los campos requeridos para continuar.'))])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }