import api from '@/api'

export default {

	login(context, credentials) {
		context.state.core.loading = true
		return new Promise((resolve, reject) => {
			api.post('login', {
				username: credentials.username,
				password: credentials.password,
			})
			.then(response => {
				api.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.session.access_token
				context.commit('save_websocket_token', response.data.websocket_token)
				context.commit('save_account', response.data.account)
				context.commit('save_session', response.data.session)
				context.state.core.loading = false
				resolve(response)
			})
			.catch(error => {
				context.state.core.loading = false
				reject(error)
			})
		})
	},

	session_check(context) {
		context.state.core.loading = true
		api.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.local.session.access_token
		return new Promise((resolve, reject) => {
			api.get('refresh')
			.then(response => {
				context.commit('save_websocket_token', response.data.websocket_token)
				context.commit('save_account', response.data.account)
				context.state.core.loading = false
				resolve(response)
			})
			.catch(error => {
				context.state.core.loading = false
				reject(error)
			})
		})
	},

	logout(context) {
		return new Promise((resolve, reject) => {
			context.commit('destroy_session')
			api.get('logout')
			.then(response => {
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},

	recover(context, username) {
		return new Promise((resolve, reject) => {
			api.post('recover', {
				username: username
			})
			.then(response => {
				context.commit('destroy_session')
				resolve(response)
			})
			.catch(error => {
				context.commit('destroy_session')
				reject(error)
			})
		})
	},

	direct(context, username) {
		return new Promise((resolve, reject) => {
			api.post('direct', {
				username: username
			})
			.then(response => {
				context.commit('destroy_session')
				resolve(response)
			})
			.catch(error => {
				context.commit('destroy_session')
				reject(error)
			})
		})
	},

	sms(context, phone) {
		return new Promise((resolve, reject) => {
			api.post('sms', {
				phone: phone
			})
			.then(response => {
				context.commit('destroy_session')
				resolve(response)
			})
			.catch(error => {
				context.commit('destroy_session')
				reject(error)
			})
		})
	},

	validate_reset(context, token) {
		return new Promise((resolve, reject) => {
			api.get('reset/'+ token)
			.then(response => {
				context.commit('destroy_session')
				resolve(response)
			})
			.catch(error => {
				context.commit('destroy_session')
				reject(error)
			})
		})
	},

	validate_direct(context, token) {
		context.state.core.loading = true
		return new Promise((resolve, reject) => {
			api.get('direct/'+ token)
			.then(response => {
				context.state.core.loading = false
				api.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.session.access_token
				context.commit('save_websocket_token', response.data.websocket_token)
				context.commit('save_account', response.data.account)
				context.commit('save_session', response.data.session)
				resolve(response)
			})
			.catch(error => {
				context.state.core.loading = false
				context.commit('destroy_session')
				reject(error)
			})
		})
	},

	validate_sms(context, data) {
		context.state.core.loading = true
		return new Promise((resolve, reject) => {
			api.post('sms/validate', data)
			.then(response => {
				context.state.core.loading = false
				api.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.session.access_token
				context.commit('save_websocket_token', response.data.websocket_token)
				context.commit('save_account', response.data.account)
				context.commit('save_session', response.data.session)
				resolve(response)
			})
			.catch(error => {
				context.state.core.loading = false
				context.commit('destroy_session')
				reject(error)
			})
		})
	},

	reset(context, data) {
		return new Promise((resolve, reject) => {
			api.post('reset', {
				token: data.token,
				password: data.password
			})
			.then(response => {
				context.commit('destroy_session')
				resolve(response)
			})
			.catch(error => {
				context.commit('destroy_session')
				reject(error)
			})
		})
	},
	
	get_build_frontend(context) {
		return new Promise((resolve, reject) => {
			api.get('public/build_frontend')
			.then(response => {
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},

	get_regions(context) {
		context.state.regions.loading = true
		return new Promise((resolve, reject) => {
			api.get('regions')
			.then(response => {
				context.state.regions.loading = false
				context.commit('set_regions', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.regions.loading = false
				reject(error)
			})
		})
	},

	get_communes(context) {
		context.state.communes.loading = true
		return new Promise((resolve, reject) => {
			api.get('communes')
			.then(response => {
				context.state.communes.loading = false
				context.commit('set_communes', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.communes.loading = false
				reject(error)
			})
		})
	},

	get_privileges(context, user_id) {
		context.state.privileges = { loading: true, data: { user: [], available: [], enabled: [] } }
		return new Promise((resolve, reject) => {
			api.get('users/'+user_id+'/privileges')
			.then(response => {
				context.commit('set_privileges', response.data)
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},

	get_system_parameters(context) {
		context.state.system_parameters.loading = true
		//context.state.system.parameters.data = []
		return new Promise((resolve, reject) => {
			api.get('system_parameters')
			.then(response => {
				context.commit('set_system_parameters', response.data)
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},

	get_users(context, query) {
		context.state.users.loading = true
		return new Promise((resolve, reject) => {
			api.get('users', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
				}
			})
			.then(response => {
				context.state.users.loading = false
				context.commit('set_users', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.users.loading = false
				context.commit('set_users', [])
				reject(error)
			})
		})
	},




	communes(context, query) {
		context.state.communes.loading = true
		return new Promise((resolve, reject) => {
			api.get('communes')
			.then(response => {
				context.state.communes.loading = false
				context.commit('communes', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.communes.loading = false
				context.commit('communes', [])
				reject(error)
			})
		})
	},

	hvac_panel(context, data) {
		context.state.hvac_panel.loading = true
		return new Promise((resolve, reject) => {
			var api_uri = ''
			if (data.client_id != null && data.site_id != null) {
				api_uri = 'hvac/panel/'+data.client_id+'/'+data.site_id
			} else if (data.client_id != null) {
				api_uri = 'hvac/panel/'+data.client_id
			} else {
				api_uri = 'hvac/panel'
			}
			api.get(api_uri)
			.then(response => {
				context.state.hvac_panel.loading = false
				context.commit('hvac_panel', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.hvac_panel.loading = false
				context.commit('hvac_panel', [])
				reject(error)
			})
		})
	},

	contracts(context, query) {
		context.state.contracts.loading = true
		return new Promise((resolve, reject) => {
			api.get('contracts', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					type_id: query ? query.type_id : null,
				}
			})
			.then(response => {
				context.state.contracts.loading = false
				context.commit('contracts', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.contracts.loading = false
				context.commit('contracts', [])
				reject(error)
			})
		})
	},

	dashboard_home(context, query) {
		context.state.dashboard_home.loading = true
		return new Promise((resolve, reject) => {
			api.get('dashboard/home', {
				params: {
					test: query ? query.test : null,
				}
			})
			.then(response => {
				context.state.dashboard_home.loading = false
				context.commit('dashboard_home', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.dashboard_home.loading = false
				context.commit('dashboard_home', [])
				reject(error)
			})
		})
	},

	teamviewer_devices(context, query) {
		context.state.teamviewer_devices.loading = true
		return new Promise((resolve, reject) => {
			api.get('teamviewer/devices', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					type_id: query ? query.type_id : null,
				}
			})
			.then(response => {
				context.state.teamviewer_devices.loading = false
				context.commit('teamviewer_devices', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.teamviewer_devices.loading = false
				context.commit('teamviewer_devices', [])
				reject(error)
			})
		})
	},

	defontana_employees(context, query) {
		context.state.defontana_employees.loading = true
		return new Promise((resolve, reject) => {
			api.get('defontana/employees', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					type_id: query ? query.type_id : null,
				}
			})
			.then(response => {
				context.state.defontana_employees.loading = false
				context.commit('defontana_employees', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.defontana_employees.loading = false
				context.commit('defontana_employees', [])
				reject(error)
			})
		})
	},

	defontana_purchase_orders(context, query) {
		context.state.defontana_purchase_orders.loading = true
		return new Promise((resolve, reject) => {
			api.get('defontana/purchase_orders', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					type_id: query ? query.type_id : null,
				}
			})
			.then(response => {
				context.state.defontana_purchase_orders.loading = false
				context.commit('defontana_purchase_orders', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.defontana_purchase_orders.loading = false
				context.commit('defontana_purchase_orders', [])
				reject(error)
			})
		})
	},

	purchase_requests(context, query) {
		context.state.purchase_requests.loading = true
		return new Promise((resolve, reject) => {
			api.get('purchase_requests', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					type_id: query ? query.type_id : null,
				}
			})
			.then(response => {
				context.state.purchase_requests.loading = false
				context.commit('purchase_requests', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.purchase_requests.loading = false
				context.commit('purchase_requests', [])
				reject(error)
			})
		})
	},

	purchase_request(context, id) {
		context.state.purchase_request.loading = true
		return new Promise((resolve, reject) => {
			api.get('purchase_requests/'+id)
			.then(response => {
				context.state.purchase_request.loading = false
				context.commit('purchase_request', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.purchase_request.loading = false
				context.commit('purchase_request', {})
				reject(error)
			})
		})
	},

	clients(context, query) {
		context.state.clients.loading = true
		return new Promise((resolve, reject) => {
			api.get('clients', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					type_id: query ? query.type_id : null,
				}
			})
			.then(response => {
				context.state.clients.loading = false
				context.commit('clients', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.clients.loading = false
				context.commit('clients', [])
				reject(error)
			})
		})
	},

	warehouses(context, query) {
		context.state.warehouses.loading = true
		return new Promise((resolve, reject) => {
			api.get('warehouses', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					type_id: query ? query.type_id : null,
				}
			})
			.then(response => {
				context.state.warehouses.loading = false
				context.commit('warehouses', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.warehouses.loading = false
				context.commit('warehouses', [])
				reject(error)
			})
		})
	},

	stock_requirements(context, query) {
		context.state.stock_requirements.loading = true
		return new Promise((resolve, reject) => {
			api.get('stock_requirements', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					hvac_client_id: query ? query.hvac_client_id : null,
				}
			})
			.then(response => {
				context.state.stock_requirements.loading = false
				context.commit('stock_requirements', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.stock_requirements.loading = false
				context.commit('stock_requirements', [])
				reject(error)
			})
		})
	},

	defontana_sales(context, query) {
		context.state.defontana_sales.loading = true
		return new Promise((resolve, reject) => {
			api.get('defontana/sales', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					type_id: query ? query.type_id : null,
				}
			})
			.then(response => {
				context.state.defontana_sales.loading = false
				context.commit('defontana_sales', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.defontana_sales.loading = false
				context.commit('defontana_sales', [])
				reject(error)
			})
		})
	},

	defontana_sale(context, sale_id) {
		context.state.defontana_sale.loading = true
		return new Promise((resolve, reject) => {
			api.get('defontana/sales/'+sale_id)
			.then(response => {
				context.state.defontana_sale.loading = false
				context.commit('defontana_sale', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.defontana_sale.loading = false
				context.commit('defontana_sale', [])
				reject(error)
			})
		})
	},

	defontana_pricings(context, query) {
		context.state.defontana_pricings.loading = true
		return new Promise((resolve, reject) => {
			api.get('defontana/pricings', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					type_id: query ? query.type_id : null,
				}
			})
			.then(response => {
				context.state.defontana_pricings.loading = false
				context.commit('defontana_pricings', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.defontana_pricings.loading = false
				context.commit('defontana_pricings', [])
				reject(error)
			})
		})
	},

	defontana_orders(context, query) {
		context.state.defontana_orders.loading = true
		return new Promise((resolve, reject) => {
			api.get('defontana/orders', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					type_id: query ? query.type_id : null,
				}
			})
			.then(response => {
				context.state.defontana_orders.loading = false
				context.commit('defontana_orders', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.defontana_orders.loading = false
				context.commit('defontana_orders', [])
				reject(error)
			})
		})
	},

	defontana_pricing(context, pricing_id) {
		context.state.defontana_pricing.loading = true
		return new Promise((resolve, reject) => {
			api.get('defontana/pricings/'+pricing_id)
			.then(response => {
				context.state.defontana_pricing.loading = false
				context.commit('defontana_pricing', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.defontana_pricing.loading = false
				context.commit('defontana_pricing', [])
				reject(error)
			})
		})
	},

	defontana_services(context, query) {
		context.state.defontana_services.loading = true
		return new Promise((resolve, reject) => {
			api.get('defontana/services', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					type_id: query ? query.type_id : null,
				}
			})
			.then(response => {
				context.state.defontana_services.loading = false
				context.commit('defontana_services', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.defontana_services.loading = false
				context.commit('defontana_services', [])
				reject(error)
			})
		})
	},

	defontana_products(context, query) {
		context.state.defontana_products.loading = true
		return new Promise((resolve, reject) => {
			api.get('defontana/products', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					type: query ? query.type : null,
				}
			})
			.then(response => {
				context.state.defontana_products.loading = false
				context.commit('defontana_products', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.defontana_products.loading = false
				context.commit('defontana_products', [])
				reject(error)
			})
		})
	},

	products(context, query) {
		context.state.products.loading = true
		return new Promise((resolve, reject) => {
			api.get('products', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					type: query ? query.type : null,
				}
			})
			.then(response => {
				context.state.products.loading = false
				context.commit('products', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.products.loading = false
				context.commit('products', [])
				reject(error)
			})
		})
	},

	defontana_cost_centers(context, query) {
		context.state.defontana_cost_centers.loading = true
		return new Promise((resolve, reject) => {
			api.get('defontana/cost_centers', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					type_id: query ? query.type_id : null,
				}
			})
			.then(response => {
				context.state.defontana_cost_centers.loading = false
				context.commit('defontana_cost_centers', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.defontana_cost_centers.loading = false
				context.commit('defontana_cost_centers', [])
				reject(error)
			})
		})
	},

	client_contacts(context, client_id) {
		context.state.client_contacts.loading = true
		return new Promise((resolve, reject) => {
			api.get('clients/'+client_id+'/contacts')
			.then(response => {
				context.state.client_contacts.loading = false
				context.commit('client_contacts', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.client_contacts.loading = false
				context.commit('client_contacts', [])
				reject(error)
			})
		})
	},

	request_logs(context, query) {
		context.state.request_logs.loading = true
		return new Promise((resolve, reject) => {
			api.get('request_logs', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
				}
			})
			.then(response => {
				context.state.request_logs.loading = false
				context.commit('request_logs', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.request_logs.loading = false
				context.commit('request_logs', [])
				reject(error)
			})
		})
	},

	corporate_templates(context) {
		context.state.corporate_templates.loading = true
		return new Promise((resolve, reject) => {
			api.get('corporate_templates')
			.then(response => {
				context.state.corporate_templates.loading = false
				context.commit('corporate_templates', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.corporate_templates.loading = false
				context.commit('corporate_templates', [])
				reject(error)
			})
		})
	},

	corporate_template_groups(context) {
		context.state.corporate_template_groups.loading = true
		return new Promise((resolve, reject) => {
			api.get('corporate_template_groups')
			.then(response => {
				context.state.corporate_template_groups.loading = false
				context.commit('corporate_template_groups', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.corporate_template_groups.loading = false
				context.commit('corporate_template_groups', [])
				reject(error)
			})
		})
	},

	client_contracts(context, client_id) {
		context.state.client_contracts.loading = true
		return new Promise((resolve, reject) => {
			api.get('clients/'+client_id+'/contracts')
			.then(response => {
				context.state.client_contracts.loading = false
				context.commit('client_contracts', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.client_contracts.loading = false
				context.commit('client_contracts', [])
				reject(error)
			})
		})
	},

	client_units(context, client_id) {
		context.state.client_units.loading = true
		return new Promise((resolve, reject) => {
			api.get('clients/'+client_id+'/units')
			.then(response => {
				context.state.client_units.loading = false
				context.commit('client_units', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.client_units.loading = false
				context.commit('client_units', [])
				reject(error)
			})
		})
	},

	client_sites(context, client_id) {
		context.state.client_sites.loading = true
		return new Promise((resolve, reject) => {
			api.get('clients/'+client_id+'/sites')
			.then(response => {
				context.state.client_sites.loading = false
				context.commit('client_sites', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.client_sites.loading = false
				context.commit('client_sites', [])
				reject(error)
			})
		})
	},

	client_areas(context, client_id) {
		context.state.client_areas.loading = true
		return new Promise((resolve, reject) => {
			api.get('clients/'+client_id+'/areas')
			.then(response => {
				context.state.client_areas.loading = false
				context.commit('client_areas', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.client_areas.loading = false
				context.commit('client_areas', [])
				reject(error)
			})
		})
	},

	client_contract_areas(context, client_id) {
		context.state.client_contract_areas.loading = true
		return new Promise((resolve, reject) => {
			api.get('clients/'+client_id+'/contract_areas')
			.then(response => {
				context.state.client_contract_areas.loading = false
				context.commit('client_contract_areas', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.client_contract_areas.loading = false
				context.commit('client_contract_areas', [])
				reject(error)
			})
		})
	},

	client_time_switches(context, client_id) {
		context.state.client_time_switches.loading = true
		return new Promise((resolve, reject) => {
			api.get('clients/'+client_id+'/time_switches')
			.then(response => {
				context.state.client_time_switches.loading = false
				context.commit('client_time_switches', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.client_time_switches.loading = false
				context.commit('client_time_switches', [])
				reject(error)
			})
		})
	},

	client_temperature_controls(context, client_id) {
		context.state.client_temperature_controls.loading = true
		return new Promise((resolve, reject) => {
			api.get('clients/'+client_id+'/temperature_controls')
			.then(response => {
				context.state.client_temperature_controls.loading = false
				context.commit('client_temperature_controls', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.client_temperature_controls.loading = false
				context.commit('client_temperature_controls', [])
				reject(error)
			})
		})
	},

	site_units(context, site_id) {
		context.state.site_units.loading = true
		return new Promise((resolve, reject) => {
			api.get('sites/'+site_id+'/units')
			.then(response => {
				context.state.site_units.loading = false
				context.commit('site_units', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.site_units.loading = false
				context.commit('site_units', [])
				reject(error)
			})
		})
	},

	hvac_coolants(context, query) {
		context.state.hvac_coolants.loading = true
		return new Promise((resolve, reject) => {
			api.get('hvac/coolants', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
				}
			})
			.then(response => {
				context.state.hvac_coolants.loading = false
				context.commit('hvac_coolants', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.hvac_coolants.loading = false
				context.commit('hvac_coolants', [])
				reject(error)
			})
		})
	},

	brands(context, query) {
		context.state.brands.loading = true
		return new Promise((resolve, reject) => {
			api.get('brands', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
				}
			})
			.then(response => {
				context.state.brands.loading = false
				context.commit('brands', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.brands.loading = false
				context.commit('brands', [])
				reject(error)
			})
		})
	},

	brand_models(context, brand_id) {
		context.state.brand_models.loading = true
		return new Promise((resolve, reject) => {
			api.get('brands/'+brand_id+'/models')
			.then(response => {
				context.state.brand_models.loading = false
				context.commit('brand_models', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.brand_models.loading = false
				context.commit('brand_models', [])
				reject(error)
			})
		})
	},

	download_token(context, uuid) {
		context.state.download_token = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('files/'+ uuid + '/token')
			.then(response => {
				context.state.download_token.loading = false
				context.commit('download_token', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.download_token.loading = false
				context.commit('download_token', [])
				reject(error)
			})
		})
	},

	models(context, query) {
		context.state.models.loading = true
		return new Promise((resolve, reject) => {
			api.get('models', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
				}
			})
			.then(response => {
				context.state.models.loading = false
				context.commit('models', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.models.loading = false
				context.commit('models', [])
				reject(error)
			})
		})
	},

	model_types(context, query) {
		context.state.model_types.loading = true
		return new Promise((resolve, reject) => {
			api.get('model_types', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
				}
			})
			.then(response => {
				context.state.model_types.loading = false
				context.commit('model_types', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.model_types.loading = false
				context.commit('model_types', [])
				reject(error)
			})
		})
	},

	units(context, query) {
		context.state.units.loading = true
		return new Promise((resolve, reject) => {
			api.get('units', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					client_id: query ? query.client_id : null,
				}
			})
			.then(response => {
				context.state.units.loading = false
				context.commit('units', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.units.loading = false
				context.commit('units', [])
				reject(error)
			})
		})
	},

	unit(context, unit_id) {
		context.state.unit.loading = true
		return new Promise((resolve, reject) => {
			api.get('units/'+unit_id)
			.then(response => {
				context.state.unit.loading = false
				context.commit('unit', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.unit.loading = false
				context.commit('unit', [])
				reject(error)
			})
		})
	},

	jobs(context, query) {
		context.state.jobs.loading = true
		return new Promise((resolve, reject) => {
			api.get('jobs', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					client_id: query ? query.client_id : null,
				}
			})
			.then(response => {
				context.state.jobs.loading = false
				context.commit('jobs', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.jobs.loading = false
				context.commit('jobs', [])
				reject(error)
			})
		})
	},

	job(context, job_id) {
		context.state.job.loading = true
		return new Promise((resolve, reject) => {
			api.get('jobs/'+job_id)
			.then(response => {
				context.state.job.loading = false
				context.commit('job', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.job.loading = false
				context.commit('job', [])
				reject(error)
			})
		})
	},

	helpers_measurement_units(context) {
		context.state.helpers.measurement_units.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/measurement_units')
			.then(response => {
				context.state.helpers.measurement_units.loading = false
				context.commit('helpers_measurement_units', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.measurement_units.loading = false
				context.commit('helpers_measurement_units', [])
				reject(error)
			})
		})
	},

	helpers_warehouses(context) {
		context.state.helpers.warehouses.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/warehouses')
			.then(response => {
				context.state.helpers.warehouses.loading = false
				context.commit('helpers_warehouses', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.warehouses.loading = false
				context.commit('helpers_warehouses', [])
				reject(error)
			})
		})
	},

	helpers_warehouses_movement_types(context) {
		context.state.helpers.warehouses_movement_types.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/warehouses/movement_types')
			.then(response => {
				context.state.helpers.warehouses_movement_types.loading = false
				context.commit('helpers_warehouses_movement_types', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.warehouses_movement_types.loading = false
				context.commit('helpers_warehouses_movement_types', [])
				reject(error)
			})
		})
	},

	helpers_profiles(context) {
		context.state.helpers.profiles.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/profiles')
			.then(response => {
				context.state.helpers.profiles.loading = false
				context.commit('helpers_profiles', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.profiles.loading = false
				context.commit('helpers_profiles', [])
				reject(error)
			})
		})
	},

	helpers_clients(context, query) {
		context.state.helpers.clients.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/clients')
			.then(response => {
				context.state.helpers.clients.loading = false
				context.commit('helpers_clients', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.clients.loading = false
				context.commit('helpers_clients', [])
				reject(error)
			})
		})
	},

	helpers_job_types(context, query) {
		context.state.helpers.job_types.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/jobs/types')
			.then(response => {
				context.state.helpers.job_types.loading = false
				context.commit('helpers_job_types', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.job_types.loading = false
				context.commit('helpers_job_types', [])
				reject(error)
			})
		})
	},

	helpers_unit_conditions(context, query) {
		context.state.helpers.unit_conditions.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/units/conditions')
			.then(response => {
				context.state.helpers.unit_conditions.loading = false
				context.commit('helpers_unit_conditions', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.unit_conditions.loading = false
				context.commit('helpers_unit_conditions', [])
				reject(error)
			})
		})
	},

	helpers_unit_priorities(context, query) {
		context.state.helpers.unit_priorities.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/units/priorities')
			.then(response => {
				context.state.helpers.unit_priorities.loading = false
				context.commit('helpers_unit_priorities', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.unit_priorities.loading = false
				context.commit('helpers_unit_priorities', [])
				reject(error)
			})
		})
	},

	helpers_unit_coolants(context, query) {
		context.state.helpers.unit_coolants.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/units/coolants')
			.then(response => {
				context.state.helpers.unit_coolants.loading = false
				context.commit('helpers_unit_coolants', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.unit_coolants.loading = false
				context.commit('helpers_unit_coolants', [])
				reject(error)
			})
		})
	},

	helpers_unit_voltages(context, query) {
		context.state.helpers.unit_voltages.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/units/voltages')
			.then(response => {
				context.state.helpers.unit_voltages.loading = false
				context.commit('helpers_unit_voltages', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.unit_voltages.loading = false
				context.commit('helpers_unit_voltages', [])
				reject(error)
			})
		})
	},

	helpers_unit_capacity_measurement_units(context, query) {
		context.state.helpers.unit_capacity_measurement_units.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/units/capacity_measurement_units')
			.then(response => {
				context.state.helpers.unit_capacity_measurement_units.loading = false
				context.commit('helpers_unit_capacity_measurement_units', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.unit_capacity_measurement_units.loading = false
				context.commit('helpers_unit_capacity_measurement_units', [])
				reject(error)
			})
		})
	},

	helpers_unit_maintenance_windows(context, query) {
		context.state.helpers.unit_maintenance_windows.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/units/maintenance_windows')
			.then(response => {
				context.state.helpers.unit_maintenance_windows.loading = false
				context.commit('helpers_unit_maintenance_windows', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.unit_maintenance_windows.loading = false
				context.commit('helpers_unit_maintenance_windows', [])
				reject(error)
			})
		})
	},

	helpers_defontana_purchase_orders(context, query) {
		context.state.helpers.defontana_purchase_orders.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/defontana/purchase_orders')
			.then(response => {
				context.state.helpers.defontana_purchase_orders.loading = false
				context.commit('helpers_defontana_purchase_orders', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.defontana_purchase_orders.loading = false
				context.commit('helpers_defontana_purchase_orders', [])
				reject(error)
			})
		})
	},

	helpers_defontana_clients(context, query) {
		context.state.helpers.defontana_clients.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/defontana/clients')
			.then(response => {
				context.state.helpers.defontana_clients.loading = false
				context.commit('helpers_defontana_clients', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.defontana_clients.loading = false
				context.commit('helpers_defontana_clients', [])
				reject(error)
			})
		})
	},

	helpers_defontana_payment_conditions(context, query) {
		context.state.helpers.defontana_payment_conditions.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/defontana/payment_conditions')
			.then(response => {
				context.state.helpers.defontana_payment_conditions.loading = false
				context.commit('helpers_defontana_payment_conditions', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.defontana_payment_conditions.loading = false
				context.commit('helpers_defontana_payment_conditions', [])
				reject(error)
			})
		})
	},

	helpers_defontana_cost_centers(context, query) {
		context.state.helpers.defontana_cost_centers.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/defontana/cost_centers')
			.then(response => {
				context.state.helpers.defontana_cost_centers.loading = false
				context.commit('helpers_defontana_cost_centers', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.defontana_cost_centers.loading = false
				context.commit('helpers_defontana_cost_centers', [])
				reject(error)
			})
		})
	},

	helpers_defontana_sellers(context, query) {
		context.state.helpers.defontana_sellers.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/defontana/sellers')
			.then(response => {
				context.state.helpers.defontana_sellers.loading = false
				context.commit('helpers_defontana_sellers', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.defontana_sellers.loading = false
				context.commit('helpers_defontana_sellers', [])
				reject(error)
			})
		})
	},

	helpers_defontana_document_types(context, query) {
		context.state.helpers.defontana_document_types.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/defontana/document_types')
			.then(response => {
				context.state.helpers.defontana_document_types.loading = false
				context.commit('helpers_defontana_document_types', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.defontana_document_types.loading = false
				context.commit('helpers_defontana_document_types', [])
				reject(error)
			})
		})
	},

	helpers_defontana_services(context, query) {
		context.state.helpers.defontana_services.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/defontana/services')
			.then(response => {
				context.state.helpers.defontana_services.loading = false
				context.commit('helpers_defontana_services', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.defontana_services.loading = false
				context.commit('helpers_defontana_services', [])
				reject(error)
			})
		})
	},

	helpers_defontana_products(context, query) {
		context.state.helpers.defontana_products.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/defontana/products')
			.then(response => {
				context.state.helpers.defontana_products.loading = false
				context.commit('helpers_defontana_products', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.defontana_products.loading = false
				context.commit('helpers_defontana_products', [])
				reject(error)
			})
		})
	},

	helpers_branches(context, query) {
		context.state.helpers.branches.loading = true
		return new Promise((resolve, reject) => {
			api.get('helpers/branches')
			.then(response => {
				context.state.helpers.branches.loading = false
				context.commit('helpers_branches', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.helpers.branches.loading = false
				context.commit('helpers_branches', [])
				reject(error)
			})
		})
	},

	

	home(context, query) {
		context.state.home.loading = true
		return new Promise((resolve, reject) => {
			api.get('home', {
				params: {
					limit: query ? query.limit : null,
					page: query ? query.page : null,
					sortDesc: query ? query.sortDesc : null,
					sortBy: query ? query.sortBy : null,
					search: query ? query.search : null,
					type_id: query ? query.type_id : null,
				}
			})
			.then(response => {
				context.state.home.loading = false
				context.commit('home', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.home.loading = false
				context.commit('home', [])
				reject(error)
			})
		})
	},


	get_units(context, client_id) {
		context.state.units = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.post('unit/all', { client_id: client_id })
			.then(response => { context.commit('set_units', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	get_jobs(context, client_id) {
		context.state.jobs = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.post('job/all', { client_id: client_id })
			.then(response => { context.commit('set_jobs', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	get_schedules(context, client_id) {
		context.state.schedules = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('v2/schedules/'+client_id)
			.then(response => {
				context.state.schedules.loading = false
				context.commit('set_schedules', response.data)
				resolve(response)
			})
			.catch(error => {
				context.state.schedules.loading = false
				reject(error)
			})
		})
	},
	get_parts(context) {
		context.state.parts = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('part/all')
			.then(response => { context.commit('set_parts', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	
	get_part_types(context) {
		context.state.part_types = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('part_type/all')
			.then(response => { context.commit('set_part_types', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	get_model_types(context) {
		context.state.model_types = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('model_type/all')
			.then(response => { context.commit('set_model_types', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	get_models(context) {
		context.state.models = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('model/all')
			.then(response => { context.commit('set_models', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	get_brand_models(context, brand_id) {
		context.state.current_models = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.post('brand/models', { brand_id: brand_id })
			.then(response => { context.commit('set_current_models', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	get_brands(context) {
		context.state.brands = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('brand/all')
			.then(response => { context.commit('set_brands', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	get_clients_with_units(context) {
		context.state.clients_with_units = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('client_with_units/all')
			.then(response => {
				context.commit('set_clients_with_units', response.data.object)
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},
	get_clients(context) {
		context.state.clients = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('client/all')
			.then(response => {
				context.commit('set_clients', response.data.object)
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},
	get_current_client_sites(context, client_id) {
		context.state.current_client_sites = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.post('client/sites', { client_id: client_id })
			.then(response => { context.commit('set_current_client_sites', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	get_job_types(context) {
		context.state.job_types = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('job_type/all')
			.then(response => { context.commit('set_job_types', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	get_job_statuses(context) {
		context.state.job_statuses = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('job_status/all')
			.then(response => { context.commit('set_job_statuses', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	set_current_contract_contacts(context, client_id) {
		var contacts = []
		for (let i = 0; i < context.state.contacts.length; i++) {
			if (context.state.contacts[i].cliente == client_id) {
				contacts.push(context.state.contacts[i])
			}
		}
		context.commit('set_current_contract_contacts', contacts)
	},
	get_current_contract_sites(context, contract_id) {
		return new Promise((resolve, reject) => {
			api.get('contract_sites/'+contract_id)
			.then(response => { context.commit('set_current_contract_sites', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	get_unit(context, unit_id) {
		context.state.current_unit = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('unit/'+unit_id)
			.then(response => { context.commit('set_current_unit', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	get_job(context, job_id) {
		context.state.current_job = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('job/'+job_id)
			.then(response => { context.commit('set_current_job', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	get_current_contract_units(context, contract_id) {
		context.state.current_contract_units = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('contract_units/'+contract_id)
			.then(response => { context.commit('set_current_contract_units', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	get_current_contract_files(context, contract_id) {
		console.log(contract_id)
		return new Promise((resolve, reject) => {
			api.get('contract_files/'+contract_id)
			.then(response => { context.commit('set_current_contract_files', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	get_file_extensions(context) {
		return new Promise((resolve, reject) => {
			api.get('file_extension/all')
			.then(response => { context.commit('set_file_extensions', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	get_file_types(context) {
		return new Promise((resolve, reject) => {
			api.get('file_type/all')
			.then(response => { context.commit('set_file_types', response.data); resolve(response) })
			.catch(error => { reject(error) })
		})
	},
	set_current_contract(context, contract) {
		context.commit('set_current_contract', contract)
	},

	get_contacts(context) {
		return new Promise((resolve, reject) => {
			api.get('contact/all')
			.then(response => {
				context.commit('set_contacts', response.data.object)
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},
	get_contracts(context) {
		context.state.contracts = { loading: true, data: [] }
		return new Promise((resolve, reject) => {
			api.get('contract/all')
			.then(response => {
				context.commit('set_contracts', response.data)
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},















}