import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import VCalendar from 'v-calendar'
import './styles/index.scss'

api.interceptors.response.use(function (response) {
	return response
}, function (error) {
	if (error.response.status === 401 && store.getters.session) {
		router.push({ name: 'Logout' })
	}
	return Promise.reject(error)
})

router.beforeEach((to, from, next) => {
	if (to.meta.multi === true) {
		next()
	} else if (to.meta.private === true) {
		store.getters.session ? next() : next({ name: 'Login' })
	} else if (to.meta.private === false) {
		store.getters.session ? next({ name: 'Home' }) : next()
	} else {
		next({ name: 'Home' })
	}
})

Vue.component('apex-chart', VueApexCharts)
Vue.use(VCalendar)
Vue.use(BootstrapVue, {
	BToast: { variant: 'info', solid: true, toaster: 'b-toaster-bottom-left' }
})
Vue.prototype.$api = api
Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
