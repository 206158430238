<template>

	<!-- MODAL -->
	<b-modal
		id="admin-reset-password"
		title="Reiniciar Contraseña"
		size="md"
		body-class="p-0"
	>

		<!-- CONTENT -->
		<b-overlay :show="loading" rounded="sm" class="px-4 py-3">

			<!-- FORM -->
			<b-row>
				<b-col>
					<b-form-group
						label="¿Cómo desea realizar el cambio?"
						label-class="text-strong mb-2 fs-12 pb-0"
						class="fs-11"
					>
						<b-form-radio-group v-model="method" :state="val_method" @input="updateMethod()">
							<b-form-radio :value="1" class="ml-3 mb-1">Enviar e-mail de recuperación</b-form-radio>
							<b-form-radio :value="2" class="ml-3">Establecer contraseña manualmente</b-form-radio>
						</b-form-radio-group>
						<b-form-invalid-feedback :state="val_method" class="ml-3">Requerido</b-form-invalid-feedback>
						<b-form-valid-feedback :state="val_method" class="ml-3">Correcto</b-form-valid-feedback>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row v-if="method == 2">
				<b-col sm="12">
					<b-form-group
						label="Nueva Contraseña"
						:label-class="val_password ? 'text-success text-strong mb-0 pb-0' : 'text-danger text-strong mb-0 pb-0'"
						label-size="lg"
					>
						<b-form-text class="mt-0 mb-2 text-size-10">
							<span>Mínimo 5 caracteres.</span>
						</b-form-text>
						<b-input v-model="password" type="password" size="lg" :state="val_password"></b-input>
						<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
						<b-form-valid-feedback>Correcto</b-form-valid-feedback>
					</b-form-group>
				</b-col>
				<b-col sm="12">
					<b-form-group
						label="Repita Nueva Contraseña"
						:label-class="val_repeat_password ? 'text-success text-strong mb-0 pb-0' : 'text-danger text-strong mb-0 pb-0'"
						label-size="lg"
						class="mb-2"
					>
						<b-form-text class="mt-0 mb-2 text-size-10">
							<span>Asegúrese de que sean idénticas.</span>
						</b-form-text>
						<b-input v-model="repeat_password" type="password" size="lg" :state="val_repeat_password"></b-input>
						<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
						<b-form-valid-feedback>Correcto</b-form-valid-feedback>
					</b-form-group>
				</b-col>
			</b-row>
			<!-- /XXXX -->

			<!-- LOADING PROGRESS -->
			<template #overlay>
				<div class="text-center">
					<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
				</div>
			</template>
			<!-- /LOADING PROGRESS -->

		</b-overlay>
		<!-- /CONTENT -->

		<!-- FOOTER -->
		<template v-slot:modal-footer="{ close }">
			<div class="w-100">
				<b-row>
					<b-col class="text-left">
						<b-button @click="close()" variant="outline-dark" :disabled="loading">Cancelar</b-button>
					</b-col>
					<b-col class="text-right">
						<b-button @click="change()" :variant="val_full ? 'success' : 'danger'" :disabled="!val_full || loading" class="w-space">CONFIRMAR</b-button>
					</b-col>
				</b-row>
			</div>
		</template>
		<!-- /FOOTER -->

	</b-modal>
	<!-- /MODAL -->

</template>

<script>
export default {
	name: 'AdminResetPassword',
	components: {
	},
	data() {
		return {
			loading: false,
			password: '',
			repeat_password: '',
			method: null,
		}
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		getters() { return this.$store.getters },
		//////-----------------------------------------------------------
		val_password() {
			return this.password.length >= 5 ? true : false
		},
		val_repeat_password() {
			return this.val_password && this.password == this.repeat_password ? true : false
		},
		val_method() {
			return this.method != null ? true : false
		},
		val_full() {
			return this.method == 1 || (this.method == 2 && this.val_repeat_password) ? true : false
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'admin-reset-password') { this.reset() }
		})
	},
	methods: {
		reset() {
			this.method = null
			this.password = ''
			this.repeat_password = ''
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		change() {
			this.loading = true
			this.$api.put('users/'+this.core.account.id+'/admin_password', {
				password: this.password,
				method: this.method
			})
			.then(response => {
				this.loading = false
				this.toast(true, this.method == 2 ? 'La contraseña se estableció correctamente.' : 'Se envió un enlace de recuperación a '+response.data.email+' para establecer una nueva contraseña.', 5000)
				this.reset()
				this.$bvModal.hide('admin-reset-password')
			})
			.catch(() => {
				this.loading = false
				this.toast(false, this.local.messages.default_system_error, 5000)
			})
		},
		updateMethod() {
			if (this.method == 1) {
				this.password = ''
				this.repeat_password = ''
			}
		}
	}
}
</script>

<style scoped>
</style>
