export default {
	api_path: process.env.VUE_APP_API_PATH,

	websocket: {
		token: localStorage.getItem('websocket_token') || null,
		online: false,
	},
	
	local: {
		build: 14,
		session: {
			token_type: localStorage.getItem('token_type') || null,
			expires_in: localStorage.getItem('expires_in') || null,
			access_token: localStorage.getItem('access_token') || null,
			refresh_token: localStorage.getItem('refresh_token') || null
		},
		preferences: {
			welcome_tutorial: {
				hidden: localStorage.getItem('hide_welcome_tutorial') || false,
				step: null,
			},
			ui_size: localStorage.getItem('ui_size') || 'md',
			ui_theme: localStorage.getItem('ui_theme') || 'light',
			screen_size: { width: 0, height: 0 },
			fluid: null,
			access: null,
		},
		messages: {
			default_system_error: 'Ha ocurrido un problema interno en el sistema. Por favor, inténtelo nuevamente más tarde.',
			empty_search: 'Escriba algún término para buscar.',
		}
	},

	new_purchase_request: {
		id: null,
        branch_id: null,
        number: null,
        title: null,
        request_date: null,
        delivery_date: null,
        reviewed: null,
        reviewed_on: null,
        completed: null,
        completed_on: null,
        reviewed_by: null,
        cost_center_id: null,
        priority_id: null,
        created_by: null,
        comment: null,
        enabled: null,
        disabled_at: null,
		items: [],
	},

	new_pricing: {
        id: null,
        branch_id: null,
        documentType: null,
        clientId: null,
        priceListId: null,
        title: null,
        gloss: null,
        billingCoinId: null,
        billingRate: null,
        contactId: null,
        sellerFileId: null,
        paymentCondition: null,
        state: null,
        folio: null,
        templateId: null,
        closingProbability: null,
        dueDate: null,
        pricingDate: null,
        closingDate: null,
        taxeCode1: null,
        taxePercentaje1: null,
        taxeValue1: null,
        exempt: null,
        affectable: null,
        totalDiscountSurcharge: null,
        total: null,
        subTotal: null,
        source: null,
        last_sync: null,
        enabled: null,
        disabled_at: null,
		items: [],
	},

	new_order: {
        id: null,
	},

	
	corporate_template_groups: {
		loading: false,
		data: []
	},
	
	events: {
		loading: false,
		data: []
	},
	
	corporate_templates: {
		loading: false,
		data: []
	},
	
	request_logs: {
		loading: false,
		data: []
	},
	
	stock_requirements: {
		loading: false,
		data: []
	},
	
	products: {
		loading: false,
		data: []
	},
	
	warehouses: {
		loading: false,
		data: []
	},
	
	new_warehouse_movement: {
        number: null,
        type_id: null,
        warehouse_id: null,
        date: null,
        comment: null,
        enabled: null,
        disabled_at: null,
		items: [],
	},
	
	new_stock_requirement: {
		id: null,
		client_id: null,
        warehouse_id: null,
        item_id: null,
		description: null,
		unit_id: null,
        item_type: null,
        minimum: null,
        critical: null,
        enabled: null,
        disabled_at: null,
		items: [],
	},
	
	system_parameters: {
		loading: false,
		data: []
	},
	
	dashboard_home: {
		loading: false,
		data: {
			hvac: {
				contracts: {
					count: null
				},
				jobs: {
					count: null
				},
				maintenances: {
					count: null
				},
				sites: {
					count: null
				},
				units: {
					count: null
				},
			},
			defontana: {
				orders: {
					totals: [],
					count: null,
				},
				pricings: {
					totals: [],
					count: null,
				},
				sales: {
					totals: [],
					count: null,
				},
				purchase_orders: {
					totals: [],
					count: null,
				},
				cost_centers: {
					totals: [],
					count: null,
				},
				clients: {
					totals: [],
					count: null,
				},
				products: {
					totals: [],
					count: null,
				},
				services: {
					totals: [],
					count: null,
				},
				employees: {
					totals: [],
					count: null,
				},
				sellers: {
					totals: [],
					count: null,
				},
				shops: {
					totals: [],
					count: null,
				},
			}
		}
	},
	
	purchase_request: {
		loading: false,
		data: {
			id: null,
			branch_id: null,
			number: null,
			title: null,
			request_date: null,
			delivery_date: null,
			reviewed: null,
			reviewed_on: null,
			completed: null,
			completed_on: null,
			reviewed_by: null,
			cost_center_id: null,
			priority_id: null,
			created_by: null,
			comment: null,
			enabled: null,
			disabled_at: null,
			items: [],
			branch: {
				id: null,
				company_id: null,
				name: null,
				codename: null,
				description: null,
				enabled: null,
				disabled_at: null,
			},
			reviewer: {
				name: null,
				email: null,
				vatin: null,
				lastname: null,
				phone: null,
				management: null,
				enabled: null,
				created_at: null,
				disabled_at: null,
			},
			cost_center: {
				id: null,
				branch_id: null,
				parent_code: null,
				code: null,
				format_code: null,
				billable: null,
				level: null,
				description: null,
				enabled: null,
				disabled_at: null,
				exploded: [],
				created: null,
			},
			creator: {
				name: null,
				email: null,
				vatin: null,
				lastname: null,
				phone: null,
				management: null,
				enabled: null,
				created_at: null,
				disabled_at: null,
			}
		}
	},
	
	purchase_requests: {
		loading: false,
		data: []
	},
	
	defontana_cost_centers: {
		loading: false,
		data: []
	},
	
	defontana_employees: {
		loading: false,
		data: []
	},
	
	defontana_purchase_orders: {
		loading: false,
		data: []
	},
	
	defontana_sales: {
		loading: false,
		data: []
	},
	
	defontana_pricings: {
		loading: false,
		data: []
	},
	
	defontana_orders: {
		loading: false,
		data: []
	},
	
	teamviewer_devices: {
		loading: false,
		data: []
	},
	
	defontana_pricing: {
		loading: false,
		data: {
			id: null,
		}
	},
	
	defontana_sale: {
		loading: false,
		data: {
			documentType: null,
			firstFolio: null,
			lastFolio: null,
			status: null,
			emissionDate: null,
			dateTime: null,
			expirationDate: null,
			clientFile: null,
			contactIndex: null,
			paymentCondition: null,
			sellerFileId: null,
			billingCoin: null,
			billingRate: null,
			shopId: null,
			priceList: null,
			giro: null,
			city: null,
			district: null,
			contact: null,
			gloss: null,
			affectableTotal: null,
			exemptTotal: null,
			taxeValue: null,
			total: null,
			taxeCode: null,
			isTransferDocument: null,
			imestamp: null,
			enabled: null,
			disabled_at: null,
			client: {
				active: null,
				isProvider: null,
				lastName1: null,
				lastName2: null,
				city: null,
				client: null,
				legalCode: null,
				agreedDiscount: null,
				address: null,
				district: null,
				email: null,
				sendEmailDTE: null,
				state: null,
				fax: null,
				business: null,
				companyID: null,
				fileID: null,
				localID: null,
				coinID: null,
				paymentID: null,
				productID: null,
				rubroId: null,
				docTypeID: null,
				sellerID: null,
				priceList: null,
				name: null,
				fantasyname: null,
				country: null,
				poBox: null,
				provider: null,
				phone: null,
				productType: null,
				tipRcgoDctoDocVta: null,
				usaRcgoDctoDocVta: null,
				webSite: null,
				zipCode: null,
			},
			rel_status: {
				name: null,
				description: null,
			},
			billing_coin: {
				coinID: null,
				name: null,
			},
			payment_condition: {
				code: null,
				name: null,
				type: null,
				duesAmount: null,
				daysBetweenPayments: null,
			},
			document_type: {
				code: null,
				electronicCode: null,
				name: null,
				voucherInfo: null,
			},
			seller: {
				code: null,
				mail: null,
				name: null,

			},
			shop: {
				code: null,
				description: null,
				address: null,
				bussinessCode: null,
				storage: null,
			},
			attached_documents: []
		}
	},

	communes: {
		loading: false,
		data: []
	},

	privileges: {
		loading: false,
		data: {
			user: [],
			available: [],
			enabled: []
		}
	},

	core: {
		loading: true,
		account: {
			id: null,
			name: null,
			lastname: null,
			email: null,
			phone: null,
			privileges: {
				loading: false,
				data: []
			},
			profile: {
				id: null,
				name: null,
				codename: null,
				description: null,
				icon: null,
			},
			client: {
				id: null,
				vatin: null,
				name: null,
				codename: null,
			}
		},
	},
	
	interval: null,

	hvac: {
		client_id: null,
		site_id: null,
	},

	home: {
		loading: false,
		data: {
			carbon: {}
		}
	},


	hvac_panel: {
		loading: false,
		data: []
	},

	users: {
		loading: false,
		data: []
	},

	regions: {
		loading: false,
		data: []
	},

	contracts: {
		loading: false,
		data: []
	},

	units: {
		loading: false,
		data: []
	},

	clients: {
		loading: false,
		data: []
	},
	
	client_contacts: {
		loading: false,
		data: []
	},
	
	client_contracts: {
		loading: false,
		data: []
	},
	
	client_units: {
		loading: false,
		data: []
	},
	
	client_sites: {
		loading: false,
		data: []
	},
	client_areas: {
		loading: false,
		data: []
	},
	client_contract_areas: {
		loading: false,
		data: []
	},
	client_time_switches: {
		loading: false,
		data: []
	},
	client_temperature_controls: {
		loading: false,
		data: []
	},
	models: {
		loading: false,
		data: []
	},

	brands: {
		loading: false,
		data: []
	},

	jobs: {
		loading: false,
		data: []
	},
	
	job: {
		loading: true,
		data: {
			id: null,
			type_id: null,
			client_id: null,
			site_id: null,
		}
	},

	brand_models: {
		loading: false,
		data: []
	},

	site_units: {
		loading: false,
		data: []
	},

	model_types: {
		loading: false,
		data: []
	},

	download_token: {
		loading: false,
		data: []
	},

	defontana_products: {
		loading: false,
		data: []
	},

	defontana_services: {
		loading: false,
		data: []
	},

	helpers: {
		warehouses: {
			loading: true,
			data: []
		},
		measurement_units: {
			loading: true,
			data: []
		},
		warehouses_movement_types: {
			loading: true,
			data: []
		},
		branches: {
			loading: true,
			data: []
		},
		defontana_cost_centers: {
			loading: false,
			data: []
		},
		defontana_services: {
			loading: false,
			data: []
		},
		defontana_products: {
			loading: true,
			data: []
		},
		defontana_sellers: {
			loading: true,
			data: []
		},
		defontana_payment_conditions: {
			loading: true,
			data: []
		},
		defontana_purchase_orders: {
			loading: true,
			data: []
		},
		defontana_document_types: {
			loading: true,
			data: []
		},
		defontana_clients: {
			loading: true,
			data: []
		},
		unit_conditions: {
			loading: true,
			data: []
		},
		unit_priorities: {
			loading: true,
			data: []
		},
		unit_coolants: {
			loading: true,
			data: []
		},
		unit_voltages: {
			loading: true,
			data: []
		},
		unit_capacity_measurement_units: {
			loading: true,
			data: []
		},
		unit_maintenance_windows: {
			loading: true,
			data: []
		},
		job_types: {
			loading: true,
			data: []
		},
		clients: {
			loading: true,
			data: []
		},
		profiles: {
			loading: true,
			data: []
		},
	},
	unit: {
		loading: true,
		data: {
			id: null,
			brand_id: null,
			model_id: null,
			voltage_id: null,
			coolant_id: null,
			client_id: null,
			area_id: null,
			contract_area_id: null,
			time_switch_id: null,
			temperature_control_id: null,
			capacity_measurement_unit_id: null,
			maintenance_window_id: null,
			site_id: null,
			serial_number: '',
			install_date: '',
			priority_id: null,
			condition_id: null,
			is_working: null,
			identifier: '',
			floor: '',
			location_interior: '',
			location_exterior: '',
			capacity: '',
			description: '',
			comment: '',
		}
	},

	hvac_coolants: {
		loading: true,
		data: []
	},

	build: 23, // added 2023 to cheques calendar
	username: localStorage.getItem('username') || null,
	access_token: localStorage.getItem('access_token') || null,
	nubox_token: localStorage.getItem('nubox_token') || null,
	workers_new: { loading: false, data: [] },
	document_types: { loading: false, data: [] },
	expirations: { loading: false, data: [] },
	current_date_expirations: { loading: false, data: [] },
	current_worker_documents: { loading: false, data: [] },
	schedules: { loading: false, data: [] },
	current_user_privileges: { loading: false, data: [] },
	parts: { loading: false, data: [] },
	part_types: { loading: false, data: [] },
	current_models: { loading: false, data: [] },
	clients_with_units: { loading: false, data: [] },
	current_client_sites: { loading: false, data: [] },
	setup_project_email: { loading: false, data: [] },
	job_types: { loading: false, data: [] },
	job_statuses: { loading: false, data: [] },
	job_modal_step: 1,
	units_current_client_id: null,
	contacts: [],
	workers: [],
	current_contract: {},
	invoices: [],
	cost_centers: [],
	file_types: [],
	purchase_orders: { loading: false, data: [] },
	file_extensions: [],
	current_unit: { loading: false, data: [] },
	current_excel: { loading: false, data: [], error: false },
	current_jobs: { loading: false, data: [] },
	current_job: { loading: false, data: [] },
	current_contract_sites: [],
	current_contract_files: [],
	current_contract_contacts: [],
	current_contract_units: { loading: false, data: [] },
	current_contract_invoices: [],
	request_types: [
		{ value: null, text: 'Seleccione una opción...', disabled: true },
		{ value: 1, text: 'Mantenimiento Correctivo * URGENTE *' },
		{ value: 1, text: 'Mantenimiento Correctivo Normal' },
		{ value: 1, text: 'Proyectos e Ingeniería' },
	],
	projects_history: [],
	current_schedule_day: ''












}