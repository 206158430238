import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import UsersView from '@/views/UsersView.vue'
import DirectAccess from '@/views/DirectAccess.vue'
import SystemParametersView from '@/views/SystemParametersView.vue'
import HomeTechnichianView from '@/views/HomeTechnichianView.vue'
import HomeClientView from '@/views/HomeClientView.vue'
import HVAC_ContractsView from '@/views/HVAC_ContractsView.vue'
import HVAC_JobsView from '@/views/HVAC_JobsView.vue'
import HVAC_UnitsView from '@/views/HVAC_UnitsView.vue'
import RemoteSupportView from '@/views/RemoteSupportView.vue'
import DefontanaSalesView from '@/views/DefontanaSalesView.vue'
import DefontanaPricingsView from '@/views/DefontanaPricingsView.vue'
import OrganizationView from '@/views/OrganizationView.vue'
import PhonesView from '@/views/PhonesView.vue'
import TemplatesView from '@/views/TemplatesView.vue'
import EmailView from '@/views/EmailView.vue'
import OneDriveView from '@/views/OneDriveView.vue'
import WebRequestsView from '@/views/WebRequestsView.vue'
import TasksView from '@/views/TasksView.vue'
import CostCentersView from '@/views/CostCentersView.vue'
import HVAC_MaintenanceView from '@/views/HVAC_MaintenanceView.vue'
import RequestLogsView from '@/views/RequestLogsView.vue'
import DefontanaProductsView from '@/views/DefontanaProductsView.vue'
import DefontanaServicesView from '@/views/DefontanaServicesView.vue'
import DefontanaOrdersView from '@/views/DefontanaOrdersView.vue'
import DefontanaEmployeesView from '@/views/DefontanaEmployeesView.vue'
import DefontanaPurchaseOrdersView from '@/views/DefontanaPurchaseOrdersView.vue'
import PurchaseRequestsView from '@/views/PurchaseRequestsView.vue'
import ShippingCalculatorView from '@/views/ShippingCalculatorView.vue'
import TeamViewerDevices from '@/views/TeamViewerDevices.vue'
import DirectPurchaseRequest from '@/views/DirectPurchaseRequest.vue'
import StockRequirements from '@/views/StockRequirements.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: HomeView,
		meta: {
			module: 'Intranet',
			title: 'Inicio',
			private: true,
			fluid: true,
		}
	},
	{
		path: '/',
		name: 'Login',
		component: () => import(/* webpackChunkName: "Login" */ '../views/LoginView.vue'),
		meta: {
			private: false,
			fluid: false,
		}
	},
	{
		path: '/acceso',
		name: 'AccessSelect',
		component: () => import(/* webpackChunkName: "AccessPassword" */ '../views/AccessSelect.vue'),
		meta: {
			private: false,
			fluid: false,
		}
	},
	{
		path: '/ingreso',
		name: 'AccessPassword',
		component: () => import(/* webpackChunkName: "AccessPassword" */ '../views/AccessPassword.vue'),
		meta: {
			private: false,
			fluid: false,
		}
	},
	{
		path: '/ingreso/email',
		name: 'AccessEmail',
		component: () => import(/* webpackChunkName: "AccessEmail" */ '../views/AccessEmail.vue'),
		meta: {
			private: false,
			fluid: false,
		}
	},
	{
		path: '/ingreso/celular',
		name: 'AccessPhone',
		component: () => import(/* webpackChunkName: "AccessPhone" */ '../views/AccessPhone.vue'),
		meta: {
			private: false,
			fluid: false,
		}
	},
	{
		path: '/activar/:token',
		name: 'WelcomePassword',
		component: () => import(/* webpackChunkName: "WelcomePassword" */ '../views/WelcomePassword.vue'),
		meta: {
			private: false,
			fluid: false,
		}
	},
	{
		path: '/directo/:token',
		name: 'DirectAccess',
		component: DirectAccess,
		meta: {
			private: false,
			fluid: false,
		}
	},
	{
		path: '/token/requisiciones/:token',
		name: 'DirectPurchaseRequest',
		component: DirectPurchaseRequest,
		meta: {
			private: false,
			fluid: false,
		}
	},
	{
		path: '/recuperar',
		name: 'RecoverAccount',
		component: () => import(/* webpackChunkName: "RecoverAccount" */ '../views/RecoverAccountView.vue'),
		meta: {
			private: false,
			fluid: false,
		}
	},
	{
		path: '/salir',
		name: 'Logout',
		component: () => import(/* webpackChunkName: "Logout" */ '../views/LogoutView.vue'),
		meta: {
			private: true,
			fluid: false,
		}
	},

	{
		path: '/tecnico',
		name: 'HomeTechnichian',
		component: HomeTechnichianView,
		meta: {
			title: 'HomeTechnichian',
			private: true,
			fluid: true,
		}
	},

	{
		path: '/cliente',
		name: 'HomeClient',
		component: HomeClientView,
		meta: {
			title: 'HomeClient',
			private: true,
			fluid: true,
		}
	},

	{
		path: '/electrofrio/organigrama',
		name: 'Organization',
		component: OrganizationView,
		meta: {
			module: 'Electrofrio',
			title: 'Organigrama',
			private: true,
			fluid: true,
		}
	},
	{
		path: '/electrofrio/agenda',
		name: 'Phones',
		component: PhonesView,
		meta: {
			module: 'Electrofrio',
			title: 'Agenda Corporativa',
			private: true,
			fluid: true,
		}
	},
	{
		path: '/electrofrio/web',
		name: 'WebRequests',
		component: WebRequestsView,
		meta: {
			module: 'Electrofrio',
			title: 'Solicitudes Web',
			private: true,
			fluid: true,
		}
	},

	{
		path: '/electrofrio/formatos',
		name: 'Templates',
		component: TemplatesView,
		meta: {
			module: 'Electrofrio',
			title: 'Formatos Corporativos',
			private: true,
			fluid: true,
		}
	},

	{
		path: '/electrofrio/email',
		name: 'Email',
		component: EmailView,
		meta: {
			module: 'Electrofrio',
			title: 'E-mail Corporativo',
			private: true,
			fluid: false,
		}
	},

	{
		path: '/electrofrio/onedrive',
		name: 'OneDrive',
		component: OneDriveView,
		meta: {
			module: 'Electrofrio',
			title: 'Carpetas OneDrive',
			private: true,
			fluid: true,
		}
	},

	{
		path: '/electrofrio/soporte-remoto',
		name: 'RemoteSupport',
		component: RemoteSupportView,
		meta: {
			module: 'Electrofrio',
			title: 'Soporte Remoto',
			private: true,
			fluid: true,
		}
	},

	{
		path: '/planificacion/tareas',
		name: 'Tasks',
		component: TasksView,
		meta: {
			module: 'Planificación',
			title: 'Tareas',
			private: true,
			fluid: true,
		}
	},

	{
		path: '/comercial/centros-negocio',
		name: 'CostCenters',
		component: CostCentersView,
		meta: {
			module: 'Comercial',
			title: 'Centros de Negocio',
			private: true,
			fluid: false,
		}
	},

	{
		path: '/comercial/calculadora-flete',
		name: 'ShippingCalculator',
		component: ShippingCalculatorView,
		meta: {
			module: 'Comercial',
			title: 'Calculadora de Flete',
			private: true,
			fluid: false,
		}
	},

	{
		path: '/comercial/ventas',
		name: 'DefontanaSales',
		component: DefontanaSalesView,
		meta: {
			module: 'Comercial',
			title: 'Ventas',
			private: true,
			fluid: false,
		}
	},

	{
		path: '/comercial/requisiciones',
		name: 'PurchaseRequests',
		component: PurchaseRequestsView,
		meta: {
			module: 'Comercial',
			title: 'Requisiciones',
			private: true,
			fluid: false,
		}
	},

	{
		path: '/comercial/compras',
		name: 'DefontanaPurchaseOrders',
		component: DefontanaPurchaseOrdersView,
		meta: {
			module: 'Comercial',
			title: 'Compras',
			private: true,
			fluid: false,
		}
	},

	{
		path: '/comercial/cotizaciones',
		name: 'DefontanaPricings',
		component: DefontanaPricingsView,
		meta: {
			module: 'Defontana',
			title: 'Cotizaciones',
			private: true,
			fluid: false,
		}
	},

	{
		path: '/comercial/articulos',
		name: 'DefontanaProducts',
		component: DefontanaProductsView,
		meta: {
			module: 'Defontana',
			title: 'Artículos',
			private: true,
			fluid: false,
		}
	},

	{
		path: '/comercial/servicios',
		name: 'DefontanaServices',
		component: DefontanaServicesView,
		meta: {
			module: 'Defontana',
			title: 'Servicios',
			private: true,
			fluid: false,
		}
	},

	{
		path: '/sistemas/dispositivos',
		name: 'TeamViewerDevices',
		component: TeamViewerDevices,
		meta: {
			module: 'Sistemas',
			title: 'Dispositivos',
			private: true,
			fluid: false,
		}
	},

	{
		path: '/rrhh/trabajadores',
		name: 'DefontanaEmployees',
		component: DefontanaEmployeesView,
		meta: {
			module: 'Recursos Humanos',
			title: 'Trabajadores',
			private: true,
			fluid: false,
		}
	},

	{
		path: '/comercial/pedidos',
		name: 'DefontanaOrders',
		component: DefontanaOrdersView,
		meta: {
			module: 'Comercial',
			title: 'Pedidos',
			private: true,
			fluid: false,
		}
	},

	{
		path: '/operaciones/resumen',
		name: 'HVAC_Maintenance',
		component: HVAC_MaintenanceView,
		meta: {
			module: 'Operaciones',
			title: 'Resumen de Mantenimiento',
			private: true,
			fluid: true,
		}
	},

	{
		path: '/operaciones/ordenes',
		name: 'HVAC_Jobs',
		component: HVAC_JobsView,
		meta: {
			module: 'Operaciones',
			title: 'Órdenes de Trabajo',
			private: true,
			fluid: true,
		}
	},
	{
		path: '/operaciones/equipos',
		name: 'HVAC_Units',
		component: HVAC_UnitsView,
		meta: {
			module: 'Operaciones',
			title: 'Equipos',
			private: true,
			fluid: true,
		}
	},
	{
		path: '/operaciones/contratos',
		name: 'HVAC_Contracts',
		component: HVAC_ContractsView,
		meta: {
			module: 'Operaciones',
			title: 'Contratos',
			private: true,
			fluid: true,
		}
	},
	{
		path: '/operaciones/materiales',
		name: 'StockRequirements',
		component: StockRequirements,
		meta: {
			module: 'Operaciones',
			title: 'Stock Contratos',
			private: true,
			fluid: true,
		}
	},



	// SYSTEM
	{
		path: '/sistemas/usuarios',
		name: 'Users',
		component: UsersView,
		meta: {
			module: 'Sistema',
			title: 'Usuarios',
			private: true,
			fluid: true,
		}
	},
	{
		path: '/sistemas/actividad',
		name: 'RequestLogs',
		component: RequestLogsView,
		meta: {
			module: 'Sistema',
			title: 'Actividad',
			private: true,
			fluid: true,
		}
	},
	{
		path: '/sistemas/parametros',
		name: 'SystemParameters',
		component: SystemParametersView,
		meta: {
			module: 'Sistema',
			title: 'Parámetros de Sistema',
			private: true,
			fluid: true,
		}
	},
]

const router = new VueRouter({
	routes
})

export default router
