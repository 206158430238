<template>
	<div class="mt-3">

		<SiteModuleHeader
			:showBack="true"
			:showHome="true"
			:showSize="true"
			:showTheme="true"
			:showFluid="true"
			:showTitle="true"
			@reload="reload"
			:reloading="reloading || resources.loading"
		></SiteModuleHeader>

		<CreateUnit @new_brand_id="updateBrandID" :brand_id="current.brand_id" @update="reload" @new_client_id="updateClientID"></CreateUnit>
		<UpdateUnit @new_brand_id="updateBrandID" :brand_id="current.brand_id" @update="reload" @new_client_id="updateClientID"></UpdateUnit>
		<CreateBrand></CreateBrand>
		<CreateModel></CreateModel>
		<CreateClient></CreateClient>
		<CreateSite></CreateSite>
		<CreateCoolant></CreateCoolant>
		<ViewUnit></ViewUnit>
		<CreateHVACArea :client_id="current.client_id"></CreateHVACArea>
		<CreateHVACContractArea :client_id="current.client_id"></CreateHVACContractArea>
		<CreateHVACTimeSwitch :client_id="current.client_id"></CreateHVACTimeSwitch>
		<CreateHVACTemperatureControl :client_id="current.client_id"></CreateHVACTemperatureControl>

		<!-- TOOLBAR -->
		<b-row no-gutters>

			<!-- CLIENT -->
			<b-col md="sm" lg="auto" xl="auto" class="mr-2" v-if="core.loading === false && profile.name != 'client'">
				<p class="mb-2"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
						'text-primary' : val_client == true,
						'text-danger' : val_client == false,
					}">
					<span>Cliente</span>
					<i class="fas fa-arrow-turn-down ml-2"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto">
						<b-overlay
							:show="clients.loading"
							rounded="sm"
							:variant="ui_theme == 'light' ? 'light' : 'dark'"
						>
							<template #overlay>
								<div class="text-center">
									<b-spinner type="grow" variant="primary" small></b-spinner>
								</div>
							</template>
							<b-select
								:state="val_client"
								v-model="hvac_client_id"
								:size="ui_size"
								:class="{
									'bg-transparent text-white' : ui_theme == 'dark',
								}"
								@change="reload()"
							>
								<b-select-option :value="null" disabled>Presione aquí</b-select-option>
								<b-select-option v-for="(item, i) in clients.data" :key="i" :value="item.id">{{ item.codename }}</b-select-option>
							</b-select>
						</b-overlay>
					</b-col>
				</b-row>
			</b-col>

			<!-- CREATE -->
			<b-col md="sm" lg="auto" xl="auto">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Acciones</span>
					<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto" v-if="core.loading === false && profile.name != 'client'">
						<!-- CREATE -->
						<SitePrimaryButton
							:loading="resources.loading"
							text="Nuevo Equipo"
							title="Presione para crear"
							icon="plus"
							@clicked="createUnit()"
						></SitePrimaryButton>
					</b-col>

					<!-- SEARCH INPUT -->
					<b-col sm="auto" v-if="val_client">
						<b-overlay
							:show="resources.loading"
							rounded
							opacity="0.6"
						>
							<template #overlay>
								<span>&nbsp;</span>
							</template>
							<b-input-group :size="ui_size">
								<b-input
									v-model="query.search"
									placeholder="N° Equipo"
									@changed="searchChanged"
									@blur="searchBlur()"
									@keyup.delete="searchClear()"
									@keydown.enter="search()"
									v-bind:class="{
										'bg-white text-primary' : true,
										'border-primary' : search != null,
										'border-0' : search == null,
									}"
								></b-input>
								<template #append>
									<b-button
										title="Presione para buscar"
										:disabled="query.search == null || query.search.length == 0"
										:variant="query.search != null && query.search.length > 0 ? 'primary' : 'outline-primary'"
										@click="search()"
									>
										<i class="fas fa-search mr-1"></i>
										<span>Buscar</span>
									</b-button>
								</template>
							</b-input-group>
						</b-overlay>
					</b-col>
				</b-row>
			</b-col>

			<!-- SPACER -->
			<b-col>
			</b-col>

			<!-- PER PAGE -->
			<b-col md="12" lg="auto" xl="auto">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Visualización</span>
					<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto">
						<SitePerPage
							:loading="resources.loading"
							:limit="query.limit"
							:options="paginationOptions"
							@clicked="perPageChanged"
						></SitePerPage>
					</b-col>
				</b-row>
			</b-col>

			<!-- PER PAGE -->
			<b-col md="12" lg="auto" xl="auto">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Página Actual</span>
					<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto">
						<SitePagination
							:loading="resources.loading"
							:page="query.page"
							:total="resources.data.total"
							:limit="query.limit"
							@clicked="paginationChanged"
						></SitePagination>
					</b-col>
				</b-row>
			</b-col>

		</b-row>
		<!-- /TOOLBAR -->

		<!-- DATA -->
		<b-skeleton-wrapper :loading="resources.loading && !reloading">

			<!-- LOADING -->
			<template #loading>
				<b-card no-body>
					<b-skeleton-table
						animation="fade"
						:rows="10"
						:columns="7"
					></b-skeleton-table>
				</b-card>
			</template>


			<!-- TABLE :fields="visibleTableFields" -->
			<b-table
				:items="resources.data.data"
				:fields="fields"
				:busy="resources.loading"
				:no-local-sorting="true"
				@sort-changed="sortingChanged"
				show-empty
				:stacked="mobile"
				hover
				bordered
				:small="ui_size == 'sm' || false"
				responsive
				sort-icon-left
				:head-variant="ui_theme == 'light' ? 'light' : 'dark'"
				:table-variant="ui_theme == 'light' ? 'light' : 'dark'"
				class="mt-0 mb-0 rounded"
				:class="{
					'fs-09' : ui_size == 'sm',
					'fs-10' : ui_size == 'md',
					'fs-13' : ui_size == 'lg',
					'table-dark' : ui_theme == 'dark',
				}"
			>
				<template v-slot:empty>
					<div class="p-3">
						<div v-if="val_client">
							<span class="text-muted" >No hay Equipos que coincidan con los filtros aplicados.</span>
						</div>
						<div v-else>
							<span class="fs-15 text-secondary">
								<i class="fas fa-up mr-2"></i>
								<span>Seleccione un Cliente</span>
							</span>
						</div>
					</div>
				</template>
				<template v-slot:table-busy>
					<div class="text-center my-2">
						<b-spinner class="align-middle"></b-spinner>
					</div>
				</template>
				<template v-slot:cell()="data">
					<div v-if="data.value">
						{{ data.value }}
					</div>
					<div v-else>
						<span class="text-warning w-space">INCOMPLETO <i class="fas fa-exclamation-triangle"></i></span>
					</div>
				</template>
				<template v-slot:cell(number)="data">
					<div class="text-center" v-if="data.value">
						<b-button
							:variant="ui_theme == 'dark' ? 'light' : 'primary'"
							block size="sm"
							class="btn-xs"
							title="Presione para abrir la Ficha de este Equipo"
							@click="profile.name === 'client' ? viewUnit(data.item) : updateUnit(data.item)"
						>EQ-{{data.value}}</b-button>
					</div>
				</template>
				<template v-slot:cell(status)="data">
					<div class="text-center">
						<b-badge v-if="data.value.id==2" variant="success" class="ml-1">REALIZADA</b-badge>
						<b-badge v-else variant="danger" class="ml-1">PENDIENTE</b-badge>
					</div>
				</template>
				<template v-slot:cell(model)="data">
					<div v-if="data.value && data.value.part_number">
						{{ data.value.part_number }}
					</div>
					<div v-else>
						<span class="text-warning w-space">INCOMPLETO <i class="fas fa-exclamation-triangle"></i></span>
					</div>
				</template>
				<template v-slot:cell(brand)="data">
					<div v-if="data.value && data.value.name">
						{{ data.value.name }}
					</div>
					<div v-else>
						<span class="text-warning w-space">INCOMPLETO <i class="fas fa-exclamation-triangle"></i></span>
					</div>
				</template>
				<template v-slot:cell(client)="data">
					<div v-if="data.value && data.value.codename">
						{{ data.value.codename }}
					</div>
					<div v-else>
						<span class="text-warning w-space">INCOMPLETO <i class="fas fa-exclamation-triangle"></i></span>
					</div>
				</template>
				<template v-slot:cell(type)="data">
					<div v-if="data.value && data.value.name">
						{{ data.value.name }}
					</div>
					<div v-else>
						<span class="text-warning w-space">INCOMPLETO <i class="fas fa-exclamation-triangle"></i></span>
					</div>
				</template>
				<template v-slot:cell(site)="data">
					<div v-if="data.value && data.value.address">
						{{ data.value.address.toUpperCase() }}
					</div>
					<div v-else>
						<span class="text-warning w-space">INCOMPLETO <i class="fas fa-exclamation-triangle"></i></span>
					</div>
				</template>
				<template v-slot:cell(location)="data">
					<div v-if="data.value">
						{{ data.value.toUpperCase() }}
					</div>
					<div v-else>
						<span class="text-warning w-space">INCOMPLETO <i class="fas fa-exclamation-triangle"></i></span>
					</div>
				</template>
				<template v-slot:cell(capacity)="data">
					<div v-if="data.value">
						<span>{{ parseNumber(data.value) }}</span>
						<span v-if="data.item.capacity_measurement_unit && data.item.capacity_measurement_unit.name"> {{ data.item.capacity_measurement_unit.name }}</span>
					</div>
					<div v-else>
						<span class="text-warning w-space">INCOMPLETO <i class="fas fa-exclamation-triangle"></i></span>
					</div>
				</template>
				<template v-slot:cell(voltage.name)="data">
					<div v-if="data.value">
						{{ data.value }}
					</div>
					<div v-else>
						<span class="text-warning w-space">INCOMPLETO <i class="fas fa-exclamation-triangle"></i></span>
					</div>
				</template>
				<template v-slot:cell(site.commune)="data">
					<div v-if="data.value && data.value.name">
						{{ data.value.name.toUpperCase() }}
					</div>
					<div v-else>
						<span class="text-warning w-space">INCOMPLETO <i class="fas fa-exclamation-triangle"></i></span>
					</div>
				</template>
			</b-table>
		</b-skeleton-wrapper>
		
		<!-- PAGINATION -->
		<SitePagination
			:loading="resources.loading"
			:page="query.page"
			:total="resources.data.total"
			:limit="query.limit"
			@clicked="paginationChanged"
			class="mt-2"
		></SitePagination>

	</div>
</template>

<script>
import Helpers from '@/helpers'
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
import SitePrimaryButton from '@/components/SitePrimaryButton.vue'
import SitePerPage from '@/components/SitePerPage.vue'
import SitePagination from '@/components/SitePagination.vue'
import ViewUnit from '@/components/ViewUnit.vue'
import CreateUnit from '@/components/CreateUnit.vue'
import UpdateUnit from '@/components/UpdateUnit.vue'
import CreateBrand from '@/components/CreateBrand.vue'
import CreateClient from '@/components/CreateClient.vue'
import CreateSite from '@/components/CreateSite.vue'
import CreateModel from '@/components/CreateModel.vue'
import CreateCoolant from '@/components/CreateCoolant.vue'
import CreateHVACArea from '@/components/CreateHVACArea.vue'
import CreateHVACContractArea from '@/components/CreateHVACContractArea.vue'
import CreateHVACTimeSwitch from '@/components/CreateHVACTimeSwitch.vue'
import CreateHVACTemperatureControl from '@/components/CreateHVACTemperatureControl.vue'

export default {
	name: 'HVAC_UnitsView',
	components: {
		SiteModuleHeader,
		SitePrimaryButton,
		SitePerPage,
		SitePagination,
		ViewUnit,
		CreateUnit,
		UpdateUnit,
		CreateBrand,
		CreateClient,
		CreateSite,
		CreateModel,
		CreateCoolant,
		CreateHVACArea,
		CreateHVACContractArea,
		CreateHVACTimeSwitch,
		CreateHVACTemperatureControl,
	},
	data() {
		return {
			reloading: false,
			query: {
				limit: 10,
				page: 1,
				sortDesc: false,
				sortBy: 'name',
				search: null,
			},
			current: {
				brand_id: null,
				client_id: null,
			},
			paginationOptions: [
				{ value: 5, text: '5 por página' },
				{ value: 10, text: '10 por página' },
				{ value: 15, text: '15 por página' },
				{ value: 20, text: '20 por página' },
				{ value: 50, text: '50 por página' },
				{ value: 100, text: '100 por página' },
				{ value: 9999, text: 'Mostrar todo' },
			],
			fields: [
				{ key: 'number', label: 'Ficha', sortable: true },
				{ key: 'status', label: 'Mantención', sortable: true },
				{ key: 'site', label: 'Sucursal/Faena', sortable: true },
				{ key: 'site.commune', label: 'Comuna', sortable: true },
				{ key: 'client', label: 'Cliente', sortable: true },
				{ key: 'location_interior', label: 'Ubicación', sortable: true },
				{ key: 'brand', label: 'Marca', sortable: true },
				{ key: 'type', label: 'Tipo', sortable: true },
				{ key: 'model', label: 'Modelo', sortable: true },
				//{ key: 'voltage.name', label: 'Voltaje', sortable: true },
				//{ key: 'priority.name', label: 'Prioridad', sortable: true },
				//{ key: 'capacity', label: 'Capacidad', sortable: true },
				//{ key: 'maintenance_window.name', label: 'Mantenimiento', sortable: true },
				//{ key: 'coolant.name', label: 'Refrigerante', sortable: true },
			],
		}
	},
	computed: {
		client() { return this.$store.state.core.account.client },
		profile() { return this.$store.state.core.account.profile },
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		resources() { return this.$store.state.units },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		clients() { return this.$store.state.helpers.clients },
		val_client() { return this.hvac_client_id != null ? true : false },
		hvac_client_id: {
			get() { return this.$store.getters.hvac_client_id },
			set(data) { this.$store.commit('hvac_client_id', data) }
		},
		interval: {
			get() { return this.$store.getters.interval; },
			set(data) { this.$store.commit('interval', data); }
		},
	},
	mounted() {
		this.interval = setInterval(() => {
			if (this.core.loading === false && this.profile.name === 'client') {
				if (this.client && this.client.id && this.client.id > 0) {
					this.hvac_client_id = this.client.id
					this.load()
					clearInterval(this.interval)
				}
			} else if (this.core.loading === false && this.profile.name != 'client') {
				this.load()
				this.$store.dispatch('helpers_clients')
				clearInterval(this.interval)
			}
		}, 1 * 500)
	},
	created() {
		this.$store.dispatch('brands')
		this.$store.dispatch('helpers_unit_conditions')
		this.$store.dispatch('helpers_unit_priorities')
		this.$store.dispatch('helpers_unit_coolants')
		this.$store.dispatch('helpers_unit_voltages')
		this.$store.dispatch('helpers_unit_capacity_measurement_units')
		this.$store.dispatch('helpers_unit_maintenance_windows')
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
		load() {
			if (this.hvac_client_id != null) {
				this.query.client_id = this.hvac_client_id
				this.$store.dispatch('units', this.query)
				.then(() => { this.reloading = false })
			}
		},
		reload() {
			this.reloading = true
			this.load()
		},
		createUnit() {
			this.$bvModal.show('create-unit')
		},
		perPageChanged(limit) {
			this.query.limit = limit
			this.reload()
		},
		paginationChanged(page) {
			this.query.page = page
			this.reload()
		},
		sortingChanged(ctx) {
			this.query.sortDesc = ctx.sortDesc
			this.query.sortBy = ctx.sortBy
			this.reload()
		},
		search() {
			if (this.query.search == null) {
				this.query.search = ''
				//this.$refs.search.focus()
			}
			else if (this.query.search == '') {
				this.query.search = null
			}
			else if (this.query.search.length > 0) {
				this.reload()
			}
		},
		searchBlur() {
			if (this.query.search == '') {
				this.query.search = null
			}
		},
		searchClear() {
			if (this.query.search != null && this.query.search.length == 0) {
				this.query.page = 1
				this.reload()
			}
		},
		searchChanged(search) {
			this.query.search = search
		},
		updateBrandID(data) {
			this.current.brand_id = data
		},
		updateClientID(client_id) {
			this.current.client_id = client_id
		},
		updateUnit(unit) {
			this.current.client_id = unit.client.id
			unit.client_id = unit.client.id
			unit.brand_id = unit.model.brand_id
			this.$store.dispatch('client_sites', unit.client_id)
			this.$store.dispatch('client_areas', unit.client_id)
			this.$store.dispatch('client_contract_areas', unit.client_id)
			this.$store.dispatch('client_time_switches', unit.client_id)
			this.$store.dispatch('client_temperature_controls', unit.client_id)
			this.$store.dispatch('brand_models', unit.brand_id)
			this.$store.dispatch('unit', unit.id)
			this.$bvModal.show('update-unit')
		},
		createContract() {
			this.$store.dispatch('clients')
			this.$bvModal.show('create-contract')
		},
		createSite() {
			this.$store.dispatch('helpers_clients')
			this.$store.dispatch('communes')
			this.$bvModal.show('create-site')
		},
		parseNumber(number) {
			return Helpers.parseNumber(number)
		},
		viewUnit(unit) {
			this.$bvModal.show('view-unit')
			this.$store.dispatch('unit', unit.id)
		},
	}
}
</script>

