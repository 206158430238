<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="cancel-job"
			title="Anular Orden de Trabajo"
			:size="ui_size == 'sm' ? 'md' : 'md'"
			body-class="p-0"
			:title-class="{
				'fs-11' : ui_size == 'sm',
				'fs-13' : ui_size == 'md',
				'fs-15' : ui_size == 'lg',
			}"
			:header-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:header-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:body-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:body-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:footer-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:footer-text-variant="ui_theme == 'dark' ? 'light' : ''"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-4" :variant="ui_theme == 'dark' ? 'dark': 'light'">

				<b-row>

					<b-col sm="12" class="mb-3">
						<b-alert show variant="dark" class="mb-0" v-if="job && job.number">
							<p class="mb-2 fs-12 fw-bold">¿Confirma que desea anular la Orden de Trabajo N°{{ job.number }}?</p>
							<p class="mb-0 fs-11">Todos los Equipos quedarán liberados para ser vinculados a nuevas Órdenes de Trabajo.</p>
						</b-alert>
					</b-col>

					<!-- COMMENT -->
					<b-col sm="12">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-0' : ui_size == 'md',
								'fs-12 pb-0' : ui_size == 'lg',
							}"
							:description="val_comment ? '' : 'Opcional (hasta 100 caracteres)'"
						>
							<template #label>
								Observación
							</template>
							<b-input
								v-model="comment"
								:maxlength="100"
								:state="val_comment"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							></b-input>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

				</b-row>

				<!-- LOADING -->
				<template #overlay>
					<div class="text-center py-5">
						<b-spinner type="grow" variant="primary"></b-spinner>
					</div>
				</template>

			</b-overlay>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :disabled="loading" :size="ui_size">Cancelar</b-button>
				<b-button @click="confirm()" variant="dark" :disabled="loading" :size="ui_size">CONFIRMAR</b-button>
			</template>

		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'CancelJob',
	components: {
	},
	data() {
		return {
			loading: false,
			comment: ''
		}
	},
	props: {
		job: Object,
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'cancel-job') { this.reset() }
		})
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		val_comment() {
			return this.comment.length > 0 ? true : null
		}
	},
	methods: {
		reset() {
			this.comment = ''
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		confirm() {
			this.loading = true
			this.$api.put('jobs/'+this.job.id+'/cancel', {
				comment: this.comment
			})
			.then(response => {
				this.loading = false
				this.$bvModal.hide('cancel-job')
				this.$emit('update')
				this.$store.dispatch('job', this.job.id)
				this.toast(true, 'Se anuló la Orden N°'+ response.data.number+' correctamente.')
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
	}
}
</script>