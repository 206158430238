var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":"select-product","title":"Seleccionar Producto","size":_vm.ui_size == 'sm' ? 'lg' : 'xl',"body-class":"p-0","title-class":{
			'fs-11' : _vm.ui_size == 'sm',
			'fs-13' : _vm.ui_size == 'md',
			'fs-15' : _vm.ui_size == 'lg',
		},"header-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"header-text-variant":_vm.ui_theme == 'dark' ? 'light' : '',"body-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"body-text-variant":_vm.ui_theme == 'dark' ? 'light' : '',"footer-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"footer-text-variant":_vm.ui_theme == 'dark' ? 'light' : ''},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ close }){return [_c('b-button',{attrs:{"variant":"outline-secondary","disabled":_vm.loading,"size":_vm.ui_size},on:{"click":function($event){return close()}}},[_vm._v("Cancelar")]),_c('b-button',{attrs:{"variant":"success","disabled":_vm.loading||!_vm.val_full,"size":_vm.ui_size},on:{"click":function($event){return _vm.finish()}}},[_c('i',{staticClass:"fas fa-check mr-1"}),_c('span',[_vm._v("Seleccionar")])])]}}])},[_c('b-overlay',{staticClass:"p-4",attrs:{"show":_vm.loading,"rounded":"sm","variant":_vm.ui_theme == 'dark' ? 'dark': 'light'},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center py-5"},[_c('b-spinner',{attrs:{"type":"grow","variant":"primary"}})],1)]},proxy:true}])},[_c('b-row',[_c('b-col',[_c('p',{staticClass:"fs-12 fw-bold mb-1",class:{ 'text-danger' : _vm.items.length == 0, 'text-success' : _vm.items.length >= 1 }},[_c('i',{staticClass:"fas fa-check mr-2"}),_c('span',[_vm._v("Producto Seleccionado")])])]),_c('b-col',{staticClass:"text-right mb-2"},[_c('b-button',{attrs:{"variant":"success","disabled":_vm.loading||!_vm.val_full||!_vm.items.length,"size":"sm"},on:{"click":function($event){return _vm.finish()}}},[_c('i',{staticClass:"fas fa-check mr-1"}),_c('span',[_vm._v("Seleccionar")])])],1)],1),_c('b-table',{staticClass:"mt-0 mb-4 rounded",class:{
					'fs-09' : _vm.ui_size == 'sm',
					'fs-10' : _vm.ui_size == 'md',
					'fs-13' : _vm.ui_size == 'lg',
					'table-dark' : _vm.ui_theme == 'dark',
				},attrs:{"id":"table-transition-example","items":_vm.items,"fields":[
					{ key: 'discard', label: '', sortable: false },
					{ key: 'code', label: 'Código', sortable: false },
					{ key: 'name', label: 'Descripción', sortable: false },
					{ key: 'unit', label: 'Unidad', sortable: false },
				],"show-empty":"","stacked":_vm.mobile,"hover":"","bordered":"","small":_vm.ui_size == 'sm' || false,"responsive":"","tbody-tr-class":"add-row-animation","sort-icon-left":"","head-variant":_vm.ui_theme == 'light' ? 'light' : 'dark'},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
							width: field.key === 'discard' ? '5%' :
									field.key === 'code' ? '20%' : '75%'
						})})})}},{key:"empty",fn:function(){return [_c('div',[_c('span',{staticClass:"text-danger"},[_vm._v("Seleccione un producto de los resultados de búsqueda.")])])]},proxy:true},{key:"head(discard)",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('i',{staticClass:"fas fa-fw fa-trash"})])]},proxy:true},{key:"cell(discard)",fn:function(data){return [_c('div',{staticClass:"text-center",attrs:{"role":"button","title":"Descartar Ítem"},on:{"click":function($event){return _vm.discardProduct(data)}}},[_c('i',{staticClass:"fas fa-circle-xmark text-danger"})])]}},{key:"cell(name)",fn:function(data){return [_c('div',[(data.item.name && data.item.name.length > 0)?_c('span',[_vm._v(_vm._s(data.item.name))]):(data.item.description && data.item.description.length > 0)?_c('span',[_vm._v(_vm._s(data.item.description))]):_c('span',[_vm._v(_vm._s(data.value))])])]}},{key:"cell(unit)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.value.code)+" ")])]}}])}),_c('p',{staticClass:"fs-12 fw-bold mb-1 text-primary"},[_c('i',{staticClass:"fas fa-search mr-2"}),_c('span',[_vm._v("Búsqueda")])]),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"py-0 px-3 bg-light"},[_c('b-row',[_c('b-col',{staticClass:"py-3"},[_c('b-overlay',{attrs:{"show":_vm.resources.loading,"rounded":"","opacity":"0.6"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('span',[_vm._v(" ")])]},proxy:true}])},[_c('b-input-group',{attrs:{"size":_vm.ui_size},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-button',{attrs:{"title":"Presione para buscar","disabled":_vm.query.search == null || _vm.query.search.length == 0,"variant":_vm.query.search != null && _vm.query.search.length > 0 ? 'primary' : 'outline-primary'},on:{"click":function($event){return _vm.search()}}},[_c('i',{staticClass:"fas fa-search mr-1"}),_c('span',[_vm._v("Buscar")])])]},proxy:true}])},[_c('b-input',{class:{
											'bg-white text-primary' : true,
											'border-primary' : _vm.search != null,
											'border-0' : _vm.search == null,
										},attrs:{"placeholder":"Escriba un código o palabra"},on:{"changed":_vm.searchChanged,"blur":function($event){return _vm.searchBlur()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.searchClear()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search()}},model:{value:(_vm.query.search),callback:function ($$v) {_vm.$set(_vm.query, "search", $$v)},expression:"query.search"}})],1)],1)],1)],1)],1),_c('hr',{staticClass:"mt-0"}),_c('b-card-body',{staticClass:"pt-0 pb-0 px-3"},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"pt-1"},[_c('p',{staticClass:"mb-0 fw-bold fs-11"},[_vm._v("Resultados de Búsqueda")]),_c('p',{staticClass:"mb-0 fs-10"},[_vm._v(_vm._s(_vm.parseNumber(_vm.products.data.total))+" producto"),(_vm.products.data && _vm.products.data.total && _vm.products.data.total > 1)?_c('span',[_vm._v("s")]):_vm._e(),_vm._v(" encontrado"),(_vm.products.data && _vm.products.data.total && _vm.products.data.total > 1)?_c('span',[_vm._v("s")]):_vm._e()])]),_c('b-col',{attrs:{"md":"12","lg":"auto","xl":"auto"}},[_c('p',{staticClass:"mb-2 text-dark",class:{
									'fs-09' : _vm.ui_size == 'sm',
									'fs-10' : _vm.ui_size == 'md',
									'fs-13' : _vm.ui_size == 'lg',
								}},[_c('span',[_vm._v("Mostrar")]),_c('i',{staticClass:"fas fa-arrow-turn-down ml-2 text-muted"})]),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"sm":"auto"}},[_c('SitePerPage',{attrs:{"loading":_vm.resources.loading,"limit":_vm.query.limit,"options":_vm.paginationOptions},on:{"clicked":_vm.perPageChanged}})],1)],1)],1),_c('b-col',{attrs:{"md":"12","lg":"auto","xl":"auto"}},[_c('p',{staticClass:"mb-2 text-dark",class:{
									'fs-09' : _vm.ui_size == 'sm',
									'fs-10' : _vm.ui_size == 'md',
									'fs-13' : _vm.ui_size == 'lg',
								}},[_c('span',[_vm._v("Página Actual")]),_c('i',{staticClass:"fas fa-arrow-turn-down ml-2 text-muted"})]),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"sm":"auto"}},[_c('SitePagination',{attrs:{"loading":_vm.resources.loading,"page":_vm.query.page,"total":_vm.resources.data.total,"limit":_vm.query.limit},on:{"clicked":_vm.paginationChanged}})],1)],1)],1)],1)],1),_c('b-card-body',{staticClass:"pt-0 pb-0 px-0"},[_c('b-skeleton-wrapper',{attrs:{"loading":_vm.resources.loading && !_vm.reloading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-card',{attrs:{"no-body":""}},[_c('b-skeleton-table',{attrs:{"animation":"fade","rows":10,"columns":7}})],1)]},proxy:true}])},[_c('b-table',{ref:"selectableTable2",staticClass:"mt-0 mb-0",class:{
								'fs-09' : _vm.ui_size == 'sm',
								'fs-10' : _vm.ui_size == 'md',
								'fs-13' : _vm.ui_size == 'lg',
								'table-dark' : _vm.ui_theme == 'dark',
							},attrs:{"items":_vm.productsWithSelected,"fields":_vm.computedFields,"busy":_vm.resources.loading,"no-local-sorting":true,"show-empty":"","stacked":_vm.mobile,"hover":"","small":_vm.ui_size == 'sm' || false,"responsive":"","select-mode":"single","selectable":"","sort-icon-left":"","selected-variant":"success"},on:{"sort-changed":_vm.sortingChanged,"row-clicked":_vm.onRowSelected},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
										width: field.key === 'selected' ? '10%' :
												field.key === 'code' ? '15%' :
												field.key === 'unit' ? '15%' : '60%'
									})})})}},{key:"empty",fn:function(){return [_c('div',{staticClass:"p-3"},[_c('b-alert',{staticClass:"mb-0",attrs:{"show":"","variant":"light"}},[_c('p',{staticClass:"mb-0 fs-12"},[_vm._v("No se encontraron coincidencias.")])])],1)]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"head(selected)",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('i',{staticClass:"fas fa-fw fa-check text-success"})])]},proxy:true},{key:"cell(selected)",fn:function({ rowSelected }){return [_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mb-0 text-center",attrs:{"title":"Ítem Seleccionado"}},[(rowSelected === true)?_c('span',[_c('i',{staticClass:"fas fa-check-circle text-success"})]):_vm._e()])]}},{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(description)",fn:function(data){return [_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(unit)",fn:function(data){return [_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(data.value.code)+" ")])]}}])})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }