<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="manage-defontana-pricing"
			:size="ui_size == 'sm' ? 'xl' : 'xl'"
			body-class="p-0"
			:title-class="{
				'fs-11' : ui_size == 'sm',
				'fs-13' : ui_size == 'md',
				'fs-15' : ui_size == 'lg',
			}"
			:header-bg-variant="ui_theme == 'dark' ? 'primary' : 'primary'"
			:header-text-variant="ui_theme == 'dark' ? 'light' : 'light'"
			:body-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:body-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:footer-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:footer-text-variant="ui_theme == 'dark' ? 'light' : ''"
		>

			<template #modal-header>
				<div class="w-100">
					<b-row>
						<b-col sm="auto">
							<b-spinner v-if="resource.loading" type="grow" small variant="light"></b-spinner>
							<p class="mb-0" v-else-if="resource.data && resource.data.folio">
								<span class="fw-bold fs-13">Cotización N°{{ resource.data.folio }}</span>
							</p>
						</b-col>
						<b-col class="text-right" v-if="!resource.loading">
							<!--
							<b-button
								size="sm"
								variant="success"
								class="mr-2"
								@click="completePurchaseRequest()"
							>
								<i class="fas fa-check-circle mr-1"></i>
								<span>Aprobar</span>
							</b-button>
							<b-button
								size="sm"
								variant="secondary"
								class="mr-2"
								@click="cancelPurchaseRequest()"
							>
								<i class="fas fa-do-not-enter mr-1"></i>
								<span>Anular</span>
							</b-button>
							-->
							<b-button
								size="sm"
								variant="primary"
								@click="$bvModal.hide('manage-defontana-pricing')"
							>
								<i class="fas fa-times mr-1"></i>
								<span>Cerrar</span>
							</b-button>
						</b-col>
					</b-row>
				</div>
			</template>


			
			<b-tabs
				card
				active-nav-item-class="font-weight-bold"
				class="mb-0"
			>

				<!-- JOB -->
				<b-tab
					active
					title="Información"
					title-link-class="fc-black"
				>
					<b-list-group class="mb-4">

						<b-list-group-item class="py-2 pl-3 pr-2">
							<b-row no-gutters>
								<b-col sm="3">
									<strong>Folio</strong>
								</b-col>
								<b-col>
									<div v-if="resource.loading">
										<b-skeleton class="mt-1"></b-skeleton>
									</div>
									<div class="mb-0 text-uppercase" v-else-if="resource.data">
										<p class="mb-0">
											<span>{{ resource.data.folio }}</span>
										</p>
									</div>
									<p class="mb-0" v-else>
										<span class="text-muted">&mdash;</span>
									</p>
								</b-col>
							</b-row>
						</b-list-group-item>

					</b-list-group>

					<b-skeleton-wrapper :loading="resource.loading">

						<!-- LOADING -->
						<template #loading>
							<b-card no-body>
								<b-skeleton-table
									animation="fade"
									:rows="1"
									:columns="5"
								></b-skeleton-table>
							</b-card>
						</template>

						<b-table
							:items="resource.data.items"
							:fields="[
								{ key: 'meta', label: 'Ítem', sortable: false },
								{ key: 'unit', label: 'U.M.', sortable: false },
								{ key: 'count', label: 'Cantidad', sortable: false },
								{ key: 'price', label: 'Precio', sortable: false },
								{ key: 'subtotal', label: 'Subtotal', sortable: false },
							]"
							show-empty
							:stacked="mobile"
							hover
							bordered
							:small="ui_size == 'sm' || false"
							sort-icon-left
							:head-variant="ui_theme == 'light' ? 'light' : 'dark'"
							class="mt-0 mb-4 rounded"
							fixed
							:class="{
								'fs-09' : ui_size == 'sm',
								'fs-10' : ui_size == 'md',
								'fs-13' : ui_size == 'lg',
								'table-dark' : ui_theme == 'dark',
							}"
						>
							<template #table-colgroup="scope">
								<col
									v-for="field in scope.fields"
									:key="field.key"
									:style="{
										width: field.key === 'line' ? '5%' :
												field.key === 'meta' ? '50%' :
												field.key === 'unit' ? '5%' :
												field.key === 'count' ? '10%' :
												field.key === 'purchase_order' ? '15%' : '15%'
									}"
								>
							</template>
							<template v-slot:empty>
								<div class="p-3">
									<span class="fs-12 text-danger">Agregue al menos un ítem a la cotización.</span>
								</div>
							</template>
							<template v-slot:head(line)>
								<div class="text-center">
									Línea
								</div>
							</template>
							<template v-slot:cell(line)="data">
								<div class="text-center">
									{{ data.index + 1 }}
								</div>
							</template>
							<template v-slot:cell(unit)="data">
								<div class="text-left">
									<span v-if="data.item && data.item.item">{{ data.item.item.unit }}</span>
									<span v-else>UN</span>
								</div>
							</template>
							<template v-slot:cell(meta)="data">
								<div>
									<code>{{ data.item }}</code>
									<p class="mb-0 fs-08 text-dark text-uppercase fw-bold">CÓD. {{ data.item.type == 'S' ? 'SERV.' : 'ART.' }} {{ data.item.code }}</p>
									<p class="mb-0 fs-09">{{ data.item.name ? data.item.name : data.item.description }}</p>
									<b-collapse id="collapse-1" v-b-toggle.collapse-1>
										<code>{{ data }}</code>
									</b-collapse>
								</div>
							</template>
							<template v-slot:cell(count)="data">
								<div class="text-right w-space">
									<span class="float-left text-muted">$</span>
									<span>{{ parseCurrency(data.value, true, false) }}</span>
								</div>
							</template>
						</b-table>

					</b-skeleton-wrapper>

				</b-tab>

			</b-tabs>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :disabled="loading" :size="ui_size">Cerrar</b-button>
			</template>

		</b-modal>

	</div>
</template>

<script>
import Helpers from '@/helpers';

export default {
	name: 'ManageDefontanaPricing',
	components: {
	},
	data() {
		return {
			loading: false,
			current: {
				maintenance: {},
			},
			classes: {
				list_group: 'py-2 pl-3 pr-2 test-hover',
			},
			fields: {
				maintenances: [
					{ key: 'unit', label: 'Equipo', sortable: true },
					{ key: 'status', label: 'Mantenimiento', sortable: true },
					{ key: 'unit.location_interior', label: 'Ubicación', sortable: true },
					{ key: 'actions', label: 'Acciones', sortable: true },
				],
				comments: [
					{ key: 'comment', label: 'Anotación', sortable: true },
					{ key: 'user', label: 'Escrita Por', sortable: true },
					{ key: 'created', label: 'Fecha / Hora', sortable: true },
				],
				updates: [
					{ key: 'old_status.name', label: 'Estado Original', sortable: true },
					{ key: 'new_status.name', label: 'Nuevo Estado', sortable: true },
					{ key: 'comment', label: 'Observación', sortable: true },
					{ key: 'created', label: 'Fecha / Hora', sortable: true },
				],
			}
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'update-defontana-pricing') { this.reset() }
		})
	},
	computed: {
		resource: {
			get() { return this.$store.getters.defontana_pricing },
			set(data) { this.$store.commit('defontana_pricing', data) }
		},
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		clients() { return this.$store.state.clients },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
	},
	methods: {
		reset() {
			this.$store.commit('reset_defontana_pricing')
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		parseVATIN(vatin) {
			return Helpers.parseVATIN(vatin)
		},
		createPurchaseRequestComment() {
			this.$bvModal.show('create-defontana-pricing-comment')
		},
		completePurchaseRequest() {
			this.$bvModal.show('complete-defontana-pricing')
		},
		cancelPurchaseRequest() {
			this.$bvModal.show('cancel-defontana-pricing')
		},
		completeMaintenance(data) {
			this.current.maintenance = data
			this.$bvModal.show('complete-maintenance')
		},
		cancelMaintenance(data) {
			this.current.maintenance = data
			this.$bvModal.show('cancel-maintenance')
		},
		viewUnit(unit) {
			this.$bvModal.show('view-unit')
			this.$store.dispatch('unit', unit.unit_id)
		},
		update() {
			this.$emit('update')
		},
		parseCurrency(number, decimal, reverse) {
			return Helpers.parseCurrency(number, decimal, reverse)
		},
	}
}
</script>