<template>
	<div id="footer" class="mt-4">
		<span class="copyright text-secondary">Desarrollado por</span>
		<b-img src="/img/logo_ubidata_gray.svg" class="logo ml-1"></b-img>
	</div>
</template>

<script>
export default {
	name: 'SiteFooter',
	computed: {
		core() {
			return this.$store.state.core
		},
    }
}
</script>
<style>
#footer {
    text-align: center;
    margin-top: 10px;
    padding-bottom: 40px;
}
#footer .logo {
    width: 16px;
    margin-right: 5px;
}
#footer .disclaimer {
    color:#aaa;
    font-size: 11px;
}
</style>