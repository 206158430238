export default {
	session(state) {
		return state.local.session.access_token !== null
	},
	websocket(state) {
		return state.websocket
	},
	events(state) {
		return state.events
	},
	ui_size(state) {
		return state.local.preferences.ui_size
	},
	ui_theme(state) {
		return state.local.preferences.ui_theme
	},
	screen_size(state) {
		return state.local.preferences.screen_size
	},
	profile(state) {
		return state.core.account.profile
	},
	new_purchase_request(state) {
		return state.new_purchase_request
	},
	stock_requirements(state) {
		return state.stock_requirements
	},
	new_stock_requirement(state) {
		return state.new_stock_requirement
	},
	new_warehouse_movement(state) {
		return state.new_warehouse_movement
	},
	new_pricing(state) {
		return state.new_pricing
	},
	products(state) {
		return state.products
	},
	warehouses(state) {
		return state.warehouses
	},
	dashboard_home(state) {
		return state.dashboard_home
	},
	defontana_employees(state) {
		return state.defontana_employees
	},
	purchase_requests(state) {
		return state.purchase_requests
	},
	teamviewer_devices(state) {
		return state.teamviewer_devices
	},
	purchase_request(state) {
		return state.purchase_request
	},

	defontana_purchase_orders(state) {
		return state.defontana_purchase_orders
	},
	fluid(state) {

		// primer inicio
		if (state.local.preferences.fluid == null) {

			// si existe String(1) en almacenaje local
			if (localStorage.getItem('ui_fluid') === '1') {
				return true
			}
			
			// si existe String(0) en almacenaje local
			else if (localStorage.getItem('ui_fluid') === '0') {
				return false
			}
			
			// si no existe en almacenaje local, activar por default
			else {
				return true
			}
		}

		// ya iniciado, devolver de memoria
		else {
			return state.local.preferences.fluid
		}
		
	},
	access(state) {
		return state.local.preferences.access
	},
	hvac_client_id(state) {
		return state.hvac.client_id
	},
	hvac_site_id(state) {
		return state.hvac.site_id
	},
	interval(state) {
		return state.interval
	},
	unit(state) {
		return state.unit
	},
	job(state) {
		return state.job
	},
	defontana_sale(state) {
		return state.defontana_sale
	},
	defontana_pricing(state) {
		return state.defontana_pricing
	},

	prv_system: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_system') != -1 ? true : false },
	prv_system_users: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_system_users') != -1 ? true : false },
	prv_system_users_manage: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_system_users_manage') != -1 ? true : false },
	prv_system_parameters: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_system_parameters') != -1 ? true : false },
	prv_system_logs: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_system_logs') != -1 ? true : false },

	prv_internal_mgmt: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_internal_mgmt') != -1 ? true : false },
	prv_internal_mgmt_tasks: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_internal_mgmt_tasks') != -1 ? true : false },
	prv_internal_mgmt_cost_centers: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_internal_mgmt_cost_centers') != -1 ? true : false },

	prv_internal_ops: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_internal_ops') != -1 ? true : false },
	prv_internal_ops_panel: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_internal_ops_panel') != -1 ? true : false },
	prv_internal_ops_clients: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_internal_ops_clients') != -1 ? true : false },
	prv_internal_ops_clients_create: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_internal_ops_clients_create') != -1 ? true : false },
	prv_internal_ops_sites: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_internal_ops_sites') != -1 ? true : false },
	prv_internal_ops_sites_create: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_internal_ops_sites_create') != -1 ? true : false },
	prv_internal_ops_units: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_internal_ops_units') != -1 ? true : false },
	prv_internal_ops_units_create: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_internal_ops_units_create') != -1 ? true : false },
	prv_internal_ops_jobs: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_internal_ops_jobs') != -1 ? true : false },
	prv_internal_ops_jobs_create: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_internal_ops_jobs_create') != -1 ? true : false },

	prv_external_ops: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_external_ops') != -1 ? true : false },
	prv_external_ops_panel: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_external_ops_panel') != -1 ? true : false },
	prv_external_ops_sites: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_external_ops_sites') != -1 ? true : false },
	prv_external_ops_sites_create: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_external_ops_sites_create') != -1 ? true : false },
	prv_external_ops_units: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_external_ops_units') != -1 ? true : false },
	prv_external_ops_units_create: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_external_ops_units_create') != -1 ? true : false },
	prv_external_ops_jobs: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_external_ops_jobs') != -1 ? true : false },
	prv_external_ops_jobs_create: state => { return state.core.account.privileges.data.findIndex(prv => prv.name === 'prv_external_ops_jobs_create') != -1 ? true : false },

}