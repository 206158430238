<template>
	<div class="mt-3">

		<SiteModuleHeader></SiteModuleHeader>
		
		<p class="text-muted fs-15 mt-3 mb-5">Recuerde que el correo electrónico es la vía oficial para formalizar sus gestiones.</p>
		<b-row>
			<b-col lg="6">
				<b-card no-body class="mb-5">
					<b-card-header>
						<b-row>
							<b-col>
								<p class="mb-0 fw-bold fs-12">Nombre</p>
							</b-col>
							<b-col>
								<p class="mb-0 fw-bold fs-12">Apellido</p>
							</b-col>
							<b-col>
								<p class="mb-0 fw-bold fs-12">Celular</p>
							</b-col>
							<b-col>
								<p class="mb-0 fw-bold fs-12">E-mail</p>
							</b-col>
							<b-col>
								<p class="mb-0 fw-bold fs-12">Sucursal</p>
							</b-col>
						</b-row>
					</b-card-header>
					<b-card-body>
						<b-row>
							<b-col>
								<p class="mb-0 fs-12">Nombre</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Apellido</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Celular</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">E-mail</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Sucursal</p>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<p class="mb-0 fs-12">Nombre</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Apellido</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Celular</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">E-mail</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Sucursal</p>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<p class="mb-0 fs-12">Nombre</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Apellido</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Celular</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">E-mail</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Sucursal</p>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<p class="mb-0 fs-12">Nombre</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Apellido</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Celular</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">E-mail</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Sucursal</p>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<p class="mb-0 fs-12">Nombre</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Apellido</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Celular</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">E-mail</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Sucursal</p>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<p class="mb-0 fs-12">Nombre</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Apellido</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Celular</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">E-mail</p>
							</b-col>
							<b-col>
								<p class="mb-0 fs-12">Sucursal</p>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>

	</div>
</template>

<script>
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
export default {
	name: 'PhonesView',
	components: {
		SiteModuleHeader,
	},
	data: function() {
		return {
		}
	},
	mounted() {
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		getters() { return this.$store.getters },
		//////-----------------------------------------------------------
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
	}
}
</script>
