<template>
	<div class="mt-3">

		<SiteModuleHeader
			:showBack="true"
			:showHome="true"
			:showSize="true"
			:showTheme="true"
			:showFluid="true"
			:showTitle="true"
			@reload="reload"
			:reloading="reloading || resources.loading"
		></SiteModuleHeader>

		<!-- TOOLBAR -->
		<b-row no-gutters>

			<!-- CLIENT -->
			<b-col md="sm" lg="auto" xl="auto" class="mr-2">
				<p class="mb-2"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
						'text-primary' : val_client == true,
						'text-danger' : val_client == false,
					}">
					<span>Cliente</span>
					<i class="fas fa-arrow-turn-down ml-2"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto">
						<b-overlay
							:show="clients.loading"
							rounded="sm"
							:variant="ui_theme == 'light' ? 'light' : 'dark'"
						>
							<template #overlay>
								<div class="text-center">
									<b-spinner type="grow" variant="primary" small></b-spinner>
								</div>
							</template>
							<b-select
								:state="val_client"
								v-model="hvac_client_id"
								:size="ui_size"
								:class="{
									'bg-transparent text-white' : ui_theme == 'dark',
								}"
								@change="reload()"
							>
								<b-select-option :value="null" disabled>Presione aquí</b-select-option>
								<b-select-option v-for="(item, i) in clients.data" :key="i" :value="item.id">{{ item.codename }}</b-select-option>
							</b-select>
						</b-overlay>
					</b-col>
				</b-row>
			</b-col>

			<!-- CREATE -->
			<b-col md="sm" lg="auto" xl="auto">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Acciones</span>
					<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
				</p>
				<b-row no-gutters>

					<!-- CREATE -->
					<b-col md="12" lg="auto" xl="auto">
						<SitePrimaryButton
							:loading="resources.loading"
							text="Nueva Regla"
							title="Presione para crear"
							icon="plus"
							@clicked="createRequirement()"
						></SitePrimaryButton>
					</b-col>

					<!-- CREATE -->
					<b-col md="12" lg="auto" xl="auto">
						<SitePrimaryButton
							:loading="resources.loading"
							text="Registrar Movimiento"
							title="Presione para crear"
							icon="arrow-up-arrow-down"
							@clicked="createMovement()"
						></SitePrimaryButton>
					</b-col>

				</b-row>
			</b-col>

			<!-- SPACER -->
			<b-col>
			</b-col>

			<!-- PER PAGE -->
			<b-col md="12" lg="auto" xl="auto">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Visualización</span>
					<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto">
						<SitePerPage
							:loading="resources.loading"
							:limit="query.limit"
							:options="paginationOptions"
							@clicked="perPageChanged"
						></SitePerPage>
					</b-col>
				</b-row>
			</b-col>

			<!-- PER PAGE -->
			<b-col md="12" lg="auto" xl="auto">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Página Actual</span>
					<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto">
						<SitePagination
							:loading="resources.loading"
							:page="query.page"
							:total="resources.data.total"
							:limit="query.limit"
							@clicked="paginationChanged"
						></SitePagination>
					</b-col>
				</b-row>
			</b-col>

		</b-row>
		<!-- /TOOLBAR -->

		<!-- DATA -->
		<b-skeleton-wrapper :loading="resources.loading && !reloading">

			<!-- LOADING -->
			<template #loading>
				<b-card no-body>
					<b-skeleton-table
						animation="fade"
						:rows="10"
						:columns="7"
					></b-skeleton-table>
				</b-card>
			</template>


			<!-- TABLE :fields="visibleTableFields" -->
			<b-table
				:items="resources.data.data"
				:busy="resources.loading"
				:fields="fields"
				:no-local-sorting="true"
				@sort-changed="sortingChanged"
				show-empty
				:stacked="mobile"
				hover
				bordered
				:small="ui_size == 'sm' || false"
				responsive
				sort-icon-left
				:head-variant="ui_theme == 'light' ? 'light' : 'dark'"
				:table-variant="ui_theme == 'light' ? 'light' : 'dark'"
				class="mt-0 mb-0 rounded"
				:class="{
					'fs-09' : ui_size == 'sm',
					'fs-10' : ui_size == 'md',
					'fs-13' : ui_size == 'lg',
					'table-dark' : ui_theme == 'dark',
				}"
			>
				<template v-slot:empty>
					<div class="px-3 py-2">
						<span class="text-muted fs-11">
							{{ hvac_client_id == null ? 'Seleccione primero un cliente.' : 'Este cliente no registra reglas.' }}
						</span>
					</div>
				</template>
				<template v-slot:table-busy>
					<div class="text-center my-2">
						<b-spinner class="align-middle"></b-spinner>
					</div>
				</template>
				<template #cell(priority)>
					<div>
						<b-dropdown variant="light" size="xs" block>
							<template #button-content>
								<i class="fas fa-circle-small text-danger mr-1"></i>
								<span>Alta</span>
							</template>
							<b-dropdown-item>
								<i class="fas fa-circle-small text-danger mr-1"></i>
								<span>Alta</span>
							</b-dropdown-item>
							<b-dropdown-item>
								<i class="fas fa-circle-small text-warning mr-1"></i>
								<span>Media</span>
							</b-dropdown-item>
							<b-dropdown-item>
								<i class="fas fa-circle-small text-secondary mr-1"></i>
								<span>Baja</span>
							</b-dropdown-item>
						</b-dropdown>
					</div>
				</template>
				<template #cell()="data">
					<div class="text-left">
						{{ data.value }}
					</div>
				</template>
				<template #cell(warehouse)="data">
					<div class="text-left">
						{{ data.value.code }}
					</div>
				</template>
				<template #cell(stock)="data">
					<div class="text-left">
						<span>{{ data.item.inbound - data.item.outbound }}</span>
						<i class="fas fa-exclamation-triangle text-danger ml-1" v-if="data.item.inbound - data.item.outbound <= data.item.minimum"></i>
					</div>
				</template>
				<template #cell(outbound)="data">
					<div class="text-left">
						{{ data.value }}
					</div>
				</template>
				<template #cell(critical)="data">
					<div class="text-left">
						<i class="fas fa-circle-small mr-1" :class="{'text-danger' : data.value == true, 'text-secondary' : data.value == false}"></i>
						{{ data.value == true ? 'Sí' : 'No' }}
					</div>
				</template>
			</b-table>
		</b-skeleton-wrapper>
		
		<!-- PAGINATION -->
		<SitePagination
			:loading="resources.loading"
			:page="query.page"
			:total="resources.data.total"
			:limit="query.limit"
			@clicked="paginationChanged"
			class="mt-2"
		></SitePagination>

		<CreateStockRequirement @update="reload"></CreateStockRequirement>
		<CreateWarehouseMovement @update="reload"></CreateWarehouseMovement>
		<SearchProducts></SearchProducts>
		<SelectProduct></SelectProduct>

	</div>
</template>

<script>
import Helpers from '@/helpers'
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
import SitePerPage from '@/components/SitePerPage.vue'
import SitePagination from '@/components/SitePagination.vue'
import SitePrimaryButton from '@/components/SitePrimaryButton.vue'
import CreateStockRequirement from '@/components/CreateStockRequirement.vue'
import CreateWarehouseMovement from '@/components/CreateWarehouseMovement.vue'
import SearchProducts from '@/components/SearchProducts3.vue'
import SelectProduct from '@/components/SelectProduct.vue'

export default {
	name: 'StockRequests',
	components: {
		SiteModuleHeader,
		SitePerPage,
		SitePagination,
		SitePrimaryButton,
		CreateStockRequirement,
		CreateWarehouseMovement,
		SearchProducts,
		SelectProduct,
	},
	data() {
		return {
			reloading: false,
			query: {
				limit: 10,
				page: 1,
				sortDesc: true,
				sortBy: 'id',
				search: null,
				hvac_client_id: null
			},
			paginationOptions: [
				{ value: 5, text: '5 por página' },
				{ value: 10, text: '10 por página' },
				{ value: 15, text: '15 por página' },
				{ value: 20, text: '20 por página' },
				{ value: 50, text: '50 por página' },
				{ value: 100, text: '100 por página' },
				{ value: 9999, text: 'Mostrar todo' },
			],
			fields: [
				{ key: 'warehouse', label: 'Bodega', sortable: false },
				{ key: 'item.description', label: 'Producto', sortable: false },
				{ key: 'item.unit.code', label: 'Unidad', sortable: false },
				{ key: 'critical', label: 'Crítico', sortable: false },
				{ key: 'minimum', label: 'Mínimo', sortable: false },
				{ key: 'stock', label: 'Stock', sortable: false },
				{ key: 'outbound', label: 'Consumo', sortable: false },
			],
		}
	},
	computed: {
		new_stock_requirement: {
			get() { return this.$store.getters.new_stock_requirement; },
			set(data) { this.$store.commit('new_stock_requirement', data); }
		},
		client() { return this.$store.state.core.account.client },
		profile() { return this.$store.state.core.account.profile },
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		resources() { return this.$store.state.stock_requirements },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		clients() { return this.$store.state.helpers.clients },
		val_client() { return this.hvac_client_id != null ? true : false },
		hvac_client_id: {
			get() { return this.$store.getters.hvac_client_id },
			set(data) { this.$store.commit('hvac_client_id', data) }
		},
	},
	mounted() {
		//this.load()
	},
	created() {
		this.$store.dispatch('helpers_clients')
		this.$store.dispatch('helpers_warehouses')
		this.$store.dispatch('helpers_warehouses_movement_types')
		this.$store.dispatch('helpers_measurement_units')
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
		load() {
			this.query.hvac_client_id = this.hvac_client_id
			this.$store.dispatch('stock_requirements', this.query).then(() => { this.reloading = false })
		},
		reload() {
			this.reloading = true
			this.load()
		},
		perPageChanged(limit) {
			this.query.limit = limit
			this.reload()
		},
		paginationChanged(page) {
			this.query.page = page
			this.reload()
		},
		sortingChanged(ctx) {
			this.query.sortDesc = ctx.sortDesc
			this.query.sortBy = ctx.sortBy
			this.reload()
		},
		createRequirement() {
			this.$bvModal.show('create-stock-requirement')
		},
		createMovement() {
			this.$bvModal.show('create-warehouse-movement')
		},
	}
}
</script>

