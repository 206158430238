var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[(_vm.core.loading)?_c('div',[_c('SiteModuleHeader',{attrs:{"showBack":true,"showHome":true,"showSize":true,"showTheme":true,"showFluid":true,"showTitle":false,"reloading":_vm.reloading || _vm.resource.loading},on:{"reload":_vm.reload}}),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-skeleton',{staticClass:"mb-1",attrs:{"height":"15px"}}),_c('b-skeleton',{staticClass:"mb-4",attrs:{"height":"20px"}}),_c('b-skeleton',{attrs:{"height":"100px"}})],1)],1)],1):(_vm.profile.name != 'technician')?_c('SiteDenied'):_c('div',[_c('SiteModuleHeader',{attrs:{"showBack":true,"showHome":true,"showSize":true,"showTheme":true,"showFluid":true,"showTitle":false,"reloading":_vm.reloading || _vm.resource.loading},on:{"reload":_vm.reload}}),_c('b-row',{staticClass:"mb-4",class:{
				'text-primary' : _vm.ui_theme == 'light',
				'text-white' : _vm.ui_theme == 'dark'
			}},[_c('b-col',{attrs:{"sm":"auto"}},[(_vm.resource.loading)?_c('b-skeleton',{attrs:{"height":"34px","width":"200px"}}):_c('p',{staticClass:"mb-0 fw-bold",class:{
						'fs-15 mb-1' : _vm.ui_size == 'sm',
						'fs-20 mb-2' : _vm.ui_size == 'md',
						'fs-25 mb-2' : _vm.ui_size == 'lg',
						'text-primary' : _vm.ui_theme == 'light',
						'text-white' : _vm.ui_theme == 'dark'
					}},[_vm._v(" "+_vm._s(_vm.resource.data.date)+" ")]),(_vm.resource.loading)?_c('b-skeleton',{attrs:{"height":"18px","width":"100px"}}):_c('p',{staticClass:"mb-0 flh-10",class:{
						'fs-12' : _vm.ui_size == 'sm',
						'fs-14' : _vm.ui_size == 'md',
						'fs-16' : _vm.ui_size == 'lg',
						'text-primary' : _vm.ui_theme == 'light',
						'text-secondary' : _vm.ui_theme == 'dark'
					}},[_vm._v(_vm._s(_vm.core.account.name)+" "+_vm._s(_vm.core.account.lastname))])],1),_c('b-col',{attrs:{"sm":"auto"}},[(_vm.resource.loading)?_c('b-skeleton',{attrs:{"height":"34px","width":"200px"}}):_c('p',{staticClass:"fw-light mb-0"},[_c('i',{staticClass:"fas mr-1",class:{
							'fs-08 mt-3' : _vm.ui_size == 'sm',
							'fs-12 mt-3' : _vm.ui_size == 'md',
							'fs-15 mt-3' : _vm.ui_size == 'lg',
							'text-primary' : _vm.ui_theme == 'light',
							'text-secondary' : _vm.ui_theme == 'dark',
							'fa-shield-check' : _vm.profile.name == 'management',
							'fa-helmet-safety' : _vm.profile.name == 'technician',
							'fa-buildings' : _vm.profile.name == 'client',
						}}),_c('span',{staticClass:"mb-0 flh-10",class:{
							'fs-08' : _vm.ui_size == 'sm',
							'fs-12' : _vm.ui_size == 'md',
							'fs-15' : _vm.ui_size == 'lg',
							'text-primary' : _vm.ui_theme == 'light',
							'text-secondary' : _vm.ui_theme == 'dark'
						}},[_vm._v(" "+_vm._s(_vm.profile.description)+" ")])])],1)],1),_c('b-skeleton-wrapper',{attrs:{"loading":_vm.reloading || _vm.resource.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"lg":"12","xl":"6"}},[_c('b-card',{staticClass:"mb-4",attrs:{"no-body":""}},[_c('b-skeleton',{attrs:{"height":"32px","width":"100%"}}),_c('b-card-body',{staticClass:"pb-1"},[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"sm":"6","md":"4","lg":"4","xl":"3"}},[_c('b-skeleton',{attrs:{"height":"50px"}})],1),_c('b-col',{staticClass:"mb-3",attrs:{"sm":"6","md":"4","lg":"4","xl":"3"}},[_c('b-skeleton',{attrs:{"height":"50px"}})],1),_c('b-col',{staticClass:"mb-3",attrs:{"sm":"6","md":"4","lg":"4","xl":"3"}},[_c('b-skeleton',{attrs:{"height":"50px"}})],1),_c('b-col',{staticClass:"mb-3",attrs:{"sm":"6","md":"4","lg":"4","xl":"3"}},[_c('b-skeleton',{attrs:{"height":"50px"}})],1)],1)],1)],1)],1)],1)]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","xl":"6"}},[_c('b-card',{staticClass:"mb-4",staticStyle:{"background":"none"},attrs:{"no-body":"","bg-variant":_vm.ui_theme == 'light' ? '' : '',"text-variant":_vm.ui_theme == 'dark' ? 'light' : 'light',"border-variant":_vm.ui_theme == 'dark' ? 'primary' : 'tertiary'}},[_c('b-card-header',{staticClass:"py-1",class:{
								'bg-success' : _vm.ui_theme == 'light',
								'fs-10' : _vm.ui_size == 'sm',
								'fs-12' : _vm.ui_size == 'md',
								'fs-14' : _vm.ui_size == 'lg',
							}},[_c('span',[_vm._v("Mantenimiento")])]),_c('b-card-body',{staticClass:"pb-1"},[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"sm":"6","md":"4","lg":"4","xl":_vm.ui_size == 'lg' ? 4 : _vm.ui_size == 'md' ? 4 : 3}},[_c('b-button',{class:{
											'fs-09' : _vm.ui_size == 'sm',
											'fs-12' : _vm.ui_size == 'md',
											'fs-14' : _vm.ui_size == 'lg',
										},attrs:{"variant":_vm.ui_theme == 'light' ? 'light' : 'primary',"block":""},on:{"click":function($event){return _vm.goTo('HVAC_Maintenance')}}},[_c('i',{staticClass:"fas fa-grid"}),_c('p',{staticClass:"mb-0"},[_vm._v("Resumen")])])],1),_c('b-col',{staticClass:"mb-3",attrs:{"sm":"6","md":"4","lg":"4","xl":_vm.ui_size == 'lg' ? 4 : _vm.ui_size == 'md' ? 4 : 3}},[_c('b-button',{class:{
											'fs-09' : _vm.ui_size == 'sm',
											'fs-12' : _vm.ui_size == 'md',
											'fs-14' : _vm.ui_size == 'lg',
										},attrs:{"variant":_vm.ui_theme == 'light' ? 'light' : 'primary',"block":""},on:{"click":function($event){return _vm.goTo('HVAC_Units')}}},[_c('i',{staticClass:"fas fa-air-conditioner"}),_c('p',{staticClass:"mb-0"},[_vm._v("Unidades")])])],1),_c('b-col',{staticClass:"mb-3",attrs:{"sm":"6","md":"4","lg":"4","xl":_vm.ui_size == 'lg' ? 4 : _vm.ui_size == 'md' ? 4 : 3}},[_c('b-button',{class:{
											'fs-09' : _vm.ui_size == 'sm',
											'fs-12' : _vm.ui_size == 'md',
											'fs-14' : _vm.ui_size == 'lg',
										},attrs:{"variant":_vm.ui_theme == 'light' ? 'light' : 'primary',"block":""},on:{"click":function($event){return _vm.goTo('HVAC_Jobs')}}},[_c('i',{staticClass:"fas fa-screwdriver-wrench"}),_c('p',{staticClass:"mb-0"},[_vm._v("Ordenes")])])],1)],1)],1)],1)],1),_c('b-col',{attrs:{"lg":"12","xl":"6"}},[_c('b-card',{staticClass:"mb-4",staticStyle:{"background":"none"},attrs:{"no-body":"","bg-variant":_vm.ui_theme == 'light' ? '' : '',"text-variant":_vm.ui_theme == 'dark' ? 'light' : 'light',"border-variant":_vm.ui_theme == 'dark' ? 'primary' : 'tertiary'}},[_c('b-card-header',{staticClass:"py-1",class:{
								'bg-primary' : _vm.ui_theme == 'light',
								'fs-10' : _vm.ui_size == 'sm',
								'fs-12' : _vm.ui_size == 'md',
								'fs-14' : _vm.ui_size == 'lg',
							}},[_c('span',[_vm._v("Electrofrio")])]),_c('b-card-body',{staticClass:"pb-1",staticStyle:{"background":"none"}},[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"sm":"6","md":"4","lg":"4","xl":_vm.ui_size == 'lg' ? 4 : _vm.ui_size == 'md' ? 4 : 3}},[_c('b-button',{class:{
											'fs-09' : _vm.ui_size == 'sm',
											'fs-12' : _vm.ui_size == 'md',
											'fs-14' : _vm.ui_size == 'lg',
										},attrs:{"variant":_vm.ui_theme == 'light' ? 'light' : 'primary',"block":""},on:{"click":function($event){return _vm.goTo('Organization')}}},[_c('i',{staticClass:"fas fa-sitemap"}),_c('p',{staticClass:"mb-0"},[_vm._v("Empresa")])])],1),_c('b-col',{staticClass:"mb-3",attrs:{"sm":"6","md":"4","lg":"4","xl":_vm.ui_size == 'lg' ? 4 : _vm.ui_size == 'md' ? 4 : 3}},[_c('b-button',{class:{
											'fs-09' : _vm.ui_size == 'sm',
											'fs-12' : _vm.ui_size == 'md',
											'fs-14' : _vm.ui_size == 'lg',
										},attrs:{"variant":_vm.ui_theme == 'light' ? 'light' : 'primary',"block":""},on:{"click":function($event){return _vm.goTo('Templates')}}},[_c('i',{staticClass:"fas fa-files"}),_c('p',{staticClass:"mb-0"},[_vm._v("Formatos")])])],1),_c('b-col',{staticClass:"mb-3",attrs:{"sm":"6","md":"4","lg":"4","xl":_vm.ui_size == 'lg' ? 4 : _vm.ui_size == 'md' ? 4 : 3}},[_c('b-button',{class:{
											'fs-09' : _vm.ui_size == 'sm',
											'fs-12' : _vm.ui_size == 'md',
											'fs-14' : _vm.ui_size == 'lg',
										},attrs:{"variant":_vm.ui_theme == 'light' ? 'light' : 'primary',"block":""},on:{"click":function($event){return _vm.goTo('Email')}}},[_c('i',{staticClass:"fas fa-envelope"}),_c('p',{staticClass:"mb-0"},[_vm._v("E-mail")])])],1),_c('b-col',{staticClass:"mb-3",attrs:{"sm":"6","md":"4","lg":"4","xl":_vm.ui_size == 'lg' ? 4 : _vm.ui_size == 'md' ? 4 : 3}},[_c('b-button',{class:{
											'fs-09' : _vm.ui_size == 'sm',
											'fs-12' : _vm.ui_size == 'md',
											'fs-14' : _vm.ui_size == 'lg',
										},attrs:{"variant":_vm.ui_theme == 'light' ? 'light' : 'primary',"block":""},on:{"click":function($event){return _vm.goTo('OneDrive')}}},[_c('i',{staticClass:"fas fa-folders"}),_c('p',{staticClass:"mb-0"},[_vm._v("OneDrive")])])],1),_c('b-col',{staticClass:"mb-3",attrs:{"sm":"6","md":"4","lg":"4","xl":_vm.ui_size == 'lg' ? 4 : _vm.ui_size == 'md' ? 4 : 3}},[_c('a',{staticClass:"btn btn-block",class:{
											'btn-light' : _vm.ui_theme == 'light',
											'btn-primary' : _vm.ui_theme == 'dark',
											'fs-09' : _vm.ui_size == 'sm',
											'fs-12' : _vm.ui_size == 'md',
											'fs-14' : _vm.ui_size == 'lg',
										},attrs:{"block":"","href":"https://www.electrofrio.cl/","role":"button","target":"_new"}},[_c('i',{staticClass:"fas fa-up-right-from-square"}),_c('p',{staticClass:"mb-0"},[_vm._v("Abrir Página Web")])])]),_c('b-col',{staticClass:"mb-3",attrs:{"sm":"6","md":"4","lg":"4","xl":_vm.ui_size == 'lg' ? 4 : _vm.ui_size == 'md' ? 4 : 3}},[_c('b-button',{class:{
											'fs-09' : _vm.ui_size == 'sm',
											'fs-12' : _vm.ui_size == 'md',
											'fs-14' : _vm.ui_size == 'lg',
										},attrs:{"variant":_vm.ui_theme == 'light' ? 'light' : 'primary',"block":""},on:{"click":function($event){return _vm.goTo('RemoteSupport')}}},[_c('i',{staticClass:"fas fa-computer"}),_c('p',{staticClass:"mb-0"},[_vm._v("Soporte Remoto")])])],1)],1)],1)],1)],1)],1)],1)],1),_c('SystemChangelog')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }