var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{staticClass:"px-3 pt-2"},[_c('p',{staticClass:"mb-0 fs-30 text-danger"},[_c('i',{staticClass:"fas fa-ban"})]),_c('p',{staticClass:"mb-0 flh-10",class:{
				'fs-12' : _vm.ui_size == 'sm',
				'fs-14' : _vm.ui_size == 'md',
				'fs-16' : _vm.ui_size == 'lg',
				'text-primary' : _vm.ui_theme == 'light',
				'text-secondary' : _vm.ui_theme == 'dark'
			}},[_vm._v("Error")]),_c('p',{staticClass:"mb-0 fw-bold",class:{
				'fs-15 mb-1' : _vm.ui_size == 'sm',
				'fs-20 mb-2' : _vm.ui_size == 'md',
				'fs-25 mb-2' : _vm.ui_size == 'lg',
				'text-primary' : _vm.ui_theme == 'light',
				'text-white' : _vm.ui_theme == 'dark'
			}},[_vm._v("Acceso Denegado")]),_c('p',{staticClass:"fs-12 text-secondary"},[_vm._v("Su cuenta no posee el perfil de acceso necesario para usar esta sección.")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }