<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="update-job"
			:size="ui_size == 'sm' ? 'xl' : 'xl'"
			body-class="p-0"
			:title-class="{
				'fs-11' : ui_size == 'sm',
				'fs-13' : ui_size == 'md',
				'fs-15' : ui_size == 'lg',
			}"
			:header-bg-variant="ui_theme == 'dark' ? 'primary' : 'primary'"
			:header-text-variant="ui_theme == 'dark' ? 'light' : 'light'"
			:body-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:body-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:footer-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:footer-text-variant="ui_theme == 'dark' ? 'light' : ''"
		>

			<template #modal-header>
				<div class="w-100">
					<b-row>
						<b-col sm="auto">
							<b-spinner v-if="resource.loading" type="grow" small variant="primary"></b-spinner>
							<p class="mb-0" v-else-if="resource.data && resource.data.number">
								<span class="fw-bold fs-13">Orden de Trabajo N°{{ resource.data.number }}</span>
								<span class="ml-2">{{ resource.data.status.name }}</span>
							</p>
						</b-col>
						<b-col class="text-right" v-if="!resource.loading">
							<b-button
								size="sm"
								variant="info"
								class="mr-2"
								@click="generateJob()"
								:disabled="loading"
								v-if="resource.data.status_id == 1"
							>
								<i class="fas fa-download mr-1"></i>
								<span>Generar PDF</span>
							</b-button>
							<b-button
								size="sm"
								variant="info"
								class="mr-2"
								@click="downloadJob()"
								:disabled="loading"
								v-if="resource.data.status_id == 2"
							>
								<i class="fas fa-download mr-1"></i>
								<span>Descargar PDF</span>
							</b-button>
							<b-button
								size="sm"
								variant="success"
								class="mr-2"
								@click="completeJob()"
								v-if="resource.data.status_id == 1"
							>
								<i class="fas fa-check-circle mr-1"></i>
								<span>Finalizar</span>
							</b-button>
							<b-button
								size="sm"
								variant="secondary"
								class="mr-2"
								@click="cancelJob()"
								v-if="resource.data.status_id == 1"
							>
								<i class="fas fa-do-not-enter mr-1"></i>
								<span>Anular</span>
							</b-button>
							<b-button
								size="sm"
								variant="primary"
								@click="$bvModal.hide('update-job')"
							>
								<i class="fas fa-times mr-1"></i>
								<span>Cerrar</span>
							</b-button>
						</b-col>
					</b-row>
				</div>
			</template>


			
			<b-tabs
				card
				active-nav-item-class="font-weight-bold"
				class="mb-0"
			>

				<!-- JOB -->
				<b-tab
					active
					title="Información"
					title-link-class="fc-black"
				>
					<b-list-group>

						<b-list-group-item class="py-2 pl-3 pr-2">
							<b-row no-gutters>
								<b-col sm="3">
									<strong>Tipo</strong>
								</b-col>
								<b-col>
									<div v-if="resource.loading">
										<b-skeleton class="mt-1"></b-skeleton>
									</div>
									<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.type && resource.data.type.name">
										<p class="mb-0">
											<span>{{ resource.data.type.name }}</span>
										</p>
									</div>
									<p class="mb-0" v-else>
										<span class="text-muted">&mdash;</span>
									</p>
								</b-col>
							</b-row>
						</b-list-group-item>

						<b-list-group-item class="py-2 pl-3 pr-2">
							<b-row no-gutters>
								<b-col sm="3">
									<strong>Estado</strong>
								</b-col>
								<b-col>
									<div v-if="resource.loading">
										<b-skeleton class="mt-1"></b-skeleton>
									</div>
									<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.status && resource.data.status.name">
										<div v-if="resource.data && resource.data.updates && resource.data.updates.length">
											<div v-for="(item, index) in resource.data.updates" :key="index">
												<p class="mb-2">
													<b-badge v-if="item.new_status.id == 1" variant="danger" class="fs-09">
														{{ item.new_status.name }}
													</b-badge>
													<b-badge v-else-if="item.new_status.id == 2" variant="success" class="fs-09">
														{{ item.new_status.name }}
													</b-badge>
													<b-badge v-else-if="item.new_status.id == 3" variant="dark" class="fs-09">
														{{ item.new_status.name }}
													</b-badge>
													<i class="fas fa-chevron-right mx-2 fs-09 text-muted"></i>
													<span>{{ item.created }}</span>
												</p>
												<p class="mb-0 fs-08">
													<span v-if="item.comment != null && item.comment.length > 0">
														<span>Observación: "<span class="fw-bold text-uppercase">{{ item.comment }}</span>"</span>
													</span>
													<span v-else class="text-muted">
														Sin observaciones.
													</span>
												</p>
											</div>
										</div>
										<div v-else>
											<b-badge v-if="resource.data.status.id == 1" variant="danger" class="fs-09">
												{{ resource.data.status.name }}
											</b-badge>
											<b-badge v-else-if="resource.data.status.id == 2" variant="success" class="fs-09">
												{{ resource.data.status.name }}
											</b-badge>
											<b-badge v-else-if="resource.data.status.id == 3" variant="dark" class="fs-09">
												{{ resource.data.status.name }}
											</b-badge>
										</div>
									</div>
									<p class="mb-0" v-else>
										<span class="text-muted">&mdash;</span>
									</p>
								</b-col>
							</b-row>
						</b-list-group-item>

						<b-list-group-item class="py-2 pl-3 pr-2">
							<b-row no-gutters>
								<b-col sm="3">
									<strong>Cliente</strong>
								</b-col>
								<b-col>
									<div v-if="resource.loading">
										<b-skeleton class="mt-1"></b-skeleton>
										<b-skeleton class="mt-1"></b-skeleton>
									</div>
									<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.client && resource.data.client.name">
										<p class="mb-0">
											<span>{{ resource.data.client.name }}</span>
											<span v-if="resource.data.client.codename"> ({{ resource.data.client.codename }})</span>
										</p>
										<p class="mb-0 fs-08 text-muted"><span class="mr-2">RUT</span>{{ parseVATIN(resource.data.client.vatin) }}</p>
									</div>
									<p class="mb-0" v-else>
										<span class="text-muted">&mdash;</span>
									</p>
								</b-col>
							</b-row>
						</b-list-group-item>

						<b-list-group-item class="py-2 pl-3 pr-2">
							<b-row no-gutters>
								<b-col sm="3">
									<strong>Sucursal</strong>
								</b-col>
								<b-col>
									<div v-if="resource.loading">
										<b-skeleton class="mt-1"></b-skeleton>
									</div>
									<div
										class="mb-0 text-uppercase"
										v-else-if="resource.data && resource.data.site && resource.data.site.address && resource.data.site.commune && resource.data.site.commune.name">
										<p class="mb-0">
											<span>{{ resource.data.site.address }}, {{ resource.data.site.commune.name }}</span>
										</p>
									</div>
									<p class="mb-0" v-else>
										<span class="text-muted">&mdash;</span>
									</p>
								</b-col>
							</b-row>
						</b-list-group-item>

						<b-list-group-item class="py-2 pl-3 pr-2">
							<b-row no-gutters>
								<b-col sm="3">
									<strong>Creación</strong>
								</b-col>
								<b-col>
									<div v-if="resource.loading">
										<b-skeleton class="mt-1"></b-skeleton>
									</div>
									<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.created">
										<p class="mb-0">
											<span>{{ resource.data.created }}</span>
										</p>
									</div>
									<p class="mb-0" v-else>
										<span class="text-muted">&mdash;</span>
									</p>
								</b-col>
							</b-row>
						</b-list-group-item>

					</b-list-group>

				</b-tab>
				
				<!-- UNITS -->
				<b-tab
					title="Equipos"
					title-link-class="fc-black"
				>
					<b-card no-body v-if="!resource.loading && resource.data && resource.data.maintenances && resource.data.maintenances.length">
						<b-table
							:items="resource.data.maintenances"
							:small="ui_size == 'sm' ? true : false"
							hover
							striped
							:fields="fields.maintenances"
							class="mb-0"
							responsive
							:head-variant="ui_theme == 'light' ? 'light' : 'dark'"
							:table-variant="ui_theme == 'light' ? 'light' : 'dark'"
							selected-variant="success"
						>
							<template #cell(actions)="data">
								<div v-if="data.item.status_id == 1">
									<b-button
										variant="outline-success"
										size="sm"
										:disabled="data.item.status_id != 1"
										class="mr-2"
										@click="completeMaintenance(data.item)"
									>
										<i class="fas fa-check mr-1"></i>
										<span>Completar</span>
									</b-button>
									<b-button
										variant="outline-secondary"
										size="sm"
										:disabled="data.item.status_id != 1"
										@click="cancelMaintenance(data.item)"
									>
										<i class="fas fa-do-not-enter mr-1"></i>
										<span>Anular</span>
									</b-button>
								</div>
								<div v-else>
									<span class="text-muted">&ndash;</span>
								</div>
							</template>
							<template #cell(status)="data">
								<span v-if="data.item && data.item.updates && data.item.updates.length > 0">
									<div v-for="(item, index) in data.item.updates" :key="index">
										<p class="mb-2 text-uppercase">
											<b-badge v-if="item.new_status.id == 1" variant="danger" class="fs-09">
												{{ item.new_status.name }}
											</b-badge>
											<b-badge v-else-if="item.new_status.id == 2" variant="success" class="fs-09">
												{{ item.new_status.name }}
											</b-badge>
											<b-badge v-else-if="item.new_status.id == 3" variant="dark" class="fs-09">
												{{ item.new_status.name }}
											</b-badge>
											<i class="fas fa-chevron-right mx-2 fs-09 text-muted"></i>
											<span>{{ item.created }}</span>
										</p>
										<p class="mb-0 fs-08">
											<span v-if="item.comment != null && item.comment.length > 0">
												<span>Observación: "<span class="fw-bold text-uppercase">{{ item.comment }}</span>"</span>
											</span>
											<span v-else class="text-muted">
												Sin observaciones.
											</span>
										</p>
									</div>
								</span>
								<span v-else class="text-uppercase">
									<b-badge v-if="data.item.status_id == 1" variant="danger" class="fs-09">
										{{ data.item.status.name }}
									</b-badge>
									<b-badge v-else-if="data.item.status_id == 2" variant="success" class="fs-09">
										{{ data.item.status.name }}
									</b-badge>
									<b-badge v-else-if="data.item.status_id == 3" variant="dark" class="fs-09">
										{{ data.item.status.name }}
									</b-badge>
								</span>
							</template>
							<template #cell(unit)="data">
								<b-button
									:id="`popover-units-${data.index}`"
									:variant="ui_theme == 'dark' ? 'light' : 'primary'"
									block size="sm"
									class="btn-xs"
									title="Presione aquí para abrir la Ficha de este Equipo"
									@click="viewUnit(data.item)"
								>
									<span>EQ-{{ data.value.number }}</span>
									<i class="fas fa-info-circle ml-2"></i>
								</b-button>
								<b-popover
									:target="`popover-units-${data.index}`"
									triggers="hover focus"
								>
									<template #title>
										Equipo <strong>EQ-{{ data.value.number }}</strong>
									</template>
									<b-list-group>
										<b-list-group-item class="py-1 px-2 fs-11">
											<p class="mb-0 fw-bold fs-09">Clase</p>
											<p class="mb-0">{{ data.value.model.type.name }}</p>
										</b-list-group-item>
										<b-list-group-item class="py-1 px-2 fs-11">
											<p class="mb-0 fw-bold fs-09">Marca</p>
											<p class="mb-0">{{ data.value.model.brand.name }}</p>
										</b-list-group-item>
										<b-list-group-item class="py-1 px-2 fs-11">
											<p class="mb-0 fw-bold fs-09">Modelo</p>
											<p class="mb-0">{{ data.value.model.part_number }}</p>
										</b-list-group-item>
									</b-list-group>
								</b-popover>
							</template>
						</b-table>
					</b-card>
				</b-tab>
				
				<!-- COMMENTS -->
				<b-tab
					title="Anotaciones"
					title-link-class="fc-black"
				>
					<b-row>
						<b-col>
							<b-button
								variant="primary"
								:size="ui_size"
								@click="createJobComment()"
							>
								<i class="fas fa-plus mr-2"></i>
								Nueva Anotación
							</b-button>
						</b-col>
					</b-row>
					<b-card no-body v-if="!resource.loading && resource.data && resource.data.comments && resource.data.comments.length" class="mt-3">
						<b-table
							:items="resource.data.comments"
							:fields="fields.comments"
							small
							hover
							class="mb-0"
							responsive
							:head-variant="ui_theme == 'light' ? 'light' : 'dark'"
							:table-variant="ui_theme == 'light' ? 'light' : 'dark'"
							selected-variant="success"
						>
							<template #cell(user)="data">
								{{ data.value.name }} {{ data.value.lastname }}
							</template>
						</b-table>
					</b-card>
				</b-tab>

			</b-tabs>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :disabled="loading" :size="ui_size">Cerrar</b-button>
			</template>

		</b-modal>

		<CompleteJob :job="resource.data" @update="update"></CompleteJob>
		<CancelJob :job="resource.data" @update="update"></CancelJob>
		<SecureDownload></SecureDownload>
		<CompleteMaintenance :maintenance="current.maintenance" :job="resource.data"></CompleteMaintenance>
		<CancelMaintenance :maintenance="current.maintenance" :job="resource.data"></CancelMaintenance>
		<CreateJobComment :job="resource.data"></CreateJobComment>

	</div>
</template>

<script>
import Helpers from '@/helpers';
import CompleteJob from '@/components/CompleteJob.vue'
import CancelJob from '@/components/CancelJob.vue'
import CompleteMaintenance from '@/components/CompleteMaintenance.vue'
import CancelMaintenance from '@/components/CancelMaintenance.vue'
import CreateJobComment from '@/components/CreateJobComment.vue'
import SecureDownload from '@/components/SecureDownload.vue'

export default {
	name: 'UpdateJob',
	components: {
		CreateJobComment,
		CompleteJob,
		CancelJob,
		CompleteMaintenance,
		CancelMaintenance,
		SecureDownload,
	},
	data() {
		return {
			loading: false,
			current: {
				maintenance: {},
			},
			classes: {
				list_group: 'py-2 pl-3 pr-2 test-hover',
			},
			fields: {
				maintenances: [
					{ key: 'unit', label: 'Equipo', sortable: true },
					{ key: 'status', label: 'Mantenimiento', sortable: true },
					{ key: 'unit.location_interior', label: 'Ubicación', sortable: true },
					{ key: 'actions', label: 'Acciones', sortable: true },
				],
				comments: [
					{ key: 'comment', label: 'Anotación', sortable: true },
					{ key: 'user', label: 'Escrita Por', sortable: true },
					{ key: 'created', label: 'Fecha / Hora', sortable: true },
				],
				updates: [
					{ key: 'old_status.name', label: 'Estado Original', sortable: true },
					{ key: 'new_status.name', label: 'Nuevo Estado', sortable: true },
					{ key: 'comment', label: 'Observación', sortable: true },
					{ key: 'created', label: 'Fecha / Hora', sortable: true },
				],
			}
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'update-job') { this.reset() }
		})
	},
	computed: {
		resource: {
			get() { return this.$store.getters.job },
			set(data) { this.$store.commit('job', data) }
		},
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		clients() { return this.$store.state.clients },
		client_sites() { return this.$store.state.client_sites },
		site_units() { return this.$store.state.site_units },
		helpers_job_types() { return this.$store.state.helpers.job_types },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
	},
	methods: {
		reset() {
			this.$store.commit('reset_job')
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		parseVATIN(vatin) {
			return Helpers.parseVATIN(vatin)
		},
		createJobComment() {
			this.$bvModal.show('create-job-comment')
		},
		completeJob() {
			this.$bvModal.show('complete-job')
		},
		cancelJob() {
			this.$bvModal.show('cancel-job')
		},
		completeMaintenance(data) {
			this.current.maintenance = data
			this.$bvModal.show('complete-maintenance')
		},
		cancelMaintenance(data) {
			this.current.maintenance = data
			this.$bvModal.show('cancel-maintenance')
		},
		generateJob() {
			this.$store.state.download_token.loading = true
			this.$bvModal.show('secure-download')
			this.$api.get('jobs/'+this.resource.data.id+'/generate')
			.then(response => {
				this.$store.dispatch('download_token', response.data.uuid)
			})
			.catch(error => {
				this.toast(false, error.response.data.message, 10000)
			})
		},
		downloadJob() {
			this.$store.state.download_token.loading = true
			this.$bvModal.show('secure-download')
			this.$api.get('jobs/'+this.resource.data.id+'/download')
			.then(response => {
				this.$store.dispatch('download_token', response.data.uuid)
			})
			.catch(error => {
				this.toast(false, error.response.data.message, 10000)
			})
		},
		viewUnit(unit) {
			this.$bvModal.show('view-unit')
			this.$store.dispatch('unit', unit.unit_id)
		},
		update() {
			this.$emit('update')
		},
	}
}
</script>