<template>
	<div class="mt-3">

		<div v-if="core.loading">
			<SiteModuleHeader
				:showBack="true"
				:showHome="true"
				:showSize="true"
				:showTheme="true"
				:showFluid="true"
				:showTitle="false"
				@reload="reload"
				:reloading="reloading || resource.loading"
			></SiteModuleHeader>
			<b-row>
				<b-col sm="6">
					<b-skeleton class="mb-1" height="15px"></b-skeleton>
					<b-skeleton class="mb-4" height="20px"></b-skeleton>
					<b-skeleton height="100px"></b-skeleton>
				</b-col>
			</b-row>
		</div>
		<SiteDenied v-else-if="profile.name != 'management'"></SiteDenied>
		<div v-else>

			<SiteModuleHeader
				:showBack="true"
				:showHome="true"
				:showSize="true"
				:showTheme="true"
				:showFluid="true"
				:showTitle="false"
				@reload="reload"
				:reloading="reloading || resource.loading"
			></SiteModuleHeader>

			<b-row
				class="mb-4"
				:class="{
					'text-primary' : ui_theme == 'light',
					'text-white' : ui_theme == 'dark'
				}"
			>
				<b-col sm="auto">
					<b-skeleton height="34px" width="200px" v-if="resource.loading"></b-skeleton>
					<p
						class="mb-0 fw-bold"
						:class="{
							'fs-15 mb-1' : ui_size == 'sm',
							'fs-20 mb-2' : ui_size == 'md',
							'fs-25 mb-2' : ui_size == 'lg',
							'text-primary' : ui_theme == 'light',
							'text-white' : ui_theme == 'dark'
						}"
						v-else
					>
						{{ resource.data.date }}
					</p>
					<b-skeleton height="18px" width="100px" v-if="resource.loading"></b-skeleton>
					<p
						class="mb-0 flh-10"
						:class="{
							'fs-12' : ui_size == 'sm',
							'fs-14' : ui_size == 'md',
							'fs-16' : ui_size == 'lg',
							'text-primary' : ui_theme == 'light',
							'text-secondary' : ui_theme == 'dark'
						}"
						v-else
					>{{ core.account.name }} {{ core.account.lastname }}</p>
				</b-col>
				<b-col sm="auto">
					<div>
						<b-skeleton height="34px" width="200px" v-if="resource.loading"></b-skeleton>
						<p
							v-else
							class="fw-light mb-0"
						>
							<i
								class="fas"
								:class="{
									'fs-08 mt-3' : ui_size == 'sm',
									'fs-12 mt-3' : ui_size == 'md',
									'fs-15 mt-3' : ui_size == 'lg',
									'text-primary' : ui_theme == 'light',
									'text-secondary' : ui_theme == 'dark',
									'fa-shield-check' : profile.name == 'management',
									'fa-helmet-safety' : profile.name == 'technician',
									'fa-buildings' : profile.name == 'client',
								}"
							></i>
							<span
								class="mb-0 flh-10"
								:class="{
									'fs-08' : ui_size == 'sm',
									'fs-11' : ui_size == 'md',
									'fs-15' : ui_size == 'lg',
									'text-primary' : ui_theme == 'light',
									'text-secondary' : ui_theme == 'dark'
								}"
							>
								{{ profile.description }}
							</span>
						</p>
					</div>
					<div v-if="resource.data && resource.data.indicators && resource.data.indicators.usd && resource.data.indicators.clf">
						<b-skeleton height="34px" width="200px" v-if="resource.loading"></b-skeleton>
						<p
							v-else
							class="fw-light mb-0"
						>
							<i
								class="fas fa-arrow-up-arrow-down mr-1"
								:class="{
									'fs-08 mt-3' : ui_size == 'sm',
									'fs-10 mt-3' : ui_size == 'md',
									'fs-12 mt-3' : ui_size == 'lg',
									'text-primary' : ui_theme == 'light',
									'text-secondary' : ui_theme == 'dark',
								}"
							></i>
							<span
								class="mb-0 flh-10"
								:class="{
									'fs-08' : ui_size == 'sm',
									'fs-10' : ui_size == 'md',
									'fs-12' : ui_size == 'lg',
									'text-primary' : ui_theme == 'light',
									'text-secondary' : ui_theme == 'dark'
								}"
							>
								<span><span class="fs-09">Dólar</span> ${{ parseIndicator(resource.data.indicators.usd.value) }}</span>
								<span class="ml-2"><span class="fs-09">UF</span> ${{ parseIndicator(resource.data.indicators.clf.value) }}</span>
							</span>
						</p>
					</div>
				</b-col>
			</b-row>

			<b-row>
				<b-col sm="12" md="12" lg="6" xl="3" class="mb-3">
					<b-card no-body>
						<b-overlay :show="dashboard_home.loading" rounded spinner-type="grow" spinner-variant="primary">
							<b-card-body class="pt-2 pb-0 px-3">
								<p class="mb-0 fw-bold fs-14">Compras</p>
							</b-card-body>
							<b-card-body class="py-0 pl-0 pr-2">
								<apex-chart :options="purchaseOrdersOptions" :series="dashboard_home.data.defontana.purchase_orders.totals" height="250"></apex-chart>
							</b-card-body>
						</b-overlay>
					</b-card>
				</b-col>
				<b-col sm="12" md="12" lg="6" xl="3" class="mb-3">
					<b-card no-body>
						<b-overlay :show="dashboard_home.loading" rounded spinner-type="grow" spinner-variant="primary">
							<b-card-body class="pt-2 pb-0 px-3">
								<p class="mb-0 fw-bold fs-14">Cotizaciones</p>
							</b-card-body>
							<b-card-body class="py-0 pl-0 pr-2">
								<apex-chart :options="pricingsOptions" :series="dashboard_home.data.defontana.pricings.totals" height="250"></apex-chart>
								
							</b-card-body>
						</b-overlay>
					</b-card>
				</b-col>
				<b-col sm="12" md="12" lg="6" xl="3" class="mb-3">
					<b-card no-body>
						<b-overlay :show="dashboard_home.loading" rounded spinner-type="grow" spinner-variant="primary">
							<b-card-body class="pt-2 pb-0 px-3">
								<p class="mb-0 fw-bold fs-14">Pedidos</p>
							</b-card-body>
							<b-card-body class="py-0 pl-0 pr-2">
								<apex-chart :options="ordersOptions" :series="dashboard_home.data.defontana.orders.totals" height="250"></apex-chart>
							</b-card-body>
						</b-overlay>
					</b-card>
				</b-col>
				<b-col sm="12" md="12" lg="6" xl="3" class="mb-3">
					<b-card no-body>
						<b-overlay :show="dashboard_home.loading" rounded spinner-type="grow" spinner-variant="primary">
							<b-card-body class="pt-2 pb-0 px-3">
								<p class="mb-0 fw-bold fs-14">Ventas</p>
							</b-card-body>
							<b-card-body class="py-0 pl-0 pr-2">
								<apex-chart :options="salesOptions" :series="dashboard_home.data.defontana.sales.totals" height="250"></apex-chart>
							</b-card-body>
						</b-overlay>
					</b-card>
				</b-col>

				
				<b-col sm="12" md="12" lg="6" xl="3" class="mb-3">
					<b-card no-body bg-variant="light">
						<b-card-body class="py-5 px-3">
							<p class="mb-0 fs-14 text-center text-muted">
								<b-row>
									<b-col>
										<i class="fas fa-table"></i>
									</b-col>
									<b-col>
										<i class="fas fa-chart-pie"></i>
									</b-col>
									<b-col>
										<i class="fas fa-list"></i>
									</b-col>
									<b-col>
										<i class="fas fa-text"></i>
									</b-col>
								</b-row>
							</p>
						</b-card-body>
					</b-card>
				</b-col>
				
				<b-col sm="12" md="12" lg="6" xl="3" class="mb-3">
					<b-card no-body bg-variant="light">
						<b-card-body class="py-5 px-3">
							<p class="mb-0 fs-14 text-center text-muted">
								<b-row>
									<b-col>
										<i class="fas fa-table"></i>
									</b-col>
									<b-col>
										<i class="fas fa-chart-pie"></i>
									</b-col>
									<b-col>
										<i class="fas fa-list"></i>
									</b-col>
									<b-col>
										<i class="fas fa-text"></i>
									</b-col>
								</b-row>
							</p>
						</b-card-body>
					</b-card>
				</b-col>
				
				<b-col sm="12" md="12" lg="6" xl="3" class="mb-3">
					<b-card no-body bg-variant="light">
						<b-card-body class="py-5 px-3">
							<p class="mb-0 fs-14 text-center text-muted">
								<b-row>
									<b-col>
										<i class="fas fa-table"></i>
									</b-col>
									<b-col>
										<i class="fas fa-chart-pie"></i>
									</b-col>
									<b-col>
										<i class="fas fa-list"></i>
									</b-col>
									<b-col>
										<i class="fas fa-text"></i>
									</b-col>
								</b-row>
							</p>
						</b-card-body>
					</b-card>
				</b-col>
				
				<b-col sm="12" md="12" lg="6" xl="3" class="mb-3">
					<b-card no-body bg-variant="light">
						<b-card-body class="py-5 px-3">
							<p class="mb-0 fs-14 text-center text-muted">
								<b-row>
									<b-col>
										<i class="fas fa-table"></i>
									</b-col>
									<b-col>
										<i class="fas fa-chart-pie"></i>
									</b-col>
									<b-col>
										<i class="fas fa-list"></i>
									</b-col>
									<b-col>
										<i class="fas fa-text"></i>
									</b-col>
								</b-row>
							</p>
						</b-card-body>
					</b-card>
				</b-col>


<!--
				<b-col sm="4" class="mb-3">
					<b-card no-body>
						<b-card-body class="pt-2 pb-0 px-3">
							<p class="mb-0 fw-bold fs-14">Mantenimiento</p>
						</b-card-body>
						<b-card-body>
							<b-row>
								<b-col sm="6">
									<b-row>
										<b-col sm="3">
											<b-skeleton v-if="dashboard_home.loading"></b-skeleton>
											<p v-else class="mb-0 text-right">
												<span>{{ dashboard_home.data.hvac.jobs.count }}</span>
											</p>
										</b-col>
										<b-col>
											Órdenes de Trabajo
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="6">
									<b-row>
										<b-col sm="3">
											<b-skeleton v-if="dashboard_home.loading"></b-skeleton>
											<p v-else class="mb-0 text-right">
												<span>{{ dashboard_home.data.hvac.units.count }}</span>
											</p>
										</b-col>
										<b-col>
											Unidades
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="6">
									<b-row>
										<b-col sm="3">
											<b-skeleton v-if="dashboard_home.loading"></b-skeleton>
											<p v-else class="mb-0 text-right">
												<span>{{ dashboard_home.data.hvac.maintenances.count }}</span>
											</p>
										</b-col>
										<b-col>
											Mantenimientos
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="6">
									<b-row>
										<b-col sm="3">
											<b-skeleton v-if="dashboard_home.loading"></b-skeleton>
											<p v-else class="mb-0 text-right">
												<span>{{ dashboard_home.data.hvac.contracts.count }}</span>
											</p>
										</b-col>
										<b-col>
											Contratos
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="6">
									<b-row>
										<b-col sm="3">
											<b-skeleton v-if="dashboard_home.loading"></b-skeleton>
											<p v-else class="mb-0 text-right">
												<span>{{ dashboard_home.data.hvac.sites.count }}</span>
											</p>
										</b-col>
										<b-col>
											Sucursales
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</b-card-body>
					</b-card>
				</b-col>
				<b-col sm="4" class="mb-3">
					<b-card no-body>
						<b-card-body class="pt-2 pb-0 px-3">
							<b-row>
								<b-col>
									<p class="mb-0 fw-bold fs-14">Defontana</p>
								</b-col>
							</b-row>
						</b-card-body>
						<b-card-body>
							<b-row>
								<b-col sm="6">
									<b-row>
										<b-col sm="3">
											<b-skeleton v-if="dashboard_home.loading"></b-skeleton>
											<p v-else class="mb-0 text-right">
												<span>{{ dashboard_home.data.defontana.clients.count }}</span>
											</p>
										</b-col>
										<b-col>
											Clientes
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="6">
									<b-row>
										<b-col sm="3">
											<b-skeleton v-if="dashboard_home.loading"></b-skeleton>
											<p v-else class="mb-0 text-right">
												<span>{{ dashboard_home.data.defontana.cost_centers.count }}</span>
											</p>
										</b-col>
										<b-col>
											Centros de Negocios
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="6">
									<b-row>
										<b-col sm="3">
											<b-skeleton v-if="dashboard_home.loading"></b-skeleton>
											<p v-else class="mb-0 text-right">
												<span>{{ dashboard_home.data.defontana.pricings.count }}</span>
											</p>
										</b-col>
										<b-col>
											Cotizaciones
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="6">
									<b-row>
										<b-col sm="3">
											<b-skeleton v-if="dashboard_home.loading"></b-skeleton>
											<p v-else class="mb-0 text-right">
												<span>{{ dashboard_home.data.defontana.orders.count }}</span>
											</p>
										</b-col>
										<b-col>
											Pedidos
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="6">
									<b-row>
										<b-col sm="3">
											<b-skeleton v-if="dashboard_home.loading"></b-skeleton>
											<p v-else class="mb-0 text-right">
												<span>{{ dashboard_home.data.defontana.sales.count }}</span>
											</p>
										</b-col>
										<b-col>
											Ventas
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="6">
									<b-row>
										<b-col sm="3">
											<b-skeleton v-if="dashboard_home.loading"></b-skeleton>
											<p v-else class="mb-0 text-right">
												<span>{{ dashboard_home.data.defontana.products.count }}</span>
											</p>
										</b-col>
										<b-col>
											Artículos
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="6">
									<b-row>
										<b-col sm="3">
											<b-skeleton v-if="dashboard_home.loading"></b-skeleton>
											<p v-else class="mb-0 text-right">
												<span>{{ dashboard_home.data.defontana.services.count }}</span>
											</p>
										</b-col>
										<b-col>
											Servicios
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="6">
									<b-row>
										<b-col sm="3">
											<b-skeleton v-if="dashboard_home.loading"></b-skeleton>
											<p v-else class="mb-0 text-right">
												<span>{{ dashboard_home.data.defontana.employees.count }}</span>
											</p>
										</b-col>
										<b-col>
											Trabajadores
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="6">
									<b-row>
										<b-col sm="3">
											<b-skeleton v-if="dashboard_home.loading"></b-skeleton>
											<p v-else class="mb-0 text-right">
												<span>{{ dashboard_home.data.defontana.sellers.count }}</span>
											</p>
										</b-col>
										<b-col>
											Vendedores
										</b-col>
									</b-row>
								</b-col>
								<b-col sm="6">
									<b-row>
										<b-col sm="3">
											<b-skeleton v-if="dashboard_home.loading"></b-skeleton>
											<p v-else class="mb-0 text-right">
												<span>{{ dashboard_home.data.defontana.shops.count }}</span>
											</p>
										</b-col>
										<b-col>
											Bodegas
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</b-card-body>
					</b-card>
				</b-col>
-->
			</b-row>
			<code v-if="0">{{ dashboard_home }}</code>
<!--
			<b-skeleton-wrapper :loading="reloading || resource.loading">
				<template #loading>
					<b-row>
						<b-col lg="12" xl="6">
							<b-card class="mb-4" no-body>
								<b-skeleton height="32px" width="100%"></b-skeleton>
								<b-card-body class="pb-1">
									<b-row>
										<b-col sm="6" md="4" lg="4" xl="3" class="mb-3">
											<b-skeleton height="50px"></b-skeleton>
										</b-col>
										<b-col sm="6" md="4" lg="4" xl="3" class="mb-3">
											<b-skeleton height="50px"></b-skeleton>
										</b-col>
										<b-col sm="6" md="4" lg="4" xl="3" class="mb-3">
											<b-skeleton height="50px"></b-skeleton>
										</b-col>
										<b-col sm="6" md="4" lg="4" xl="3" class="mb-3">
											<b-skeleton height="50px"></b-skeleton>
										</b-col>
									</b-row>
								</b-card-body>
							</b-card>
						</b-col>
					</b-row>
				</template>

				<b-row>

					<b-col lg="12" xl="6">
						<b-card
							class="mb-4"
							no-body
							:bg-variant="ui_theme == 'light' ? '' : ''"
							:text-variant="ui_theme == 'dark' ? 'light' : 'light'"
							style="background: none;"
							:border-variant="ui_theme == 'dark' ? 'primary' : 'tertiary'"
						>
							<b-card-header
								class="py-1"
								:class="{
									'bg-success' : ui_theme == 'light',
									'fs-10' : ui_size == 'sm',
									'fs-11' : ui_size == 'md',
									'fs-14' : ui_size == 'lg',
								}"
							>
								<span>xxxx</span>
							</b-card-header>
							<b-card-body class="pb-1">
								<b-row>

									<b-col
										sm="6"
										md="4"
										lg="4"
										:xl="ui_size == 'lg' ? 4 : ui_size == 'md' ? 4 : 3"
										class="mb-3"
									>
										<b-button
											:variant="ui_theme == 'light' ? 'light' : 'primary'"
											block
											@click="goTo('HVAC_Units')"
											:class="{
												'fs-09' : ui_size == 'sm',
												'fs-11' : ui_size == 'md',
												'fs-14' : ui_size == 'lg',
											}"
										>
											<i class="fas fa-air-conditioner"></i>
											<p class="mb-0">xxxx</p>
										</b-button>
									</b-col>

								</b-row>
							</b-card-body>
						</b-card>
					</b-col>
					
				</b-row>

			</b-skeleton-wrapper>
-->
		</div>

		<SystemChangelog></SystemChangelog>

	</div>
</template>

<script>
import Helpers from '@/helpers'
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
import SiteDenied from '@/components/SiteDenied.vue'
import SystemChangelog from '@/components/SystemChangelog.vue'
export default {
	name: 'HomeView',
	components: {
		SystemChangelog,
		SiteDenied,
		SiteModuleHeader,
	},
	data: function() {
		return {
			reloading: false,
			online: false,
			pricingsOptions: {
				chart: {
					type: 'area',
					height: 200,
					toolbar: {
						show: false
					}
				},
				tooltip: {
					y: {
						formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
							return '$ '+this.parseCurrency(value, false, false).toString()
						}
					}
				},
				dataLabels: {
					enabled: false,
				},
				yaxis: {
					labels: {
						formatter: value => {
							return (value / 1000000).toString() + ' M'
						}
					}
				},
				colors: [ '#FEB019' ],
			},
			ordersOptions: {
				chart: {
					type: 'area',
					height: 200,
					toolbar: {
						show: false
					}
				},
				tooltip: {
					y: {
						formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
							return '$ '+this.parseCurrency(value, false, false).toString()
						}
					}
				},
				dataLabels: {
					enabled: false,
				},
				yaxis: {
					labels: {
						formatter: value => {
							return (value / 1000000).toString() + ' M'
						}
					}
				},
				colors: [ '#008FFB' ],
			},
			salesOptions: {
				chart: {
					type: 'area',
					height: 200,
					toolbar: {
						show: false
					}
				},
				tooltip: {
					y: {
						formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
							return '$ '+this.parseCurrency(value, false, false).toString()
						}
					}
				},
				dataLabels: {
					enabled: false,
				},
				yaxis: {
					labels: {
						formatter: value => {
							return (value / 1000000).toString() + ' M'
						}
					}
				},
				colors: [ '#00E396' ],
			},
			purchaseOrdersOptions: {
				chart: {
					type: 'area',
					height: 200,
					toolbar: {
						show: false
					}
				},
				tooltip: {
					y: {
						formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
							return '$ '+this.parseCurrency(value, false, false).toString()
						}
					}
				},
				dataLabels: {
					enabled: false,
				},
				yaxis: {
					labels: {
						formatter: value => {
							return (value / 1000000).toString() + ' M'
						}
					}
				},
				colors: [ '#775DD0' ],
			},
		}
	},
	mounted() {
		this.load()
	},
	computed: {
		profile() { return this.$store.state.core.account.profile },
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		getters() { return this.$store.getters },
		resource() { return this.$store.state.home },
		events: {
			get() { return this.$store.getters.events; },
			set(data) { this.$store.commit('events', data); }
		},
		dashboard_home: {
			get() { return this.$store.getters.dashboard_home; },
			set(data) { this.$store.commit('dashboard_home', data); }
		},
		//////-----------------------------------------------------------
	},
	methods: {
		toast(success, title, message, delay) {
			this.$bvToast.toast(message, {
				title: title,
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
		load() {
			this.$store.dispatch('home').then(() => { this.reloading = false; })
			this.$store.dispatch('dashboard_home').then(() => { this.reloading = false; })
		},
		reload() {
			this.reloading = true
			this.load()
		},
		systemChangelog() {
			this.$bvModal.show('system-changelog')
		},
		saveUISize(size) {
			this.$store.commit('save_ui_size', size)
		},
		decreaseUISize() {
			if (this.ui_size == 'md') {
				this.$store.commit('save_ui_size', 'sm')
			} else if (this.ui_size == 'lg') {
				this.$store.commit('save_ui_size', 'md')
			} else {
				return false
			}
		},
		increaseUISize() {
			if (this.ui_size == 'sm') {
				this.$store.commit('save_ui_size', 'md')
			} else if (this.ui_size == 'md') {
				this.$store.commit('save_ui_size', 'lg')
			} else {
				return false
			}
		},
		parseIndicator(data) {
			return Helpers.parseIndicator(data)
		},
		parseCurrency(number, decimal, reverse) {
			return Helpers.parseCurrency(number, decimal, reverse)
		},
	}
}
</script>
