<template>
	<div class="mt-3">

		<SiteModuleHeader></SiteModuleHeader>
		
		<p class="text-dark fs-15 mt-0 mb-4">Las direcciones terminadas @electrofrio.cl funcionan gracias a Microsoft Outlook, un software para usar correo electrónico disponible en distintas versiones.</p>

		<b-row>
			<b-col lg="3">
				<b-card no-body class="mb-5">
					<b-card-header>
						<p class="mb-0 fw-bold fs-12">Versión Web</p>
					</b-card-header>
					<b-card-body class="pb-2">
						<p class="fs-11 mb-3">Esta versión funciona desde cualquier navegador web con Internet y no necesita instalación.</p>
						<p class="fs-12 mb-3">Presione para abrir:</p>
						<b-alert show variant="light" class="mb-0">
							<b-row no-gutters>
								<b-col sm="auto" class="pr-3">
									<i class="fas fa-lock text-success"></i>
								</b-col>
								<b-col>
									<a
										href="https://outlook.office.com/"
										target="_blank"
										class="fs-12"
										style="text-decoration:none!important;"
										title="Presione para abrir"
									>
										<code class="text-primary">https://outlook.office.com</code>
									</a>
								</b-col>
							</b-row>
						</b-alert>
					</b-card-body>
					<b-card-body class="text-center pb-2">
						<p class="text-left text-muted">Navegadores Compatibles</p>
						<b-row>
							<b-col>
								<img src="/assets/custom_icons/chrome.png" style="width: 75%" />
								<p class="fs-08 mt-1 text-muted">Google<br>Chrome</p>
							</b-col>
							<b-col>
								<img src="/assets/custom_icons/edge.png" style="width: 75%" />
								<p class="fs-08 mt-1 text-muted">Microsoft<br>Edge</p>
							</b-col>
							<b-col>
								<img src="/assets/custom_icons/safari.png" style="width: 75%" />
								<p class="fs-08 mt-1 text-muted">Apple<br>Safari</p>
							</b-col>
							<b-col>
								<img src="/assets/custom_icons/firefox.png" style="width: 75%" />
								<p class="fs-08 mt-1 text-muted">Mozilla<br>Firefox</p>
							</b-col>
							<b-col>
								<img src="/assets/custom_icons/opera.png" style="width: 75%" />
								<p class="fs-08 mt-1 text-muted">Opera<br>Browser</p>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</b-col>
			<b-col lg="3">
				<b-card no-body class="mb-5">
					<b-card-header>
						<p class="mb-0 fw-bold fs-12">Versión Escritorio</p>
					</b-card-header>
					<b-card-body class="pb-0">
						<p class="fs-11 mb-3">Esta versión es compatible sólo con computadores tradicionales.</p>
						<p class="fs-12 mb-3">Si ya se encuentra instalada en su computador, búsquela el menú de aplicaciones:</p>
						<b-alert show variant="light" class="mb-2 py-4">
							<b-row no-gutters>
								<b-col sm="3" class="pr-3">
									<img src="/assets/custom_icons/outlook.png" style="width: 100%" />
								</b-col>
								<b-col>
									<p class="mb-0 fs-13">Microsoft Outlook</p>
									<p class="mb-0 fs-10 text-dark">Versiones 2016, 2019, 2021, 365</p>
								</b-col>
							</b-row>
						</b-alert>
					</b-card-body>
					<b-card-body class="text-center pb-2">
						<p class="text-left text-muted">Sistemas Operativos Compatibles</p>
						<b-row>
							<b-col sm="3">
								<img src="/assets/custom_icons/windows.png" style="width: 70%" />
								<p class="fs-08 mt-1 text-muted">Microsoft<br>Windows</p>
							</b-col>
							<b-col sm="3">
								<img src="/assets/custom_icons/apple.png" style="width: 70%" />
								<p class="fs-08 mt-1 text-muted">Apple<br>macOS</p>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</b-col>
			<b-col lg="3">
				<b-card no-body class="mb-5">
					<b-card-header>
						<p class="mb-0 fw-bold fs-12">Versión iOS</p>
					</b-card-header>
					<b-card-body class="pb-2">
						<p class="fs-11 mb-3">Esta versión es compatible sólo con celulares iPhone y tablets iPad.</p>
						<p class="fs-12 mb-3">Abrir en Apple App Store:</p>
						<b-alert show variant="light" class="mb-3 py-4">
							<b-row no-gutters>
								<b-col sm="3" class="pr-3">
									<img src="/assets/custom_icons/apple.png" style="width: 100%" />
								</b-col>
								<b-col>
									<p class="mb-2 fs-13">Microsoft Outlook</p>
									<a
										href="https://apps.apple.com/es/app/microsoft-outlook/id951937596"
										target="_blank"
										class="btn btn-dark text-white"
										role="button"
									>Abrir en Apple App Store</a>
								</b-col>
							</b-row>
						</b-alert>
					</b-card-body>
				</b-card>
			</b-col>
			<b-col lg="3">
				<b-card no-body class="mb-5">
					<b-card-header>
						<p class="mb-0 fw-bold fs-12">Versión Android</p>
					</b-card-header>
					<b-card-body class="pb-2">
						<p class="fs-11 mb-3">Esta versión es compatible sólo con celulares y tablets Android.</p>
						<p class="fs-12 mb-3">Abrir en Play Store:</p>
						<b-alert show variant="light" class="mb-3 py-4">
							<b-row no-gutters>
								<b-col sm="3" class="pr-3">
									<img src="/assets/custom_icons/android.png" style="width: 100%" />
								</b-col>
								<b-col>
									<p class="mb-2 fs-13">Microsoft Outlook</p>
									<a
										href="https://play.google.com/store/apps/details?id=com.microsoft.office.outlook&hl=es"
										target="_blank"
										class="btn btn-dark text-white"
										role="button"
									>Abrir en Google Play Store</a>
								</b-col>
							</b-row>
						</b-alert>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>

	</div>
</template>

<script>
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
export default {
	name: 'EmailView',
	components: {
		SiteModuleHeader,
	},
	data: function() {
		return {
		}
	},
	mounted() {
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		getters() { return this.$store.getters },
		//////-----------------------------------------------------------
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
	}
}
</script>
