<template>
	<div>
		<b-overlay
			:show="loading"
			rounded
			opacity="0.6"
			class="d-inline-block"
		>
			<template #overlay>
				<span>&nbsp;</span>
			</template>
			<b-pagination
				v-model="current_page"
				:total-rows="total"
				:per-page="limit"
				align="left"
				:size="ui_size"
				label-first-page="Ir al comienzo"
				label-last-page="Ir al final"
				label-next-page="Siguiente página"
				label-prev-page="Página anterior"
				label-page="Página"
				class="mb-0"
			>
				<template #first-text>
					<i class="fas fa-arrow-left-to-line"></i>
				</template>
				<template #prev-text>
					<i class="fas fa-angle-left"></i>
				</template>
				<template #next-text>
					<i class="fas fa-angle-right"></i>
				</template>
				<template #last-text>
					<i class="fas fa-arrow-right-to-line"></i>
				</template>
			</b-pagination>
		</b-overlay>
	</div>
</template>

<script>
export default {
	name: 'SitePagination',
	components: {
	},
	data() {
		return {
		}
	},
	props: {
		loading: {
			type: Boolean,
			required: false,
		},
		page: {
			type: Number,
			required: false,
			default: 0
		},
		total: {
			type: Number,
			required: false,
			default: 0
		},
		limit: {
			type: Number,
			required: false,
			default: 0
		},
	},
	created() {
		//window.addEventListener('event_name', this.function_name())
	},
	mounted() {
	},
	destroyed() {
		//window.removeEventListener('event_name')
	},
	computed: {
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		screen_size() { return this.$store.getters.screen_size },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		current_page: {
			get() {
				return this.page
			},
			set(new_value) {
				this.$emit('clicked', new_value)
			}
		}
	},
	watch: {
		/*
		name(new_value, old_value) {
			this.current = new_value
			this.last = old_value
		}
		*/
	},
	methods: {
	}
}
</script>
