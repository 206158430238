<template>
	<div>
		<b-card class="px-3 pt-2">
			<p class="mb-0 fs-30 text-danger">
				<i class="fas fa-ban"></i>
			</p>
			<p
				class="mb-0 flh-10"
				:class="{
					'fs-12' : ui_size == 'sm',
					'fs-14' : ui_size == 'md',
					'fs-16' : ui_size == 'lg',
					'text-primary' : ui_theme == 'light',
					'text-secondary' : ui_theme == 'dark'
				}">Error</p>
			<p
				class="mb-0 fw-bold"
				:class="{
					'fs-15 mb-1' : ui_size == 'sm',
					'fs-20 mb-2' : ui_size == 'md',
					'fs-25 mb-2' : ui_size == 'lg',
					'text-primary' : ui_theme == 'light',
					'text-white' : ui_theme == 'dark'
				}">Acceso Denegado</p>
			<p class="fs-12 text-secondary">Su cuenta no posee el perfil de acceso necesario para usar esta sección.</p>
		</b-card>
	</div>
</template>

<script>
export default {
	name: 'SiteDenied',
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		getters() { return this.$store.getters },
		//////-----------------------------------------------------------
	},
}
</script>
