export default {
	save_session(state, session) {
		state.local.session = session
		localStorage.setItem('token_type', session.token_type)
		localStorage.setItem('expires_in', session.expires_in)
		localStorage.setItem('access_token', session.access_token)
		localStorage.setItem('refresh_token', session.refresh_token)
	},
	
	save_websocket_token(state, token) {
		state.websocket.token = token
		localStorage.setItem('websocket_token', token)
	},

	destroy_session(state) {
		state.local.session = {
			token_type: null,
			expires_in: null,
			access_token: null,
			refresh_token: null
		}
		state.websocket.token = null
		localStorage.removeItem('token_type')
		localStorage.removeItem('expires_in')
		localStorage.removeItem('access_token')
		localStorage.removeItem('refresh_token')
		localStorage.removeItem('websocket_token')
	},

	save_account(state, account) {
		state.core.account.id = account.id
		state.core.account.name = account.name
		state.core.account.lastname = account.lastname
		state.core.account.email = account.email
		state.core.account.phone = account.phone
		state.core.account.id = account.id
		state.core.account.privileges = {
			loading: false,
			data: account.privileges
		}
		state.core.account.profile = account.profile
		state.core.account.client = account.client
	},

	save_ui_size(state, ui_size) {
		state.local.preferences.ui_size = ui_size
		localStorage.setItem('ui_size', ui_size)
	},

	save_ui_theme(state, ui_theme) {
		state.local.preferences.ui_theme = ui_theme
		localStorage.setItem('ui_theme', ui_theme)
	},

	fluid(state, fluid) {
		state.local.preferences.fluid = fluid
		localStorage.setItem('ui_fluid', fluid == true ? 1 : 0)
	},

	interval(state, interval) {
		state.interval = interval
	},

	new_purchase_request(state, data) {
		state.new_purchase_request = data
	},

	new_pricing(state, data) {
		state.new_pricing = data
	},

	new_order(state, data) {
		state.new_order = data
	},

	new_stock_requirement(state, data) {
		state.new_stock_requirement = data
	},

	new_warehouse_movement(state, data) {
		state.new_warehouse_movement = data
	},

	reset_purchase_request(state) {
		state.purchase_request.data = {
			id: null,
			branch_id: null,
			number: null,
			title: null,
			request_date: null,
			delivery_date: null,
			reviewed: null,
			reviewed_on: null,
			completed: null,
			completed_on: null,
			reviewed_by: null,
			cost_center_id: null,
			priority_id: null,
			created_by: null,
			comment: null,
			enabled: null,
			disabled_at: null,
			items: [],
			branch: {
				id: null,
				company_id: null,
				name: null,
				codename: null,
				description: null,
				enabled: null,
				disabled_at: null,
			},
			reviewer: {
				name: null,
				email: null,
				vatin: null,
				lastname: null,
				phone: null,
				management: null,
				enabled: null,
				created_at: null,
				disabled_at: null,
			},
			cost_center: {
				id: null,
				branch_id: null,
				parent_code: null,
				code: null,
				format_code: null,
				billable: null,
				level: null,
				description: null,
				enabled: null,
				disabled_at: null,
				exploded: [],
				created: null,
			},
			creator: {
				name: null,
				email: null,
				vatin: null,
				lastname: null,
				phone: null,
				management: null,
				enabled: null,
				created_at: null,
				disabled_at: null,
			}
		}
	},

	clear_new_pricing(state, data) {
		state.new_pricing = {
			id: null,
			branch_id: null,
			documentType: null,
			clientId: null,
			priceListId: null,
			title: null,
			gloss: null,
			billingCoinId: null,
			billingRate: null,
			contactId: null,
			sellerFileId: null,
			paymentCondition: null,
			state: null,
			folio: null,
			templateId: null,
			closingProbability: null,
			dueDate: null,
			pricingDate: null,
			closingDate: null,
			taxeCode1: null,
			taxePercentaje1: null,
			taxeValue1: null,
			exempt: null,
			affectable: null,
			totalDiscountSurcharge: null,
			total: null,
			subTotal: null,
			source: null,
			last_sync: null,
			enabled: null,
			disabled_at: null,
			items: [],
		}
	},

	clear_new_purchase_request(state, data) {
		state.new_purchase_request = {
			id: null,
			branch_id: null,
			number: null,
			title: null,
			request_date: null,
			delivery_date: null,
			reviewed: null,
			reviewed_on: null,
			completed: null,
			completed_on: null,
			reviewed_by: null,
			cost_center_id: null,
			priority_id: null,
			created_by: null,
			comment: null,
			enabled: null,
			disabled_at: null,
			items: [],
		}
	},

	clear_new_stock_requirement(state, data) {
		state.new_stock_requirement = {
			id: null,
			client_id: null,
			warehouse_id: null,
			item_id: null,
			description: null,
			unit_id: null,
			item_type: null,
			minimum: null,
			critical: null,
			enabled: null,
			disabled_at: null,
			items: [],
		}
	},

	clear_new_warehouse_movement(state, data) {
		state.new_warehouse_movement = {
			number: null,
			type_id: null,
			warehouse_id: null,
			date: null,
			comment: null,
			enabled: null,
			disabled_at: null,
			items: [],
		}
	},

	clear_new_order(state, data) {
		state.new_order = {
			id: null,
		}
	},

	hvac_client_id(state, data) {
		state.hvac.client_id = data
	},

	hvac_site_id(state, data) {
		state.hvac.site_id = data
	},

	access(state, data) {
		state.local.preferences.access = data
	},

	home(state, items) {
		state.home = {
			loading: false,
			data: items
		}
	},
	
	set_screen_size_width(state, width) {
		state.local.preferences.screen_size.width = width
	},
	
	set_screen_size_height(state, height) {
		state.local.preferences.screen_size.height = height
	},

	set_system_parameters(state, system_parameters) {
		state.system_parameters = {
			loading: false,
			data: system_parameters
		}
	},

	set_regions(state, regions) {
		state.regions = {
			loading: false,
			data: regions
		}
	},

	set_communes(state, communes) {
		state.communes = {
			loading: false,
			data: communes
		}
	},

	hvac_panel(state, items) {
		state.hvac_panel = {
			loading: false,
			data: items
		}
	},

	set_privileges(state, privileges) {
		state.privileges = {
			loading: false,
			data: privileges
		}
	},

	set_users(state, users) {
		state.users = {
			loading: false,
			data: users
		}
	},

	communes(state, items) {
		state.communes = {
			loading: false,
			data: items
		}
	},

	stock_requirements(state, items) {
		state.stock_requirements = {
			loading: false,
			data: items
		}
	},

	corporate_templates(state, items) {
		state.corporate_templates = {
			loading: false,
			data: items
		}
	},

	request_logs(state, items) {
		state.request_logs = {
			loading: false,
			data: items
		}
	},

	warehouses(state, items) {
		state.warehouses = {
			loading: false,
			data: items
		}
	},

	corporate_template_groups(state, items) {
		state.corporate_template_groups = {
			loading: false,
			data: items
		}
	},

	units(state, items) {
		state.units = {
			loading: false,
			data: items
		}
	},

	client_units(state, items) {
		state.client_units = {
			loading: false,
			data: items
		}
	},

	contracts(state, items) {
		state.contracts = {
			loading: false,
			data: items
		}
	},

	clients(state, items) {
		state.clients = {
			loading: false,
			data: items
		}
	},

	client_contacts(state, items) {
		state.client_contacts = {
			loading: false,
			data: items
		}
	},

	client_contracts(state, items) {
		state.client_contracts = {
			loading: false,
			data: items
		}
	},

	client_sites(state, items) {
		state.client_sites = {
			loading: false,
			data: items
		}
	},

	hvac_coolants(state, items) {
		state.hvac_coolants = {
			loading: false,
			data: items
		}
	},

	client_contract_areas(state, items) {
		state.client_contract_areas = {
			loading: false,
			data: items
		}
	},

	client_time_switches(state, items) {
		state.client_time_switches = {
			loading: false,
			data: items
		}
	},

	client_temperature_controls(state, items) {
		state.client_temperature_controls = {
			loading: false,
			data: items
		}
	},

	client_areas(state, items) {
		state.client_areas = {
			loading: false,
			data: items
		}
	},

	brands(state, items) {
		state.brands = {
			loading: false,
			data: items
		}
	},

	models(state, items) {
		state.models = {
			loading: false,
			data: items
		}
	},

	defontana_sales(state, items) {
		state.defontana_sales = {
			loading: false,
			data: items
		}
	},

	dashboard_home(state, item) {
		state.dashboard_home = {
			loading: false,
			data: item
		}
	},

	purchase_requests(state, item) {
		state.purchase_requests = {
			loading: false,
			data: item
		}
	},

	purchase_request(state, item) {
		state.purchase_request = {
			loading: false,
			data: item
		}
	},

	defontana_employees(state, item) {
		state.defontana_employees = {
			loading: false,
			data: item
		}
	},

	defontana_purchase_orders(state, item) {
		state.defontana_purchase_orders = {
			loading: false,
			data: item
		}
	},

	defontana_sale(state, item) {
		state.defontana_sale = {
			loading: false,
			data: item
		}
	},

	defontana_pricings(state, items) {
		state.defontana_pricings = {
			loading: false,
			data: items
		}
	},

	defontana_orders(state, items) {
		state.defontana_orders = {
			loading: false,
			data: items
		}
	},

	defontana_pricing(state, item) {
		state.defontana_pricing = {
			loading: false,
			data: item
		}
	},

	jobs(state, items) {
		state.jobs = {
			loading: false,
			data: items
		}
	},

	job(state, items) {
		state.job = {
			loading: false,
			data: items
		}
	},

	teamviewer_devices(state, items) {
		state.teamviewer_devices = {
			loading: false,
			data: items
		}
	},

	brand_models(state, items) {
		state.brand_models = {
			loading: false,
			data: items
		}
	},

	model_types(state, items) {
		state.model_types = {
			loading: false,
			data: items
		}
	},

	products(state, items) {
		state.products = {
			loading: false,
			data: items
		}
	},

	download_token(state, items) {
		state.download_token = {
			loading: false,
			data: items
		}
	},

	defontana_cost_centers(state, items) {
		state.defontana_cost_centers = {
			loading: false,
			data: items
		}
	},

	site_units(state, items) {
		state.site_units = {
			loading: false,
			data: items
		}
	},

	reset_site_units(state) {
		state.site_units = {
			loading: false,
			data: []
		}
	},

	reset_job(state) {
		state.job = {
			loading: false,
			data: {
				id: null,
				type_id: null,
				client_id: null,
				site_id: null,
			}
		}
	},

	unit(state, items) {
		state.unit = {
			loading: false,
			data: items
		}
	},

	defontana_services(state, items) {
		state.defontana_services = {
			loading: false,
			data: items
		}
	},

	defontana_products(state, items) {
		state.defontana_products = {
			loading: false,
			data: items
		}
	},

	helpers_warehouses(state, items) {
		state.helpers.warehouses = {
			loading: false,
			data: items
		}
	},

	helpers_measurement_units(state, items) {
		state.helpers.measurement_units = {
			loading: false,
			data: items
		}
	},

	helpers_warehouses_movement_types(state, items) {
		state.helpers.warehouses_movement_types = {
			loading: false,
			data: items
		}
	},

	helpers_defontana_purchase_orders(state, items) {
		state.helpers.defontana_purchase_orders = {
			loading: false,
			data: items
		}
	},

	helpers_defontana_products(state, items) {
		state.helpers.defontana_products = {
			loading: false,
			data: items
		}
	},

	helpers_defontana_cost_centers(state, items) {
		state.helpers.defontana_cost_centers = {
			loading: false,
			data: items
		}
	},

	helpers_defontana_services(state, items) {
		state.helpers.defontana_services = {
			loading: false,
			data: items
		}
	},

	helpers_defontana_document_types(state, items) {
		state.helpers.defontana_document_types = {
			loading: false,
			data: items
		}
	},

	helpers_defontana_sellers(state, items) {
		state.helpers.defontana_sellers = {
			loading: false,
			data: items
		}
	},

	helpers_defontana_payment_conditions(state, items) {
		state.helpers.defontana_payment_conditions = {
			loading: false,
			data: items
		}
	},

	helpers_branches(state, items) {
		state.helpers.branches = {
			loading: false,
			data: items
		}
	},

	helpers_defontana_clients(state, items) {
		state.helpers.defontana_clients = {
			loading: false,
			data: items
		}
	},

	helpers_clients(state, items) {
		state.helpers.clients = {
			loading: false,
			data: items
		}
	},

	helpers_job_types(state, items) {
		state.helpers.job_types = {
			loading: false,
			data: items
		}
	},

	helpers_unit_conditions(state, items) {
		state.helpers.unit_conditions = {
			loading: false,
			data: items
		}
	},

	helpers_unit_priorities(state, items) {
		state.helpers.unit_priorities = {
			loading: false,
			data: items
		}
	},

	helpers_unit_coolants(state, items) {
		state.helpers.unit_coolants = {
			loading: false,
			data: items
		}
	},

	helpers_unit_voltages(state, items) {
		state.helpers.unit_voltages = {
			loading: false,
			data: items
		}
	},

	helpers_unit_capacity_measurement_units(state, items) {
		state.helpers.unit_capacity_measurement_units = {
			loading: false,
			data: items
		}
	},

	helpers_unit_maintenance_windows(state, items) {
		state.helpers.unit_maintenance_windows = {
			loading: false,
			data: items
		}
	},

	helpers_profiles(state, items) {
		state.helpers.profiles = {
			loading: false,
			data: items
		}
	},
	


	set_real_name(state, real_name) { state.account = { name: real_name.name, lastname: real_name.lastname } },
	set_current_user_privileges(state, privileges) { state.current_user_privileges = { loading: false, data: privileges } },
	set_units(state, units) { state.units = { loading: false, data: units } },
	set_workers_new(state, workers) { state.workers_new = { loading: false, data: workers } },
	set_expirations(state, expirations) { state.expirations = { loading: false, data: expirations } },
	set_current_date_expirations(state, expirations) { state.current_date_expirations = { loading: false, data: expirations } },
	set_document_types(state, document_types) { state.document_types = { loading: false, data: document_types } },
	set_current_worker_documents(state, documents) { state.current_worker_documents = { loading: false, data: documents } },
	set_parts(state, parts) { state.parts = { loading: false, data: parts } },
	set_part_types(state, part_types) { state.part_types = { loading: false, data: part_types } },
	set_model_types(state, model_types) { state.model_types = { loading: false, data: model_types } },
	set_brands(state, brands) { state.brands = { loading: false, data: brands } },
	set_models(state, models) { state.models = { loading: false, data: models } },
	set_current_models(state, current_models) { state.current_models = { loading: false, data: current_models } },
	set_clients(state, clients) { state.clients = { loading: false, data: clients } },
	set_clients_with_units(state, clients) { state.clients_with_units = { loading: false, data: clients } },
	set_current_client_sites(state, sites) { state.current_client_sites = { loading: false, data: sites } },
	set_job_types(state, job_types) { state.job_types = { loading: false, data: job_types } },
	set_job_statuses(state, job_statuses) { state.job_statuses = { loading: false, data: job_statuses } },
	set_jobs(state, jobs) { state.jobs = { loading: false, data: jobs } },
	set_access_token(state, token) { state.access_token = token },
	set_username(state, username) { state.username = username },
	set_nubox_token(state, token) { state.nubox_token = token },
	set_contacts(state, contacts) { state.contacts = contacts },
	set_contracts(state, contracts) { state.contracts = { loading: false, data: contracts } },
	set_workers(state, workers) { state.workers = workers },
	set_current_contract(state, contract) { state.current_contract = contract },
	set_invoices(state, invoices) { state.invoices = invoices },
	set_cost_centers(state, cost_centers) { state.cost_centers = cost_centers },
	set_file_types(state, file_types) { state.file_types = file_types },
	set_file_extensions(state, file_extensions) { state.file_extensions = file_extensions },
	set_current_contract_sites(state, sites) { state.current_contract_sites = sites },
	set_current_contract_files(state, files) { state.current_contract_files = files },
	set_current_contract_contacts(state, contacts) { state.current_contract_contacts = contacts },
	set_current_contract_units(state, units) { state.current_contract_units.data = units; state.current_contract_units.loading = false },
	set_projects_history(state, projects_history) { state.projects_history = projects_history },
	set_current_unit(state, unit) { state.current_unit.data = unit; state.current_unit.loading = false; },
	set_current_job(state, job) { state.current_job.data = job; state.current_job.loading = false; },
	set_current_jobs(state, jobs) { state.current_jobs.data = jobs; state.current_jobs.loading = false; },
	set_current_excel(state, data) { state.current_excel = { loading: false, data: data, error: false } },
	set_purchase_orders(state, purchase_orders) { state.purchase_orders = { loading: false, data: purchase_orders } },
	set_setup_project_email(state, setup_project_email) { state.setup_project_email = { loading: false, data: setup_project_email } },
	set_schedules(state, schedules) { state.schedules = { loading: false, data: schedules } },









}