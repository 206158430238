<template>
	<div class="mt-3">

		<SiteModuleHeader
			:showBack="true"
			:showHome="true"
			:showSize="true"
			:showTheme="true"
			:showFluid="true"
			:showTitle="true"
			@reload="reload"
			:reloading="reloading || resources.loading"
		></SiteModuleHeader>

		<CreateJob @update="reload"></CreateJob>
		<UpdateJob @update="reload"></UpdateJob>
		<CreateClient></CreateClient>
		<CreateSite></CreateSite>
		<ViewUnit></ViewUnit>

		<!-- TOOLBAR -->
		<b-row no-gutters>

			<!-- CLIENT -->
			<b-col md="sm" lg="auto" xl="auto" class="mr-2" v-if="core.loading === false && profile.name != 'client'">
				<p class="mb-2"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
						'text-primary' : val_client == true,
						'text-danger' : val_client == false,
					}">
					<span>Cliente</span>
					<i class="fas fa-arrow-turn-down ml-2"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto">
						<b-overlay
							:show="clients.loading"
							rounded="sm"
							:variant="ui_theme == 'light' ? 'light' : 'dark'"
						>
							<template #overlay>
								<div class="text-center">
									<b-spinner type="grow" variant="primary" small></b-spinner>
								</div>
							</template>
							<b-select
								:state="val_client"
								v-model="hvac_client_id"
								:size="ui_size"
								:class="{
									'bg-transparent text-white' : ui_theme == 'dark',
								}"
								@change="reload()"
							>
								<b-select-option :value="null" disabled>Presione aquí</b-select-option>
								<b-select-option v-for="(item, i) in clients.data" :key="i" :value="item.id">{{ item.codename }}</b-select-option>
							</b-select>
						</b-overlay>
					</b-col>
				</b-row>
			</b-col>

			

			<!-- CREATE -->
			<b-col md="sm" lg="auto" xl="auto">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Acciones</span>
					<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto" v-if="core.loading === false && profile.name != 'client'">
						<!-- CREATE -->
						<SitePrimaryButton
							:loading="resources.loading"
							text="Nueva Orden"
							title="Presione para crear"
							icon="plus"
							@clicked="createJob()"
						></SitePrimaryButton>
					</b-col>

					<!-- SEARCH INPUT -->
					<b-col sm="auto" v-if="val_client">
						<b-overlay
							:show="resources.loading"
							rounded
							opacity="0.6"
						>
							<template #overlay>
								<span>&nbsp;</span>
							</template>
							<b-input-group :size="ui_size">
								<b-input
									v-model="query.search"
									placeholder="N° Orden de Trabajo"
									@changed="searchChanged"
									@blur="searchBlur()"
									@keyup.delete="searchClear()"
									@keydown.enter="search()"
									v-bind:class="{
										'bg-white text-primary' : true,
										'border-primary' : search != null,
										'border-0' : search == null,
									}"
								></b-input>
								<template #append>
									<b-button
										title="Presione para buscar"
										:disabled="query.search == null || query.search.length == 0"
										:variant="query.search != null && query.search.length > 0 ? 'primary' : 'outline-primary'"
										@click="search()"
									>
										<i class="fas fa-search mr-1"></i>
										<span>Buscar</span>
									</b-button>
								</template>
							</b-input-group>
						</b-overlay>
					</b-col>
				</b-row>
			</b-col>

			<!-- SPACER -->
			<b-col>
			</b-col>

			<!-- PER PAGE -->
			<b-col md="12" lg="auto" xl="auto">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Visualización</span>
					<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto">
						<SitePerPage
							:loading="resources.loading"
							:limit="query.limit"
							:options="paginationOptions"
							@clicked="perPageChanged"
						></SitePerPage>
					</b-col>
				</b-row>
			</b-col>

			<!-- PER PAGE -->
			<b-col md="12" lg="auto" xl="auto">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Página Actual</span>
					<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto">
						<SitePagination
							:loading="resources.loading"
							:page="query.page"
							:total="resources.data.total"
							:limit="query.limit"
							@clicked="paginationChanged"
						></SitePagination>
					</b-col>
				</b-row>
			</b-col>

		</b-row>
		<!-- /TOOLBAR -->

		<!-- DATA -->
		<b-skeleton-wrapper :loading="resources.loading && !reloading">

			<!-- LOADING -->
			<template #loading>
				<b-card no-body>
					<b-skeleton-table
						animation="fade"
						:rows="10"
						:columns="7"
					></b-skeleton-table>
				</b-card>
			</template>


			<!-- TABLE :fields="visibleTableFields" -->
			<b-table
				:items="resources.data.data"
				:fields="fields"
				:busy="resources.loading"
				:no-local-sorting="true"
				@sort-changed="sortingChanged"
				show-empty
				:stacked="mobile"
				hover
				bordered
				:small="ui_size == 'sm' || false"
				responsive
				sort-icon-left
				:head-variant="ui_theme == 'light' ? 'light' : 'dark'"
				:table-variant="ui_theme == 'light' ? 'light' : 'dark'"
				class="mt-0 mb-0 rounded"
				:class="{
					'fs-09' : ui_size == 'sm',
					'fs-10' : ui_size == 'md',
					'fs-13' : ui_size == 'lg',
					'table-dark' : ui_theme == 'dark',
				}"
			>
				<template v-slot:empty>
					<div class="p-3">
						<div v-if="val_client">
							<span class="fs-15 text-muted" >No hay Órdenes que coincidan con los filtros aplicados.</span>
						</div>
						<div v-else>
							<span class="fs-15 text-secondary">
								<i class="fas fa-up mr-2"></i>
								<span>Seleccione un Cliente</span>
							</span>
						</div>
					</div>
				</template>
				<template v-slot:table-busy>
					<div class="text-center my-2 p-3">
						<b-spinner class="align-middle"></b-spinner>
					</div>
				</template>
				<template v-slot:cell(number)="data">
					<div class="text-center" v-if="data.value">
						<b-button
							:variant="ui_theme == 'dark' ? 'light' : 'primary'"
							block size="sm"
							class="btn-xs"
							title="Presione aquí para abrir la Ficha de este Equipo"
							@click="updateJob(data.item)"
						>N°{{data.value}}</b-button>
					</div>
				</template>
				<template v-slot:cell(status_id)="data">
					<div class="text-center">
						<b-badge v-if="data.value==2" variant="success" class="ml-1">TERMINADA</b-badge>
						<b-badge v-else-if="data.value==3" variant="dark" class="ml-1">ANULADA</b-badge>
						<b-badge v-else variant="danger" class="ml-1">EN CURSO</b-badge>
					</div>
				</template>
				<template v-slot:cell(type)="data">
					<div>
						<span>{{data.value.name}}</span>
					</div>
				</template>
				<template v-slot:cell(maintenances)="data">
					<div class="text-center">
						<span>{{data.value.length}}</span>
					</div>
				</template>
				<template v-slot:cell(address)="data">
					<div>
						<span>{{data.value}}</span>
					</div>
				</template>
				<template v-slot:cell(commune)="data">
					<div>
						<span>{{data.value}}</span>
					</div>
				</template>
			</b-table>
		</b-skeleton-wrapper>
		
		<!-- PAGINATION -->
		<SitePagination
			:loading="resources.loading"
			:page="query.page"
			:total="resources.data.total"
			:limit="query.limit"
			@clicked="paginationChanged"
			class="mt-2"
		></SitePagination>

	</div>
</template>

<script>
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
import SitePrimaryButton from '@/components/SitePrimaryButton.vue'
import SitePerPage from '@/components/SitePerPage.vue'
import SitePagination from '@/components/SitePagination.vue'
import CreateJob from '@/components/CreateJob.vue'
import UpdateJob from '@/components/UpdateJob.vue'
import CreateClient from '@/components/CreateClient.vue'
import CreateSite from '@/components/CreateSite.vue'
import ViewUnit from '@/components/ViewUnit.vue'

export default {
	name: 'HVAC_JobsView',
	components: {
		SiteModuleHeader,
		SitePrimaryButton,
		SitePerPage,
		SitePagination,
		CreateJob,
		UpdateJob,
		CreateClient,
		CreateSite,
		ViewUnit,
	},
	data() {
		return {
			reloading: false,
			query: {
				limit: 10,
				page: 1,
				sortDesc: false,
				sortBy: 'name',
				search: null,
			},
			paginationOptions: [
				{ value: 5, text: '5 por página' },
				{ value: 10, text: '10 por página' },
				{ value: 15, text: '15 por página' },
				{ value: 20, text: '20 por página' },
				{ value: 50, text: '50 por página' },
				{ value: 100, text: '100 por página' },
				{ value: 9999, text: 'Mostrar todo' },
			],
			fields: [
				{ key: 'number', label: 'Ficha', sortable: true },
				{ key: 'status_id', label: 'Estado', sortable: true },
				{ key: 'type', label: 'Tipo', sortable: true },
				{ key: 'site.address', label: 'Sucursal/Faena', sortable: true },
				{ key: 'site.commune.name', label: 'Comuna', sortable: true },
				{ key: 'maintenances', label: 'Equipos', sortable: true },
			],
		}
	},
	mounted() {
		this.interval = setInterval(() => {
			if (this.core.loading === false && this.profile.name === 'client') {
				if (this.client && this.client.id && this.client.id > 0) {
					this.hvac_client_id = this.client.id
					this.load()
					clearInterval(this.interval)
				}
			} else if (this.core.loading === false && this.profile.name != 'client') {
				this.load()
				this.$store.dispatch('helpers_clients')
				clearInterval(this.interval)
			}
		}, 1 * 500)
	},
	created() {
		this.$store.dispatch('helpers_job_types')
	},
	computed: {
		client() { return this.$store.state.core.account.client },
		profile() { return this.$store.state.core.account.profile },
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		resources() { return this.$store.state.jobs },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		clients() { return this.$store.state.helpers.clients },
		val_client() { return this.hvac_client_id != null ? true : false },
		hvac_client_id: {
			get() { return this.$store.getters.hvac_client_id },
			set(data) { this.$store.commit('hvac_client_id', data) }
		},
		interval: {
			get() { return this.$store.getters.interval; },
			set(data) { this.$store.commit('interval', data); }
		},
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
		load() {
			if (this.hvac_client_id != null) {
				this.query.client_id = this.hvac_client_id
				this.$store.dispatch('jobs', this.query)
				.then(() => { this.reloading = false })
			}
		},
		reload() {
			this.reloading = true
			this.load()
		},
		createJob() {
			this.$bvModal.show('create-job')
		},
		updateJob(job) {
			this.$store.dispatch('job', job.id)
			this.$bvModal.show('update-job')
		},
		perPageChanged(limit) {
			this.query.limit = limit
			this.reload()
		},
		paginationChanged(page) {
			this.query.page = page
			this.reload()
		},
		sortingChanged(ctx) {
			this.query.sortDesc = ctx.sortDesc
			this.query.sortBy = ctx.sortBy
			this.reload()
		},
		search() {
			if (this.query.search == null) {
				this.query.search = ''
				//this.$refs.search.focus()
			}
			else if (this.query.search == '') {
				this.query.search = null
			}
			else if (this.query.search.length > 0) {
				this.reload()
			}
		},
		searchBlur() {
			if (this.query.search == '') {
				this.query.search = null
			}
		},
		searchClear() {
			if (this.query.search != null && this.query.search.length == 0) {
				this.query.page = 1
				this.reload()
			}
		},
		searchChanged(search) {
			this.query.search = search
		},
	}
}
</script>

<style scoped>
</style>
