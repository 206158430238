<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="create-brand"
			title="Nueva Marca"
			:size="ui_size == 'sm' ? 'md' : 'md'"
			body-class="p-0"
			:title-class="{
				'fs-11' : ui_size == 'sm',
				'fs-13' : ui_size == 'md',
				'fs-15' : ui_size == 'lg',
			}"
			:header-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:header-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:body-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:body-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:footer-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:footer-text-variant="ui_theme == 'dark' ? 'light' : ''"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-4" :variant="ui_theme == 'dark' ? 'dark': 'light'">

				<b-row>

					<!-- NAME -->
					<b-col sm="12">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								Nombre
							</template>
							<b-input
								v-model="brand.name"
								:state="val_name"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

				</b-row>

				<!-- VALIDATION -->
				<b-alert
					show
					:variant="val_full ? 'success' : 'danger'"
					class="mb-0"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-12' : ui_size == 'lg',
					}"
				>
					<p class="mb-0 text-size-10">
						<i class="fas fa-exclamation-circle mr-2"></i>
						<span>{{ val_full ?
									'Para continuar, presione el botón CREAR.' :
									'Complete los campos requeridos para continuar.'}}</span>
					</p>
				</b-alert>

				<!-- LOADING -->
				<template #overlay>
					<div class="text-center py-5">
						<b-spinner type="grow" variant="primary"></b-spinner>
					</div>
				</template>

			</b-overlay>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :disabled="loading" :size="ui_size">Cancelar</b-button>
				<b-button @click="create()" variant="success" :disabled="loading||!val_full" :size="ui_size">CREAR</b-button>
			</template>

		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'CreateBrand',
	components: {
	},
	data() {
		return {
			loading: false,
			brand: {
				name: '',
			},
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'create-brand') { this.reset() }
		})
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		val_name() {
			return this.brand.name && this.brand.name.length > 0 ? true : false
		},
		val_full() {
			return this.val_name ? true : false
		},
	},
	methods: {
		reset() {
			this.brand = {
				name: '',
			}
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		create() {
			this.loading = true
			this.$api.post('brands', this.brand)
			.then(response => {
				this.loading = false
				this.$bvModal.hide('create-brand')
				this.$store.dispatch('brands')
				this.toast(true, 'Se creó la Marca "'+response.data.name+'" correctamente.')
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
	}
}
</script>