<template>
	<div class="mt-3">

		<SiteModuleHeader
			:showBack="true"
			:showHome="true"
			:showSize="true"
			:showTheme="true"
			:showFluid="true"
			:showTitle="true"
			@reload="reload"
			:reloading="reloading || resources.loading"
		></SiteModuleHeader>

		<CreateContract @new_client_id="updateClientID" @update="reload"></CreateContract>
		<CreateClient></CreateClient>
		<CreateClientContact @new_client_id="updateClientID" :client_id="current.client_id" @update="reload"></CreateClientContact>
		<CreateSite @new_client_id="updateClientID" :client_id="current.client_id" @update="reload"></CreateSite>

		<!-- TOOLBAR -->
		<b-row no-gutters>

			<!-- CREATE -->
			<b-col md="12" lg="auto" xl="auto">
				<SitePrimaryButton
					:loading="resources.loading"
					text="Nuevo Contrato"
					title="Presione para crear"
					icon="plus"
					@clicked="createContract()"
				></SitePrimaryButton>
			</b-col>

			<!-- CREATE -->
			<b-col md="12" lg="auto" xl="auto">
				<SitePrimaryButton
					:loading="resources.loading"
					text="Nueva Sucursal"
					title="Presione para crear"
					icon="plus"
					@clicked="createSite()"
				></SitePrimaryButton>
			</b-col>

		</b-row>
		<!-- /TOOLBAR -->

		<!-- DATA -->
		<b-skeleton-wrapper :loading="resources.loading && !reloading">

			<!-- LOADING -->
			<template #loading>
				<b-card no-body>
					<b-skeleton-table
						animation="fade"
						:rows="10"
						:columns="7"
					></b-skeleton-table>
				</b-card>
			</template>


			<!-- TABLE :fields="visibleTableFields" -->
			<b-table
				:items="resources.data"
				:fields="fields"
				:busy="resources.loading"
				:no-local-sorting="true"
				@sort-changed="sortingChanged"
				show-empty
				:stacked="mobile"
				hover
				bordered
				:small="ui_size == 'sm' || false"
				responsive
				sort-icon-left
				:head-variant="ui_theme == 'light' ? 'light' : 'dark'"
				:table-variant="ui_theme == 'light' ? 'light' : 'dark'"
				class="mt-0 mb-0 rounded"
				:class="{
					'fs-09' : ui_size == 'sm',
					'fs-10' : ui_size == 'md',
					'fs-13' : ui_size == 'lg',
					'table-dark' : ui_theme == 'dark',
				}"
			>
				<template #empty>
					<p class="px-3 text-size-12 my-3">No se encontraron registros.</p>
				</template>
				<template v-slot:head()="data">
					<div class="text-left text-secondary">
						<p class="m-0">{{data.label}}</p>
					</div>
				</template>
				<template v-slot:cell()="data">
					<div class="text-left">
						{{data.value}}
					</div>
				</template>
				<template v-slot:cell(contact)="data">
					<div class="text-left">
						<span>{{data.value.name}} {{data.value.lastname}}</span>
					</div>
				</template>
				<template v-slot:cell(client)="data">
					<div class="text-left">
						<span v-if="data.value.codename">{{data.value.codename}}</span>
						<span v-else class="text-megamuted">SIN NOMBRE CORTO</span>
					</div>
				</template>
				<template v-slot:cell(units)="data">
					<div class="text-left">
						<span v-if="data.value">{{data.value.length}}</span>
						<span v-else class="text-muted">-</span>
					</div>
				</template>
				<template v-slot:cell(sites)="data">
					<div class="text-left">
						<span v-if="data.value">{{data.value.length}}</span>
						<span v-else class="text-muted">-</span>
					</div>
				</template>
				<template v-slot:cell(end_date)="data">
					<div>
						<span v-if="data.value">{{data.value}}</span>
						<span v-else>Indefinido</span>
					</div>
				</template>
				<template v-slot:emptyfiltered>
					<div class="text-center p-3">
						<span class="text-muted">No hay Contratos que coincidan con la búsqueda ingresada.</span>
					</div>
				</template>
				<template v-slot:table-busy>
					<div class="text-center my-2">
						<b-spinner class="align-middle"></b-spinner>
					</div>
				</template>
			</b-table>
		</b-skeleton-wrapper>

	</div>
</template>

<script>
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
import SitePrimaryButton from '@/components/SitePrimaryButton.vue'
import CreateClient from '@/components/CreateClient.vue'
import CreateClientContact from '@/components/CreateClientContact.vue'
import CreateContract from '@/components/CreateContract.vue'
import CreateSite from '@/components/CreateSite.vue'

export default {
	name: 'HVAC_ContractsView',
	components: {
		SiteModuleHeader,
		SitePrimaryButton,
		CreateContract,
		CreateClient,
		CreateClientContact,
		CreateSite,
	},
	data() {
		return {
			reloading: false,
			query: {
				limit: 9999,
				page: 1,
				sortDesc: false,
				sortBy: 'name',
				search: null,
			},
			current: {
				client_id: null,
			},
            fields: [
                { key: 'number', label: 'Folio Contrato', sortable: true },
                { key: 'client', label: 'Cliente', sortable: true },
                { key: 'contact', label: 'Contacto', sortable: true },
                { key: 'start_date', label: 'Inicio', sortable: true },
                { key: 'end_date', label: 'Término', sortable: true },
                { key: 'sites', label: 'Sucursales', sortable: true },
                { key: 'units', label: 'Equipos', sortable: true },
			],
		}
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		resources() { return this.$store.state.contracts },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
	},
	created() {
		this.load()
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
		load() {
			this.$store.dispatch('contracts').then(() => { this.reloading = false; })
		},
		reload() {
			this.reloading = true
			this.load()
		},
		createContract() {
			this.$store.dispatch('helpers_clients')
			this.$bvModal.show('create-contract')
		},
		createSite() {
			this.$store.dispatch('helpers_clients')
			this.$store.dispatch('communes')
			this.$bvModal.show('create-site')
		},
		perPageChanged(limit) {
			this.query.limit = limit
			this.reload()
		},
		paginationChanged(page) {
			this.query.page = page
			this.reload()
		},
		sortingChanged(ctx) {
			this.query.sortDesc = ctx.sortDesc
			this.query.sortBy = ctx.sortBy
			this.reload()
		},
		updateClientID(data) {
			this.current.client_id = data
		},
	}
}
</script>
