<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="view-unit"
			:size="ui_size == 'sm' ? 'lg' : 'lg'"
			body-class="p-0"
			:title-class="{
				'fs-11' : ui_size == 'sm',
				'fs-13' : ui_size == 'md',
				'fs-15' : ui_size == 'lg',
			}"
			:header-bg-variant="ui_theme == 'dark' ? 'primary' : 'primary'"
			:header-text-variant="ui_theme == 'dark' ? 'light' : 'light'"
			:body-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:body-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:footer-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:footer-text-variant="ui_theme == 'dark' ? 'light' : ''"
		>
			<template #modal-header>
				<div class="w-100">
					<b-row>
						<b-col sm="auto">
							<b-spinner v-if="resource.loading" type="grow" small variant="primary"></b-spinner>
							<div class="mb-0" v-else-if="resource.data && resource.data.number">
								<b-row no-gutters>
									<b-col sm="auto" class="pr-3 pt-2">
										<i class="fas fa-air-conditioner fs-15"></i>
									</b-col>
									<b-col sm="auto">
										<p class="mb-1 fs-08">
											Ficha de Equipo
										</p>
										<p class="mb-0 fs-11 flh-10">
											<strong>EQ-{{ resource.data.number }}</strong>
										</p>
									</b-col>
								</b-row>
							</div>
						</b-col>
						<b-col class="text-right pt-1" v-if="!resource.loading">
							<b-button
								size="sm"
								variant="primary"
								@click="$bvModal.hide('view-unit')"
							>
								<i class="fas fa-times mr-1"></i>
								<span>Cerrar</span>
							</b-button>
						</b-col>
					</b-row>
				</div>
			</template>
		
			<!-- CONTENT -->
			<b-overlay :show="loading || resource.loading" rounded="sm" class="p-4" :variant="ui_theme == 'dark' ? 'dark': 'light'">

				<b-list-group class="mb-3">

					<!-- CLIENT -->
					<b-list-group-item class="py-2 pl-3 pr-2">
						<b-row no-gutters>
							<b-col sm="3">
								<strong>Cliente</strong>
							</b-col>
							<b-col>
								<div v-if="resource.loading">
									<b-skeleton class="mt-1"></b-skeleton>
								</div>
								<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.client && resource.data.client.name">
									<p class="mb-0">
										<span>{{ resource.data.client.name }}</span>
									</p>
								</div>
								<p class="mb-0" v-else>
									<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
									<span class="text-danger">Pendiente</span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>

					<!-- SITE -->
					<b-list-group-item class="py-2 pl-3 pr-2">
						<b-row no-gutters>
							<b-col sm="3">
								<strong>Sucursal</strong>
							</b-col>
							<b-col>
								<div v-if="resource.loading">
									<b-skeleton class="mt-1"></b-skeleton>
								</div>
								<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.site && resource.data.site.address">
									<p class="mb-0">
										<span>{{ resource.data.site.address }}</span>
										<span v-if="resource.data.site.commune && resource.data.site.commune.name">, {{ resource.data.site.commune.name }}</span>
									</p>
								</div>
								<p class="mb-0" v-else>
									<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
									<span class="text-danger">Pendiente</span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>

					<!-- LOCATION INTERIOR -->
					<b-list-group-item class="py-2 pl-3 pr-2">
						<b-row no-gutters>
							<b-col sm="3">
								<strong>Ubicación U. Interior</strong>
							</b-col>
							<b-col>
								<div v-if="resource.loading">
									<b-skeleton class="mt-1"></b-skeleton>
								</div>
								<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.location_interior">
									<p class="mb-0">
										<span>{{ resource.data.location_interior }}</span>
									</p>
								</div>
								<p class="mb-0" v-else>
									<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
									<span class="text-danger">Pendiente</span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>

					<!-- LOCATION EXTERIOR -->
					<b-list-group-item class="py-2 pl-3 pr-2">
						<b-row no-gutters>
							<b-col sm="3">
								<strong>Ubicación U. Exterior</strong>
							</b-col>
							<b-col>
								<div v-if="resource.loading">
									<b-skeleton class="mt-1"></b-skeleton>
								</div>
								<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.location_exterior">
									<p class="mb-0">
										<span>{{ resource.data.location_exterior }}</span>
									</p>
								</div>
								<p class="mb-0" v-else>
									<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
									<span class="text-danger">Pendiente</span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>

					<!-- AREA -->
					<b-list-group-item class="py-2 pl-3 pr-2">
						<b-row no-gutters>
							<b-col sm="3">
								<strong>Área a Climatizar</strong>
							</b-col>
							<b-col>
								<div v-if="resource.loading">
									<b-skeleton class="mt-1"></b-skeleton>
								</div>
								<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.area && resource.data.area.name">
									<p class="mb-0">
										<span>{{ resource.data.area.name }}</span>
									</p>
								</div>
								<p class="mb-0" v-else>
									<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
									<span class="text-danger">Pendiente</span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>

					<!-- CONTRACT AREA -->
					<b-list-group-item class="py-2 pl-3 pr-2">
						<b-row no-gutters>
							<b-col sm="3">
								<strong>Área</strong>
							</b-col>
							<b-col>
								<div v-if="resource.loading">
									<b-skeleton class="mt-1"></b-skeleton>
								</div>
								<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.contract_area && resource.data.contract_area.name">
									<p class="mb-0">
										<span>{{ resource.data.contract_area.name }}</span>
									</p>
								</div>
								<p class="mb-0" v-else>
									<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
									<span class="text-danger">Pendiente</span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>

					<!-- TIME SWITCH -->
					<b-list-group-item class="py-2 pl-3 pr-2">
						<b-row no-gutters>
							<b-col sm="3">
								<strong>Reloj Horario</strong>
							</b-col>
							<b-col>
								<div v-if="resource.loading">
									<b-skeleton class="mt-1"></b-skeleton>
								</div>
								<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.time_switch && resource.data.time_switch.name">
									<p class="mb-0">
										<span>{{ resource.data.time_switch.name }}</span>
									</p>
								</div>
								<p class="mb-0" v-else>
									<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
									<span class="text-danger">Pendiente</span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>

					<!-- TEMPERATURE CONTROL -->
					<b-list-group-item class="py-2 pl-3 pr-2">
						<b-row no-gutters>
							<b-col sm="3">
								<strong>Control de Temperatura</strong>
							</b-col>
							<b-col>
								<div v-if="resource.loading">
									<b-skeleton class="mt-1"></b-skeleton>
								</div>
								<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.temperature_control && resource.data.temperature_control.name">
									<p class="mb-0">
										<span>{{ resource.data.temperature_control.name }}</span>
									</p>
								</div>
								<p class="mb-0" v-else>
									<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
									<span class="text-danger">Pendiente</span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>

					<!-- INSTALL DATE -->
					<b-list-group-item class="py-2 pl-3 pr-2">
						<b-row no-gutters>
							<b-col sm="3">
								<strong>Fecha de Instalación</strong>
							</b-col>
							<b-col>
								<div v-if="resource.loading">
									<b-skeleton class="mt-1"></b-skeleton>
								</div>
								<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.install_date">
									<p class="mb-0">
										<span>{{ resource.data.install_date }}</span>
									</p>
								</div>
								<p class="mb-0" v-else>
									<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
									<span class="text-danger">Pendiente</span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>

					<!-- BRAND -->
					<b-list-group-item class="py-2 pl-3 pr-2">
						<b-row no-gutters>
							<b-col sm="3">
								<strong>Marca</strong>
							</b-col>
							<b-col>
								<div v-if="resource.loading">
									<b-skeleton class="mt-1"></b-skeleton>
								</div>
								<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.brand && resource.data.brand.name">
									<p class="mb-0">
										<span>{{ resource.data.brand.name }}</span>
									</p>
								</div>
								<p class="mb-0" v-else>
									<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
									<span class="text-danger">Pendiente</span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>

					<!-- MODEL -->
					<b-list-group-item class="py-2 pl-3 pr-2">
						<b-row no-gutters>
							<b-col sm="3">
								<strong>Modelo</strong>
							</b-col>
							<b-col>
								<div v-if="resource.loading">
									<b-skeleton class="mt-1"></b-skeleton>
								</div>
								<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.model && resource.data.model.part_number">
									<p class="mb-0">
										<span v-if="resource.data.type && resource.data.type.name">{{ resource.data.type.name }} // </span>
										<span>{{ resource.data.model.part_number }}</span>
									</p>
								</div>
								<p class="mb-0" v-else>
									<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
									<span class="text-danger">Pendiente</span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>

					<!-- FLOOR -->
					<b-list-group-item class="py-2 pl-3 pr-2">
						<b-row no-gutters>
							<b-col sm="3">
								<strong>Nivel o Piso</strong>
							</b-col>
							<b-col>
								<div v-if="resource.loading">
									<b-skeleton class="mt-1"></b-skeleton>
								</div>
								<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.floor">
									<p class="mb-0">
										<span>{{ resource.data.floor }}</span>
									</p>
								</div>
								<p class="mb-0" v-else>
									<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
									<span class="text-danger">Pendiente</span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>

					<!-- IDENTIFIER -->
					<b-list-group-item class="py-2 pl-3 pr-2">
						<b-row no-gutters>
							<b-col sm="3">
								<strong>Identificador</strong>
							</b-col>
							<b-col>
								<div v-if="resource.loading">
									<b-skeleton class="mt-1"></b-skeleton>
								</div>
								<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.identifier">
									<p class="mb-0">
										<span>{{ resource.data.identifier }}</span>
									</p>
								</div>
								<p class="mb-0" v-else>
									<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
									<span class="text-danger">Pendiente</span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>

					<!-- SERIAL NUMBER -->
					<b-list-group-item class="py-2 pl-3 pr-2">
						<b-row no-gutters>
							<b-col sm="3">
								<strong>Número de Serie</strong>
							</b-col>
							<b-col>
								<div v-if="resource.loading">
									<b-skeleton class="mt-1"></b-skeleton>
								</div>
								<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.serial_number">
									<p class="mb-0">
										<span>{{ resource.data.serial_number }}</span>
									</p>
								</div>
								<p class="mb-0" v-else>
									<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
									<span class="text-danger">Pendiente</span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>
						
				</b-list-group>

				
				<b-row>

					<b-col sm="4" class="mb-3">
						<b-card no-body>
							<b-card-body class="p-3">
								<b-row no-gutters>
									<b-col sm="auto">
										<i class="fas fa-fan fs-30 text-primary mr-3"></i>
									</b-col>
									<b-col>
										<p class="mb-0 fs-09 fw-bold">Capacidad</p>
										<div v-if="resource.loading">
											<b-skeleton class="mt-1"></b-skeleton>
										</div>
										<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.capacity">
											<p class="mb-0 fs-13">
												<span>{{ resource.data.capacity }}</span>
												<span v-if="resource.data.capacity_measurement_unit && resource.data.capacity_measurement_unit.name"> {{ resource.data.capacity_measurement_unit.name }}</span>
											</p>
										</div>
										<p class="mb-0" v-else>
											<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
											<span class="text-danger">Pendiente</span>
										</p>
									</b-col>
								</b-row>
							</b-card-body>
						</b-card>
					</b-col>

					<b-col sm="4" class="mb-3">
						<b-card no-body>
							<b-card-body class="p-3">
								<b-row no-gutters>
									<b-col sm="auto">
										<i class="fas fa-bolt fs-30 text-primary mr-3"></i>
									</b-col>
									<b-col>
										<p class="mb-0 fs-09 fw-bold">Voltaje</p>
										<div v-if="resource.loading">
											<b-skeleton class="mt-1"></b-skeleton>
										</div>
										<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.voltage && resource.data.voltage.name">
											<p class="mb-0 fs-13">
												<span>{{ resource.data.voltage.name }}V</span>
											</p>
										</div>
										<p class="mb-0" v-else>
											<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
											<span class="text-danger">Pendiente</span>
										</p>
									</b-col>
								</b-row>
							</b-card-body>
						</b-card>
					</b-col>
					
					<b-col sm="4" class="mb-3">
						<b-card no-body>
							<b-card-body class="p-3">
								<b-row no-gutters>
									<b-col sm="auto">
										<i class="fas fa-temperature-snow fs-30 text-primary mr-3"></i>
									</b-col>
									<b-col>
										<p class="mb-0 fs-09 fw-bold">Refrigerante</p>
										<div v-if="resource.loading">
											<b-skeleton class="mt-1"></b-skeleton>
										</div>
										<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.coolant && resource.data.coolant.name">
											<p class="mb-0 fs-13">
												<span>{{ resource.data.coolant.name }}</span>
											</p>
										</div>
										<p class="mb-0" v-else>
											<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
											<span class="text-danger">Pendiente</span>
										</p>
									</b-col>
								</b-row>
							</b-card-body>
						</b-card>
					</b-col>
					
					<b-col sm="4" class="mb-3">
						<b-card no-body>
							<b-card-body class="p-3">
								<b-row no-gutters>
									<b-col sm="auto">
										<i class="fas fa-calendar-days fs-30 text-primary mr-3"></i>
									</b-col>
									<b-col>
										<p class="mb-0 fs-09 fw-bold">Mantenimiento</p>
										<div v-if="resource.loading">
											<b-skeleton class="mt-1"></b-skeleton>
										</div>
										<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.maintenance_window && resource.data.maintenance_window.name">
											<p class="mb-0 fs-13">
												<span>{{ resource.data.maintenance_window.name }}</span>
											</p>
										</div>
										<p class="mb-0" v-else>
											<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
											<span class="text-danger">Pendiente</span>
										</p>
									</b-col>
								</b-row>
							</b-card-body>
						</b-card>
					</b-col>
					
					<b-col sm="4" class="mb-3">
						<b-card no-body>
							<b-card-body class="p-3">
								<b-row no-gutters>
									<b-col sm="auto">
										<i class="fas fa-badge-check fs-30 text-primary mr-3"></i>
									</b-col>
									<b-col>
										<p class="mb-0 fs-09 fw-bold">Criticidad</p>
										<div v-if="resource.loading">
											<b-skeleton class="mt-1"></b-skeleton>
										</div>
										<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.priority && resource.data.priority.name">
											<p class="mb-0 fs-13">
												<span>{{ resource.data.priority.name }}</span>
											</p>
										</div>
										<p class="mb-0" v-else>
											<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
											<span class="text-danger">Pendiente</span>
										</p>
									</b-col>
								</b-row>
							</b-card-body>
						</b-card>
					</b-col>
					
					<b-col sm="4" class="mb-3">
						<b-card no-body>
							<b-card-body class="p-3">
								<b-row no-gutters>
									<b-col sm="auto">
										<i class="fas fa-gears fs-30 text-primary mr-3"></i>
									</b-col>
									<b-col>
										<p class="mb-0 fs-09 fw-bold">Condición</p>
										<div v-if="resource.loading">
											<b-skeleton class="mt-1"></b-skeleton>
										</div>
										<div class="mb-0 text-uppercase" v-else-if="resource.data && resource.data.condition && resource.data.condition.name">
											<p class="mb-0 fs-13">
												<span>{{ resource.data.condition.name }}</span>
											</p>
										</div>
										<p class="mb-0" v-else>
											<i class="fas fa-triangle-exclamation text-warning mr-1"></i>
											<span class="text-danger">Pendiente</span>
										</p>
									</b-col>
								</b-row>
							</b-card-body>
						</b-card>
					</b-col>

				</b-row>

				<!-- LOADING -->
				<template #overlay>
					<div class="text-center py-5">
						<b-spinner type="grow" variant="primary"></b-spinner>
					</div>
				</template>

			</b-overlay>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :disabled="loading" :size="ui_size">Cerrar</b-button>
			</template>

		</b-modal>
	</div>
</template>

<script>
import Helpers from '@/helpers';
export default {
	name: 'UpdateUnit',
	components: {
	},
	data() {
		return {
			loading: false,
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'update-unit') { this.reset() }
		})
	},
	computed: {
		resource: {
			get() { return this.$store.getters.unit },
			set(data) { this.$store.commit('unit', data) }
		},
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		clients() { return this.$store.state.clients },
		client_sites() { return this.$store.state.client_sites },
		brand_models() { return this.$store.state.brand_models },
		brands() { return this.$store.state.brands },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		helpers_unit_voltages() { return this.$store.state.helpers.unit_voltages },
		helpers_unit_coolants() { return this.$store.state.helpers.unit_coolants },
		helpers_unit_conditions() { return this.$store.state.helpers.unit_conditions },
		helpers_unit_priorities() { return this.$store.state.helpers.unit_priorities },
		helpers_unit_capacity_measurement_units() { return this.$store.state.helpers.unit_capacity_measurement_units },
		helpers_unit_maintenance_windows() { return this.$store.state.helpers.unit_maintenance_windows },
		currentUnitCapacityMeasurement() {
			if (!this.$store.state.unit.loading && this.resource.data.capacity_measurement_unit_id != null && !this.helpers_unit_capacity_measurement_units.loading && this.helpers_unit_capacity_measurement_units.data.length > 0) {
				var mu = this.helpers_unit_capacity_measurement_units.data.find(item => item.id == this.resource.data.capacity_measurement_unit_id)
				return mu.name
			} else {
				return 'U.M.'
			}
		},
	},
	methods: {
		reset() {
			this.resource = {
				id: null,
				brand_id: null,
				model_id: null,
				voltage_id: null,
				coolant_id: null,
				client_id: null,
				capacity_measurement_unit_id: null,
				maintenance_window_id: null,
				site_id: null,
				serial_number: '',
				install_date: '',
				priority_id: null,
				condition_id: null,
				is_working: null,
				location: '',
				capacity: '',
				description: '',
				comment: '',
			}
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		create() {
			this.loading = true
			this.$api.put('units/'+this.resource.data.id, this.resource.data)
			.then(response => {
				this.loading = false
				this.$bvModal.hide('create-unit')
				this.$emit('update')
				this.toast(true, 'Se actualizó la Unidad N°'+response.data.id+' correctamente.')
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		reloadClientSites() {
			this.resource.data.site_id = null
			this.$emit('new_client_id', this.resource.data.client_id)
			this.$store.dispatch('client_sites', this.resource.data.client_id)
		},
		reloadBrandModels() {
			this.resource.data.model_id = null
			this.$emit('new_brand_id', this.resource.data.brand_id)
			this.$store.dispatch('brand_models', this.resource.data.brand_id)
		},
		onlyNumbers(data) {
			return Helpers.onlyNumbers(data)
		},
	}
}
</script>