<template>
	<div>
		<b-modal
			id="enable-user"
			title="Confirmación"
			:hide-header="done"
		>

			<div v-if="done">
				<p class="mb-0 text-center mt-4">
					<i class="fas fa-unlock text-success fs-50"></i>
				</p>
				<p class="mb-2 mt-3 fs-15 text-center">
					<strong>Usuario Desbloqueado</strong>
				</p>
				<p class="mb-2 fs-11 text-center text-muted">Ya puede cerrar esta ventana.</p>
				<!-- Esta ventana se cerrará automáticamente en {{ timer }} segundos. -->
			</div>
			<div v-else>
				<b-overlay :show="loading" rounded="sm" class="p-0">
					<b-row>
						<b-col>
							<b-alert show variant="light">
								<b-row no-gutters>
									<b-col sm="auto" class="pr-2">
										<i class="fas fa-exclamation-triangle"></i>
									</b-col>
									<b-col>
										<p class="mb-0 fs-11">El usuario podrá ingresar nuevamente al sistema con los privilegios que tenga actualmente asignados.</p>
									</b-col>
								</b-row>
							</b-alert>
							<p class="mt-4 fs-13 text-success text-center">¿Desea desbloquear el acceso a {{ user && user.item ? user.item.name : '' }}?</p>
						</b-col>
					</b-row>
					<template #overlay>
						<div class="text-center py-5">
							<b-spinner type="grow" variant="secondary"></b-spinner>
						</div>
					</template>
				</b-overlay>
			</div>
			<template v-slot:modal-footer="{ close }">
				<b-button
					v-if="done"
					@click="close()"
					variant="outline-secondary"
				>Cerrar</b-button>
				<div class="w-100" v-else>
					<b-row>
						<b-col>
							<b-button @click="close()" variant="outline-secondary" :disabled="loading">Cancelar</b-button>
						</b-col>
						<b-col class="text-right" >
							<b-button @click="enableUser(user)" variant="success" :disabled="loading">
							<i class="fas fa-unlock mr-2"></i>
							<span>DESBLOQUEAR</span></b-button>
						</b-col>
					</b-row>
				</div>
			</template>
		</b-modal>

	</div>
</template>

<script>
export default {
	name: 'EnableUser',
	components: {
	},
	data() {
		return {
			loading: false,
			done: false,
			timer: 5,
		}
	},
	props: {
		user: Object,
	},
	created() {
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'enable-user') { this.reset() }
		})
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		resources() { return this.$store.state.internal_workers },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		internal_divisions() {
			return this.$store.state.internal_divisions
		},
		internal_branches() {
			return this.$store.state.internal_branches
		},
		internal_workers_documents() {
			return this.$store.state.internal_workers_documents
		},
		internal_workers_documents_types() {
			return this.$store.state.internal_workers_documents_types
		},
		internal_workers_documents_folders() {
			return this.$store.state.internal_workers_documents_folders
		},
	},
	methods: {
		reset() {
			this.loading = false
			this.done = false
			this.timer = 5
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		close() {
			this.$bvModal.hide('enable-user')
		},
		enableUser() {
			this.loading = true
			let user_id = this.user.item.id
			this.$api.get('users/'+ user_id +'/enable')
			.then(() => {
				this.done = true
				this.loading = false
				//this.runTimer()
				this.$emit('update')
				this.$store.dispatch('get_privileges', user_id)
			})
			.catch(error => {
				this.done = false
				this.loading = false
				console.log(error)
			})
		},
		runTimer(){
			var self = this
			this.interval = setInterval(function() {
				if (self.timer > 0) {
					self.timer--
				}
            }, 1000)
			this.timeout = setTimeout(function() {
				self.$bvModal.hide('enable-user')
				self.stopTimer()
			}, 5 * 1000)
		},
		stopTimer() {
			clearInterval(this.interval)
			clearTimeout(this.timeout)
		},
	}
}
</script>

<style scoped>

</style>