<template>
	<div
		id="header"
		v-bind:class="{ 'background' : login || logout || welcome || recover || access || direct }"
	></div>
</template>

<script>
export default {
	name: 'SiteBackground',
	computed: {
		login() {
			return this.$route.name === 'Login'
		},
		logout() {
			return this.$route.name === 'Logout'
		},
		access() {
			return this.$route.name === 'AccessSelect' || this.$route.name === 'AccessPassword' || this.$route.name === 'AccessEmail' || this.$route.name === 'AccessPhone'
		},
		welcome() {
			return this.$route.name === 'WelcomePassword'
		},
		recover() {
			return this.$route.name === 'RecoverAccount'
		},
		direct() {
			return this.$route.name === 'DirectAccess'
		},
	},
}
</script>