var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":"create-unit","title":"Nuevo Equipo","size":_vm.ui_size == 'sm' ? 'lg' : 'xl',"body-class":"p-0","title-class":{
			'fs-11' : _vm.ui_size == 'sm',
			'fs-13' : _vm.ui_size == 'md',
			'fs-15' : _vm.ui_size == 'lg',
		},"header-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"header-text-variant":_vm.ui_theme == 'dark' ? 'light' : '',"body-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"body-text-variant":_vm.ui_theme == 'dark' ? 'light' : '',"footer-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"footer-text-variant":_vm.ui_theme == 'dark' ? 'light' : ''},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ close }){return [_c('b-button',{attrs:{"variant":"outline-secondary","disabled":_vm.loading,"size":_vm.ui_size},on:{"click":function($event){return close()}}},[_vm._v("Cancelar")]),_c('b-button',{attrs:{"variant":"success","disabled":_vm.loading||!_vm.val_full,"size":_vm.ui_size},on:{"click":function($event){return _vm.create()}}},[_vm._v("CREAR")])]}}])},[_c('b-overlay',{staticClass:"p-4",attrs:{"show":_vm.loading,"rounded":"sm","variant":_vm.ui_theme == 'dark' ? 'dark': 'light'},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center py-5"},[_c('b-spinner',{attrs:{"type":"grow","variant":"primary"}})],1)]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[_vm._v(" Seleccione Cliente ")]),_c('b-col',{attrs:{"sm":"auto"}},[_c('b-button',{staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark'},on:{"click":function($event){return _vm.$bvModal.show('create-client')}}},[_c('i',{staticClass:"fas fa-fw fa-plus"})])],1),_c('b-col',{staticClass:"pl-2",attrs:{"sm":"auto"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark',"title":"Dependencias en las que se encuentra el equipo"}},[_c('i',{staticClass:"fas fa-fw fa-question"})])],1)],1)]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.clients.loading,"state":_vm.val_client,"size":_vm.ui_size},on:{"change":function($event){return _vm.reloadClientResources()}},model:{value:(_vm.unit.client_id),callback:function ($$v) {_vm.$set(_vm.unit, "client_id", $$v)},expression:"unit.client_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Presione aquí")]),_vm._l((_vm.clients.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
							'text-muted' : _vm.unit.client_id == null,
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[_vm._v(" Sucursal/Faena/Division ")]),_c('b-col',{attrs:{"sm":"auto"}},[_c('b-button',{staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark'},on:{"click":function($event){return _vm.$bvModal.show('create-site')}}},[_c('i',{staticClass:"fas fa-fw fa-plus"})])],1),_c('b-col',{staticClass:"pl-2",attrs:{"sm":"auto"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark',"title":"Dependencias en las que se encuentra el equipo"}},[_c('i',{staticClass:"fas fa-fw fa-question"})])],1)],1)]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.unit.client_id == null || _vm.clients.loading || _vm.client_sites.loading,"state":_vm.val_site,"size":_vm.ui_size},model:{value:(_vm.unit.site_id),callback:function ($$v) {_vm.$set(_vm.unit, "site_id", $$v)},expression:"unit.site_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[(_vm.unit.client_id != null)?_c('span',[_vm._v("Presione aquí")]):_c('span',[_vm._v("Seleccione primero un cliente")])]),_vm._l((_vm.client_sites.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.address))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_contract_area || _vm.clients.loading || _vm.client_contract_areas.loading ? '' : 'Opcional'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[_vm._v(" Área ")]),_c('b-col',{attrs:{"sm":"auto"}},[_c('b-button',{staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark'},on:{"click":function($event){return _vm.$bvModal.show('create-hvac-contract-area')}}},[_c('i',{staticClass:"fas fa-fw fa-plus"})])],1),_c('b-col',{staticClass:"pl-2",attrs:{"sm":"auto"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark',"title":"Nombres de las áreas que componen una sucursal o sitio"}},[_c('i',{staticClass:"fas fa-fw fa-question"})])],1)],1)]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.unit.client_id == null || _vm.clients.loading || _vm.client_contract_areas.loading,"state":_vm.val_contract_area,"size":_vm.ui_size},model:{value:(_vm.unit.contract_area_id),callback:function ($$v) {_vm.$set(_vm.unit, "contract_area_id", $$v)},expression:"unit.contract_area_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[(_vm.unit.client_id != null)?_c('span',[_vm._v("Presione aquí")]):_c('span',[_vm._v("Seleccione primero un cliente")])]),_vm._l((_vm.client_contract_areas.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_area ? '' : 'Opcional'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[_vm._v(" Área a Climatizar ")]),_c('b-col',{attrs:{"sm":"auto"}},[_c('b-button',{staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark'},on:{"click":function($event){return _vm.$bvModal.show('create-hvac-area')}}},[_c('i',{staticClass:"fas fa-fw fa-plus"})])],1),_c('b-col',{staticClass:"pl-2",attrs:{"sm":"auto"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark',"title":"Nombre del área que climatiza este equipo"}},[_c('i',{staticClass:"fas fa-fw fa-question"})])],1)],1)]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.unit.client_id == null || _vm.clients.loading || _vm.client_areas.loading,"state":_vm.val_area,"size":_vm.ui_size},model:{value:(_vm.unit.area_id),callback:function ($$v) {_vm.$set(_vm.unit, "area_id", $$v)},expression:"unit.area_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[(_vm.unit.client_id != null)?_c('span',[_vm._v("Presione aquí")]):_c('span',[_vm._v("Seleccione primero un cliente")])]),_vm._l((_vm.client_areas.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_time_switch ? '' : 'Opcional'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[_vm._v(" Reloj Horario ")]),_c('b-col',{attrs:{"sm":"auto"}},[_c('b-button',{staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark'},on:{"click":function($event){return _vm.$bvModal.show('create-hvac-time-switch')}}},[_c('i',{staticClass:"fas fa-fw fa-plus"})])],1),_c('b-col',{staticClass:"pl-2",attrs:{"sm":"auto"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark',"title":"Temporizadores automatizados de operación"}},[_c('i',{staticClass:"fas fa-fw fa-question"})])],1)],1)]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.unit.client_id == null || _vm.clients.loading || _vm.client_time_switches.loading || !_vm.client_time_switches.data.length,"state":_vm.val_time_switch,"size":_vm.ui_size},model:{value:(_vm.unit.time_switch_id),callback:function ($$v) {_vm.$set(_vm.unit, "time_switch_id", $$v)},expression:"unit.time_switch_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[(_vm.unit.client_id != null)?_c('span',[_vm._v("Presione aquí")]):_c('span',[_vm._v("Seleccione primero un cliente")])]),_vm._l((_vm.client_time_switches.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_temperature_control ? '' : 'Opcional'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[_vm._v(" Control Temperatura ")]),_c('b-col',{attrs:{"sm":"auto"}},[_c('b-button',{staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark'},on:{"click":function($event){return _vm.$bvModal.show('create-hvac-temperature-control')}}},[_c('i',{staticClass:"fas fa-fw fa-plus"})])],1),_c('b-col',{staticClass:"pl-2",attrs:{"sm":"auto"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark',"title":"Lorem ipsum"}},[_c('i',{staticClass:"fas fa-fw fa-question"})])],1)],1)]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.unit.client_id == null || _vm.clients.loading || _vm.client_temperature_controls.loading,"state":_vm.val_temperature_control,"size":_vm.ui_size},model:{value:(_vm.unit.temperature_control_id),callback:function ($$v) {_vm.$set(_vm.unit, "temperature_control_id", $$v)},expression:"unit.temperature_control_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[(_vm.unit.client_id != null)?_c('span',[_vm._v("Presione aquí")]):_c('span',[_vm._v("Seleccione primero un cliente")])]),_vm._l((_vm.client_temperature_controls.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ubicación de Unidad Evaporadora (Interior) ")]},proxy:true}])},[_c('b-input',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"state":_vm.val_location_interior,"size":_vm.ui_size},model:{value:(_vm.unit.location_interior),callback:function ($$v) {_vm.$set(_vm.unit, "location_interior", $$v)},expression:"unit.location_interior"}}),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_location_exterior ? '' : 'Opcional'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ubicación de Unidad Condensadora (Exterior) ")]},proxy:true}])},[_c('b-input',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"state":_vm.val_location_exterior,"size":_vm.ui_size},model:{value:(_vm.unit.location_exterior),callback:function ($$v) {_vm.$set(_vm.unit, "location_exterior", $$v)},expression:"unit.location_exterior"}}),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_identifier ? '' : 'Opcional'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Identificador ")]},proxy:true}])},[_c('b-input',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"state":_vm.val_identifier,"size":_vm.ui_size},model:{value:(_vm.unit.identifier),callback:function ($$v) {_vm.$set(_vm.unit, "identifier", $$v)},expression:"unit.identifier"}}),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_floor ? '' : 'Opcional'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nivel o Piso ")]},proxy:true}])},[_c('b-input',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"state":_vm.val_floor,"size":_vm.ui_size},model:{value:(_vm.unit.floor),callback:function ($$v) {_vm.$set(_vm.unit, "floor", $$v)},expression:"unit.floor"}}),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_install_date ? '' : 'Opcional'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Fecha de Instalación ")]},proxy:true}])},[_c('b-form-datepicker',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"date-format-options":{ year: 'numeric', month: 'long', day: 'numeric' },"placeholder":"Presione aquí","state":_vm.val_install_date,"size":_vm.ui_size},model:{value:(_vm.unit.install_date),callback:function ($$v) {_vm.$set(_vm.unit, "install_date", $$v)},expression:"unit.install_date"}}),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[_vm._v(" Marca ")]),_c('b-col',{attrs:{"sm":"auto"}},[_c('b-button',{staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark'},on:{"click":function($event){return _vm.$bvModal.show('create-brand')}}},[_c('i',{staticClass:"fas fa-fw fa-plus"})])],1),_c('b-col',{staticClass:"pl-2",attrs:{"sm":"auto"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark',"title":"Lorem ipsum"}},[_c('i',{staticClass:"fas fa-fw fa-question"})])],1)],1)]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.brands.loading,"state":_vm.val_brand,"size":_vm.ui_size},on:{"change":function($event){return _vm.reloadBrandModels()}},model:{value:(_vm.unit.brand_id),callback:function ($$v) {_vm.$set(_vm.unit, "brand_id", $$v)},expression:"unit.brand_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Presione aquí")]),_vm._l((_vm.brands.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[_vm._v(" Modelo ")]),_c('b-col',{attrs:{"sm":"auto"}},[_c('b-button',{staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark'},on:{"click":function($event){return _vm.$bvModal.show('create-model')}}},[_c('i',{staticClass:"fas fa-fw fa-plus"})])],1),_c('b-col',{staticClass:"pl-2",attrs:{"sm":"auto"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark',"title":"Lorem ipsum"}},[_c('i',{staticClass:"fas fa-fw fa-question"})])],1)],1)]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.brand_models.loading || _vm.unit.brand_id == null,"state":_vm.val_model,"size":_vm.ui_size},model:{value:(_vm.unit.model_id),callback:function ($$v) {_vm.$set(_vm.unit, "model_id", $$v)},expression:"unit.model_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[(_vm.unit.brand_id != null)?_c('span',[_vm._v("Presione aquí")]):_c('span',[_vm._v("Seleccione primero una marca")])]),_vm._l((_vm.brand_models.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.type.name)+" // "+_vm._s(item.part_number))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_serial_number ? '' : 'Opcional'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Número de Serie ")]},proxy:true}])},[_c('b-input',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"state":_vm.val_serial_number,"size":_vm.ui_size},model:{value:(_vm.unit.serial_number),callback:function ($$v) {_vm.$set(_vm.unit, "serial_number", $$v)},expression:"unit.serial_number"}}),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_capacity ? '' : 'Opcional'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Capacidad ")]},proxy:true}])},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-dropdown',{attrs:{"variant":_vm.val_capacity ? 'success' : 'outline-secondary',"size":_vm.ui_size},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.currentUnitCapacityMeasurement)+" ")]},proxy:true}])},_vm._l((_vm.helpers_unit_capacity_measurement_units.data),function(item,i){return _c('b-dropdown-item',{key:i,on:{"click":function($event){_vm.unit.capacity_measurement_unit_id = item.id}}},[_vm._v(_vm._s(item.name))])}),1)]},proxy:true}])},[_c('b-input',{class:{
									'bg-primary text-white' : _vm.ui_theme == 'dark',
								},attrs:{"state":_vm.val_capacity,"formatter":_vm.onlyNumbers,"size":_vm.ui_size},model:{value:(_vm.unit.capacity),callback:function ($$v) {_vm.$set(_vm.unit, "capacity", $$v)},expression:"unit.capacity"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.val_capacity}},[_vm._v("Requerido")]),_c('b-form-valid-feedback',{attrs:{"state":_vm.val_capacity}},[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_voltage ? '' : 'Opcional'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Voltaje ")]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.helpers_unit_voltages.loading,"state":_vm.val_voltage,"size":_vm.ui_size},model:{value:(_vm.unit.voltage_id),callback:function ($$v) {_vm.$set(_vm.unit, "voltage_id", $$v)},expression:"unit.voltage_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Presione aquí")]),_vm._l((_vm.helpers_unit_voltages.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name)+"V")])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_coolant ? '' : 'Opcional'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[_vm._v(" Refrigerante ")]),_c('b-col',{attrs:{"sm":"auto"}},[_c('b-button',{staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark'},on:{"click":function($event){return _vm.$bvModal.show('create-coolant')}}},[_c('i',{staticClass:"fas fa-fw fa-plus"})])],1),_c('b-col',{staticClass:"pl-2",attrs:{"sm":"auto"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-dark',"title":"Lorem ipsum"}},[_c('i',{staticClass:"fas fa-fw fa-question"})])],1)],1)]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.helpers_unit_coolants.loading,"state":_vm.val_coolant,"size":_vm.ui_size},model:{value:(_vm.unit.coolant_id),callback:function ($$v) {_vm.$set(_vm.unit, "coolant_id", $$v)},expression:"unit.coolant_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Presione aquí")]),_vm._l((_vm.helpers_unit_coolants.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_maintenance_window ? '' : 'Opcional'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Mantenimiento ")]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.helpers_unit_maintenance_windows.loading,"state":_vm.val_maintenance_window,"size":_vm.ui_size},model:{value:(_vm.unit.maintenance_window_id),callback:function ($$v) {_vm.$set(_vm.unit, "maintenance_window_id", $$v)},expression:"unit.maintenance_window_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Presione aquí")]),_vm._l((_vm.helpers_unit_maintenance_windows.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_priority ? '' : 'Opcional'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Criticidad ")]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.helpers_unit_priorities.loading,"size":_vm.ui_size,"state":_vm.val_priority},model:{value:(_vm.unit.priority_id),callback:function ($$v) {_vm.$set(_vm.unit, "priority_id", $$v)},expression:"unit.priority_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Presione aquí")]),_vm._l((_vm.helpers_unit_priorities.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_condition ? '' : 'Opcional'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Condición ")]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.helpers_unit_conditions.loading,"size":_vm.ui_size,"state":_vm.val_condition},model:{value:(_vm.unit.condition_id),callback:function ($$v) {_vm.$set(_vm.unit, "condition_id", $$v)},expression:"unit.condition_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Presione aquí")]),_vm._l((_vm.helpers_unit_conditions.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1)],1),_c('b-alert',{staticClass:"mb-0",class:{
					'fs-09' : _vm.ui_size == 'sm',
					'fs-10' : _vm.ui_size == 'md',
					'fs-12' : _vm.ui_size == 'lg',
				},attrs:{"show":"","variant":_vm.val_full ? 'success' : 'danger'}},[_c('p',{staticClass:"mb-0 text-size-10"},[_c('i',{staticClass:"fas fa-exclamation-circle mr-2"}),_c('span',[_vm._v(_vm._s(_vm.val_full ? 'Para continuar, presione el botón CREAR.' : 'Complete los campos requeridos para continuar.'))])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }