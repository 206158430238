<template>
	<div>

		<!-- PUBLIC NAVBAR -->
		<div
			v-if="!session && this.$route.meta.multi === true"
			id="navbar"
			class="px-3 py-2 text-white"
			style="background-color: #2c3e50; position:relative;"
		>
			<b-row no-gutters>
				<b-col cols="6">
					<b-row no-gutters>
						<b-col sm="auto" style="position:relative">
							<img
								id="navbar-logo"
								src="/img/electrofrio/logo_single_color_dark.svg"
								v-bind:class="{
									'navbar-logo-sm' : ui_size == 'sm',
									'navbar-logo-md' : ui_size == 'md',
									'navbar-logo-lg' : ui_size == 'lg',
								}"
							>
						</b-col>
						<b-col
							class="d-none d-lg-block"
						>
							<p class="mb-0 mt-1 fs-12">Enlace Seguro</p>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="6" class="text-right">
					<b-button variant="primary" @click="goTo('Login')">
						<i class="fas fa-lock mr-2"></i>
						<span>Iniciar Sesión</span>
					</b-button>
				</b-col>
			</b-row>
		</div>

		<!-- PRIVATE NAVBAR -->
		<div
			v-else-if="session"
			id="navbar"
			class="px-3 py-2 text-white"
			style="background-color: #2c3e50; position:relative;"
		>
			<b-row no-gutters>
				<b-col cols="10">
					<b-row no-gutters>
						<b-col sm="auto" style="position:relative">
							<img
								id="navbar-logo"
								src="/img/electrofrio/logo_single_color_dark.svg"
								@click="goToHome()"
								v-bind:class="{
									'navbar-logo-sm' : ui_size == 'sm',
									'navbar-logo-md' : ui_size == 'md',
									'navbar-logo-lg' : ui_size == 'lg',
								}"
							>
						</b-col>
						<b-col v-if="core.loading">
							<p class="fs-08 mt-2 mb-0">Cargando</p>
						</b-col>
						<b-col class="d-none d-lg-block" v-else>

							<!-- ACCESOS DIRECTOS -->
							<b-button
								variant="primary"
								@click="goToHome()"
								:size="ui_size"
								no-caret
								class="mr-1"
							>Inicio</b-button>

							<!-- CORPORATIVO -->
							<b-dropdown
								text="Corporativo"
								variant="primary"
								:size="ui_size"
								no-caret
								class="mr-1"
								v-if="profile.name == 'management' || profile.name == 'technician'"
							>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('Templates')"
								>Formatos corporativos</b-dropdown-item>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('Organization')"
								>Organigrama Electrofrio</b-dropdown-item>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('Email')"
								>E-mail corporativo</b-dropdown-item>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('OneDrive')"
								>Carpetas compartidas</b-dropdown-item>
								<b-dropdown-item
									href="https://www.electrofrio.cl/" target="_new"
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
								>Página web corporativa</b-dropdown-item>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('RemoteSupport')"
								>Soporte técnico</b-dropdown-item>
							</b-dropdown>

							<!-- DEFONTANA -->
							<b-dropdown
								text="Comercial"
								variant="primary"
								:size="ui_size"
								no-caret
								class="mr-1"
								v-if="profile.name == 'management'"
							>
								<b-dropdown-item
									href="https://jump.defontana.com/" target="_new"
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
								>Abrir Defontana</b-dropdown-item>
								<b-dropdown-divider></b-dropdown-divider>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('CostCenters')"
								>Centros de Negocios</b-dropdown-item>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('DefontanaProducts')"
								>Artículos</b-dropdown-item>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('DefontanaServices')"
								>Servicios</b-dropdown-item>
								<b-dropdown-divider></b-dropdown-divider>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('DefontanaPricings')"
								>Cotizaciones</b-dropdown-item>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('DefontanaOrders')"
								>Pedidos</b-dropdown-item>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('DefontanaSales')"
								>Ventas</b-dropdown-item>
								<b-dropdown-divider></b-dropdown-divider>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('PurchaseRequests')"
								>Requisiciones</b-dropdown-item>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('DefontanaPurchaseOrders')"
								>Compras</b-dropdown-item>
								<b-dropdown-divider></b-dropdown-divider>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('ShippingCalculator')"
								>Calculadora de Flete</b-dropdown-item>
							</b-dropdown>

							<!-- EQUIPOS CLIENTES -->
							<b-dropdown
								text="Operaciones"
								variant="primary"
								:size="ui_size"
								no-caret
								class="mr-1"
								v-if="profile.name == 'management' || profile.name == 'technician' || profile.name == 'client'"
							>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('HVAC_Maintenance')"
								>Estado de Mantenimientos</b-dropdown-item>
								<b-dropdown-item
									v-if="profile.name == 'management'"
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('HVAC_Contracts')"
								>Contratos de Mantenimiento</b-dropdown-item>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('HVAC_Units')"
								>Equipos Clientes</b-dropdown-item>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('HVAC_Jobs')"
								>Órdenes de Trabajo</b-dropdown-item>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('StockRequirements')"
								>Stock Contratos</b-dropdown-item>
								<b-dropdown-item
									v-if="profile.name == 'management'"
									href="https://retaillink.wal-mart.com/" target="_new"
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
								>Abrir Retail Link</b-dropdown-item>
							</b-dropdown>

							<!-- RECURSOS HUMANOS -->
							<b-dropdown
								text="RRHH"
								variant="primary"
								:size="ui_size"
								no-caret
								class="mr-1"
								v-if="profile.name == 'management' || profile.name == 'technician' || profile.name == 'client'"
							>
								<b-dropdown-item
									href="https://jump.defontana.com/" target="_new"
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
								>Abrir Zenda</b-dropdown-item>	
								<b-dropdown-divider></b-dropdown-divider>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('DefontanaEmployees')"
								>Trabajadores</b-dropdown-item>
							</b-dropdown>

							<!-- SYSTEM -->
							<b-dropdown
								text="Sistemas"
								variant="primary"
								v-if="profile.name == 'management'"
								:size="ui_size"
								no-caret
							>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('Users')"
									v-if="getters.prv_system_users"
								>Usuarios</b-dropdown-item>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('TeamViewerDevices')"
									v-if="getters.prv_system_users"
								>Dispositivos</b-dropdown-item>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('SystemParameters')"
									v-if="getters.prv_system_parameters"
								>Parámetros</b-dropdown-item>
								<b-dropdown-item
									v-bind:class="{ 'fs-sm' : ui_size == 'sm', 'fs-md' : ui_size == 'md', 'fs-lg' : ui_size == 'lg' }"
									@click="goTo('RequestLogs')"
									v-if="getters.prv_system_parameters"
								>Actividad</b-dropdown-item>
							</b-dropdown>

						</b-col>
					</b-row>
				</b-col>
				<b-col class="text-right">
					<div class="d-none d-lg-block">
						<b-row no-gutters>

							<!-- SEARCH -->
							<!--
							<b-col>
								<b-row
									v-if="false"
									style="position: relative"
									class="mr-2"
									no-gutters
								>
									<b-col>
										<b-input
											ref="input"
											v-model="quick_search.input"
											@blur="blur_search()"
											placeholder="Buscar"
											class="navbar-search d-inline-block"
											:size="ui_size"
											v-bind:class="{ 'focus' : quick_search.enabled }"
										></b-input>
									</b-col>
									<b-col sm="auto" id="navbar-search">
										<b-button
											:variant="quick_search.input.length > 0 ? 'success' : 'primary'"
											:size="ui_size"
											class="ml-2"
											@click="focus_search()"
										>
											<i class="fas fa-search" v-bind:class="{ 'text-muted' : quick_search.input.length == 0 }"></i>
										</b-button>
									</b-col>
								</b-row>
							</b-col>
							-->
							<b-col></b-col>
							
							<!-- PREFERENCES -->
							<b-col sm="auto">
								<b-dropdown
									id="navbar-account"
									variant="primary"
									right
									no-caret
									:size="ui_size"
								>
									<template #button-content>
										<i class="fas fa-circle-user"></i>
									</template>

									<b-dropdown-item
										@click="openChangePassword()"
										v-bind:class="{
											'fs-sm' : ui_size == 'sm',
											'fs-md' : ui_size == 'md',
											'fs-lg' : ui_size == 'lg'
										}"
									>Cambiar Contraseña</b-dropdown-item>

									<b-dropdown-item
										@click="openAbout()"
										v-bind:class="{
											'fs-sm' : ui_size == 'sm',
											'fs-md' : ui_size == 'md',
											'fs-lg' : ui_size == 'lg'
										}"
									>Acerca de</b-dropdown-item>

									<b-dropdown-item
										@click="goTo('Logout')"
										v-bind:class="{
											'fs-sm' : ui_size == 'sm',
											'fs-md' : ui_size == 'md',
											'fs-lg' : ui_size == 'lg'
										}"
									>Cerrar Sesión</b-dropdown-item>

								</b-dropdown>
							</b-col>

						</b-row>
					</div>

					<div
						class="d-block d-lg-none"
						style="position:relative"
					>
						<b-button
							id="navbar-menu-mobile"
							variant="primary"
							:size="ui_size"
							@click="toggleResponsiveMenu()"
						>
							<i class="mr-2" v-bind:class="{ 'fas fa-bars': !responsive_menu.active, 'fas fa-arrow-up-to-line' : responsive_menu.active}"></i>
							<span v-bind:class="{ 'text-muted': responsive_menu.active }">Menú</span>
						</b-button>
					</div>
				</b-col>
			</b-row>

			<b-collapse :visible="responsive_menu.active" class="mt-3 mb-3 d-lg-none">
				<b-row
					style="position: relative"
					class="mr-2"
					no-gutters
					v-if="false"
				>
					<b-col>
						<b-input-group>
							<b-input
								id="navbar-search-mobile"
								ref="input_mobile"
								v-model="quick_search.input"
								@blur="blur_search_mobile()"
								placeholder="Buscar"
								class="d-inline-block"
								:size="ui_size"
								v-bind:class="{ 'focus' : quick_search.enabled, 'bg-primary': quick_search.input.length == 0 }"
							></b-input>
							<b-input-group-append>
								<b-button
									:variant="quick_search.input.length == 0 ? 'outline-light' : 'success'"
									:size="ui_size"
									@click="focus_search_mobile()"
								>
									<i class="fas fa-search" v-bind:class="{ 'text-muted' : quick_search.input.length == 0 }"></i>
								</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-col>
				</b-row>

				<b-list-group
					v-bind:class="{
						'fs-sm' : ui_size == 'sm',
						'fs-md' : ui_size == 'md',
						'fs-lg' : ui_size == 'lg'
					}"
					style="position: relative;"
				>

				
					<!-- MOBILE: CORPORATE -->
					<b-list-group-item
						class="menu-item text-right"
						@click="toggleResponsiveSubMenu('corporate')"
						v-bind:class="{
							'active' : responsive_menu.submenu == 'corporate',
							'sm' : ui_size == 'sm',
							'md' : ui_size == 'md',
							'lg' : ui_size == 'lg',
						}"
					>
						<span :class="{ 'fw-bold' : responsive_menu.submenu == 'corporate' }">Corporativo</span>
					</b-list-group-item>
					<b-collapse :visible="responsive_menu.submenu == 'corporate'" class="text-right">
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('Templates')"
						>Formatos corporativos</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('Organization')"
						>Organigrama Electrofrio</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('Email')"
						>E-mail corporativo</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('OneDrive')"
						>Carpetas compartidas</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							href="https://www.electrofrio.cl/" target="_new"
						>Página web corporativa</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('RemoteSupport')"
						>Soporte técnico</b-list-group-item>
					</b-collapse>

				
					<!-- MOBILE: COMMERCIAL -->
					<b-list-group-item
						class="menu-item text-right"
						@click="toggleResponsiveSubMenu('commercial')"
						v-bind:class="{
							'active' : responsive_menu.submenu == 'commercial',
							'sm' : ui_size == 'sm',
							'md' : ui_size == 'md',
							'lg' : ui_size == 'lg',
						}"
					>
						<span :class="{ 'fw-bold' : responsive_menu.submenu == 'commercial' }">Comercial</span>
					</b-list-group-item>
					<b-collapse :visible="responsive_menu.submenu == 'commercial'" class="text-right">
						<b-list-group-item
							class="submenu-item"
							href="https://jump.defontana.com/" target="_new"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
						>Abrir Defontana</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('CostCenters')"
						>Centros de Negocios</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('DefontanaProducts')"
						>Artículos</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('DefontanaServices')"
						>Servicios</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('DefontanaPricings')"
						>Cotizaciones</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('DefontanaOrders')"
						>Pedidos</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('DefontanaSales')"
						>Ventas</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('PurchaseRequests')"
						>Requisiciones</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('DefontanaPurchaseOrders')"
						>Compras</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('ShippingCalculator')"
						>Calculadora de Flete</b-list-group-item>
					</b-collapse>

				
					<!-- MOBILE: OPERATIONS -->
					<b-list-group-item
						class="menu-item text-right"
						@click="toggleResponsiveSubMenu('operations')"
						v-bind:class="{
							'active' : responsive_menu.submenu == 'operations',
							'sm' : ui_size == 'sm',
							'md' : ui_size == 'md',
							'lg' : ui_size == 'lg',
						}"
					>
						<span :class="{ 'fw-bold' : responsive_menu.submenu == 'operations' }">Operaciones</span>
					</b-list-group-item>
					<b-collapse :visible="responsive_menu.submenu == 'operations'" class="text-right">
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('HVAC_Maintenance')"
						>Estado de Mantenimientos</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('HVAC_Contracts')"
						>Contratos de Mantenimiento</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('HVAC_Units')"
						>Equipos Clientes</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('HVAC_Jobs')"
						>Órdenes de Trabajo</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('StockRequirements')"
						>Stock Contratos</b-list-group-item>
						<b-list-group-item
							href="https://retaillink.wal-mart.com/" target="_new"
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
						>Abrir Retail Link</b-list-group-item>
					</b-collapse>

				
					<!-- MOBILE: HUMAN RESOURCES -->
					<b-list-group-item
						class="menu-item text-right"
						@click="toggleResponsiveSubMenu('human_resources')"
						v-bind:class="{
							'active' : responsive_menu.submenu == 'human_resources',
							'sm' : ui_size == 'sm',
							'md' : ui_size == 'md',
							'lg' : ui_size == 'lg',
						}"
					>
						<span :class="{ 'fw-bold' : responsive_menu.submenu == 'human_resources' }">Recursos Humanos</span>
					</b-list-group-item>
					<b-collapse :visible="responsive_menu.submenu == 'human_resources'" class="text-right">
						<b-list-group-item
							class="submenu-item"
							href="https://jump.defontana.com/" target="_new"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
						>Abrir Zenda</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('DefontanaEmployees')"
						>Trabajadores</b-list-group-item>
					</b-collapse>


					<!-- MOBILE: SYSTEMS -->
					<b-list-group-item
						class="menu-item text-right"
						@click="toggleResponsiveSubMenu('system')"
						v-bind:class="{
							'active' : responsive_menu.submenu == 'system',
							'sm' : ui_size == 'sm',
							'md' : ui_size == 'md',
							'lg' : ui_size == 'lg',
						}"
					>
						<span :class="{ 'fw-bold' : responsive_menu.submenu == 'system' }">Sistemas</span>
					</b-list-group-item>
					<b-collapse :visible="responsive_menu.submenu == 'system'" class="text-right">
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('Users')"
						>Usuarios</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('TeamViewerDevices')"
						>Dispositivos</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('SystemParameters')"
						>Parámetros</b-list-group-item>
						<b-list-group-item
							class="submenu-item"
							v-bind:class="{ 'sm' : ui_size == 'sm', 'md' : ui_size == 'md', 'lg' : ui_size == 'lg' }"
							@click="goTo('RequestLogs')"
						>Actividad</b-list-group-item>
					</b-collapse>
					

					<!-- MOBILE: PREFERENCES -->
					<b-list-group-item
						class="menu-item text-right"
						@click="toggleResponsiveSubMenu('preferences')"
						v-bind:class="{
							'active' : responsive_menu.submenu == 'preferences',
							'sm' : ui_size == 'sm',
							'md' : ui_size == 'md',
							'lg' : ui_size == 'lg',
						}"
					>
						<p class="mb-0" v-if="responsive_menu.submenu == 'preferences'">
							<i class="fas fa-arrow-up-to-line mr-2"></i>
							<strong>{{ core.account.name }}</strong>
						</p>
						<p class="mb-0" v-else>
							<span id="navbar-account-mobile">{{ core.account.name }}</span>
						</p>
					</b-list-group-item>

					<b-collapse :visible="responsive_menu.submenu == 'preferences'" class="text-right">

						<b-list-group-item
							class="submenu-item" 
							v-bind:class="{
								'sm' : ui_size == 'sm',
								'md' : ui_size == 'md',
								'lg' : ui_size == 'lg',
							}"
							@click="openChangePassword()"
						>
							<span>Cambiar Contraseña</span>
						</b-list-group-item>

						<b-list-group-item
							class="submenu-item" 
							v-bind:class="{
								'sm' : ui_size == 'sm',
								'md' : ui_size == 'md',
								'lg' : ui_size == 'lg',
							}"
							@click="openAbout()"
						>
							<span>Acerca de</span>
						</b-list-group-item>

						<b-list-group-item
							class="submenu-item" 
							v-bind:class="{
								'sm' : ui_size == 'sm',
								'md' : ui_size == 'md',
								'lg' : ui_size == 'lg',
							}"
						@click="goTo('Logout')">Cerrar Sesión</b-list-group-item>

					</b-collapse>

				</b-list-group>
			</b-collapse>

		</div>
		<!-- /NAVBAR -->

		<ChangePassword></ChangePassword>
		<AboutModal></AboutModal>

	</div>
</template>

<script>
import AboutModal from '@/components/AboutModal.vue'
import ChangePassword from '@/components/ChangePassword.vue'
export default {
	name: 'SiteNavbar',
	components: {
		ChangePassword,
		AboutModal,
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		getters() { return this.$store.getters },
		profile() { return this.$store.state.core.account.profile },
		//////-----------------------------------------------------------
	},
	data: function() {
		return {
			quick_search: {
				input: '',
				enabled: false,
			},
			responsive_menu: {
				active: false,
				submenu: null,
			},
		}
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) {
				this.responsive_menu.active = false
				this.$router.push({ name: route_name })
			}
		},
		goToHome() {
			if (this.profile.name == 'management') {
				this.goTo('Home')
			}
			else if (this.profile.name == 'technician') {
				this.goTo('HomeTechnichian')
			}
			else if (this.profile.name == 'client') {
				this.goTo('HomeClient')
			}
		},
		saveUISize(size) {
			this.$store.commit('save_ui_size', size)
		},
		saveUITheme(theme) {
			this.$store.commit('save_ui_theme', theme)
		},
		increaseUISize() {
			if (this.ui_size == 'sm') { this.saveUISize('md') }
			else if (this.ui_size == 'md') { this.saveUISize('lg') }
			else { return false }
		},
		parseCurrency(amount) {
			if (amount == 'undefined' || amount == null || amount == '' || amount == false) return 0
			var number = amount.toString().split('.')
			return  number[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ',' + number[1]
		},
		decreaseUISize() {
			if (this.ui_size == 'lg') { this.saveUISize('md') }
			else if (this.ui_size == 'md') { this.saveUISize('sm') }
			else { return false }
		},
		focus_search() {
			this.quick_search.enabled = true
			this.$refs.input.focus()
		},
		blur_search() {
			if (this.quick_search.input.length == 0) this.quick_search.enabled = false
		},
		focus_search_mobile() {
			this.quick_search.enabled = true
			this.$refs.input_mobile.focus()
		},
		blur_search_mobile() {
			if (this.quick_search.input.length == 0) this.quick_search.enabled = false
		},
		toggleResponsiveMenu() {
			this.responsive_menu.active = !this.responsive_menu.active
		},
		toggleResponsiveSubMenu(menu) {
			if (this.responsive_menu.submenu == null || this.responsive_menu.submenu != menu) {
				this.responsive_menu.submenu = menu
			} else {
				this.responsive_menu.submenu = null
			}
		},
		openChangePassword() {
			this.responsive_menu.active = false
			this.$bvModal.show('change-password')
		},
		openAbout() {
			this.responsive_menu.active = false
			this.$bvModal.show('about')
		},
	}
}
</script>