<template>
	<div class="mt-3">

		<SiteModuleHeader
			:showBack="true"
			:showHome="true"
			:showSize="true"
			:showTheme="true"
			:showFluid="true"
			:showTitle="true"
			@reload="reload"
			:reloading="reloading || resources.loading"
		></SiteModuleHeader>

		<!-- TOOLBAR -->
		<b-row no-gutters>

			<!-- PER PAGE -->
			<b-col md="12" lg="auto" xl="auto">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Visualización</span>
					<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto">
						<SitePerPage
							:loading="resources.loading"
							:limit="query.limit"
							:options="paginationOptions"
							@clicked="perPageChanged"
						></SitePerPage>
					</b-col>
				</b-row>
			</b-col>

			<!-- PER PAGE -->
			<b-col md="12" lg="auto" xl="auto">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Página Actual</span>
					<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto">
						<SitePagination
							:loading="resources.loading"
							:page="query.page"
							:total="resources.data.total"
							:limit="query.limit"
							@clicked="paginationChanged"
						></SitePagination>
					</b-col>
				</b-row>
			</b-col>

			<!-- SPACER -->
			<b-col>
			</b-col>

		</b-row>
		<!-- /TOOLBAR -->

		<!-- DATA -->
		<b-skeleton-wrapper :loading="resources.loading && !reloading">

			<!-- LOADING -->
			<template #loading>
				<b-card no-body>
					<b-skeleton-table
						animation="fade"
						:rows="10"
						:columns="7"
					></b-skeleton-table>
				</b-card>
			</template>


			<!-- TABLE :fields="visibleTableFields" -->
			<b-table
				:items="resources.data.data"
				:busy="resources.loading"
				:fields="fields"
				:no-local-sorting="true"
				@sort-changed="sortingChanged"
				show-empty
				:stacked="mobile"
				hover
				bordered
				:small="ui_size == 'sm' || false"
				responsive
				sort-icon-left
				:head-variant="ui_theme == 'light' ? 'light' : 'dark'"
				:table-variant="ui_theme == 'light' ? 'light' : 'dark'"
				class="mt-0 mb-0 rounded"
				:class="{
					'fs-09' : ui_size == 'sm',
					'fs-10' : ui_size == 'md',
					'fs-13' : ui_size == 'lg',
					'table-dark' : ui_theme == 'dark',
				}"
			>
				<template v-slot:empty>
					<div class="p-3">
						<div v-if="val_client">
							<span class="text-muted" >No hay Equipos que coincidan con los filtros aplicados.</span>
						</div>
						<div v-else>
							<span class="fs-15 text-secondary">
								<i class="fas fa-up mr-2"></i>
								<span>Seleccione un Cliente</span>
							</span>
						</div>
					</div>
				</template>
				<template v-slot:table-busy>
					<div class="text-center my-2">
						<b-spinner class="align-middle"></b-spinner>
					</div>
				</template>
				<template #cell()="data">
					<div :class="{ 'mt-1' : ui_size != 'sm'}">
						<span v-if="data.value">{{ data.value }}</span>
						<span v-else class="text-muted">&mdash;</span>
					</div>
				</template>
				<template #cell(id)="data">
					<div class="text-center" :class="{ 'mt-1' : ui_size != 'sm'}">
						<span v-if="data.value">{{ data.value }}</span>
						<span v-else class="text-muted">&mdash;</span>
					</div>
				</template>
				<template #cell(ip_address)="data">
					<div class="text-center" :class="{ 'mt-1' : ui_size != 'sm'}">
						<span v-if="data.value">{{ data.value }}</span>
						<span v-else class="text-muted">&mdash;</span>
					</div>
				</template>
				<template #cell(path)="data">
					<div :class="{ 'mt-1' : ui_size != 'sm'}">
						<code v-if="data.value">{{ data.value }}</code>
						<span v-else class="text-muted">&mdash;</span>
					</div>
				</template>
				<template #cell(body)="data">
					<div :class="{ 'mt-1' : ui_size != 'sm'}">
						<code v-if="data.value">{{ data.value }}</code>
						<span v-else class="text-muted">&mdash;</span>
					</div>
				</template>
				<template #cell(method)="data">
					<div class="text-center" :class="{ 'mt-1' : ui_size != 'sm'}">
						<b-badge variant="light" v-if="data.value == 'GET'">{{ data.value }}</b-badge>
						<span v-else class="text-muted">&mdash;</span>
					</div>
				</template>
				<template #cell(created)="data">
					<div class="nowrap" :class="{ 'mt-1' : ui_size != 'sm'}">
						<span v-if="data.value">{{ data.value }}</span>
						<span v-else class="text-muted">&mdash;</span>
					</div>
				</template>
			</b-table>
		</b-skeleton-wrapper>
		
		<!-- PAGINATION -->
		<SitePagination
			:loading="resources.loading"
			:page="query.page"
			:total="resources.data.total"
			:limit="query.limit"
			@clicked="paginationChanged"
			class="mt-2"
		></SitePagination>

	</div>
</template>

<script>
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
import SitePerPage from '@/components/SitePerPage.vue'
import SitePagination from '@/components/SitePagination.vue'

export default {
	name: 'RequestLogs',
	components: {
		SiteModuleHeader,
		SitePerPage,
		SitePagination,
	},
	data() {
		return {
			reloading: false,
			query: {
				limit: 10,
				page: 1,
				sortDesc: true,
				sortBy: 'id',
				search: null,
			},
			paginationOptions: [
				{ value: 5, text: '5 por página' },
				{ value: 10, text: '10 por página' },
				{ value: 15, text: '15 por página' },
				{ value: 20, text: '20 por página' },
				{ value: 50, text: '50 por página' },
				{ value: 100, text: '100 por página' },
				{ value: 9999, text: 'Mostrar todo' },
			],
			fields: [
				{ key: 'id', label: '#', sortable: false, mobile: true },
				{ key: 'created', label: 'Fecha / Hora', sortable: false, mobile: true },
				{ key: 'user.email', label: 'Usuario', sortable: true, mobile: true },
				{ key: 'method', label: 'Método', sortable: true, mobile: true },
				{ key: 'path', label: 'Punto Final', sortable: true, mobile: true },
				{ key: 'body', label: 'Cuerpo', sortable: true, mobile: true },
				{ key: 'ip_address', label: 'Dirección IP', sortable: true, mobile: true },
				{ key: 'user_agent', label: 'Navegador', sortable: true, mobile: true },
			],
		}
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		resources() { return this.$store.state.request_logs },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
	},
	created() {
		this.load()
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
		load() {
			this.$store.dispatch('request_logs', this.query).then(() => { this.reloading = false })
		},
		reload() {
			this.reloading = true
			this.load()
		},
		perPageChanged(limit) {
			this.query.limit = limit
			this.reload()
		},
		paginationChanged(page) {
			this.query.page = page
			this.reload()
		},
		sortingChanged(ctx) {
			this.query.sortDesc = ctx.sortDesc
			this.query.sortBy = ctx.sortBy
			this.reload()
		},
		search() {
			if (this.query.search == null) {
				this.query.search = ''
			}
			else if (this.query.search == '') {
				this.query.search = null
			}
			else if (this.query.search.length > 0) {
				this.reload()
			}
		},
		searchBlur() {
			if (this.query.search == '') {
				this.query.search = null
			}
		},
		searchClear() {
			if (this.query.search != null && this.query.search.length == 0) {
				this.query.page = 1
				this.reload()
			}
		},
		searchChanged(search) {
			this.query.search = search
		},
	}
}
</script>

