var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":"create-job","title":"Nueva Orden","size":_vm.ui_size == 'sm' ? 'lg' : 'xl',"body-class":"p-0","title-class":{
			'fs-11' : _vm.ui_size == 'sm',
			'fs-13' : _vm.ui_size == 'md',
			'fs-15' : _vm.ui_size == 'lg',
		},"header-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"header-text-variant":_vm.ui_theme == 'dark' ? 'light' : '',"body-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"body-text-variant":_vm.ui_theme == 'dark' ? 'light' : '',"footer-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"footer-text-variant":_vm.ui_theme == 'dark' ? 'light' : ''},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ close }){return [_c('b-button',{attrs:{"variant":"outline-secondary","disabled":_vm.loading,"size":_vm.ui_size},on:{"click":function($event){return close()}}},[_vm._v("Cancelar")]),_c('b-button',{attrs:{"variant":"success","disabled":_vm.loading||!_vm.val_full,"size":_vm.ui_size},on:{"click":function($event){return _vm.create()}}},[_vm._v("CREAR")])]}}])},[(0)?_c('code',[_vm._v(_vm._s(_vm.job))]):_vm._e(),_c('b-overlay',{staticClass:"p-4",attrs:{"show":_vm.loading,"rounded":"sm","variant":_vm.ui_theme == 'dark' ? 'dark': 'light'},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center py-5"},[_c('b-spinner',{attrs:{"type":"grow","variant":"primary"}})],1)]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tipo de Orden ")]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.helpers_job_types.loading,"state":_vm.val_type,"size":_vm.ui_size},model:{value:(_vm.job.type_id),callback:function ($$v) {_vm.$set(_vm.job, "type_id", $$v)},expression:"job.type_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Presione aquí")]),_vm._l((_vm.helpers_job_types.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('b-row',[_c('b-col',[_vm._v(" Cliente ")]),_c('b-col',{attrs:{"sm":"auto"}},[_c('b-button',{staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-success'},on:{"click":function($event){return _vm.$bvModal.show('create-client')}}},[_c('i',{staticClass:"fas fa-plus mr-1"}),_c('span',[_vm._v("Nuevo Cliente")])])],1)],1)]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.clients.loading,"state":_vm.val_client,"size":_vm.ui_size},on:{"change":function($event){return _vm.reloadClientSites()}},model:{value:(_vm.job.client_id),callback:function ($$v) {_vm.$set(_vm.job, "client_id", $$v)},expression:"job.client_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Presione aquí")]),_vm._l((_vm.clients.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
							'text-muted' : _vm.job.client_id == null,
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('b-row',[_c('b-col',[_vm._v(" Sucursal ")]),_c('b-col',{attrs:{"sm":"auto"}},[_c('b-button',{staticClass:"fs-08",attrs:{"block":"","size":"xs","variant":_vm.ui_theme == 'dark' ? 'outline-success' : 'outline-success'},on:{"click":function($event){return _vm.$bvModal.show('create-site')}}},[_c('i',{staticClass:"fas fa-plus mr-1"}),_c('span',[_vm._v("Nueva Sucursal")])])],1)],1)]},proxy:true}])},[_c('b-select',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"disabled":_vm.job.client_id == null || _vm.clients.loading || _vm.client_sites.loading,"state":_vm.val_site,"size":_vm.ui_size},on:{"change":function($event){return _vm.getSiteUnits()}},model:{value:(_vm.job.site_id),callback:function ($$v) {_vm.$set(_vm.job, "site_id", $$v)},expression:"job.site_id"}},[_c('b-select-option',{attrs:{"value":null,"disabled":""}},[(_vm.job.client_id != null)?_c('span',[_vm._v("Presione aquí")]):_c('span',[_vm._v("Seleccione primero un cliente")])]),_vm._l((_vm.client_sites.data),function(item,i){return _c('b-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.address))])})],2),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1)],1),_c('b-alert',{staticClass:"mb-0",class:{
					'fs-09' : _vm.ui_size == 'sm',
					'fs-10' : _vm.ui_size == 'md',
					'fs-12' : _vm.ui_size == 'lg',
				},attrs:{"show":"","variant":_vm.val_full ? 'success' : 'danger'}},[_c('p',{staticClass:"mb-0 text-size-10"},[(_vm.site_units.loading == true)?_c('span',[_c('b-spinner',{attrs:{"small":"","variant":"light"}})],1):_c('span',[_c('i',{staticClass:"fas fa-exclamation-circle mr-2"})]),(_vm.site_units.loading == true)?_c('span',[_vm._v("Cargando")]):(_vm.val_full)?_c('span',[_vm._v("Para continuar, presione el botón CREAR.")]):_c('span',[(_vm.val_client && _vm.val_site && !_vm.site_units.loading && !_vm.site_units.data.length)?_c('span',[_vm._v("La sucursal seleccionada no registra unidades.")]):(_vm.val_client && _vm.val_site && _vm.site_units.data.length && !_vm.val_units)?_c('span',[_vm._v("Seleccione al menos una unidad para continuar.")]):(_vm.val_client && _vm.val_site && _vm.site_units.data.length && _vm.val_units)?_c('span',[_vm._v("Complete todos los campos requeridos para continuar.")]):_c('span',[_vm._v("Complete los campos requeridos para continuar.")])])])]),(_vm.site_units.data.length)?_c('b-card',{staticClass:"mt-3",attrs:{"no-body":""}},[_c('b-table',{ref:"selectableTable",staticClass:"mb-0",attrs:{"items":_vm.site_units.data,"fields":_vm.fields,"small":"","hover":"","select-mode":"multi","selectable":"","responsive":"","head-variant":_vm.ui_theme == 'light' ? 'light' : 'dark',"table-variant":_vm.ui_theme == 'light' ? 'light' : 'dark',"selected-variant":"success"},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(selected)",fn:function({ rowSelected }){return [_c('p',{staticClass:"mb-0 text-center"},[(rowSelected === true)?_c('span',[_c('i',{staticClass:"fas fa-check-circle text-success"})]):_vm._e()])]}}],null,false,788893552)})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }