<template>
	<b-modal
		id="about"
		hide-header
		hide-footer
		size="md"
		body-class="p-0"
	>
		<b-overlay :show="loading" rounded="sm" class="p-3">

			<b-row class="mb-3">
				<b-col>
					<p class="mb-0 fs-16 fw-bold">
						Intranet
					</p>
					<p class="mb-0 fs-12 text-muted">
						Versión 4.0.0
					</p>
				</b-col>
				<b-col class="text-right">
					<b-button-close @click="close()"></b-button-close>
				</b-col>
			</b-row>
			<p class="mb-3">Ubidata es una marca registrada de Electrofrio Ltda.</p>
			<p class="mb-0">
				<a href="#" class="mr-3">Licencias</a>
				<a href="#" class="mr-3">Términos de Uso</a>
				<a href="#">Política de Privacidad</a>
			</p>

			<b-img src="/img/logo_ubidata_color.svg" height="20" class="mt-4 mb-2"></b-img>

			<!-- LOADING PROGRESS -->
			<template #overlay>
				<div class="text-center">
					<b-spinner type="grow" variant="secondary"></b-spinner>
				</div>
			</template>
			<!-- /LOADING PROGRESS -->
		</b-overlay>
		<template v-slot:modal-footer>
			<b-button @click="close()" variant="outline-secondary">Cerrar</b-button>
		</template>
	</b-modal>
</template>

<script>
export default {
	name: 'AboutModal',
	components: {
	},
	data() {
		return {
			loading: false,
		}
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		getters() { return this.$store.getters },
		//////-----------------------------------------------------------
	},
	methods: {
		close() {
			this.$bvModal.hide('about')
		}
	}
}
</script>
