var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":"cancel-maintenance","title":"Anular Mantenimiento","size":_vm.ui_size == 'sm' ? 'md' : 'md',"body-class":"p-0","title-class":{
			'fs-11' : _vm.ui_size == 'sm',
			'fs-13' : _vm.ui_size == 'md',
			'fs-15' : _vm.ui_size == 'lg',
		},"header-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"header-text-variant":_vm.ui_theme == 'dark' ? 'light' : '',"body-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"body-text-variant":_vm.ui_theme == 'dark' ? 'light' : '',"footer-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"footer-text-variant":_vm.ui_theme == 'dark' ? 'light' : ''},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ close }){return [_c('b-button',{attrs:{"variant":"outline-secondary","disabled":_vm.loading,"size":_vm.ui_size},on:{"click":function($event){return close()}}},[_vm._v("Cancelar")]),_c('b-button',{attrs:{"variant":"dark","disabled":_vm.loading,"size":_vm.ui_size},on:{"click":function($event){return _vm.confirm()}}},[_vm._v("CONFIRMAR")])]}}])},[_c('b-overlay',{staticClass:"p-4",attrs:{"show":_vm.loading,"rounded":"sm","variant":_vm.ui_theme == 'dark' ? 'dark': 'light'},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center py-5"},[_c('b-spinner',{attrs:{"type":"grow","variant":"primary"}})],1)]},proxy:true}])},[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"sm":"12"}},[(_vm.maintenance && _vm.maintenance.unit && _vm.maintenance.unit.number)?_c('b-alert',{staticClass:"mb-0",attrs:{"show":"","variant":"dark"}},[_c('p',{staticClass:"mb-2 fs-12 fw-bold"},[_vm._v("¿Confirma que desea anular el mantenimiento del Equipo N°"+_vm._s(_vm.maintenance.unit.number)+"?")]),_c('p',{staticClass:"mb-0 fs-11"},[_vm._v("El Equipo quedará liberado para ser vinculado a una nueva Orden de Trabajo.")])]):_vm._e()],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-0' : _vm.ui_size == 'md',
							'fs-12 pb-0' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_comment ? '' : 'Opcional (hasta 100 caracteres)'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Observación ")]},proxy:true}])},[_c('b-input',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"maxlength":100,"state":_vm.val_comment,"size":_vm.ui_size},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }