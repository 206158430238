<template>
	<div class="mt-3">

		<SiteModuleHeader></SiteModuleHeader>

		<!-- WARNING MESSAGE -->
		<b-alert
			show
			variant="danger"
			class="mb-3"
			:class="{
				'fs-09' : ui_size == 'sm',
				'fs-10' : ui_size == 'md',
				'fs-13' : ui_size == 'lg',
			}"
		>
			<b-row no-gutters>
				<b-col sm="auto" class="pr-3">
					<i class="fas fa-skull fs-20"></i>
				</b-col>
				<b-col>
					<p class="mb-0 text-size-11">Estos parámetros de sistema permiten personalizar variables globales que afectan su funcionamiento interno.<br>Antes de continuar, por favor valide que la información ingresada sea correcta.</p>
				</b-col>
			</b-row>
		</b-alert>

		<!-- TOOLBAR -->
		<b-row no-gutters>
			
			<!-- RELOAD -->
			<b-col md="12" lg="auto" xl="auto">
				<SitePrimaryButton
					:loading="resources.loading"
					text="Recargar"
					title="Volver a cargar tabla"
					icon="redo"
					@clicked="reload()"
				></SitePrimaryButton>
			</b-col>

		</b-row>
		<!-- /TOOLBAR -->

		<!-- DATA -->
		<b-skeleton-wrapper :loading="resources.loading || reloading">

			<!-- LOADING -->
			<template #loading>
				<b-card no-body>
					<b-skeleton-table
						animation="fade"
						:rows="3"
						:columns="5"
					></b-skeleton-table>
				</b-card>
			</template>

			<b-card
				v-for="(system_parameter, index) in system_parameters.data"
				:key="index"
				:bg-variant="system_parameter.locked ? 'light' : ''"
				class="mb-2"
				:class="{
					'fs-09' : ui_size == 'sm',
					'fs-10' : ui_size == 'md',
					'fs-13' : ui_size == 'lg',
				}"
			>
				<b-overlay :show="loaders.system_parameter.index == index && loaders.system_parameter.active">
					<b-row>
						<b-col sm="4">
							<p class="mb-0 text-uppercase text-size-07 text-dark">Parámetro</p>
							<p class="mb-0 text-success text-strong">{{ system_parameter.name }}</p>
						</b-col>
						<b-col sm="4">
							<p class="mb-0 text-uppercase text-size-07 text-dark">Valor</p>
							<p class="mb-0 text-primary text-strong">
								<code :class="system_parameter.locked ? 'text-primary' : ''" v-if="system_parameter.value != null">{{ system_parameter.value }}</code>
								<span v-else>
									<code>NULL</code><span class="text-muted ml-2 fw-normal fs-08">(vacío)</span>
								</span>
								<span class="pl-2" v-if="!system_parameter.locked">
									<div v-b-tooltip.hover.right title="Editar Valor" class="d-inline-block">
										<b-overlay
											:show="loaders.system_parameter.active && loaders.system_parameter.id == system_parameter.id"
											rounded
											opacity="0.6"
											spinner-small
											spinner-variant="primary"
											class="d-inline-block ml-2"
										>
											<b-dropdown
												size="xs"
												variant="light"
												no-caret
												@show="copyValue(system_parameter)"
											>
												<template #button-content>
													<i class="fas fa-pen text-size-08 text-silenced"></i>
												</template>
												<template v-slot:default="{ hide }">
													<div class="px-2 m-0">
														<b-input
															v-model="loaders.system_parameter.new"
															style="width:300px;"
															v-on:keyup.enter="updateValue(system_parameter); hide()"
														></b-input>
														<b-button
															:variant="loaders.system_parameter.new == system_parameter.value ? 'secondary' : 'success'"
															:disabled="loaders.system_parameter.new == system_parameter.value"
															block
															size="sm"
															class="mt-2"
															@click="updateValue(system_parameter); hide()"
														>Guardar Cambios</b-button>
													</div>
												</template>
											</b-dropdown>
										</b-overlay>
									</div>
								</span>
							</p>
						</b-col>
						<b-col sm="4" v-if="!system_parameter.locked">
							<p class="mb-0 text-uppercase text-size-07 text-dark">Última Modificación</p>
							<p class="mb-0 text-primary">{{ system_parameter.updated }}</p>
						</b-col>
						<b-col sm="12" class="mt-2">
							<p class="mb-0 text-uppercase text-size-07 text-dark">Descripción</p>
							<p class="mb-0 text-primary">{{ system_parameter.description }}</p>
						</b-col>
					</b-row>
				</b-overlay>
			</b-card>

		</b-skeleton-wrapper>
		<!-- /DATA -->

	</div>
</template>

<script>
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
import SitePrimaryButton from '@/components/SitePrimaryButton.vue'
export default {
	name: 'SystemParametersView',
	components: {
		SitePrimaryButton,
		SiteModuleHeader,
	},
	data() {
		return {
			reloading: false,
			loading: false,
			current_values: [],
			system_parameter: [],
			loaders: {
				system_parameter: {
					new: '',
					active: false,
					index: null
				},
            },
			breadcrumb: {
				items: [
					{ text: 'Inicio', to: { name: 'Home' } },
					{ text: 'Sistema', active: true },
					{ text: 'Parámetros', active: true },
				],
			},
		}
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		resources() { return this.$store.state.system_parameters },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		system_parameters() {
			return this.$store.state.system_parameters
		},
	},
	created() {
		this.load()
	},
	mounted() {
	},
	methods: {
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		load() {
			this.$store.dispatch('get_system_parameters').then(() => { this.reloading = false })
		},
		reload() {
			this.reloading = true
			this.load()
		},
		copyValue(resource) {
			this.loaders.system_parameter.new = resource.value
		},
		updateValue(resource) {
			this.loaders.system_parameter.active = true
			this.loaders.system_parameter.id = resource.id
			this.$api.put('system_parameters/'+ resource.id, {
				value: this.loaders.system_parameter.new
			})
			.then(response => {
				for (let i = 0; i < this.system_parameters.data.length; i++) {
					if (this.system_parameters.data[i].id == resource.id) {
						this.system_parameters.data[i].value = response.data.value
						this.system_parameters.data[i].revision = response.data.revision
						this.system_parameters.data[i].updated = response.data.updated
					}
				}
				this.toast(true, 'Se actualizó el valor del parámetro "'+ response.data.name +'".')
				this.loaders.system_parameter = {
					new: '',
					active: false,
					id: 0,
				}
			})
			.catch(error => {
				this.toast(false, error.response.data.message, 10000)
				this.loaders.system_parameter = {
					new: '',
					active: false,
					id: 0,
				}
			})
		},
	}
}
</script>

<style scoped>
</style>
