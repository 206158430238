<template>
	<div class="mt-3">

		<SiteModuleHeader
			:showBack="true"
			:showHome="true"
			:showSize="true"
			:showTheme="true"
			:showFluid="true"
			:showTitle="true"
			@reload="reload"
			:reloading="reloading || resources.loading"
		></SiteModuleHeader>

		<ViewUnit></ViewUnit>
		
		<!-- TOOLBAR -->
		<b-row no-gutters>

			<!-- CLIENT -->
			<b-col md="sm" lg="auto" xl="auto" class="mr-2 mb-3" v-if="profile.name != 'client'">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Cliente</span>
					<i class="fas fa-arrow-turn-down ml-2"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto">
						<b-overlay
							:show="clients.loading"
							rounded="sm"
							:variant="ui_theme == 'light' ? 'light' : 'dark'"
						>
							<template #overlay>
								<div class="text-center">
									<b-spinner type="grow" variant="primary" small></b-spinner>
								</div>
							</template>
							<b-select
								v-model="hvac_client_id"
								:size="ui_size"
								:class="{
									'bg-transparent text-white' : ui_theme == 'dark',
								}"
								@change="reloadClientSites()"
							>
								<b-select-option :value="null">TODOS</b-select-option>
								<b-select-option v-for="(item, i) in clients.data" :key="i" :value="item.id">{{ item.codename }}</b-select-option>
							</b-select>
						</b-overlay>
					</b-col>
				</b-row>
			</b-col>

			<!-- SITE -->
			<b-col md="sm" lg="auto" xl="auto" class="mr-2 mb-2" v-if="hvac_client_id != null || profile.name == 'client'">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Sucursal</span>
					<i class="fas fa-arrow-turn-down ml-2"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto">
						<b-overlay
							:show="client_sites.loading"
							rounded="sm"
							:variant="ui_theme == 'light' ? 'light' : 'dark'"
						>
							<template #overlay>
								<div class="text-center">
									<b-spinner type="grow" variant="primary" small></b-spinner>
								</div>
							</template>
							<b-select
								v-model="hvac_site_id"
								:size="ui_size"
								:class="{
									'bg-transparent text-white' : ui_theme == 'dark',
								}"
								@change="reload()"
							>
								<b-select-option :value="null">TODAS</b-select-option>
								<b-select-option v-for="(item, i) in client_sites.data" :key="i" :value="item.id">{{ item.address }}</b-select-option>
							</b-select>
						</b-overlay>
					</b-col>
				</b-row>
			</b-col>

			<!-- LEGEND -->
			<b-col md="sm" lg="auto" xl="auto" class="mr-2 mb-2">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Leyenda</span>
					<i class="fas fa-arrow-turn-down ml-2"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto" class="mr-2">
						<b-row no-gutters>
							<b-col>
								<i class="fas fa-fw fa-circle-small text-success fs-15"></i>
							</b-col>
							<b-col>
								<p class="mb-0 fs-08 flh-10">
									<span>Mantenimiento</span>
								</p>
								<p class="mb-0 flh-12">
									<span>Completado</span>
								</p>
							</b-col>
						</b-row>
					</b-col>
					<b-col sm="auto" class="mr-2">
						<b-row no-gutters>
							<b-col>
								<i class="fas fa-fw fa-circle-small text-warning fs-15"></i>
							</b-col>
							<b-col>
								<p class="mb-0 fs-08 flh-10">
									<span>Mantenimiento</span>
								</p>
								<p class="mb-0 flh-12">
									<span>Programado</span>
								</p>
							</b-col>
						</b-row>
					</b-col>
					<b-col sm="auto">
						<b-row no-gutters>
							<b-col>
								<i class="fas fa-fw fa-circle-small text-secondary fs-15"></i>
							</b-col>
							<b-col>
								<p class="mb-0 fs-08 flh-10">
									<span>Mantenimiento</span>
								</p>
								<p class="mb-0 flh-12 w-space">
									<span>No Programado</span>
								</p>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-col>

			<!-- SPACER -->
			<b-col>
			</b-col>

		</b-row>
		<!-- /TOOLBAR -->

		<div v-if="resources.loading">
			<b-card class="mb-4" no-body>
				<b-card-header header-bg-variant="primary" header-text-variant="white" class="py-2">
					<b-spinner type="grow" variant="light" small></b-spinner>
				</b-card-header>
				<b-card-body>
					<b-row>
						<b-col sm="3">
							<b-skeleton></b-skeleton>
							<b-skeleton></b-skeleton>
							<b-skeleton></b-skeleton>
						</b-col>
						<b-col>
							<b-skeleton></b-skeleton>
							<b-skeleton></b-skeleton>
							<b-skeleton></b-skeleton>
						</b-col>
					</b-row>
				</b-card-body>
			</b-card>
		</div>

		<div v-else>
			<b-card v-for="(contract, index) in resources.data" :key="index" class="mb-4" no-body>
				<b-card-header header-bg-variant="primary" header-text-variant="white" class="py-2">
					<p class="mb-0 fs-09">{{ contract.client.name }}</p>
				</b-card-header>
				<div v-for="(site, site_index) in contract.sites" :key="site_index">
					<div v-if="site && site.units && site.units.length">
						<hr class="m-0">
						<b-row>
							<b-col sm="3">
								<b-card-body>
									<p class="mb-0 fs-09 text-muted text-uppercase" v-if="site && site.commune && site.commune.name">{{ site.commune.name }}</p>
									<div v-if="site.units.length">
										<p class="mb-0 fs-11 fw-bold">{{ site.address }}</p>
										<p class="mb-0 fs-10">{{ site.units.length }} unidades</p>
										<p class="mt-3 mb-1 fs-10">Mantenimiento Completado</p>
										<b-progress :max="100" height="1.25rem">
											<b-progress-bar
												:value="site.stats.progress"
												:label="`${(site.stats.progress).toFixed(0)} %`"
												variant="success"
												v-if="site.stats.progress != 0"
											></b-progress-bar>
											<b-progress-bar
												:value="100"
												label="0%"
												variant="secondary"
												v-else
											></b-progress-bar>
										</b-progress>
									</div>
								</b-card-body>
							</b-col>
							<b-col>
								<b-card-body>
									<div>
										<span
											v-for="(unit, unit_index) in site.units"
											:key="unit_index"
											class="fs-12"
											role="button"
											:title="dotTitle(unit)"
											@click="viewUnit(unit)"
										>
											<span v-if="unit.last_maintenance && unit.last_maintenance.status && unit.last_maintenance.status.id">
												<i v-if="unit.last_maintenance.status.id == 1" class="fas fa-fw fa-circle-small text-warning"></i>
												<i v-else-if="unit.last_maintenance.status.id == 2" class="fas fa-fw fa-circle-small text-success"></i>
												<i v-else-if="unit.last_maintenance.status.id == 3" class="fas fa-fw fa-circle-small text-secondary"></i>
											</span>
											<span v-else>
												<i class="fas fa-fw fa-circle-small text-secondary"></i>
											</span>
										</span>
									</div>
								</b-card-body>
							</b-col>
						</b-row>
					</div>
					<div v-else-if="contract.sites.length == 1">
						<p class="mb-0 text-muted px-3 py-3"><span class="fs-10">{{ site.address }}<span v-if="site && site.commune && site.commune.name">, {{ site.commune.name }}</span></span><span class="mx-2">&mdash;</span>Esta sucursal no registra equipos.</p>
					</div>
				</div>
			</b-card>
		</div>
		
	</div>
</template>

<script>
import Helpers from '@/helpers';
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
import ViewUnit from '@/components/ViewUnit.vue'

export default {
	name: 'HVAC_Panel',
	components: {
		SiteModuleHeader,
		ViewUnit,
	},
	data() {
		return {
			reloading: false,
			paginationOptions: [
				{ value: 5, text: '5 por página' },
				{ value: 10, text: '10 por página' },
				{ value: 15, text: '15 por página' },
				{ value: 20, text: '20 por página' },
				{ value: 50, text: '50 por página' },
				{ value: 100, text: '100 por página' },
				{ value: 9999, text: 'Mostrar todo' },
			],
			tableFields: [
				{ key: 'old_id', label: 'Ficha', sortable: true, mobile: true, worker: true, guest: true },
				{ key: 'last_maintenance', label: 'Mantención', sortable: true, mobile: true, worker: true, guest: true },
				{ key: 'site.address', label: 'Sucursal/Faena', sortable: true, mobile: true, worker: true, guest: true },
				{ key: 'site.commune.name', label: 'Comuna', sortable: true, mobile: true, worker: true, guest: true },
				{ key: 'location', label: 'Ubicación', sortable: true, mobile: true, worker: true, guest: true },
				{ key: 'brand.name', label: 'Marca', sortable: true, mobile: true, worker: true, guest: true },
				{ key: 'type.name', label: 'Tipo', sortable: true, mobile: true, worker: true, guest: true },
				{ key: 'model.part_number', label: 'Modelo', sortable: true, mobile: true, worker: true, guest: true },
			],
		}
	},
	mounted() {
		this.interval = setInterval(() => {
			if (this.profile.name === 'client') {
				if (this.client && this.client.id && this.client.id > 0) {
					this.hvac_client_id = this.client.id
					this.$store.dispatch('hvac_panel', {
						client_id: this.client.id,
						site_id: this.hvac_site_id,
					}).then(() => { this.reloading = false })
					this.reloadClientSites()
					clearInterval(this.interval)
				}
			} else {
				this.load()
				this.$store.dispatch('helpers_clients')
				clearInterval(this.interval)
			}
		}, 1 * 500)

		this.fluid = this.$route.meta.fluid
	},
	computed: {
		fluid: {
			get() { return this.$store.getters.fluid },
			set(data) { this.$store.commit('fluid', data) }
		},
		client() { return this.$store.state.core.account.client },
		profile() { return this.$store.state.core.account.profile },
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		resources() { return this.$store.state.hvac_panel },
		visibleTableFields() {
			return this.query.type_id == 1 ? this.tableFields.filter(field => field.worker) : this.tableFields.filter(field => field.guest)
		},
		//////
		clients() { return this.$store.state.helpers.clients },
		client_sites() { return this.$store.state.client_sites },
		hvac_client_id: {
			get() { return this.$store.getters.hvac_client_id },
			set(data) { this.$store.commit('hvac_client_id', data) }
		},
		hvac_site_id: {
			get() { return this.$store.getters.hvac_site_id },
			set(data) { this.$store.commit('hvac_site_id', data) }
		},
		interval: {
			get() { return this.$store.getters.interval; },
			set(data) { this.$store.commit('interval', data); }
		},
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		load() {
			var data = {
				client_id: this.hvac_client_id,
				site_id: this.hvac_site_id,
			}
			this.$store.dispatch('hvac_panel', data).then(() => { this.reloading = false })
		},
		reloadClientSites() {
			if (this.hvac_client_id != null) {
				this.$store.dispatch('client_sites', this.hvac_client_id)
				this.hvac_site_id = null
				this.reload()
			} else {
				this.reload()
			}
		},
		reload() {
			this.reloading = true
			this.load()
		},
		perPageChanged(limit) {
			this.query.limit = limit
			this.reload()
		},
		paginationChanged(page) {
			this.query.page = page
			this.reload()
		},
		sortingChanged(ctx) {
			this.query.sortDesc = ctx.sortDesc
			this.query.sortBy = ctx.sortBy
			this.reload()
		},
		parseMoney(data) {
			return Helpers.parseMoney(data)
		},
		search() {
			if (this.query.search == null) {
				this.query.search = ''
				//this.$refs.search.focus()
			}
			else if (this.query.search == '') {
				this.query.search = null
			}
			else if (this.query.search.length > 0) {
				this.reload()
			}
		},
		searchBlur() {
			if (this.query.search == '') {
				this.query.search = null
			}
		},
		searchClear() {
			if (this.query.search != null && this.query.search.length == 0) {
				this.query.page = 1
				this.reload()
			}
		},
		searchChanged(search) {
			this.query.search = search
		},
		disable(data) {
			console.log(data)
		},
		viewUnit(unit) {
			this.$bvModal.show('view-unit')
			this.$store.dispatch('unit', unit.id)
		},
		dotTitle(unit) {
			if (unit.location_interior != null && unit.location_interior.length > 0) {
				return 'EQ-'+unit.number+' / '+unit.location_interior
			} else {
				return 'EQ-'+unit.number+' / SIN UBICACION'
			}
		}
	}
}
</script>
