<template>
	<div class="mt-3">

		<div v-if="core.loading">
			<SiteModuleHeader
				:showBack="true"
				:showHome="true"
				:showSize="true"
				:showTheme="true"
				:showFluid="true"
				:showTitle="false"
				:reloading="reloading || resource.loading"
			></SiteModuleHeader>
			<b-row>
				<b-col sm="6">
					<b-skeleton class="mb-1" height="15px"></b-skeleton>
					<b-skeleton class="mb-4" height="20px"></b-skeleton>
					<b-skeleton height="100px"></b-skeleton>
				</b-col>
			</b-row>
		</div>
		<SiteDenied v-else-if="profile.name != 'client'"></SiteDenied>
		<div v-else>

			<SiteModuleHeader
				:showBack="true"
				:showHome="true"
				:showSize="true"
				:showTheme="true"
				:showFluid="true"
				:showTitle="false"
				@reload="reload"
				:reloading="reloading || resource.loading"
			></SiteModuleHeader>

			<b-row
				class="mb-4"
				:class="{
					'text-primary' : ui_theme == 'light',
					'text-white' : ui_theme == 'dark'
				}"
			>
				<b-col sm="auto">
					<b-skeleton height="34px" width="200px" v-if="resource.loading"></b-skeleton>
					<p
						class="mb-0 fw-bold"
						:class="{
							'fs-15 mb-1' : ui_size == 'sm',
							'fs-20 mb-2' : ui_size == 'md',
							'fs-25 mb-2' : ui_size == 'lg',
							'text-primary' : ui_theme == 'light',
							'text-white' : ui_theme == 'dark'
						}"
						v-else
					>
						{{ resource.data.date }}
					</p>
					<b-skeleton height="18px" width="100px" v-if="resource.loading"></b-skeleton>
					<p
						class="mb-0 flh-10"
						:class="{
							'fs-12' : ui_size == 'sm',
							'fs-14' : ui_size == 'md',
							'fs-16' : ui_size == 'lg',
							'text-primary' : ui_theme == 'light',
							'text-secondary' : ui_theme == 'dark'
						}"
						v-else
					>{{ core.account.name }} {{ core.account.lastname }}</p>
				</b-col>
				<b-col sm="auto">
					<b-skeleton height="34px" width="200px" v-if="resource.loading"></b-skeleton>
					<p
						v-else
						class="fw-light mb-0"
					>
						<i
							class="fas mr-1"
							:class="{
								'fs-08 mt-3' : ui_size == 'sm',
								'fs-12 mt-3' : ui_size == 'md',
								'fs-15 mt-3' : ui_size == 'lg',
								'text-primary' : ui_theme == 'light',
								'text-secondary' : ui_theme == 'dark',
								'fa-shield-check' : profile.name == 'management',
								'fa-helmet-safety' : profile.name == 'technician',
								'fa-buildings' : profile.name == 'client',
							}"
						></i>
						<span
							class="mb-0 flh-10"
							:class="{
								'fs-08' : ui_size == 'sm',
								'fs-12' : ui_size == 'md',
								'fs-15' : ui_size == 'lg',
								'text-primary' : ui_theme == 'light',
								'text-secondary' : ui_theme == 'dark'
							}"
						>
							{{ profile.description }}
						</span>
					</p>
				</b-col>
			</b-row>

			<b-skeleton-wrapper :loading="reloading || resource.loading">
				<template #loading>
					<b-row>
						<b-col lg="12" xl="6">
							<b-card class="mb-4" no-body>
								<b-skeleton height="32px" width="100%"></b-skeleton>
								<b-card-body class="pb-1">
									<b-row>
										<b-col sm="6" md="4" lg="4" xl="3" class="mb-3">
											<b-skeleton height="50px"></b-skeleton>
										</b-col>
										<b-col sm="6" md="4" lg="4" xl="3" class="mb-3">
											<b-skeleton height="50px"></b-skeleton>
										</b-col>
										<b-col sm="6" md="4" lg="4" xl="3" class="mb-3">
											<b-skeleton height="50px"></b-skeleton>
										</b-col>
										<b-col sm="6" md="4" lg="4" xl="3" class="mb-3">
											<b-skeleton height="50px"></b-skeleton>
										</b-col>
									</b-row>
								</b-card-body>
							</b-card>
						</b-col>
					</b-row>
				</template>

				<b-row>

					<!-- MANTENIMIENTO -->
					<b-col lg="12" xl="6">
						<b-card
							class="mb-4"
							no-body
							:bg-variant="ui_theme == 'light' ? '' : ''"
							:text-variant="ui_theme == 'dark' ? 'light' : 'light'"
							style="background: none;"
							:border-variant="ui_theme == 'dark' ? 'primary' : 'tertiary'"
						>
							<b-card-header
								class="py-1"
								:class="{
									'bg-success' : ui_theme == 'light',
									'fs-10' : ui_size == 'sm',
									'fs-12' : ui_size == 'md',
									'fs-14' : ui_size == 'lg',
								}"
							>
								<span>Mantenimiento</span>
							</b-card-header>
							<b-card-body class="pb-1">
								<b-row>

									<!-- RESUMEN -->
									<b-col
										sm="6"
										md="4"
										lg="4"
										:xl="ui_size == 'lg' ? 4 : ui_size == 'md' ? 4 : 3"
										class="mb-3"
									>
										<b-button
											:variant="ui_theme == 'light' ? 'light' : 'primary'"
											block
											@click="goTo('HVAC_Maintenance')"
											:class="{
												'fs-09' : ui_size == 'sm',
												'fs-12' : ui_size == 'md',
												'fs-14' : ui_size == 'lg',
											}"
										>
											<i class="fas fa-grid"></i>
											<p class="mb-0">Resumen</p>
										</b-button>
									</b-col>

									<!-- UNIDADES -->
									<b-col
										sm="6"
										md="4"
										lg="4"
										:xl="ui_size == 'lg' ? 4 : ui_size == 'md' ? 4 : 3"
										class="mb-3"
									>
										<b-button
											:variant="ui_theme == 'light' ? 'light' : 'primary'"
											block
											@click="goTo('HVAC_Units')"
											:class="{
												'fs-09' : ui_size == 'sm',
												'fs-12' : ui_size == 'md',
												'fs-14' : ui_size == 'lg',
											}"
										>
											<i class="fas fa-air-conditioner"></i>
											<p class="mb-0">Unidades</p>
										</b-button>
									</b-col>

									<!-- ORDENES -->
									<b-col
										sm="6"
										md="4"
										lg="4"
										:xl="ui_size == 'lg' ? 4 : ui_size == 'md' ? 4 : 3"
										class="mb-3"
									>
										<b-button
											:variant="ui_theme == 'light' ? 'light' : 'primary'"
											block
											@click="goTo('HVAC_Jobs')"
											:class="{
												'fs-09' : ui_size == 'sm',
												'fs-12' : ui_size == 'md',
												'fs-14' : ui_size == 'lg',
											}"
										>
											<i class="fas fa-screwdriver-wrench"></i>
											<p class="mb-0">Ordenes</p>
										</b-button>
									</b-col>

								</b-row>
							</b-card-body>
						</b-card>
					</b-col>
					
				</b-row>

			</b-skeleton-wrapper>
		</div>

		<SystemChangelog></SystemChangelog>

	</div>
</template>

<script>
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
import SiteDenied from '@/components/SiteDenied.vue'
import SystemChangelog from '@/components/SystemChangelog.vue'
export default {
	name: 'HomeView',
	components: {
		SystemChangelog,
		SiteDenied,
		SiteModuleHeader,
	},
	data: function() {
		return {
			reloading: false,
		}
	},
	mounted() {
		this.load()
	},
	computed: {
		profile() { return this.$store.state.core.account.profile },
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		getters() { return this.$store.getters },
		resource() { return this.$store.state.home },
		//////-----------------------------------------------------------
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
		load() {
			this.$store.dispatch('home').then(() => { this.reloading = false; })
		},
		reload() {
			this.reloading = true
			this.load()
		},
		systemChangelog() {
			this.$bvModal.show('system-changelog')
		},
		saveUISize(size) {
			this.$store.commit('save_ui_size', size)
		},
		decreaseUISize() {
			if (this.ui_size == 'md') {
				this.$store.commit('save_ui_size', 'sm')
			} else if (this.ui_size == 'lg') {
				this.$store.commit('save_ui_size', 'md')
			} else {
				return false
			}
		},
		increaseUISize() {
			if (this.ui_size == 'sm') {
				this.$store.commit('save_ui_size', 'md')
			} else if (this.ui_size == 'md') {
				this.$store.commit('save_ui_size', 'lg')
			} else {
				return false
			}
		},
	}
}
</script>
