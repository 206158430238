<template>
	<b-modal
		id="system-changelog"
		title="Novedades"
		size="lg"
		body-class="p-0"
	>
		<b-overlay :show="loading" rounded="sm" class="p-3">

			<code>{{getters}}</code>

			<!-- LOADING PROGRESS -->
			<template #overlay>
				<div class="text-center">
					<b-spinner type="grow" variant="secondary"></b-spinner>
				</div>
			</template>
			<!-- /LOADING PROGRESS -->
		</b-overlay>
		<template v-slot:modal-footer>
			<b-button @click="close()" variant="outline-secondary">Cerrar</b-button>
		</template>
	</b-modal>
</template>

<script>
export default {
	name: 'SystemChangelog',
	components: {
	},
	data() {
		return {
			loading: false,
		}
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		getters() { return this.$store.getters },
		//////-----------------------------------------------------------
	},
	methods: {
		close() {
			this.$bvModal.hide('system-changelog')
		}
	}
}
</script>
