<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="create-user"
			title="Nuevo Usuario"
			:size="ui_size == 'sm' ? 'lg' : 'lg'"
			body-class="p-0"
			:title-class="{
				'fs-11' : ui_size == 'sm',
				'fs-13' : ui_size == 'md',
				'fs-15' : ui_size == 'lg',
			}"
			:header-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:header-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:body-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:body-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:footer-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:footer-text-variant="ui_theme == 'dark' ? 'light' : ''"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-4" :variant="ui_theme == 'dark' ? 'dark': 'light'">


				<b-row>

					<!-- RUT -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								RUT
							</template>
							<b-input
								v-model="user.vatin"
								:formatter="cleanIdentifier"
								:maxlength="9"
								:state="val_vatin"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

					<!-- NAME -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								Nombre
							</template>
							<b-input
								v-model="user.name"
								:state="val_name"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

					<!-- LASTNAME -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								Apellido
							</template>
							<b-input
								v-model="user.lastname"
								:state="val_lastname"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

					<!-- EMAIL -->
					<b-col sm="6">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								E-mail
							</template>
							<b-input
								v-model="user.email"
								:state="val_email"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

					<!-- PHONE -->
					<b-col sm="6">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								Celular
							</template>
							<b-input-group prepend="+569" :size="ui_size">
								<b-input
									v-model="user.phone"
									:maxlength="8"
									:formatter="onlyNumbers"
									:state="val_phone"
									:class="{
										'bg-primary text-white' : ui_theme == 'dark',
									}"
								></b-input>
							</b-input-group>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					

					<!-- USER PROFILE -->
					<b-col sm="12">
						<b-form-group label-class="fw-bold">
							<template #label>
								<span>Perfil de Acceso</span>
								<b-button size="xs" variant="light" class="ml-2" @click="$bvModal.show('helper-access-profile')">
									<i class="fas fa-circle-question mr-1" title="Más Información" v-b-tooltip.hover></i>
									<span>Más información</span>
								</b-button>
							</template>
							<b-form-radio-group v-model="user.profile_id">
								<b-card
									no-body
									:class="{
										'py-2 px-2 mb-2' : true,
										'text-danger border-danger' : user.profile_id == null,
										'text-success border-success' : user.profile_id == 1,
										'text-secondary border-secondary' : user.profile_id > 1,
									}"
								>
									<b-form-radio :value="1" @input="user.client_id = null">
										<p class="mb-0 fw-bold">
											<i class="fas fa-shield-check mr-1"></i>
											<span>Administración</span>
										</p>
										<p class="mb-0">Esta cuenta podrá acceder a los módulos comerciales.</p>
									</b-form-radio>
								</b-card>
								<b-card
									no-body
									:class="{
										'py-2 px-2 mb-2' : true,
										'text-danger border-danger' : user.profile_id == null,
										'text-success border-success' : user.profile_id == 2,
										'text-secondary border-secondary' : user.profile_id == 1 || user.profile_id == 3,
									}"
								>
									<b-form-radio :value="2" @input="user.client_id = null">
										<p class="mb-0 fw-bold">
											<i class="fas fa-helmet-safety mr-1"></i>
											<span>Técnico</span>
										</p>
										<p class="mb-0">Esta cuenta podrá administrar Equipos y Órdenes de Trabajo de cualquier cliente.</p>
									</b-form-radio>
									<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
									<b-form-valid-feedback>Correcto</b-form-valid-feedback>
								</b-card>
								<b-card
									no-body
									:class="{
										'py-2 px-2 mb-2' : true,
										'text-danger border-danger' : user.profile_id == null || (user.profile_id == 3 && user.client_id == null),
										'text-success border-success' : user.profile_id == 3,
										'text-secondary border-secondary' : user.profile_id < 3,
									}"
								>
									<b-form-radio :value="3">
										<p class="mb-0 fw-bold">
											<i class="fas fa-buildings mr-1"></i>
											<span>Cliente</span>
										</p>
										<div>
											<p class="mb-0">Esta cuenta sólo podrá ver los Equipos y Órdenes de Trabajo del cliente que seleccione.</p>
											<b-form-group
												v-if="user.profile_id == 3"
												label-class="fw-bold"
												:class="{
													'mb-0 mt-2' : true,
													'fs-09 pb-0' : ui_size == 'sm',
													'fs-10 pb-1' : ui_size == 'md',
													'fs-12 pb-2' : ui_size == 'lg',
												}"
											>
												<template #label>
													<b-row>
														<b-col>
															Seleccione Cliente
														</b-col>
														<b-col sm="auto">
															<b-button
																@click="$bvModal.show('create-client')"
																block
																size="xs"
																class="fs-08"
																:variant="ui_theme == 'dark' ? 'outline-dark' : 'outline-dark'"
															>
																<i class="fas fa-plus mr-1"></i>
																<span>Nuevo Cliente</span>
															</b-button>
														</b-col>
													</b-row>
												</template>
												<b-select
													v-model="user.client_id"
													:disabled="clients.loading"
													:state="val_client"
													:size="ui_size"
													:class="{
														'bg-primary text-white' : ui_theme == 'dark',
													}"
												>
													<b-select-option :value="null" disabled>Presione aquí</b-select-option>
													<b-select-option v-for="(item, i) in clients.data" :key="i" :value="item.id">{{ item.name }}</b-select-option>
												</b-select>
												<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
												<b-form-valid-feedback>Correcto</b-form-valid-feedback>
											</b-form-group>
										</div>
									</b-form-radio>
									<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
									<b-form-valid-feedback>Correcto</b-form-valid-feedback>
								</b-card>
							</b-form-radio-group>
							<b-form-invalid-feedback :state="val_profile">Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback :state="val_profile">Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

				</b-row>

				<!-- VALIDATION -->
				<b-alert
					show
					:variant="val_full ? 'success' : 'danger'"
					class="mb-0"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-12' : ui_size == 'lg',
					}"
				>
					<p class="mb-0 text-size-10">
						<i class="fas fa-exclamation-circle mr-2"></i>
						<span>{{ val_full ?
									'Para continuar, presione el botón CREAR.' :
									'Complete los campos requeridos para continuar.'}}</span>
					</p>
				</b-alert>

				<!-- LOADING -->
				<template #overlay>
					<div class="text-center py-5">
						<b-spinner type="grow" variant="primary"></b-spinner>
					</div>
				</template>

			</b-overlay>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :disabled="loading" :size="ui_size">Cancelar</b-button>
				<b-button @click="create()" variant="success" :disabled="loading||!val_full" :size="ui_size">CREAR</b-button>
			</template>

		</b-modal>
		
		<b-modal
			id="helper-access-profile"
			hide-header
			size="md"
			body-class="px-4 py-4"
		>
			<div>
				<p class="mb-2">
					<i class="fas fa-circle-question fs-30 text-info"></i>
				</p>
				<p class="mb-2 fs-14 fw-bold">Perfil de Acceso</p>
				<p class="mb-0 fs-12 text-dark">Determina qué información almacenada en el sistema puede ver y cambiar una persona.</p>
			</div>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" block variant="outline-info" :disabled="loading" :size="ui_size">Cerrar</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import Helpers from '@/helpers'
export default {
	name: 'CreateUser',
	components: {
	},
	data() {
		return {
			loading: false,
			user: {
				vatin: '',
				name: '',
				lastname: '',
				email: '',
				phone: '',
				profile_id: null,
				client_id: null,
			},
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'create-user') { this.reset() }
		})
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		profiles() { return this.$store.state.helpers.profiles },
		clients() { return this.$store.state.helpers.clients },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		val_name() {
			return this.user.name && this.user.name.length > 0 ? true : false
		},
		val_lastname() {
			return this.user.lastname && this.user.lastname.length > 0 ? true : false
		},
		val_phone() {
			return this.user.phone && this.user.phone.length == 8 ? true : false
		},
		val_profile() {
			return this.user.profile_id != null ? true : false
		},
		val_client() {
			return this.user.client_id != null ? true : false
		},
		val_email() {
			var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return this.user.email.length > 0 ? regex.test(this.user.email) ? true : false : false
		},
		val_vatin() {
			return Helpers.validateVATIN(this.user.vatin)
		},
		val_full() {
			return this.val_vatin && this.val_name && this.val_lastname && this.val_email && this.val_phone && this.val_profile && (this.user.profile_id < 3 || this.val_client)? true : false
		},
	},
	methods: {
		reset() {
			this.user = {
				vatin: '',
				name: '',
				lastname: '',
				email: '',
				phone: '',
				profile_id: null,
				client_id: null,
			}
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		create() {
			this.loading = true
			this.$api.post('users', this.user)
			.then(response => {
				this.loading = false
				this.$bvModal.hide('create-user')
				this.$emit('update')
				this.toast(true, 'Se creó el usuario "'+response.data.email+'" correctamente.')
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		onlyNumbers(data) {
			return Helpers.onlyNumbers(data)
		},
		cleanIdentifier(value) {
			return value.replace(/[^0-9kK]/g,'');
		},
	}
}
</script>