<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="complete-job"
			title="Completar Orden de Trabajo"
			:size="ui_size == 'sm' ? 'md' : 'md'"
			body-class="p-0"
			:title-class="{
				'fs-11' : ui_size == 'sm',
				'fs-13' : ui_size == 'md',
				'fs-15' : ui_size == 'lg',
			}"
			:header-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:header-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:body-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:body-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:footer-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:footer-text-variant="ui_theme == 'dark' ? 'light' : ''"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-4" :variant="ui_theme == 'dark' ? 'dark': 'light'">

				<b-row>

					<!-- NAME -->
					<b-col sm="12" class="mb-3">
						<b-alert show variant="success" class="mb-0" v-if="job && job.number">
							<p class="mb-2 fs-12 fw-bold">¿Confirma que la Orden de Trabajo N°{{ job.number }} se encuentra completada?</p>
							<p class="mb-0 fs-11">Cargue la Orden de Trabajo original en formato PDF, incluyendo los checklist de cada equipo.</p>
						</b-alert>
					</b-col>

					<b-col sm="12" class="text-size-11">
						<b-row class="mb-2">
							<b-col>
								<b-overlay
									:show="file.loading"
									rounded
									spinner-type="grow"
									spinner-small
									opacity="0.6"
								>
									<b-form-file
										v-model="file.data"
										:state="val_file_full"
										@input="fileLoaded()"
										placeholder="Presione aquí"
										browse-text="Examinar"></b-form-file>
									<b-form-invalid-feedback :state="val_file_full">
										<span v-if="val_file">Incorrecto</span>
										<span v-else>Requerido</span>
									</b-form-invalid-feedback>
									<b-form-valid-feedback :state="val_file_full">Correcto</b-form-valid-feedback>
								</b-overlay>
							</b-col>
						</b-row>
						<b-row v-if="val_file_full && file.loading" style="margin-top: 25px;">
							<b-col>
								<b-progress :max="file.progress.total" height="5px" animated>
									<b-progress-bar :value="file.progress.current" variant="success"></b-progress-bar>
								</b-progress>
							</b-col>
						</b-row>
						<b-row v-if="(val_file && (!val_file_size || !val_file_extension))" class="mb-3">
							<b-col>
								<b-alert
									show
									variant="danger"
									class="mb-0"
								>
									<p class="mb-0" v-if="val_file && !val_file_size && !val_file_extension">Sólo se permite agregar documentos PDF de hasta 9 MB.</p>
									<p class="mb-0" v-else-if="val_file && !val_file_size">Sólo se permite agregar documentos de hasta 9 MB.</p>
									<p class="mb-0" v-else-if="val_file && !val_file_extension">Sólo se permite agregar documentos en formato PDF.</p>
								</b-alert>
							</b-col>
						</b-row>
					</b-col>

					<!-- COMMENT -->
					<b-col sm="12">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-0' : ui_size == 'md',
								'fs-12 pb-0' : ui_size == 'lg',
							}"
							:description="val_comment ? '' : 'Opcional (hasta 100 caracteres)'"
						>
							<template #label>
								Observación
							</template>
							<b-input
								v-model="comment"
								:maxlength="100"
								:state="val_comment"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							></b-input>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

				</b-row>


				<!-- LOADING -->
				<template #overlay>
					<div class="text-center py-5">
						<b-spinner type="grow" variant="primary"></b-spinner>
					</div>
				</template>

			</b-overlay>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :disabled="loading" :size="ui_size">Cancelar</b-button>
				<b-button @click="confirm()" variant="success" :disabled="loading || !val_file_full" :size="ui_size">CONFIRMAR</b-button>
			</template>

		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'CompleteJob',
	components: {
	},
	data() {
		return {
			loading: false,
			comment: '',
			file: {
				loading: false,
				progress: {
					current: 0,
					total: 0
				},
				data: null,
				allowed_extensions: ['pdf', 'PDF'],
				max_size: 50,
			},
		}
	},
	props: {
		job: Object,
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'complete-job') { this.reset() }
		})
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		val_comment() {
			return this.comment.length > 0 ? true : null
		},
		val_file() {
			return this.file.data == null ? false : true
		},
		val_file_size() {
			if (this.file.data == null) return false
			let size = this.file.data.size
			if ((size / 1048576) > this.file.max_size) return false
			return true
		},
		val_file_extension() {
			if (this.file.data == null) return false
			let filename = this.file.data.name.split(".")
			let extension = filename[filename.length-1]
			if (this.file.allowed_extensions.indexOf(extension) == -1) return false
			return true
		},
		val_file_full() {
			return this.val_file && this.val_file_size && this.val_file_extension ? true : false
		},
	},
	methods: {
		reset() {
			this.comment = ''
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		confirm() {
			var self = this
			this.file.progress.total = 0
			this.file.progress.current = 0
			this.file.loading = true
			const config = {
				onUploadProgress: function(progressEvent) {
					self.file.progress.total = progressEvent.total
					self.file.progress.current = progressEvent.loaded
				},
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
			let data = new FormData();
			data.append('file', this.file.data)
			data.append('comment', this.comment)
			this.loading = true
			this.$api.post('jobs/'+this.job.id+'/complete', data, config)
			.then(response => {
				this.loading = false
				this.$bvModal.hide('complete-job')
				this.file.data = null
				this.file.loading = false
				this.file.progress.total = 0
				this.file.progress.current = 0
				this.$emit('update')
				this.$store.dispatch('job', this.job.id)
				this.toast(true, 'Se finalizó la Orden N°'+ response.data.number+' correctamente.')
			})
			.catch(error => {
				this.loading = false
				this.file.loading = false
				this.file.progress.total = 0
				this.file.progress.current = 0
				this.toast(false, error.response.data.message, 10000)
			})
		},
		fileLoaded() {
			//
		},
	}
}
</script>