<template>
	<div>
		<b-overlay
			:show="loading"
			rounded
			opacity="0.6"
			class="mb-2"
			v-bind:class="{ 'mr-2' : !mobile, 'd-inline-block' : !mobile }"
		>
			<template #overlay>
				<span>&nbsp;</span>
			</template>
			<b-select
				v-model="current_limit"
				:size="ui_size"
				:options="options"
				v-bind:class="{
					'bg-white border-primary text-primary' : ui_theme == 'light',
					'bg-transparent border-white text-white' : ui_theme == 'dark'
				}"
			>
			</b-select>
		</b-overlay>
	</div>
</template>

<script>
export default {
	name: 'SitePerPage',
	props: {
		limit: {
			type: Number,
			required: false,
		},
		loading: {
			type: Boolean,
			required: false,
		},
		options: {
			type: Array,
			required: false,
		},
		size: {
			type: String,
			required: false,
		},
	},
	computed: {
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		screen_size() { return this.$store.getters.screen_size },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		current_limit: {
			get() {
				return this.limit
			},
			set(new_value) {
				this.$emit('clicked', new_value)
			}
		}
	},
}
</script>
