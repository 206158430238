<template>
	<b-row
		:class="{
			'pt-1 pb-2' : ui_size == 'sm',
			'pt-2 pb-2' : ui_size == 'md',
			'pt-3 pb-3' : ui_size == 'lg',
		}">
		<b-col>
			<p
				:class="{
					'mb-3' : ui_size == 'sm',
					'mb-4' : ui_size == 'md',
					'mb-4' : ui_size == 'lg',
				}">
				<b-button
					v-if="showBack === true"
					:disabled="reloading === true"
					:size="ui_size"
					:variant="ui_theme == 'light' ? 'light' : 'outline-secondary'"
					@click="goBack()"
					class="mr-2"
					title="Volver atrás"
					v-b-tooltip.hover
				>
					<i class="fas fa-arrow-left"></i>
				</b-button>
				<b-button
					v-if="showHome === true"
					:disabled="reloading === true"
					:size="ui_size"
					:variant="ui_theme == 'light' ? 'light' : 'outline-secondary'"
					@click="goToHome()"
					class="mr-2"
					title="Volver al inicio"
					v-b-tooltip.hover
				>
					<i class="fas fa-home"></i>
				</b-button>
				<b-button-group class="mr-2" v-if="showSize === true">
					<b-button
						@click="decreaseUISize()"
						:disabled="reloading === true"
						:variant="ui_theme == 'light' ? 'light' : 'outline-secondary'"
						:size="ui_size"
						title="Achicar letra"
						v-b-tooltip.hover
					>
						<i class="fas fa-minus"></i>
					</b-button>
					<b-button
						@click="increaseUISize()"
						:disabled="reloading === true"
						:variant="ui_theme == 'light' ? 'light' : 'outline-secondary'"
						:size="ui_size"
						title="Agrandar letra"
						v-b-tooltip.hover
					>
						<i class="fas fa-plus"></i>
					</b-button>
				</b-button-group>
				<b-button
					v-if="showTheme === true && false"
					:disabled="reloading === true"
					@click="changeUITheme()"
					:variant="ui_theme == 'light' ? 'light' : 'outline-secondary'"
					class="mr-2"
					:size="ui_size"
					:title="ui_theme == 'light' ? 'Cambiar a tema oscuro' : 'Cambiar a tema claro'"
					v-b-tooltip.hover
				>
					<i class="fas fa-moon"></i>
				</b-button>
				<b-button
					v-if="showFluid === true"
					:disabled="reloading === true"
					@click="toggleFluid()"
					:variant="ui_theme == 'light' ? 'light' : 'outline-secondary'"
					class="mr-2"
					:size="ui_size"
					:title="fluid ? 'Contraer vista' : 'Expandir vista'"
					v-b-tooltip.hover
				>
					<div v-if="fluid">
						<i class="fas fa-arrow-right-to-line"></i>
					</div>
					<div v-else>
						<i class="fas fa-arrows-left-right"></i>
					</div>
				</b-button>
				<b-button
					v-if="showReload === true"
					:disabled="reloading === true"
					@click="reload()"
					:variant="ui_theme == 'light' ? 'light' : 'outline-secondary'"
					class="mr-2"
					:size="ui_size"
					title="Refrescar datos"
					v-b-tooltip.hover
				>
					<i class="fas fa-rotate-right"></i>
				</b-button>
			</p>
			<p
				v-if="showTitle === true"
				class="mb-0 flh-10"
				:class="{
					'fs-12' : ui_size == 'sm',
					'fs-14' : ui_size == 'md',
					'fs-16' : ui_size == 'lg',
					'text-primary' : ui_theme == 'light',
					'text-secondary' : ui_theme == 'dark'
				}">{{ module }}</p>
			<p
				v-if="showTitle === true"
				class="mb-0 fw-bold"
				:class="{
					'fs-15 mb-1' : ui_size == 'sm',
					'fs-20 mb-2' : ui_size == 'md',
					'fs-25 mb-2' : ui_size == 'lg',
					'text-primary' : ui_theme == 'light',
					'text-white' : ui_theme == 'dark'
				}">{{ title }}</p>
		</b-col>
		<b-col sm="auto" class="text-right" v-if="options">
		</b-col>
	</b-row>
</template>

<script>
export default {
	name: 'SiteModuleHeader',
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		module() { return this.$route.meta.module },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		getters() { return this.$store.getters },
		profile() { return this.$store.state.core.account.profile },
		fluid: {
			get() { return this.$store.getters.fluid },
			set(data) { this.$store.commit('fluid', data) }
		},
	},
	props: {
		options: {
			type: Boolean,
			required: false,
			default: true
		},
		showBack: {
			type: Boolean,
			required: false,
			default: true
		},
		showHome: {
			type: Boolean,
			required: false,
			default: true
		},
		showSize: {
			type: Boolean,
			required: false,
			default: true
		},
		showTheme: {
			type: Boolean,
			required: false,
			default: true
		},
		showFluid: {
			type: Boolean,
			required: false,
			default: true
		},
		showReload: {
			type: Boolean,
			required: false,
			default: true
		},
		showTitle: {
			type: Boolean,
			required: false,
			default: true
		},
		reloading: {
			type: Boolean,
			required: false,
			default: false
		},
	},
	methods: {
		goBack() {
			this.$router.back()
		},
		goToHome() {
			if (this.profile.name == 'management') {
				this.goTo('Home')
			}
			else if (this.profile.name == 'technician') {
				this.goTo('HomeTechnichian')
			}
			else if (this.profile.name == 'client') {
				this.goTo('HomeClient')
			}
		},
		goTo(route_name) {
			if (this.$route.name != route_name) {
				this.$router.push({ name: route_name })
			}
		},
		decreaseUISize() {
			if (this.ui_size == 'md') {
				this.$store.commit('save_ui_size', 'sm')
			} else if (this.ui_size == 'lg') {
				this.$store.commit('save_ui_size', 'md')
			} else {
				return false
			}
		},
		increaseUISize() {
			if (this.ui_size == 'sm') {
				this.$store.commit('save_ui_size', 'md')
			} else if (this.ui_size == 'md') {
				this.$store.commit('save_ui_size', 'lg')
			} else {
				return false
			}
		},
		changeUITheme() {
			if (this.ui_theme == 'light') {
				this.$store.commit('save_ui_theme', 'dark')
			} else if (this.ui_theme == 'dark') {
				this.$store.commit('save_ui_theme', 'light')
			} else {
				return false
			}
		},
		reload() {
			this.$emit('reload')
		},
		toggleFluid() {
			this.fluid = !this.fluid
		}
	}
}
</script>
