<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="create-unit"
			title="Nuevo Equipo"
			:size="ui_size == 'sm' ? 'lg' : 'xl'"
			body-class="p-0"
			:title-class="{
				'fs-11' : ui_size == 'sm',
				'fs-13' : ui_size == 'md',
				'fs-15' : ui_size == 'lg',
			}"
			:header-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:header-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:body-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:body-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:footer-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:footer-text-variant="ui_theme == 'dark' ? 'light' : ''"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-4" :variant="ui_theme == 'dark' ? 'dark': 'light'">

				<b-row>

					<!-- CLIENT -->
					<b-col sm="6">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								<b-row no-gutters>
									<b-col>
										Seleccione Cliente
									</b-col>
									<b-col sm="auto">
										<b-button
											@click="$bvModal.show('create-client')"
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
										>
											<i class="fas fa-fw fa-plus"></i>
										</b-button>
									</b-col>
									<b-col sm="auto" class="pl-2">
										<b-button
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
											title="Dependencias en las que se encuentra el equipo"
											v-b-tooltip.hover
										>
											<i class="fas fa-fw fa-question"></i>
										</b-button>
									</b-col>
								</b-row>
							</template>
							<b-select
								v-model="unit.client_id"
								:disabled="clients.loading"
								:state="val_client"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
								@change="reloadClientResources()"
							>
								<b-select-option :value="null" disabled>Presione aquí</b-select-option>
								<b-select-option v-for="(item, i) in clients.data" :key="i" :value="item.id">{{ item.name }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

					<!-- SITE -->
					<b-col sm="6">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
								'text-muted' : unit.client_id == null,
							}"
						>
							<template #label>
								<b-row no-gutters>
									<b-col>
										Sucursal/Faena/Division
									</b-col>
									<b-col sm="auto">
										<b-button
											@click="$bvModal.show('create-site')"
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
										>
											<i class="fas fa-fw fa-plus"></i>
										</b-button>
									</b-col>
									<b-col sm="auto" class="pl-2">
										<b-button
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
											title="Dependencias en las que se encuentra el equipo"
											v-b-tooltip.hover
										>
											<i class="fas fa-fw fa-question"></i>
										</b-button>
									</b-col>
								</b-row>
							</template>
							<b-select
								v-model="unit.site_id"
								:disabled="unit.client_id == null || clients.loading || client_sites.loading"
								:state="val_site"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							>
								<b-select-option :value="null" disabled>
									<span v-if="unit.client_id != null">Presione aquí</span>
									<span v-else>Seleccione primero un cliente</span>
								</b-select-option>
								<b-select-option v-for="(item, i) in client_sites.data" :key="i" :value="item.id">{{ item.address }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

					<!-- CONTRACT AREA -->
					<b-col sm="6">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
							:description="val_contract_area || clients.loading || client_contract_areas.loading ? '' : 'Opcional'"
						>
							<template #label>
								<b-row no-gutters>
									<b-col>
										Área
									</b-col>
									<b-col sm="auto">
										<b-button
											@click="$bvModal.show('create-hvac-contract-area')"
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
										>
											<i class="fas fa-fw fa-plus"></i>
										</b-button>
									</b-col>
									<b-col sm="auto" class="pl-2">
										<b-button
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
											title="Nombres de las áreas que componen una sucursal o sitio"
											v-b-tooltip.hover
										>
											<i class="fas fa-fw fa-question"></i>
										</b-button>
									</b-col>
								</b-row>
							</template>
							<b-select
								v-model="unit.contract_area_id"
								:disabled="unit.client_id == null || clients.loading || client_contract_areas.loading"
								:state="val_contract_area"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							>
								<b-select-option :value="null" disabled>
									<span v-if="unit.client_id != null">Presione aquí</span>
									<span v-else>Seleccione primero un cliente</span>
								</b-select-option>
								<b-select-option v-for="(item, i) in client_contract_areas.data" :key="i" :value="item.id">{{ item.name }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

					<!-- AREA -->
					<b-col sm="6">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
							:description="val_area ? '' : 'Opcional'"
						>
							<template #label>
								<b-row no-gutters>
									<b-col>
										Área a Climatizar
									</b-col>
									<b-col sm="auto">
										<b-button
											@click="$bvModal.show('create-hvac-area')"
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
										>
											<i class="fas fa-fw fa-plus"></i>
										</b-button>
									</b-col>
									<b-col sm="auto" class="pl-2">
										<b-button
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
											title="Nombre del área que climatiza este equipo"
											v-b-tooltip.hover
										>
											<i class="fas fa-fw fa-question"></i>
										</b-button>
									</b-col>
								</b-row>
							</template>
							<b-select
								v-model="unit.area_id"
								:disabled="unit.client_id == null || clients.loading || client_areas.loading"
								:state="val_area"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							>
								<b-select-option :value="null" disabled>
									<span v-if="unit.client_id != null">Presione aquí</span>
									<span v-else>Seleccione primero un cliente</span>
								</b-select-option>
								<b-select-option v-for="(item, i) in client_areas.data" :key="i" :value="item.id">{{ item.name }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

					<!-- TIME SWITCH -->
					<b-col sm="6">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
							:description="val_time_switch ? '' : 'Opcional'"
						>
							<template #label>
								<b-row no-gutters>
									<b-col>
										Reloj Horario
									</b-col>
									<b-col sm="auto">
										<b-button
											@click="$bvModal.show('create-hvac-time-switch')"
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
										>
											<i class="fas fa-fw fa-plus"></i>
										</b-button>
									</b-col>
									<b-col sm="auto" class="pl-2">
										<b-button
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
											title="Temporizadores automatizados de operación"
											v-b-tooltip.hover
										>
											<i class="fas fa-fw fa-question"></i>
										</b-button>
									</b-col>
								</b-row>
							</template>
							<b-select
								v-model="unit.time_switch_id"
								:disabled="unit.client_id == null || clients.loading || client_time_switches.loading || !client_time_switches.data.length"
								:state="val_time_switch"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							>
								<b-select-option :value="null" disabled>
									<span v-if="unit.client_id != null">Presione aquí</span>
									<span v-else>Seleccione primero un cliente</span>
								</b-select-option>
								<b-select-option v-for="(item, i) in client_time_switches.data" :key="i" :value="item.id">{{ item.name }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

					<!-- TEMPERATURE CONTROL -->
					<b-col sm="6">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
							:description="val_temperature_control ? '' : 'Opcional'"
						>
							<template #label>
								<b-row no-gutters>
									<b-col>
										Control Temperatura
									</b-col>
									<b-col sm="auto">
										<b-button
											@click="$bvModal.show('create-hvac-temperature-control')"
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
										>
											<i class="fas fa-fw fa-plus"></i>
										</b-button>
									</b-col>
									<b-col sm="auto" class="pl-2">
										<b-button
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
											title="Lorem ipsum"
											v-b-tooltip.hover
										>
											<i class="fas fa-fw fa-question"></i>
										</b-button>
									</b-col>
								</b-row>
							</template>
							<b-select
								v-model="unit.temperature_control_id"
								:disabled="unit.client_id == null || clients.loading || client_temperature_controls.loading"
								:state="val_temperature_control"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							>
								<b-select-option :value="null" disabled>
									<span v-if="unit.client_id != null">Presione aquí</span>
									<span v-else>Seleccione primero un cliente</span>
								</b-select-option>
								<b-select-option v-for="(item, i) in client_temperature_controls.data" :key="i" :value="item.id">{{ item.name }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					
					<!-- LOCATION INTERIOR -->
					<b-col sm="6">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								Ubicación de Unidad Evaporadora (Interior)
							</template>
							<b-input
								v-model="unit.location_interior"
								:state="val_location_interior"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					
					<!-- LOCATION EXTERIOR -->
					<b-col sm="6">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
							:description="val_location_exterior ? '' : 'Opcional'"
						>
							<template #label>
								Ubicación de Unidad Condensadora (Exterior)
							</template>
							<b-input
								v-model="unit.location_exterior"
								:state="val_location_exterior"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					
					<!-- IDENTIFIER -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
							:description="val_identifier ? '' : 'Opcional'"
						>
							<template #label>
								Identificador
							</template>
							<b-input
								v-model="unit.identifier"
								:state="val_identifier"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

					<!-- FLOOR -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
							:description="val_floor ? '' : 'Opcional'"
						>
							<template #label>
								Nivel o Piso
							</template>
							<b-input
								v-model="unit.floor"
								:state="val_floor"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							></b-input>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

					<!-- INSTALL DATE -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
							:description="val_install_date ? '' : 'Opcional'"
						>
							<template #label>
								Fecha de Instalación
							</template>
							<b-form-datepicker
								v-model="unit.install_date"
								:date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
								placeholder="Presione aquí"
								:state="val_install_date"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							></b-form-datepicker>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>


					<!-- MARCA -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								<b-row no-gutters>
									<b-col>
										Marca
									</b-col>
									<b-col sm="auto">
										<b-button
											@click="$bvModal.show('create-brand')"
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
										>
											<i class="fas fa-fw fa-plus"></i>
										</b-button>
									</b-col>
									<b-col sm="auto" class="pl-2">
										<b-button
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
											title="Lorem ipsum"
											v-b-tooltip.hover
										>
											<i class="fas fa-fw fa-question"></i>
										</b-button>
									</b-col>
								</b-row>
							</template>
							<b-select
								v-model="unit.brand_id"
								:disabled="brands.loading"
								:state="val_brand"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
								@change="reloadBrandModels()"
							>
								<b-select-option :value="null" disabled>Presione aquí</b-select-option>
								<b-select-option v-for="(item, i) in brands.data" :key="i" :value="item.id">{{ item.name }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

					<!-- MODEL -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								<b-row no-gutters>
									<b-col>
										Modelo
									</b-col>
									<b-col sm="auto">
										<b-button
											@click="$bvModal.show('create-model')"
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
										>
											<i class="fas fa-fw fa-plus"></i>
										</b-button>
									</b-col>
									<b-col sm="auto" class="pl-2">
										<b-button
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
											title="Lorem ipsum"
											v-b-tooltip.hover
										>
											<i class="fas fa-fw fa-question"></i>
										</b-button>
									</b-col>
								</b-row>
							</template>
							<b-select
								v-model="unit.model_id"
								:disabled="brand_models.loading || unit.brand_id == null"
								:state="val_model"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							>
								<b-select-option :value="null" disabled>
									<span v-if="unit.brand_id != null">Presione aquí</span>
									<span v-else>Seleccione primero una marca</span>
								</b-select-option>
								<b-select-option v-for="(item, i) in brand_models.data" :key="i" :value="item.id">{{ item.type.name }} // {{ item.part_number }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					
					<!-- SERIAL NUMBER -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
							:description="val_serial_number ? '' : 'Opcional'"
						>
							<template #label>
								Número de Serie
							</template>
							<b-input
								v-model="unit.serial_number"
								:state="val_serial_number"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					
					<!-- CAPACITY -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
							:description="val_capacity ? '' : 'Opcional'"
						>
							<template #label>
								Capacidad
							</template>
							<b-input-group>
								<b-input
									v-model="unit.capacity"
									:state="val_capacity"
									:formatter="onlyNumbers"
									:size="ui_size"
									:class="{
										'bg-primary text-white' : ui_theme == 'dark',
									}"
								></b-input>
								<template #append>
									<b-dropdown
										:variant="val_capacity ? 'success' : 'outline-secondary'"
										:size="ui_size"
									>
										<template #button-content>
											{{ currentUnitCapacityMeasurement }}
										</template>
										<b-dropdown-item
											v-for="(item, i) in helpers_unit_capacity_measurement_units.data"
											:key="i"
											@click="unit.capacity_measurement_unit_id = item.id"
										>{{ item.name }}</b-dropdown-item>
									</b-dropdown>
								</template>
							</b-input-group>
							<b-form-invalid-feedback :state="val_capacity">Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback :state="val_capacity">Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					
					<!-- VOLTAGE -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
							:description="val_voltage ? '' : 'Opcional'"
						>
							<template #label>
								Voltaje
							</template>
							<b-select
								v-model="unit.voltage_id"
								:disabled="helpers_unit_voltages.loading"
								:state="val_voltage"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							>
								<b-select-option :value="null" disabled>Presione aquí</b-select-option>
								<b-select-option v-for="(item, i) in helpers_unit_voltages.data" :key="i" :value="item.id">{{ item.name }}V</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					
					<!-- COOLANT -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
							:description="val_coolant ? '' : 'Opcional'"
						>
							<template #label>
								<b-row no-gutters>
									<b-col>
										Refrigerante
									</b-col>
									<b-col sm="auto">
										<b-button
											@click="$bvModal.show('create-coolant')"
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
										>
											<i class="fas fa-fw fa-plus"></i>
										</b-button>
									</b-col>
									<b-col sm="auto" class="pl-2">
										<b-button
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-dark'"
											title="Lorem ipsum"
											v-b-tooltip.hover
										>
											<i class="fas fa-fw fa-question"></i>
										</b-button>
									</b-col>
								</b-row>
							</template>
							<b-select
								v-model="unit.coolant_id"
								:disabled="helpers_unit_coolants.loading"
								:state="val_coolant"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							>
								<b-select-option :value="null" disabled>Presione aquí</b-select-option>
								<b-select-option v-for="(item, i) in helpers_unit_coolants.data" :key="i" :value="item.id">{{ item.name }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					
					<!-- MAINTENANCE WINDOW -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
							:description="val_maintenance_window ? '' : 'Opcional'"
						>
							<template #label>
								Mantenimiento
							</template>
							<b-select
								v-model="unit.maintenance_window_id"
								:disabled="helpers_unit_maintenance_windows.loading"
								:state="val_maintenance_window"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							>
								<b-select-option :value="null" disabled>Presione aquí</b-select-option>
								<b-select-option v-for="(item, i) in helpers_unit_maintenance_windows.data" :key="i" :value="item.id">{{ item.name }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					
					<!-- PRIORITY -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
							:description="val_priority ? '' : 'Opcional'"
						>
							<template #label>
								Criticidad
							</template>
							<b-select
								v-model="unit.priority_id"
								:disabled="helpers_unit_priorities.loading"
								:size="ui_size"
								:state="val_priority"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							>
								<b-select-option :value="null" disabled>Presione aquí</b-select-option>
								<b-select-option v-for="(item, i) in helpers_unit_priorities.data" :key="i" :value="item.id">{{ item.name }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					
					<!-- CONDITION -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
							:description="val_condition ? '' : 'Opcional'"
						>
							<template #label>
								Condición
							</template>
							<b-select
								v-model="unit.condition_id"
								:disabled="helpers_unit_conditions.loading"
								:size="ui_size"
								:state="val_condition"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							>
								<b-select-option :value="null" disabled>Presione aquí</b-select-option>
								<b-select-option v-for="(item, i) in helpers_unit_conditions.data" :key="i" :value="item.id">{{ item.name }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

				</b-row>

				<!-- VALIDATION -->
				<b-alert
					show
					:variant="val_full ? 'success' : 'danger'"
					class="mb-0"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-12' : ui_size == 'lg',
					}"
				>
					<p class="mb-0 text-size-10">
						<i class="fas fa-exclamation-circle mr-2"></i>
						<span>{{ val_full ?
									'Para continuar, presione el botón CREAR.' :
									'Complete los campos requeridos para continuar.'}}</span>
					</p>
				</b-alert>

				<!-- LOADING -->
				<template #overlay>
					<div class="text-center py-5">
						<b-spinner type="grow" variant="primary"></b-spinner>
					</div>
				</template>

			</b-overlay>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :disabled="loading" :size="ui_size">Cancelar</b-button>
				<b-button @click="create()" variant="success" :disabled="loading||!val_full" :size="ui_size">CREAR</b-button>
			</template>

		</b-modal>
	</div>
</template>

<script>
import Helpers from '@/helpers';
export default {
	name: 'CreateUnit',
	components: {
	},
	data() {
		return {
			loading: false,
			unit: {
				brand_id: null,
				area_id: null,
				contract_area_id: null,
				time_switch_id: null,
				temperature_control_id: null,
				model_id: null,
				voltage_id: null,
				coolant_id: null,
				client_id: null,
				capacity_measurement_unit_id: null,
				maintenance_window_id: null,
				site_id: null,
				serial_number: '',
				install_date: '',
				priority_id: null,
				condition_id: null,
				is_working: null,
				floor: '',
				location_interior: '',
				location_exterior: '',
				identifier: '',
				capacity: '',
				description: '',
				comment: '',
			},
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'create-unit') { this.reset() }
		})
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		clients() { return this.$store.state.helpers.clients },
		client_sites() { return this.$store.state.client_sites },
		client_areas() { return this.$store.state.client_areas },
		client_contract_areas() { return this.$store.state.client_contract_areas },
		client_time_switches() { return this.$store.state.client_time_switches },
		client_temperature_controls() { return this.$store.state.client_temperature_controls },
		brand_models() { return this.$store.state.brand_models },
		brands() { return this.$store.state.brands },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		helpers_unit_voltages() { return this.$store.state.helpers.unit_voltages },
		helpers_unit_coolants() { return this.$store.state.helpers.unit_coolants },
		helpers_unit_conditions() { return this.$store.state.helpers.unit_conditions },
		helpers_unit_priorities() { return this.$store.state.helpers.unit_priorities },
		helpers_unit_capacity_measurement_units() { return this.$store.state.helpers.unit_capacity_measurement_units },
		helpers_unit_maintenance_windows() { return this.$store.state.helpers.unit_maintenance_windows },
		currentUnitCapacityMeasurement() {
			if (!this.helpers_unit_capacity_measurement_units.loading && this.helpers_unit_capacity_measurement_units.data.length > 0) {
				var mu = this.helpers_unit_capacity_measurement_units.data.find(item => item.id == this.unit.capacity_measurement_unit_id)
				return typeof mu != 'undefined' && mu.name ? mu.name : 'BTU'
			} else {
				return 'U.M.'
			}
		},
		val_client() {
			return this.unit.client_id != null ? true : false
		},
		val_site() {
			return this.unit.site_id != null ? true : false
		},
		val_brand() {
			return this.unit.brand_id != null ? true : false
		},
		val_model() {
			return this.unit.model_id != null ? true : false
		},
		val_priority() {
			return this.unit.priority_id == null ? null : true
		},
		val_condition() {
			return this.unit.condition_id == null ? null : true
		},
		val_area() {
			return this.unit.area_id == null ? null : true
		},
		val_contract_area() {
			return this.unit.contract_area_id == null ? null : true
		},
		val_time_switch() {
			return this.unit.time_switch_id == null ? null : true
		},
		val_temperature_control() {
			return this.unit.temperature_control_id == null ? null : true
		},
		val_install_date() {
			return this.unit.install_date && this.unit.install_date.length > 0 ? true : null
		},
		val_location_interior() {
			return this.unit.location_interior && this.unit.location_interior.length > 0 ? true : false
		},
		val_location_exterior() {
			return this.unit.location_exterior == null || this.unit.location_exterior.length == 0 ? null : true
		},
		val_serial_number() {
			return this.unit.serial_number.length == 0 ? null : true
		},
		val_floor() {
			return this.unit.floor == null || this.unit.floor.length == 0 ? null : true
		},
		val_capacity() {
			return this.unit.capacity > 0 ? true : null
		},
		val_capacity_measurement_unit() {
			return this.unit.capacity_measurement_unit_id == null ? null : true
		},
		val_maintenance_window() {
			return this.unit.maintenance_window_id == null ? null : true
		},
		val_voltage() {
			return this.unit.voltage_id == null ? null : true
		},
		val_identifier() {
			return this.unit.identifier == null || this.unit.identifier.length == 0 ? null : true
		},
		val_coolant() {
			return this.unit.coolant_id == null ? null : true
		},
		val_full() {
			return this.val_client && this.val_site && this.val_model && this.val_location_interior ? true : false
		},
	},
	methods: {
		reset() {
			this.unit = {
				brand_id: null,
				area_id: null,
				contract_area_id: null,
				time_switch_id: null,
				temperature_control_id: null,
				model_id: null,
				voltage_id: null,
				coolant_id: null,
				client_id: null,
				capacity_measurement_unit_id: null,
				maintenance_window_id: null,
				site_id: null,
				serial_number: '',
				install_date: '',
				priority_id: null,
				condition_id: null,
				is_working: null,
				floor: '',
				location_interior: '',
				location_exterior: '',
				identifier: '',
				capacity: '',
				description: '',
				comment: '',
			}
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		create() {
			this.loading = true
			this.$api.post('units', this.unit)
			.then(response => {
				this.loading = false
				this.$bvModal.hide('create-unit')
				this.$emit('update')
				this.$store.dispatch('units')
				this.toast(true, 'Se creó el Equipo N°'+response.data.number+' correctamente.')
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		reloadClientResources() {
			this.unit.site_id = null
			this.$emit('new_client_id', this.unit.client_id)
			this.$store.dispatch('client_sites', this.unit.client_id)
			this.$store.dispatch('client_areas', this.unit.client_id)
			this.$store.dispatch('client_contract_areas', this.unit.client_id)
			this.$store.dispatch('client_time_switches', this.unit.client_id)
			this.$store.dispatch('client_temperature_controls', this.unit.client_id)
		},
		reloadBrandModels() {
			this.unit.model_id = null
			this.$emit('new_brand_id', this.unit.brand_id)
			this.$store.dispatch('brand_models', this.unit.brand_id)
		},
		onlyNumbers(data) {
			return Helpers.onlyNumbers(data)
		},
		createModel() {
			this.$store.dispatch('model_types')
			this.$bvModal.show('create-model')
		}
	}
}
</script>