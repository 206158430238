<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="create-stock-requirement"
			title="Nueva Regla"
			:size="ui_size == 'sm' ? 'xl' : 'xl'"
			body-class="p-0"
			:title-class="{
				'fs-11' : ui_size == 'sm',
				'fs-13' : ui_size == 'md',
				'fs-15' : ui_size == 'lg',
			}"
			:header-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:header-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:body-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:body-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:footer-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:footer-text-variant="ui_theme == 'dark' ? 'light' : ''"
		>
		
			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-4" :variant="ui_theme == 'dark' ? 'dark': 'light'">

				<div v-if="result.screen == 'main'">
					
					<b-alert show variant="light" class="mb-4">
						<b-row no-gutters>
							<b-col sm="auto" class="pr-2">
								<i class="fas fa-circle-info"></i>
							</b-col>
							<b-col>
								<span>Al crear una regla, el sistema se encarga de monitorear el stock de un producto. Si marca el producto como crítico, el sistema generará una requisición automáticamente cuando el stock baje del mínimo especificado.</span>
							</b-col>
						</b-row>
					</b-alert>

					<b-row>

						<!-- CLIENT -->
						<b-col sm="6">
							<b-form-group
								label-class="fw-bold"
								:class="{
									'fs-09 pb-0' : ui_size == 'sm',
									'fs-10 pb-1' : ui_size == 'md',
									'fs-12 pb-2' : ui_size == 'lg',
								}"
							>
								<template #label>
									<b-row no-gutters>
										<b-col>
											<span>Cliente</span>
										</b-col>
									</b-row>
								</template>
								<b-select
									v-model="new_stock_requirement.client_id"
									:state="val_client"
									:disabled="clients.loading"
									:size="ui_size"
									:class="{
										'bg-primary text-white' : ui_theme == 'dark',
									}"
								>
									<b-select-option :value="null" disabled>Presione aquí</b-select-option>
									<b-select-option v-for="(item, i) in clients.data" :key="i" :value="item.id">{{ item.codename }}</b-select-option>
								</b-select>
								<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
								<b-form-valid-feedback>Correcto</b-form-valid-feedback>
							</b-form-group>
						</b-col>

						<!-- WAREHOUSE -->
						<b-col sm="4">
							<b-form-group
								label-class="fw-bold"
								:class="{
									'fs-09 pb-0' : ui_size == 'sm',
									'fs-10 pb-1' : ui_size == 'md',
									'fs-12 pb-2' : ui_size == 'lg',
								}"
							>
								<template #label>
									<b-row no-gutters>
										<b-col>
											<i class="fas fa-warehouse mr-1 text-primary"></i>
											<span>Bodega</span>
										</b-col>
									</b-row>
								</template>
								<b-select
									v-model="new_stock_requirement.warehouse_id"
									:state="val_warehouse"
									:disabled="warehouses.loading"
									:size="ui_size"
									:class="{
										'bg-primary text-white' : ui_theme == 'dark',
									}"
								>
									<b-select-option :value="null" disabled>Presione aquí</b-select-option>
									<b-select-option v-for="(item, i) in warehouses.data" :key="i" :value="item.id">{{ item.name }}</b-select-option>
								</b-select>
								<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
								<b-form-valid-feedback>Correcto</b-form-valid-feedback>
							</b-form-group>
						</b-col>

						</b-row>

						<!-- SEARCH -->
						<b-row class="mb-2 mt-2">
							<b-col>
								<b-button
									:disabled="new_stock_requirement.items && new_stock_requirement.items.length > 0"
									class="mr-2"
									variant="primary"
									@click="search()"
								>
									<i class="fas fa-plus mr-1"></i>
									<span>Seleccionar producto codificado</span>
								</b-button>
								<b-button
									:disabled="new_stock_requirement.items && new_stock_requirement.items.length > 0"
									variant="primary"
									@click="addNonExistentItem()"
								>
									<i class="fas fa-plus mr-1"></i>
									<span>Registrar producto no codificado</span>
								</b-button>
							</b-col>
						</b-row>

						<!-- ITEMS -->
						<b-table
							:items="computedItems"
							:fields="[
								{ key: 'discard', label: '', sortable: false },
								{ key: 'meta', label: 'Ítem', sortable: false },
								{ key: 'unit', label: 'U.M.', sortable: false },
								{ key: 'minimum', label: 'Mínimo', sortable: false },
								{ key: 'critical', label: 'Stock Crítico', sortable: false },
							]"
							show-empty
							:stacked="mobile"
							hover
							bordered
							:small="ui_size == 'sm' || false"
							sort-icon-left
							:head-variant="ui_theme == 'light' ? 'light' : 'dark'"
							class="mt-0 mb-4 rounded"
							fixed
							:class="{
								'fs-09' : ui_size == 'sm',
								'fs-10' : ui_size == 'md',
								'fs-13' : ui_size == 'lg',
								'table-dark' : ui_theme == 'dark',
							}"
						>
						<template #table-colgroup="scope">
							<col
								v-for="field in scope.fields"
								:key="field.key"
								:style="{
									width: field.key === 'discard' ? '5%' :
											field.key === 'meta' ? '45%' :
											field.key === 'unit' ? '10%' :
											field.key === 'critical' ? '10%' :
											field.key === 'minimum' ? '15%' : '15%'
								}"
							>
						</template>
						<template v-slot:empty>
							<div class="px-3 py-2">
								<span class="fs-12 text-danger">Seleccione o registre un producto.</span>
							</div>
						</template>
						<template v-slot:head(discard)>
							<div class="text-center">
								<i class="fas fa-trash"></i>
							</div>
						</template>
						<template v-slot:cell(discard)="data">
							<div class="text-center pt-1" @click="discardProduct(data)" role="button" v-b-tooltip.hover title="Descartar Ítem">
								<i class="fas fa-circle-xmark text-danger"></i>
							</div>
						</template>
						<template v-slot:cell(unit)="data">
							<div v-if="data.item.code == null" class="text-center">
								<b-dropdown size="sm" variant="light">
									<template #button-content>
										<span v-if="computedItems[data.index] && computedItems[data.index].unit && computedItems[data.index].unit.code">{{ computedItems[data.index].unit.code }}</span>
									</template>
									<b-dropdown-item
										v-for="(item, i) in measurement_units.data"
										:key="i"
										:value="item.id"
										@click="computedItems[data.index].unit = item"
									>{{ item.code }} - {{ item.name }}</b-dropdown-item>
								</b-dropdown>
							</div>
							<div v-else class="text-center">
								{{ data.value.code }}
							</div>
						</template>
						<template v-slot:cell(meta)="data">
							<div v-if="data.item.code == null">
								<b-form-input
									:state="validateDescription(computedItems[data.index])"
									v-model="computedItems[data.index].description"
									:maxlength="100"
									size="sm"
								></b-form-input>
							</div>
							<div v-else class="pt-1">
								<p class="mb-0 text-uppercase">{{ data.item.description }}</p>
							</div>
						</template>
						<template v-slot:cell(minimum)="data">
							<div>
								<b-input-group size="sm" class="w-space">
									<b-form-input
										class="text-center"
										:state="validateMinimum(computedItems[data.index])"
										v-model="computedItems[data.index].minimum"
										:formatter="onlyNumbers"
										:maxlength="20"
									></b-form-input>
								</b-input-group>
							</div>
						</template>
						<template v-slot:cell(critical)="data">
							<div class="pt-1">
								<b-form-checkbox v-model="computedItems[data.index].critical" switch>
								{{ computedItems[data.index] && computedItems[data.index].critical && computedItems[data.index].critical === true ? 'Sí' : 'No' }}</b-form-checkbox>
							</div>
						</template>
					</b-table>

					<!-- VALIDATION -->
					<b-alert
						show
						:variant="val_full ? 'success' : 'danger'"
						class="mb-0"
						:class="{
							'fs-09' : ui_size == 'sm',
							'fs-10' : ui_size == 'md',
							'fs-12' : ui_size == 'lg',
						}"
					>
						<p class="mb-0 text-size-10">
							<i class="fas fa-exclamation-circle mr-2"></i>
							<span>{{ val_full ?
										'Para continuar, presione el botón CREAR.' :
										'Complete los campos requeridos para continuar.'}}</span>
						</p>
					</b-alert>
				</div>

				<div v-else-if="result.screen == 'success'">
					<div class="text-center py-3">
						<i class="fas fa-check-circle text-success fs-50"></i>
						<p class="mb-2 mt-3 fw-bold fs-15">Regla Creada</p>
						<p class="mb-1 px-3 fs-13 text-muted">La regla fue registrada exitosamente.</p>
					</div>
				</div>

				<div v-else-if="result.screen == 'error'">
					<div class="text-center py-3">
						<i class="fas fa-xmark-circle text-danger fs-50"></i>
						<p class="mb-2 mt-3 fw-bold fs-15">Error Interno</p>
						<p class="mb-1 px-3 fs-13 text-muted">Ocurrió un problema al crear la regla.</p>
						<p class="mb-0 px-3 fs-13 text-muted">Por favor, verifique la información e inténtelo nuevamente.</p>
					</div>
				</div>

				<div v-else>
					<div class="text-center py-3">
						<i class="fas fa-xmark-circle text-danger fs-50"></i>
						<p class="mb-2 mt-3 fw-bold fs-15">Error Desconocido</p>
						<p class="mb-1 px-3 fs-13 text-muted">Ocurrió un problema inesperado. Por favor, contacte al Administrador del Sistema.</p>
					</div>
				</div>


				<!-- LOADING -->
				<template #overlay>
					<div class="text-center py-5">
						<b-spinner type="grow" variant="primary"></b-spinner>
					</div>
				</template>

			</b-overlay>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :disabled="loading" :size="ui_size">
					<span v-if="result.screen == 'main'">Cancelar</span>
					<span v-else>Cerrar</span>
				</b-button>
				<b-button @click="create()" variant="success" :disabled="loading||!val_full" :size="ui_size" v-if="result.screen == 'main'">CREAR</b-button>
			</template>

		</b-modal>
	</div>
</template>

<script>
import Helpers from '@/helpers';
export default {
	name: 'CreateStockRequirement',
	components: {
	},
	data() {
		return {
			reloading: false,
			result: {
				screen: 'main',
				message: '',
				number: '',
			},
			loading: false,
			query: {
				limit: 10,
				page: 1,
				sortDesc: false,
				sortBy: 'id',
				search: null,
				type: '',
			},
			paginationOptions: [
				{ value: 5, text: '5 por página' },
				{ value: 10, text: '10 por página' },
				{ value: 15, text: '15 por página' },
				{ value: 20, text: '20 por página' },
				{ value: 50, text: '50 por página' },
				{ value: 100, text: '100 por página' },
				{ value: 9999, text: 'Mostrar todo' },
			],
			fields: [
				{ key: 'code', label: 'Código', sortable: true },
				{ key: 'name', label: 'Descripción', sortable: true },
				{ key: 'stock', label: 'Stock Disponible', sortable: true },
				{ key: 'active', label: 'Activo', sortable: true },
			],
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'create-stock-requirement') { this.reset() }
		})
	},
	computed: {
		computedItems() {
			return this.new_stock_requirement.items && this.new_stock_requirement.items.length ? this.new_stock_requirement.items : []
		},
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		new_stock_requirement: {
			get() { return this.$store.getters.new_stock_requirement; },
			set(data) { this.$store.commit('new_stock_requirement', data); }
		},
		clients() { return this.$store.state.helpers.clients },
		warehouses() { return this.$store.state.helpers.warehouses },
		measurement_units() { return this.$store.state.helpers.measurement_units },
		warehouses_movement_types() { return this.$store.state.helpers.warehouses_movement_types },
		val_warehouse() {
			return this.new_stock_requirement.warehouse_id != null ? true : false
		},
		val_type() {
			return this.new_stock_requirement.type_id != null ? true : false
		},
		val_client() {
			return this.new_stock_requirement.client_id != null ? true : false
		},
		val_items() {
			if (this.new_stock_requirement.items && this.new_stock_requirement.items.length > 0) {
				var result = true
				this.new_stock_requirement.items.forEach((item, index) => {
					if (typeof item.minimum == 'undefined' || item.minimum == null || item.minimum == '' || item.minimum == 0) result = false
				})
				return result
			} else {
				return false
			}
		},
		val_full() {
			return this.val_client && this.val_warehouse && this.val_items && this.val_non_existent_items ? true : false
		},
		val_non_existent_items() {
			if (this.new_stock_requirement.items && this.new_stock_requirement.items.length > 0) {
				var result = true
				this.new_stock_requirement.items.forEach((item, index) => {
					if ((typeof item.code == 'undefined' ||
								item.code == null ||
								item.code == '' ||
								item.code == 0)
						&& (item.description == null ||
								item.description == '')) result = false
				})
				return result
			} else {
				return false
			}
		},
	},
	methods: {
		reset() {
			this.result = {
				screen: 'main',
				message: '',
				number: '',
			}
			this.$store.commit('clear_new_stock_requirement')
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		create() {
			this.loading = true
			this.$api.post('stock_requirements', this.new_stock_requirement)
			.then(response => {
				this.loading = false
				//this.$bvModal.hide('create-purchase-request')
				this.$emit('update')
				this.result = {
					screen: 'success',
					message: '',
					number: response.data.number
				}
			})
			.catch(() => {
				this.loading = false
				this.result = {
					screen: 'error',
					message: ''
				}
			})
		},
		onlyNumbers(data) {
			return Helpers.onlyNumbers(data, true)
		},
		search() {
			this.load()
			this.$bvModal.show('select-product')
		},
		load() {
			this.$store.dispatch('products', this.query).then(() => { this.reloading = false })
		},
		reload() {
			this.reloading = true
			this.load()
		},
		perPageChanged(limit) {
			this.query.limit = limit
			this.reload()
		},
		paginationChanged(page) {
			this.query.page = page
			this.reload()
		},
		sortingChanged(ctx) {
			this.query.sortDesc = ctx.sortDesc
			this.query.sortBy = ctx.sortBy
			this.reload()
		},
		discardProduct(data) {
			this.new_stock_requirement.items.splice(data.index, 1)
		},
		validateMinimum(data) {
			return data && data.minimum && data.minimum > 0 ? true : false
		},
		validateDescription(data) {
			return data && data.description && data.description.length > 0 ? true : false
		},
		addNonExistentItem() {
			if (this.measurement_units.data && this.measurement_units.data.length) {
				var unit = this.measurement_units.data.find((element) => element.code == 'UN')
			}
			var item = {
				line: null,
				description: null,
				code: null,
				type: null,
				minimum: null,
				critical: false,
				unit_id: 1,
				unit: unit,
				comment: null,
			}
			this.new_stock_requirement.items.push(item)
		}
	}
}
</script>