<template>
	<div class="mt-3">

		<SiteModuleHeader
			:showBack="true"
			:showHome="true"
			:showSize="true"
			:showTheme="true"
			:showFluid="true"
			:showTitle="true"
			@reload="reload"
			:reloading="reloading || resources.loading"
		></SiteModuleHeader>

		<b-row>
			<b-col sm="6">
				<b-card no-body bg-variant="primary" text-variant="white" class="mb-3">
					<b-card-body>
						<b-row>
							<b-col>
								<b-form-group label="Largo">
									<b-input-group append="MM">
										<b-input v-model="data.largo"></b-input>
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col>
								<b-form-group label="Ancho">
									<b-input-group append="MM">
										<b-input v-model="data.ancho"></b-input>
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col>
								<b-form-group label="Alto">
									<b-input-group append="MM">
										<b-input v-model="data.alto"></b-input>
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col>
								<b-form-group label="Peso Real">
									<b-input-group append="KG">
										<b-input v-model="data.peso"></b-input>
									</b-input-group>
								</b-form-group>
							</b-col>
						</b-row>
						<p class="mb-1 fs-13 fw-bold">Peso Volumétrico</p>
						<p class="mb-0 fs-15">{{ pesoVolumetrico }}</p>
					</b-card-body>
				</b-card>
				<b-alert show variant="success">
					<p class="mb-0 fs-15">
						<i class="fas fa-circle-info mr-2"></i>
						<span class="fw-bold">¿Cómo se calcula?</span>
					</p>
					<p class="mb-0 fs-12">El cobro se calcula sobre el mayor valor entre el <strong>peso volumétrico</strong>  vs <strong>peso real</strong>, para ejecutar el cálcul del peso volumétrico se deben tener  con la mayor exactitud posible las dimensiones L/A/H en Centímetros y se calcula <strong>PV = L * A * H / 4000</strong>.  En el ejemplo es mayor el peso real, por lo que ese valor se multiplica por el costo por kilo dependiendo del origen y el tramo. SCL-IQQ en el tramo de 501-1000 KGS.</p>
				</b-alert>
			</b-col>
			<b-col>
				<b-table
					:items="items"
					bordered
					striped hover
					small
					:fields="fields"
				>

				</b-table>
			</b-col>
		</b-row>

		
	</div>
</template>

<script>
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'

export default {
	name: 'ShippingCalculatorView',
	components: {
		SiteModuleHeader,
	},
	data() {
		return {
			reloading: false,
			query: {
				limit: 10,
				page: 1,
				sortDesc: true,
				sortBy: 'folio',
				search: null,
			},
			loaders: {
				resync: {
					active: false,
					index: null,
				}
			},
			fields: [
				{ key: 'field1', label: 'Origen', sortable: true },
				{ key: 'field2', label: 'Destino', sortable: true },
				{ key: 'field3', label: 'Tramo', sortable: true },
				{ key: 'field4', label: 'Tarifa Final', sortable: true },
				{ key: 'field5', label: 'Mínimo', sortable: true },
			],
			items: [
				{ field1: 'ANF', field2: 'IQQ', field3: '0-5', field4: ' $ 2.874', field5: ' $17.217' },
				{ field1: 'ANF', field2: 'IQQ', field3: '6-10', field4: ' $ 1.722', field5: ' $17.217' },
				{ field1: 'ANF', field2: 'IQQ', field3: '11-20', field4: ' $ 1.406', field5: ' $17.217' },
				{ field1: 'ANF', field2: 'IQQ', field3: '21-30', field4: ' $ 1.096', field5: ' $17.217' },
				{ field1: 'ANF', field2: 'IQQ', field3: '31-40', field4: ' $ 916', field5: ' $17.217' },
				{ field1: 'ANF', field2: 'IQQ', field3: '41-50', field4: ' $ 760', field5: ' $17.217' },
				{ field1: 'ANF', field2: 'IQQ', field3: '51-100', field4: ' $ 468', field5: ' $17.217' },
				{ field1: 'ANF', field2: 'IQQ', field3: '101-200', field4: ' $ 304', field5: ' $17.217' },
				{ field1: 'ANF', field2: 'IQQ', field3: '201-500', field4: ' $ 210', field5: ' $17.217' },
				{ field1: 'ANF', field2: 'IQQ', field3: '501-1000', field4: ' $ 173', field5: ' $17.217' },
				{ field1: 'ANF', field2: 'IQQ', field3: '1001-2000', field4: ' $ 146', field5: ' $17.217' },
				{ field1: 'ANF', field2: 'IQQ', field3: '2001-5000', field4: ' $ 115', field5: ' $17.217' },
				{ field1: 'ANF', field2: 'IQQ', field3: '5001-10000', field4: ' $ 97', field5: ' $17.217' },
				{ field1: 'ARI', field2: 'IQQ', field3: '0-5', field4: ' $ 2.825', field5: ' $16.922' },
				{ field1: 'ARI', field2: 'IQQ', field3: '6-10', field4: ' $ 1.692', field5: ' $16.922' },
				{ field1: 'ARI', field2: 'IQQ', field3: '11-20', field4: ' $ 1.355', field5: ' $16.922' },
				{ field1: 'ARI', field2: 'IQQ', field3: '21-30', field4: ' $ 1.047', field5: ' $16.922' },
				{ field1: 'ARI', field2: 'IQQ', field3: '31-40', field4: ' $ 873', field5: ' $16.922' },
				{ field1: 'ARI', field2: 'IQQ', field3: '41-50', field4: ' $ 721', field5: ' $16.922' },
				{ field1: 'ARI', field2: 'IQQ', field3: '51-100', field4: ' $ 437', field5: ' $16.922' },
				{ field1: 'ARI', field2: 'IQQ', field3: '101-200', field4: ' $ 273', field5: ' $16.922' },
				{ field1: 'ARI', field2: 'IQQ', field3: '201-500', field4: ' $ 179', field5: ' $16.922' },
				{ field1: 'ARI', field2: 'IQQ', field3: '501-1000', field4: ' $ 140', field5: ' $16.922' },
				{ field1: 'ARI', field2: 'IQQ', field3: '1001-2000', field4: ' $ 114', field5: ' $16.922' },
				{ field1: 'ARI', field2: 'IQQ', field3: '2001-5000', field4: ' $ 85', field5: ' $16.922' },
				{ field1: 'ARI', field2: 'IQQ', field3: '5001-10000', field4: ' $ 66', field5: ' $16.922' },
				{ field1: 'CPO', field2: 'IQQ', field3: '0-5', field4: ' $ 3.048', field5: ' $18.258' },
				{ field1: 'CPO', field2: 'IQQ', field3: '6-10', field4: ' $ 1.826', field5: ' $18.258' },
				{ field1: 'CPO', field2: 'IQQ', field3: '11-20', field4: ' $ 1.587', field5: ' $18.258' },
				{ field1: 'CPO', field2: 'IQQ', field3: '21-30', field4: ' $ 1.265', field5: ' $18.258' },
				{ field1: 'CPO', field2: 'IQQ', field3: '31-40', field4: ' $ 1.074', field5: ' $18.258' },
				{ field1: 'CPO', field2: 'IQQ', field3: '41-50', field4: ' $ 898', field5: ' $18.258' },
				{ field1: 'CPO', field2: 'IQQ', field3: '51-100', field4: ' $ 577', field5: ' $18.258' },
				{ field1: 'CPO', field2: 'IQQ', field3: '101-200', field4: ' $ 409', field5: ' $18.258' },
				{ field1: 'CPO', field2: 'IQQ', field3: '201-500', field4: ' $ 314', field5: ' $18.258' },
				{ field1: 'CPO', field2: 'IQQ', field3: '501-1000', field4: ' $ 285', field5: ' $18.258' },
				{ field1: 'CPO', field2: 'IQQ', field3: '1001-2000', field4: ' $ 262', field5: ' $18.258' },
				{ field1: 'CPO', field2: 'IQQ', field3: '2001-5000', field4: ' $ 230', field5: ' $18.258' },
				{ field1: 'CPO', field2: 'IQQ', field3: '5001-10000', field4: ' $ 206', field5: ' $18.258' },
				{ field1: 'IQQ', field2: 'IQQ', field3: '0-5', field4: ' $ 2.792', field5: ' $16.723' },
				{ field1: 'IQQ', field2: 'IQQ', field3: '6-10', field4: ' $ 1.672', field5: ' $16.723' },
				{ field1: 'IQQ', field2: 'IQQ', field3: '11-20', field4: ' $ 1.322', field5: ' $16.723' },
				{ field1: 'IQQ', field2: 'IQQ', field3: '21-30', field4: ' $ 1.011', field5: ' $16.723' },
				{ field1: 'IQQ', field2: 'IQQ', field3: '31-40', field4: ' $ 841', field5: ' $16.723' },
				{ field1: 'IQQ', field2: 'IQQ', field3: '41-50', field4: ' $ 693', field5: ' $16.723' },
				{ field1: 'IQQ', field2: 'IQQ', field3: '51-100', field4: ' $ 419', field5: ' $16.723' },
				{ field1: 'IQQ', field2: 'IQQ', field3: '101-200', field4: ' $ 252', field5: ' $16.723' },
				{ field1: 'IQQ', field2: 'IQQ', field3: '201-500', field4: ' $ 160', field5: ' $16.723' },
				{ field1: 'IQQ', field2: 'IQQ', field3: '501-1000', field4: ' $ 121', field5: ' $16.723' },
				{ field1: 'IQQ', field2: 'IQQ', field3: '1001-2000', field4: ' $ 93', field5: ' $16.723' },
				{ field1: 'IQQ', field2: 'IQQ', field3: '2001-5000', field4: ' $ 61', field5: ' $16.723' },
				{ field1: 'IQQ', field2: 'IQQ', field3: '5001-10000', field4: ' $ 43', field5: ' $16.723' },
				{ field1: 'LSC', field2: 'IQQ', field3: '0-5', field4: ' $ 3.160', field5: ' $18.928' },
				{ field1: 'LSC', field2: 'IQQ', field3: '6-10', field4: ' $ 1.893', field5: ' $18.928' },
				{ field1: 'LSC', field2: 'IQQ', field3: '11-20', field4: ' $ 1.702', field5: ' $18.928' },
				{ field1: 'LSC', field2: 'IQQ', field3: '21-30', field4: ' $ 1.376', field5: ' $18.928' },
				{ field1: 'LSC', field2: 'IQQ', field3: '31-40', field4: ' $ 1.178', field5: ' $18.928' },
				{ field1: 'LSC', field2: 'IQQ', field3: '41-50', field4: ' $ 991', field5: ' $18.928' },
				{ field1: 'LSC', field2: 'IQQ', field3: '51-100', field4: ' $ 648', field5: ' $18.928' },
				{ field1: 'LSC', field2: 'IQQ', field3: '101-200', field4: ' $ 479', field5: ' $18.928' },
				{ field1: 'LSC', field2: 'IQQ', field3: '201-500', field4: ' $ 379', field5: ' $18.928' },
				{ field1: 'LSC', field2: 'IQQ', field3: '501-1000', field4: ' $ 357', field5: ' $18.928' },
				{ field1: 'LSC', field2: 'IQQ', field3: '1001-2000', field4: ' $ 336', field5: ' $18.928' },
				{ field1: 'LSC', field2: 'IQQ', field3: '2001-5000', field4: ' $ 300', field5: ' $18.928' },
				{ field1: 'LSC', field2: 'IQQ', field3: '5001-10000', field4: ' $ 279', field5: ' $18.928' },
				{ field1: 'SCL', field2: 'IQQ', field3: '0-5', field4: ' $ 3.191', field5: ' $19.116' },
				{ field1: 'SCL', field2: 'IQQ', field3: '6-10', field4: ' $ 1.912', field5: ' $19.116' },
				{ field1: 'SCL', field2: 'IQQ', field3: '11-20', field4: ' $ 1.804', field5: ' $19.116' },
				{ field1: 'SCL', field2: 'IQQ', field3: '21-30', field4: ' $ 1.489', field5: ' $19.116' },
				{ field1: 'SCL', field2: 'IQQ', field3: '31-40', field4: ' $ 1.285', field5: ' $19.116' },
				{ field1: 'SCL', field2: 'IQQ', field3: '41-50', field4: ' $ 1.092', field5: ' $19.116' },
				{ field1: 'SCL', field2: 'IQQ', field3: '51-100', field4: ' $ 730', field5: ' $19.116' },
				{ field1: 'SCL', field2: 'IQQ', field3: '101-200', field4: ' $ 568', field5: ' $19.116' },
				{ field1: 'SCL', field2: 'IQQ', field3: '201-500', field4: ' $ 469', field5: ' $19.116' },
				{ field1: 'SCL', field2: 'IQQ', field3: '501-1000', field4: ' $ 416', field5: ' $19.116' },
				{ field1: 'SCL', field2: 'IQQ', field3: '1001-2000', field4: ' $ 400', field5: ' $19.116' },
				{ field1: 'SCL', field2: 'IQQ', field3: '2001-5000', field4: ' $ 370', field5: ' $19.116' },
				{ field1: 'SCL', field2: 'IQQ', field3: '5001-10000', field4: ' $ 348', field5: ' $19.116' },
			],
			data: {
				alto: 0,
				ancho: 0,
				largo: 0,
				peso: 0,
			}
		}
	},
	computed: {
		pesoVolumetrico() {
			return (this.data.alto * this.data.ancho * this.data.largo) / 4000
		},
		new_pricing: {
			get() { return this.$store.getters.new_pricing; },
			set(data) { this.$store.commit('new_pricing', data); }
		},
		client() { return this.$store.state.core.account.client },
		profile() { return this.$store.state.core.account.profile },
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		resources() { return this.$store.state.defontana_pricings },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
	},
	mounted() {
		this.load()
	},
	created() {
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
		load() {
			this.$store.dispatch('defontana_pricings', this.query).then(() => { this.reloading = false })
		},
		reload() {
			this.reloading = true
			this.load()
		},
	}
}
</script>

