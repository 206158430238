<template>
	<div>
		<b-overlay
			:show="loading"
			rounded="sm"
			:class="{
				'mr-2' : !mobile,
				'mb-2' : ui_size == 'sm',
				'mb-3' : ui_size == 'md',
				'mb-4' : ui_size == 'lg',
			}"
			:variant="ui_theme == 'light' ? 'light' : 'dark'"
		>
			<template #overlay>
				<div class="text-center">
					<b-spinner type="grow" variant="primary" small></b-spinner>
				</div>
			</template>
			<b-button
				:variant="ui_theme == 'light' ? 'primary' : 'outline-secondary'"
				:size="ui_size"
				@click="clicked()"
				:disabled="disabled"
				:block="mobile"
				v-b-tooltip.hover.bottomright
				:title="!mobile ? title : null"
			>
				<i
					v-if="icon.length"
					:class="current_icon"
				></i>
				<span>{{ text }}</span>
			</b-button>
		</b-overlay>
	</div>
</template>

<script>
export default {
	name: 'SitePrimaryButton',
	props: {
		loading: {
			type: Boolean,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		text: {
			type: String,
			required: false,
			default: '',
		},
		title: {
			type: String,
			required: false,
			default: '',
		},
		icon: {
			type: String,
			required: false,
			default: 'bug'
		},
		icon_style: {
			type: String,
			required: false,
			default: 'solid'
		}
	},
	computed: {
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		screen_size() { return this.$store.getters.screen_size },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		has_icon() { return this.icon.length > 0 ? true : false },
		current_icon() {
			var icon = 'fa-' + this.icon
			var style = 'fa-' + this.icon_style
			return this.text.length > 0 ? ['mr-1', icon, style].join(' ') : [icon, style].join(' ')
		}
	},
	methods: {
		clicked() {
			this.$emit('clicked')
		}
	}
}
</script>
