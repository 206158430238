export default {
    truncateText: function(limit, text) {
		return text ? text.length > limit ? text.substring(0, limit + 5) + '...' : text : ''
	},
    parseCurrency: function(number, decimal = false, reverse = false) {
		if (typeof number == 'undefined' || number == null || number == 0 || number.length == 0) return 0
		var data = typeof number != 'string' ? number.toString().split('.') : number.split('.')
		if (data.length == 2) {
			var rounded = Math.round(data[1] / (10 ** (data[1].length - 2)))
			var kSymbol = reverse === false ? '.' : ','
			var dSymbol = reverse === false ? ',' : '.'
			var onlyInteger = data[0].replace(/\B(?=(\d{3})+(?!\d))/g, kSymbol)
			if (rounded == 0) { rounded = '00' } else { rounded = rounded.toString()}
			return decimal === false ? onlyInteger.toString() : onlyInteger.toString()+dSymbol+rounded
		} else {
			var rounded2 = '00'
			var kSymbol2 = reverse === false ? '.' : ','
			var dSymbol2 = reverse === false ? ',' : '.'
			var onlyInteger2 = data[0].replace(/\B(?=(\d{3})+(?!\d))/g, kSymbol2)
			return decimal === false ? onlyInteger2.toString() : onlyInteger2.toString()+dSymbol2+rounded2
		}
	},
    parseMoney: function(amount) {
		if (amount == 0) return 0
		if (amount < 1) return amount.replace('.',',')
		if (amount == 1 ) return 1
		if (amount > 1) {
			var number = Math.round(amount).toString()
			return number.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
		}
		else {
			return 0
		}
	},
    parseNumber: function(number) {
		if (typeof number == 'undefined') return 0
		if (number == null) return 0
		if (number == 0) return 0
		if (number < 1) return number.replace('.',',')
		if (number == 1 ) return 1
		if (number > 1) {
			var data = Math.round(number).toString()
			return data.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
		}
		else {
			return 0
		}
	},
	parseIndicator: function (value) {
		if (value == 0) return 0
		if (value < 1) return value.replace('.',',')
		if (value == 1 ) return 1
		if (value > 1) {
			var number = value.split('.')
			return number[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')+','+number[1]
		}
		else {
			return 0
		}
	},
	parseVATIN: function(vatin) {
		return vatin.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+vatin.substr(vatin.length-1)
	},
	validateEmail: function(email) {
		var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return email == null ? null : email.length > 0 && regex.test(email) ? true : false
	},
	validateVATIN: function (vatin) {
		if (vatin == null) return null
		if (vatin.length < 8 || !/^[0-9]+[0-9kK]{1}$/.test(vatin)) return false
		var dv = vatin.slice(-1)
		var rut = vatin.length == 9 ? vatin.slice(0,8) : vatin.slice(0,7)
		if ( dv == 'K' ) dv = 'k'
		var M=0,S=1; for(;rut;rut=Math.floor(rut/10)) { S=(S+rut%10*(9-M++%6))%11; }
		var real_dv = S?S-1:'k'
		return (real_dv == dv)
	},
	onlyNumbers: function(value, decimal = false) {
		if (value.length <= 20) return value.replace(/[^0-9.]/g,'')
		return 0
	},
	cleanVATIN: function (value) {
		return value.replace(/[^0-9kK]/g,'');
	},
}