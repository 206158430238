<template>
	<div class="mt-3">

		<SiteModuleHeader
			:showBack="true"
			:showHome="true"
			:showSize="true"
			:showTheme="true"
			:showFluid="true"
			:showTitle="true"
			:showReload="false"
		></SiteModuleHeader>

		<p class="text-dark fs-15 mt-0 mb-3">Julio 2023</p>

		<b-card no-body class="mb-5">
			<b-card-body class="p-1">
				<img src="@/assets/organigrama.svg" class="w-100" />
			</b-card-body>
		</b-card>

	</div>
</template>

<script>
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
export default {
	name: 'OrganizationView',
	components: {
		SiteModuleHeader,
	},
	data: function() {
		return {
		}
	},
	mounted() {
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		getters() { return this.$store.getters },
		//////-----------------------------------------------------------
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
	}
}
</script>
