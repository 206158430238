<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="create-job"
			title="Nueva Orden"
			:size="ui_size == 'sm' ? 'lg' : 'xl'"
			body-class="p-0"
			:title-class="{
				'fs-11' : ui_size == 'sm',
				'fs-13' : ui_size == 'md',
				'fs-15' : ui_size == 'lg',
			}"
			:header-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:header-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:body-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:body-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:footer-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:footer-text-variant="ui_theme == 'dark' ? 'light' : ''"
		>

			<code v-if="0">{{ job }}</code>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-4" :variant="ui_theme == 'dark' ? 'dark': 'light'">

				<b-row>

					<!-- TYPE -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								Tipo de Orden
							</template>
							<b-select
								v-model="job.type_id"
								:disabled="helpers_job_types.loading"
								:state="val_type"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							>
								<b-select-option :value="null" disabled>Presione aquí</b-select-option>
								<b-select-option v-for="(item, i) in helpers_job_types.data" :key="i" :value="item.id">{{ item.name }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					
					<!-- CLIENT -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								<b-row>
									<b-col>
										Cliente
									</b-col>
									<b-col sm="auto">
										<b-button
											@click="$bvModal.show('create-client')"
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-success'"
										>
											<i class="fas fa-plus mr-1"></i>
											<span>Nuevo Cliente</span>
										</b-button>
									</b-col>
								</b-row>
							</template>
							<b-select
								v-model="job.client_id"
								:disabled="clients.loading"
								:state="val_client"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
								@change="reloadClientSites()"
							>
								<b-select-option :value="null" disabled>Presione aquí</b-select-option>
								<b-select-option v-for="(item, i) in clients.data" :key="i" :value="item.id">{{ item.name }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

					<!-- SITE -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
								'text-muted' : job.client_id == null,
							}"
						>
							<template #label>
								<b-row>
									<b-col>
										Sucursal
									</b-col>
									<b-col sm="auto">
										<b-button
											@click="$bvModal.show('create-site')"
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-success'"
										>
											<i class="fas fa-plus mr-1"></i>
											<span>Nueva Sucursal</span>
										</b-button>
									</b-col>
								</b-row>
							</template>
							<b-select
								v-model="job.site_id"
								:disabled="job.client_id == null || clients.loading || client_sites.loading"
								:state="val_site"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
								@change="getSiteUnits()"
							>
								<b-select-option :value="null" disabled>
									<span v-if="job.client_id != null">Presione aquí</span>
									<span v-else>Seleccione primero un cliente</span>
								</b-select-option>
								<b-select-option
									v-for="(item, i) in client_sites.data"
									:key="i"
									:value="item.id"
								>{{ item.address }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

				</b-row>

				<!-- VALIDATION -->
				<b-alert
					show
					:variant="val_full ? 'success' : 'danger'"
					class="mb-0"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-12' : ui_size == 'lg',
					}"
				>
					<p class="mb-0 text-size-10">
						<span v-if="site_units.loading == true">
							<b-spinner small variant="light"></b-spinner>
						</span>
						<span v-else>
							<i class="fas fa-exclamation-circle mr-2"></i>
						</span>
						<span v-if="site_units.loading == true">Cargando</span>
						<span v-else-if="val_full">Para continuar, presione el botón CREAR.</span>
						<span v-else>
							<span v-if="val_client && val_site && !site_units.loading && !site_units.data.length">La sucursal seleccionada no registra unidades.</span>
							<span v-else-if="val_client && val_site && site_units.data.length && !val_units">Seleccione al menos una unidad para continuar.</span>
							<span v-else-if="val_client && val_site && site_units.data.length && val_units">Complete todos los campos requeridos para continuar.</span>
							<span v-else>Complete los campos requeridos para continuar.</span>
						</span>
					</p>
				</b-alert>

				<b-card no-body v-if="site_units.data.length" class="mt-3">
					<b-table
						:items="site_units.data"
						:fields="fields"
						small
						hover
						class="mb-0"
						select-mode="multi"
						ref="selectableTable"
						selectable
						responsive
						:head-variant="ui_theme == 'light' ? 'light' : 'dark'"
						:table-variant="ui_theme == 'light' ? 'light' : 'dark'"
						selected-variant="success"
						@row-selected="onRowSelected"
					>
						<template #cell(selected)="{ rowSelected }">
							<p class="mb-0 text-center">
								<span v-if="rowSelected === true">
									<i class="fas fa-check-circle text-success"></i>
								</span>
							</p>
						</template>
					</b-table>
				</b-card>

				<!-- LOADING -->
				<template #overlay>
					<div class="text-center py-5">
						<b-spinner type="grow" variant="primary"></b-spinner>
					</div>
				</template>

			</b-overlay>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :disabled="loading" :size="ui_size">Cancelar</b-button>
				<b-button @click="create()" variant="success" :disabled="loading||!val_full" :size="ui_size">CREAR</b-button>
			</template>

		</b-modal>
	</div>
</template>

<script>
import Helpers from '@/helpers';
export default {
	name: 'CreateJob',
	components: {
	},
	data() {
		return {
			loading: false,
			job: {
				type_id: null,
				client_id: null,
				site_id: null,
				units: [],
			},
			fields: [
				{ key: 'selected', label: 'Incluir', sortable: true },
				{ key: 'number', label: 'Equipo', sortable: true },
				{ key: 'location_interior', label: 'Ubicación', sortable: true },
				{ key: 'model.brand.name', label: 'Marca', sortable: true },
				{ key: 'model.part_number', label: 'Modelo', sortable: true }
			],
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'create-job') { this.reset() }
		})
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		clients() { return this.$store.state.helpers.clients },
		client_sites() { return this.$store.state.client_sites },
		site_units() { return this.$store.state.site_units },
		helpers_job_types() { return this.$store.state.helpers.job_types },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		val_client() {
			return this.job.client_id != null ? true : false
		},
		val_type() {
			return this.job.type_id != null ? true : false
		},
		val_site() {
			return this.job.site_id != null ? true : false
		},
		val_units() {
			return this.job.units.length > 0 ? true : false
		},
		val_full() {
			return this.val_type && this.val_client && this.val_site && this.val_units ? true : false
		},
		hvac_client_id: {
			get() { return this.$store.getters.hvac_client_id },
			set(data) { this.$store.commit('hvac_client_id', data) }
		},
	},
	methods: {
		reset() {
			this.job = {
				type_id: null,
				client_id: null,
				site_id: null,
				units: [],
			}
			this.$store.commit('reset_site_units')
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		create() {
			this.loading = true
			this.$api.post('jobs', this.job)
			.then(response => {
				this.loading = false
				this.$bvModal.hide('create-job')
				this.$emit('update')
				this.toast(true, 'Se creó la OT-'+response.data.id+' correctamente.')
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		reloadClientSites() {
			this.job.site_id = null
			this.$emit('new_client_id', this.job.client_id)
			this.$store.dispatch('client_sites', this.job.client_id)
		},
		reloadBrandModels() {
			this.job.model_id = null
			this.$emit('new_brand_id', this.job.brand_id)
			this.$store.dispatch('brand_models', this.job.brand_id)
		},
		onlyNumbers(data) {
			return Helpers.onlyNumbers(data)
		},
		getSiteUnits() {
			this.job.units = []
			this.$store.dispatch('site_units', this.job.site_id)
		},
		onRowSelected(data) {
			this.job.units = data
		}
	}
}
</script>