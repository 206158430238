<template>
	<div class="mt-4">
		<b-container>
			<b-row>
				<b-col>
					<b-alert show variant="light">
						<p class="mb-0 fs-13 fw-bold">Requisiciones</p>
						<p class="mb-0 fs-12">Vista de revisión / confirmación en espera de validación.</p>
						<code>token: {{ token }}</code>
					</b-alert>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
export default {
	name: 'DirectPurchaseRequest',
	components: {
	},
	data() {
		return {
		}
	},
	mounted() {
	},
	created() {
	},
	computed: {
		token() {
			return this.$route.params.token
		},
	},
	methods: {
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay,
				toaster: 'b-toaster-top-center'
			})
		},
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
	}
}
</script>

