<template>
	<div class="login-sidebar">
		<div class="mb-4">
			<img src="@/assets/logo_single_color.svg" style="height: 35px" class="mb-3">
			<p class="mb-0 text-size-18 text-strong">Sistema Intranet</p>
		</div>

		<p v-if="invalid">
			<b-button size="sm" variant="light" @click="goTo('Login')">
				<i class="fas fa-arrow-left mr-2"></i>
				<span>Volver</span>
			</b-button>
		</p>

		<b-alert v-if="checking || invalid || success" show :variant="checking ? 'secondary' : invalid ? 'danger' : success ? 'success' : 'dark'" class="text-center pt-4 pb-4 mb-4">
			<p>
				<span v-if="checking">
					<b-spinner type="grow" variant="dark"></b-spinner>
				</span>
				<span v-else-if="invalid">
					<i class="fas fa-circle-exclamation text-size-30"></i>
				</span>
				<span v-else-if="success">
					<i class="fas fa-check-circle text-size-30"></i>
				</span>
			</p>
			<h4 class="mt-3">{{ checking ? 'Verificando Enlace' : invalid ? 'Enlace Inválido' : success ? 'Lorem Ipsum' : '***' }}</h4>
			<p class="text-size-11 mb-0">{{ checking ? 'Por favor, espere un momento.' : invalid ? error_message : success ? 'Dolor sit amet.' : '***' }}</p>
		</b-alert>

		<div id="footer" class="mt-4">
			<span class="copyright text-secondary">Desarrollado por</span>
			<b-img src="/img/logo_ubidata_gray.svg" class="logo ml-1"></b-img>
		</div>
	</div>
</template>

<script>
import io from 'socket.io-client'
export default {
	name: 'DirectAccess',
	components: {
	},
	data() {
		return {
			checking: true,
			invalid: false,
			loading: false,
			success: false,
			error_message: 'El enlace de recuperación utilizado no es válido.'
		}
	},
	mounted() {
	},
	created() {
		//this.$store.commit('destroy_session')
		this.$store.dispatch('validate_direct', this.token)
		.then(response => {
			var priv = io(this.websocketURL + '/priv', { auth: { token: this.websocket.token } });
			priv.on('connect', () => {
				this.websocket.online = true;
			})
			priv.on('event', data => {
				var number = data.object && data.object.number ? data.object.number : data.object.folio
				var user = data.user != null ? data.user.name +' '+ data.user.lastname : 'el sistema automáticamente.'
				this.toast(
					true,
					'Nuevo Evento',
					data.channel.noun_singular +' N°'+ number +' '+ data.type.verb_singular +' por '+ user +'.',
					5000
				)
			})
			priv.on('disconnect', () => {
				this.websocket.online = false;
			})
			this.$store.dispatch('session_check')
			if (response.data.account.profile.name == 'management') {
				this.goTo('Home')
			}
			else if (response.data.account.profile.name == 'technician') {
				this.goTo('HomeTechnichian')
			}
			else if (response.data.account.profile.name == 'client') {
				this.goTo('HomeClient')
			}
			this.checking = false
		})
		.catch(error => {
			this.checking = false
			this.invalid = true
			if (error.response.data.message) this.error_message = error.response.data.message
		})
	},
	computed: {
		websocket: {
			get() { return this.$store.getters.websocket; },
			set(data) { this.$store.commit('websocket', data); }
		},
		websocketURL() { return process.env.VUE_APP_WEBSOCKET },
		access: {
			get() { return this.$store.getters.access },
			set(data) { this.$store.commit('access', data) }
		},
		token() {
			return this.$route.params.token
		},
	},
	methods: {
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay,
				toaster: 'b-toaster-top-center'
			})
		},
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
	}
}
</script>

<style scoped>
.login-box {
	width: 320px;
	margin: 50px auto 0px;
}
</style>
