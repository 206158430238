var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":"create-corporate-template-group","title":"Nuevo Grupo de Plantillas","size":_vm.ui_size == 'sm' ? 'md' : 'md',"body-class":"p-0","title-class":{
			'fs-11' : _vm.ui_size == 'sm',
			'fs-13' : _vm.ui_size == 'md',
			'fs-15' : _vm.ui_size == 'lg',
		},"header-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"header-text-variant":_vm.ui_theme == 'dark' ? 'light' : '',"body-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"body-text-variant":_vm.ui_theme == 'dark' ? 'light' : '',"footer-bg-variant":_vm.ui_theme == 'dark' ? 'primary' : '',"footer-text-variant":_vm.ui_theme == 'dark' ? 'light' : ''},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ close }){return [_c('b-button',{attrs:{"variant":"outline-secondary","disabled":_vm.loading,"size":_vm.ui_size},on:{"click":function($event){return close()}}},[_vm._v("Cancelar")]),_c('b-button',{attrs:{"variant":"success","disabled":_vm.loading||!_vm.val_full,"size":_vm.ui_size},on:{"click":function($event){return _vm.create()}}},[_vm._v("CREAR")])]}}])},[_c('b-overlay',{staticClass:"p-4",attrs:{"show":_vm.loading,"rounded":"sm","variant":_vm.ui_theme == 'dark' ? 'dark': 'light'},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center py-5"},[_c('b-spinner',{attrs:{"type":"grow","variant":"primary"}})],1)]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-1' : _vm.ui_size == 'md',
							'fs-12 pb-2' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nombre ")]},proxy:true}])},[_c('b-input',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"state":_vm.val_name,"size":_vm.ui_size},model:{value:(_vm.group.name),callback:function ($$v) {_vm.$set(_vm.group, "name", $$v)},expression:"group.name"}}),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-0' : _vm.ui_size == 'md',
							'fs-12 pb-0' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_icon ? '' : 'Opcional (hasta 100 caracteres)'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Icono ")]},proxy:true}])},[_c('b-input',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"maxlength":20,"state":_vm.val_icon,"size":_vm.ui_size},model:{value:(_vm.group.icon),callback:function ($$v) {_vm.$set(_vm.group, "icon", $$v)},expression:"group.icon"}}),_c('b-form-invalid-feedback',[_vm._v("Requerido")]),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{class:{
							'fs-09 pb-0' : _vm.ui_size == 'sm',
							'fs-10 pb-0' : _vm.ui_size == 'md',
							'fs-12 pb-0' : _vm.ui_size == 'lg',
						},attrs:{"label-class":"fw-bold","description":_vm.val_description ? '' : 'Opcional (hasta 100 caracteres)'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Descripción ")]},proxy:true}])},[_c('b-input',{class:{
								'bg-primary text-white' : _vm.ui_theme == 'dark',
							},attrs:{"maxlength":100,"state":_vm.val_description,"size":_vm.ui_size},model:{value:(_vm.group.description),callback:function ($$v) {_vm.$set(_vm.group, "description", $$v)},expression:"group.description"}}),_c('b-form-valid-feedback',[_vm._v("Correcto")])],1)],1)],1),_c('b-alert',{staticClass:"mb-0",class:{
					'fs-09' : _vm.ui_size == 'sm',
					'fs-10' : _vm.ui_size == 'md',
					'fs-12' : _vm.ui_size == 'lg',
				},attrs:{"show":"","variant":_vm.val_full ? 'success' : 'danger'}},[_c('p',{staticClass:"mb-0 text-size-10"},[_c('i',{staticClass:"fas fa-exclamation-circle mr-2"}),_c('span',[_vm._v(_vm._s(_vm.val_full ? 'Para continuar, presione el botón CREAR.' : 'Complete los campos requeridos para continuar.'))])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }