<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="create-contract"
			title="Nuevo Contrato"
			:size="ui_size == 'sm' ? 'md' : 'lg'"
			body-class="p-0"
			:title-class="{
				'fs-11' : ui_size == 'sm',
				'fs-13' : ui_size == 'md',
				'fs-15' : ui_size == 'lg',
			}"
			:header-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:header-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:body-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:body-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:footer-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:footer-text-variant="ui_theme == 'dark' ? 'light' : ''"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-4" :variant="ui_theme == 'dark' ? 'dark': 'light'">

				<b-row>

					<!-- CLIENT -->
					<b-col sm="8">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								Seleccione Cliente
							</template>
							<b-select
								v-model="contract.client_id"
								:disabled="clients.loading"
								:state="val_client"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
								@change="reloadClientContacts()"
							>
								<b-select-option :value="null" disabled>Presione aquí</b-select-option>
								<b-select-option v-for="(item, i) in clients.data" :key="i" :value="item.id">{{ item.name }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								Cliente no existe:
							</template>
							<b-button
								@click="$bvModal.show('create-client')"
								block
								:size="ui_size"
								:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-success'"
							>
								<i class="fas fa-plus mr-1"></i>
								<span>Nuevo Cliente</span>
							</b-button>
						</b-form-group>
					</b-col>

					<!-- CONTACT -->
					<b-col sm="8">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
								'text-muted' : contract.client_id == null,
							}"
						>
							<template #label>
								Seleccione Contacto
							</template>
							<b-select
								v-model="contract.contact_id"
								:disabled="contract.client_id == null || clients.loading || client_contacts.loading"
								:state="val_contact"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							>
								<b-select-option :value="null" disabled>
									<span v-if="contract.client_id != null">Presione aquí</span>
									<span v-else>Seleccione primero un cliente</span>
								</b-select-option>
								<b-select-option v-for="(item, i) in client_contacts.data" :key="i" :value="item.id">{{ item.name }} {{ item.lastname }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
								'text-muted' : contract.client_id == null,
							}"
						>
							<template #label>
								Contacto no existe:
							</template>
							<b-button
								@click="$bvModal.show('create-client-contact')"
								block
								:disabled="contract.client_id == null"
								:size="ui_size"
								:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-success'"
							>
								<i class="fas fa-plus mr-1"></i>
								<span>Nuevo Contacto</span>
							</b-button>
						</b-form-group>
					</b-col>

					<!-- START DATE -->
					<b-col sm="6">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								Fecha de Inicio de Contrato
							</template>
							<b-form-datepicker
								v-model="contract.start_date"
								:date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
								:max="contract.end_date"
								placeholder="Presione aquí"
								:state="val_start"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							></b-form-datepicker>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

					<!-- END DATE -->
					<b-col sm="6">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								Fecha de Término de Contrato
							</template>
							<b-form-datepicker
								v-model="contract.end_date"
								:date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
								:min="contract.start_date"
								placeholder="Presione aquí"
								:state="val_end"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							></b-form-datepicker>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					
					<!-- TITLE -->
					<b-col sm="12">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								Título / Nombre del Contrato
							</template>
							<b-input
								v-model="contract.title"
								:state="val_title"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

				</b-row>

				<!-- VALIDATION -->
				<b-alert
					show
					:variant="val_full ? 'success' : 'danger'"
					class="mb-0"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-12' : ui_size == 'lg',
					}"
				>
					<p class="mb-0 text-size-10">
						<i class="fas fa-exclamation-circle mr-2"></i>
						<span>{{ val_full ?
									'Para continuar, presione el botón CREAR.' :
									'Complete los campos requeridos para continuar.'}}</span>
					</p>
				</b-alert>

				<!-- LOADING -->
				<template #overlay>
					<div class="text-center py-5">
						<b-spinner type="grow" variant="primary"></b-spinner>
					</div>
				</template>

			</b-overlay>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :disabled="loading" :size="ui_size">Cancelar</b-button>
				<b-button @click="create()" variant="success" :disabled="loading||!val_full" :size="ui_size">CREAR</b-button>
			</template>

		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'CreateContract',
	components: {
	},
	data() {
		return {
			loading: false,
			contract: {
				title: '',
				client_id: null,
				contact_id: null,
				start_date: '',
				end_date: '',
			},
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'create-contract') { this.reset() }
		})
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		clients() { return this.$store.state.helpers.clients },
		client_contacts() { return this.$store.state.client_contacts },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		val_client() {
			return this.contract.client_id != null ? true : false
		},
		val_contact() {
			return this.contract.contact_id != null ? true : false
		},
		val_start() {
			return this.contract.start_date && this.contract.start_date.length > 0 ? true : false
		},
		val_end() {
			return this.contract.end_date && this.contract.end_date.length > 0 ? true : false
		},
		val_title() {
			return this.contract.title && this.contract.title.length > 0 ? true : false
		},
		val_full() {
			return this.val_client && this.val_contact && this.val_start && this.val_end && this.val_title ? true : false
		},
	},
	methods: {
		reset() {
			this.contract = {
				client_id: null,
				contact_id: null,
				start_date: '',
				end_date: '',
			}
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		create() {
			this.loading = true
			this.$api.post('contracts', this.contract)
			.then(response => {
				this.loading = false
				this.$bvModal.hide('create-contract')
				this.$emit('update')
				this.toast(true, 'Se creó el Contrato N°'+response.data.number+' correctamente.')
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		reloadClientContacts() {
			this.contract.contact_id = null
			this.$emit('new_client_id', this.contract.client_id)
			this.$store.dispatch('client_contacts', this.contract.client_id)
		}
	}
}
</script>