<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="select-product"
			title="Seleccionar Producto"
			:size="ui_size == 'sm' ? 'lg' : 'xl'"
			body-class="p-0"
			:title-class="{
				'fs-11' : ui_size == 'sm',
				'fs-13' : ui_size == 'md',
				'fs-15' : ui_size == 'lg',
			}"
			:header-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:header-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:body-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:body-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:footer-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:footer-text-variant="ui_theme == 'dark' ? 'light' : ''"
		>

			<!-- ITEMS -->
			<b-overlay :show="loading" rounded="sm" class="p-4" :variant="ui_theme == 'dark' ? 'dark': 'light'">

				<b-row>
					<b-col>
						<p class="fs-12 fw-bold mb-1" :class="{ 'text-danger' : items.length == 0, 'text-success' : items.length >= 1 }">
							<i class="fas fa-check mr-2"></i>
							<span>Producto Seleccionado</span>
						</p>
					</b-col>
					<b-col class="text-right mb-2">
						<b-button @click="finish()" variant="success" :disabled="loading||!val_full||!items.length" size="sm">
							<i class="fas fa-check mr-1"></i>
								<span>Seleccionar</span>
						</b-button>
					</b-col>
				</b-row>
				<b-table
					id="table-transition-example"
					:items="items"
					:fields="[
						{ key: 'discard', label: '', sortable: false },
						{ key: 'code', label: 'Código', sortable: false },
						{ key: 'name', label: 'Descripción', sortable: false },
						{ key: 'unit', label: 'Unidad', sortable: false },
					]"
					show-empty
					:stacked="mobile"
					hover
					bordered
					:small="ui_size == 'sm' || false"
					responsive
					tbody-tr-class="add-row-animation"
					sort-icon-left
					:head-variant="ui_theme == 'light' ? 'light' : 'dark'"
					class="mt-0 mb-4 rounded"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
						'table-dark' : ui_theme == 'dark',
					}"
				>
					<template #table-colgroup="scope">
						<col
							v-for="field in scope.fields"
							:key="field.key"
							:style="{
								width: field.key === 'discard' ? '5%' :
										field.key === 'code' ? '20%' : '75%'
							}"
						>
					</template>
					<template v-slot:empty>
						<div>
							<span class="text-danger">Seleccione un producto de los resultados de búsqueda.</span>
						</div>
					</template>
					<template v-slot:head(discard)>
						<div class="text-center">
							<i class="fas fa-fw fa-trash"></i>
						</div>
					</template>
					<template v-slot:cell(discard)="data">
						<div class="text-center" @click="discardProduct(data)" role="button" title="Descartar Ítem">
							<i class="fas fa-circle-xmark text-danger"></i>
						</div>
					</template>
					<template v-slot:cell(name)="data">
						<div>
							<span v-if="data.item.name && data.item.name.length > 0">{{ data.item.name }}</span>
							<span v-else-if="data.item.description && data.item.description.length > 0">{{ data.item.description }}</span>
							<span v-else>{{ data.value }}</span>
						</div>
					</template>
					<template v-slot:cell(unit)="data">
						<div class="text-center">
							{{ data.value.code }}
						</div>
					</template>
				</b-table>


				<!-- SEARCH -->
				<p class="fs-12 fw-bold mb-1 text-primary">
					<i class="fas fa-search mr-2"></i>
					<span>Búsqueda</span>
				</p>
				<b-card no-body>
					<b-card-body class="py-0 px-3 bg-light">
						<b-row>
							<b-col class="py-3">
								<b-overlay
									:show="resources.loading"
									rounded
									opacity="0.6"
								>
									<template #overlay>
										<span>&nbsp;</span>
									</template>
									<b-input-group :size="ui_size">
										<b-input
											v-model="query.search"
											placeholder="Escriba un código o palabra"
											@changed="searchChanged"
											@blur="searchBlur()"
											@keyup.delete="searchClear()"
											@keydown.enter="search()"
											v-bind:class="{
												'bg-white text-primary' : true,
												'border-primary' : search != null,
												'border-0' : search == null,
											}"
										></b-input>
										<template #append>
											<b-button
												title="Presione para buscar"
												:disabled="query.search == null || query.search.length == 0"
												:variant="query.search != null && query.search.length > 0 ? 'primary' : 'outline-primary'"
												@click="search()"
											>
												<i class="fas fa-search mr-1"></i>
												<span>Buscar</span>
											</b-button>
										</template>
									</b-input-group>
								</b-overlay>
							</b-col>
						</b-row>
					</b-card-body>
					<hr class="mt-0">

					<!-- RESULTS -->
					<b-card-body class="pt-0 pb-0 px-3">
						<b-row no-gutters>

							<b-col class="pt-1">
								<p class="mb-0 fw-bold fs-11">Resultados de Búsqueda</p>
								<p class="mb-0 fs-10">{{ parseNumber(products.data.total) }} producto<span v-if="products.data && products.data.total && products.data.total > 1">s</span> encontrado<span v-if="products.data && products.data.total && products.data.total > 1">s</span></p>
							</b-col>
							
							<b-col md="12" lg="auto" xl="auto">
								<p class="mb-2 text-dark"
									:class="{
										'fs-09' : ui_size == 'sm',
										'fs-10' : ui_size == 'md',
										'fs-13' : ui_size == 'lg',
									}">
									<span>Mostrar</span>
									<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
								</p>
								<b-row no-gutters>
									<b-col sm="auto">
										<SitePerPage
											:loading="resources.loading"
											:limit="query.limit"
											:options="paginationOptions"
											@clicked="perPageChanged"
										></SitePerPage>
									</b-col>
								</b-row>
							</b-col>

							<!-- PER PAGE -->
							<b-col md="12" lg="auto" xl="auto">
								<p class="mb-2 text-dark"
									:class="{
										'fs-09' : ui_size == 'sm',
										'fs-10' : ui_size == 'md',
										'fs-13' : ui_size == 'lg',
									}">
									<span>Página Actual</span>
									<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
								</p>
								<b-row no-gutters>
									<b-col sm="auto">
										<SitePagination
											:loading="resources.loading"
											:page="query.page"
											:total="resources.data.total"
											:limit="query.limit"
											@clicked="paginationChanged"
										></SitePagination>
									</b-col>
								</b-row>
							</b-col>

						</b-row>
					</b-card-body>

					<!-- DATA -->
					<b-card-body class="pt-0 pb-0 px-0">
						<b-skeleton-wrapper :loading="resources.loading && !reloading">

							<!-- LOADING -->
							<template #loading>
								<b-card no-body>
									<b-skeleton-table
										animation="fade"
										:rows="10"
										:columns="7"
									></b-skeleton-table>
								</b-card>
							</template>


							<!-- TABLE :fields="visibleTableFields" -->
							<b-table
								:items="productsWithSelected"
								:fields="computedFields"
								:busy="resources.loading"
								:no-local-sorting="true"
								@sort-changed="sortingChanged"
								show-empty
								:stacked="mobile"
								hover
								:small="ui_size == 'sm' || false"
								responsive
								select-mode="single"
								ref="selectableTable2"
								selectable
								sort-icon-left
								class="mt-0 mb-0"
								:class="{
									'fs-09' : ui_size == 'sm',
									'fs-10' : ui_size == 'md',
									'fs-13' : ui_size == 'lg',
									'table-dark' : ui_theme == 'dark',
								}"
								selected-variant="success"
								@row-clicked="onRowSelected"
							>
								<template #table-colgroup="scope">
									<col
										v-for="field in scope.fields"
										:key="field.key"
										:style="{
											width: field.key === 'selected' ? '10%' :
													field.key === 'code' ? '15%' :
													field.key === 'unit' ? '15%' : '60%'
										}"
									>
								</template>
								<template v-slot:empty>
									<div class="p-3">
										<b-alert show variant="light" class="mb-0">
											<p class="mb-0 fs-12">No se encontraron coincidencias.</p>
										</b-alert>
									</div>
								</template>
								<template v-slot:table-busy>
									<div class="text-center my-2">
										<b-spinner class="align-middle"></b-spinner>
									</div>
								</template>
								<template v-slot:head(selected)>
									<div class="text-center">
										<i class="fas fa-fw fa-check text-success"></i>
									</div>
								</template>
								<template #cell(selected)="{ rowSelected }">
									<p class="mb-0 text-center" v-b-tooltip.hover title="Ítem Seleccionado">
										<span v-if="rowSelected === true">
											<i class="fas fa-check-circle text-success"></i>
										</span>
									</p>
								</template>
								<template #cell(name)="data">
									<div class="text-left">
										{{ data.value }}
									</div>
								</template>
								<template #cell(description)="data">
									<div class="text-left">
										{{ data.value }}
									</div>
								</template>
								<template v-slot:cell(unit)="data">
									<div class="text-left">
										{{ data.value.code }}
									</div>
								</template>
							</b-table>
						</b-skeleton-wrapper>
						<!--<hr class="m-0 p-0">-->
					</b-card-body>
					<!--
					<b-card-body class="pt-0 pb-0 px-3">
						
						<div class="text-right">
							<SitePagination
								:loading="resources.loading"
								:page="query.page"
								:total="resources.data.total"
								:limit="query.limit"
								@clicked="paginationChanged"
								class="mt-2"
							></SitePagination>
							<p class="mt-2 text-dark"
								:class="{
									'fs-09' : ui_size == 'sm',
									'fs-10' : ui_size == 'md',
									'fs-13' : ui_size == 'lg',
								}">
								<i class="fas fa-arrow-turn-down-right mr-2 text-muted"></i>
								<span>Página Actual</span>
							</p>
						</div>
					</b-card-body>
					-->
				</b-card>

				<!-- LOADING -->
				<template #overlay>
					<div class="text-center py-5">
						<b-spinner type="grow" variant="primary"></b-spinner>
					</div>
				</template>

			</b-overlay>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :disabled="loading" :size="ui_size">Cancelar</b-button>
				<b-button @click="finish()" variant="success" :disabled="loading||!val_full" :size="ui_size">
					<i class="fas fa-check mr-1"></i>
					<span>Seleccionar</span>
				</b-button>
			</template>

		</b-modal>
	</div>
</template>

<script>
import Helpers from '@/helpers';
import SitePerPage from '@/components/SitePerPage.vue'
import SitePagination from '@/components/SitePagination.vue'
export default {
	name: 'SelectProduct',
	components: {
		SitePerPage,
		SitePagination,
	},
	data() {
		return {
			reloading: false,
			loading: false,
			items: [],
			query: {
				limit: 10,
				page: 1,
				sortDesc: false,
				sortBy: 'code',
				search: null,
				type: 'articles',
			},
			paginationOptions: [
				{ value: 5, text: '5 por página' },
				{ value: 10, text: '10 por página' },
				{ value: 15, text: '15 por página' },
				{ value: 20, text: '20 por página' },
				{ value: 50, text: '50 por página' },
				{ value: 100, text: '100 por página' },
				{ value: 9999, text: 'Mostrar todo' },
			],
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'search-products') { this.reset() }
		})
	},
	computed: {
		new_stock_requirement: {
			get() { return this.$store.getters.new_stock_requirement; },
			set(data) { this.$store.commit('new_stock_requirement', data); }
		},
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		products() { return this.$store.state.products },
		productsWithSelected() {
			if (this.loading == true || this.reloading == true || this.products.data.length == 0) {
				return []
			} else {
				return this.products.data.data
			}
		},
		computedFields() {
			return [
				{ key: 'selected', label: '', sortable: false },
				{ key: 'code', label: 'Código', sortable: true },
				{ key: 'description', label: 'Descripción', sortable: true },
				{ key: 'unit', label: 'Unidad', sortable: true },
			]
		},
		services() { return this.$store.state.helpers.defontana_services },
		resources() { return this.products },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		val_full() {
			return this.items.length > 0 ? true : false
		},
	},
	methods: {
		reset() {
			this.items = []
			this.query = {
				limit: 10,
				page: 1,
				sortDesc: false,
				sortBy: 'code',
				search: null,
				type: 'articles',
			}
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		load() {
			if (this.query.type == 'services') {
				this.$store.dispatch('defontana_services', this.query).then(() => { this.reloading = false })
			} else {
				this.$store.dispatch('products', this.query).then(() => { this.reloading = false })
			}
		},
		reload() {
			this.reloading = true
			this.load()
		},
		search() {
			if (this.query.search == null) {
				this.query.search = ''
				//this.$refs.search.focus()
			}
			else if (this.query.search == '') {
				this.query.search = null
			}
			else if (this.query.search.length > 0) {
				this.reload()
			}
		},
		searchBlur() {
			if (this.query.search == '') {
				this.query.search = null
			}
		},
		searchClear() {
			if (this.query.search != null && this.query.search.length == 0) {
				this.query.page = 1
				this.reload()
			}
		},
		searchChanged(search) {
			this.query.search = search
		},
		perPageChanged(limit) {
			this.query.limit = limit
			this.reload()
		},
		paginationChanged(page) {
			this.query.page = page
			this.reload()
		},
		sortingChanged(ctx) {
			this.query.sortDesc = ctx.sortDesc
			this.query.sortBy = ctx.sortBy
			this.reload()
		},
		onRowSelected(data) {
			this.items = [ data ]
		},
		discardProduct(data) {
			this.items.splice(data.index, 1)
			this.products.data.data.forEach((product, i) => {
				if (product.code == data.item.code) this.$refs.selectableTable2.unselectRow(i)
			});
		},
		parseNumber(number) {
			return Helpers.parseNumber(number)
		},
		finish() {
			this.new_stock_requirement.items = [...this.new_stock_requirement.items, ...this.items];
			this.$bvModal.hide('select-product')
		},
		rowClass(item, type) {
			if (!item || type !== 'row') return
			if (item.id == 1) return 'table-success'
		},
		changeType() {
			this.query.sortDesc = false
			this.query.sortBy = 'code'
			this.reload()
		}
	}
}
</script>