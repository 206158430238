<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="defontana-sync"
			:size="ui_size == 'sm' ? 'md' : 'md'"
			body-class="p-0"
			:title-class="{
				'fs-11' : ui_size == 'sm',
				'fs-13' : ui_size == 'md',
				'fs-15' : ui_size == 'lg',
			}"
			:header-bg-variant="ui_theme == 'dark' ? 'primary' : 'light'"
			:header-text-variant="ui_theme == 'dark' ? 'light' : 'primary'"
			:body-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:body-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:footer-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:footer-text-variant="ui_theme == 'dark' ? 'light' : ''"
		>

		<template #modal-header>
				<div class="w-100">
					<b-row>
						<b-col>
							<p class="mb-0 fw-bold fs-13">Estado de Conexión</p>
						</b-col>
						<b-col class="text-right">
							<i class="fas fa-webhook fs-13 mt-1 text-muted"></i>
						</b-col>
					</b-row>
				</div>
			</template>
			
			<b-overlay :show="loading" rounded="sm" class="p-4" :variant="ui_theme == 'dark' ? 'dark': 'light'">

				<b-row>
					
					<!-- USER PROFILE -->
					<b-col sm="12">
						<b-form-group label-class="fw-bold">
							<template #label>
								<span>Módulo Defontana</span>
								<b-button size="xs" variant="light" class="ml-2" @click="$bvModal.show('helper-access-profile')">
									<i class="fas fa-circle-question mr-1" title="Más Información" v-b-tooltip.hover></i>
									<span>Más información</span>
								</b-button>
							</template>
							<b-form-radio-group v-model="query.module">
								<b-row>
									<b-col sm="6">
										<b-card
											no-body
											:class="{
												'py-2 px-2 mb-2' : true,
												'text-danger border-danger' : query.module == null,
												'text-success border-success' : query.module == 'pricings',
												'text-secondary border-secondary' : query.module != null && query.module != 'pricings',
											}"
										>
											<b-form-radio value="pricings">
												<p class="mb-0 fw-bold">
													<span>Cotizaciones</span>
												</p>
											</b-form-radio>
										</b-card>
									</b-col>
									<b-col sm="6">
										<b-card
											no-body
											:class="{
												'py-2 px-2 mb-2' : true,
												'text-danger border-danger' : query.module == null,
												'text-success border-success' : query.module == 'orders',
												'text-secondary border-secondary' : query.module != null && query.module != 'orders',
											}"
										>
											<b-form-radio value="orders">
												<p class="mb-0 fw-bold">
													<span>Pedidos</span>
												</p>
											</b-form-radio>
											<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
											<b-form-valid-feedback>Correcto</b-form-valid-feedback>
										</b-card>
									</b-col>
									<b-col sm="6">
										<b-card
											no-body
											:class="{
												'py-2 px-2 mb-2' : true,
												'text-danger border-danger' : query.module == null,
												'text-success border-success' : query.module == 'sales',
												'text-secondary border-secondary' : query.module != null && query.module != 'sales',
											}"
										>
											<b-form-radio value="sales">
												<p class="mb-0 fw-bold">
													<span>Ventas</span>
												</p>
											</b-form-radio>
											<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
											<b-form-valid-feedback>Correcto</b-form-valid-feedback>
										</b-card>
									</b-col>
									<b-col sm="6">
										<b-card
											no-body
											:class="{
												'py-2 px-2 mb-2' : true,
												'text-danger border-danger' : query.module == null,
												'text-success border-success' : query.module == 'purchase_orders',
												'text-secondary border-secondary' : query.module != null && query.module != 'purchase_orders',
											}"
										>
											<b-form-radio value="purchase_orders">
												<p class="mb-0 fw-bold">
													<span>Compras</span>
												</p>
											</b-form-radio>
											<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
											<b-form-valid-feedback>Correcto</b-form-valid-feedback>
										</b-card>
									</b-col>
								</b-row>
							</b-form-radio-group>
							<b-form-invalid-feedback :state="val_module">Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback :state="val_module">Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

				</b-row>

				<b-row>
					<b-col>
						<b-form-group label="Actualizar Folio">
							<b-input-group prepend="N°">
								<b-input
									v-model="query.search"
									placeholder=""
									@changed="searchChanged"
									@blur="searchBlur()"
									@keyup.delete="searchClear()"
									@keydown.enter="search()"
									:maxlength="7"
									v-bind:class="{
										'bg-white text-primary' : true,
										'border-0' : search == null,
									}"
								></b-input>
								<template #append>
									<b-button
										title="Presione para buscar"
										:disabled="query.search == null || query.search.length == 0"
										:variant="query.search != null && query.search.length > 0 ? 'primary' : 'outline-primary'"
										@click="search()"
									>
										<i class="fas fa-download"></i>
									</b-button>
								</template>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="Actualizar Todo">
							<b-button block variant="primary">
								<span>Iniciar Ahora</span>
							</b-button>
						</b-form-group>
					</b-col>
				</b-row>


				<!-- LOADING -->
				<template #overlay>
					<div class="text-center py-5">
						<b-spinner type="grow" variant="primary"></b-spinner>
					</div>
				</template>
			</b-overlay>


			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :disabled="loading" :size="ui_size">Cerrar</b-button>
			</template>

		</b-modal>

	</div>
</template>

<script>
export default {
	name: 'DefontanaSync',
	components: {
	},
	data() {
		return {
			loading: false,
			reloading: false,
			query: {
				limit: 10,
				page: 1,
				sortDesc: false,
				sortBy: 'code',
				search: null,
				module: null
			},
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'defontana-sync') { this.reset() }
		})
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		clients() { return this.$store.state.clients },
		client_sites() { return this.$store.state.client_sites },
		site_units() { return this.$store.state.site_units },
		helpers_job_types() { return this.$store.state.helpers.job_types },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		val_module() { return this.query.module != null && this.query.module.length > 0 ? true : false }
	},
	methods: {
		reset() {
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		search() {
			if (this.query.search == null) {
				this.query.search = ''
				//this.$refs.search.focus()
			}
			else if (this.query.search == '') {
				this.query.search = null
			}
			else if (this.query.search.length > 0) {
				this.reload()
			}
		},
		searchBlur() {
			if (this.query.search == '') {
				this.query.search = null
			}
		},
		searchClear() {
			if (this.query.search != null && this.query.search.length == 0) {
				this.query.page = 1
				this.reload()
			}
		},
		searchChanged(search) {
			this.query.search = search
		},
	}
}
</script>