var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('SiteModuleHeader',{attrs:{"showBack":true,"showHome":true,"showSize":true,"showTheme":true,"showFluid":true,"showTitle":true,"reloading":_vm.reloading || _vm.resources.loading},on:{"reload":_vm.reload}}),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"md":"sm","lg":"auto","xl":"auto"}},[_c('p',{staticClass:"mb-2 text-dark",class:{
					'fs-09' : _vm.ui_size == 'sm',
					'fs-10' : _vm.ui_size == 'md',
					'fs-13' : _vm.ui_size == 'lg',
				}},[_c('span',[_vm._v("Acciones")]),_c('i',{staticClass:"fas fa-arrow-turn-down ml-2 text-muted"})]),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"sm":"auto"}},[_c('b-overlay',{staticClass:"mr-2",attrs:{"show":_vm.resources.loading,"rounded":"","opacity":"0.6"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('span',[_vm._v(" ")])]},proxy:true}])},[_c('b-input-group',{attrs:{"size":_vm.ui_size},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-button',{attrs:{"title":"Presione para buscar","disabled":_vm.query.search == null || _vm.query.search.length == 0,"variant":_vm.query.search != null && _vm.query.search.length > 0 ? 'primary' : 'outline-primary'},on:{"click":function($event){return _vm.search()}}},[_c('i',{staticClass:"fas fa-search mr-1"}),_c('span',[_vm._v("Buscar")])])]},proxy:true}])},[_c('b-input',{class:{
									'bg-white text-primary' : true,
									'border-primary' : _vm.search != null,
									'border-0' : _vm.search == null,
								},attrs:{"placeholder":"Usuario o Equipo"},on:{"changed":_vm.searchChanged,"blur":function($event){return _vm.searchBlur()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.searchClear()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search()}},model:{value:(_vm.query.search),callback:function ($$v) {_vm.$set(_vm.query, "search", $$v)},expression:"query.search"}})],1)],1)],1)],1)],1),_c('b-col'),_c('b-col',{attrs:{"md":"12","lg":"auto","xl":"auto"}},[_c('p',{staticClass:"mb-2 text-dark",class:{
					'fs-09' : _vm.ui_size == 'sm',
					'fs-10' : _vm.ui_size == 'md',
					'fs-13' : _vm.ui_size == 'lg',
				}},[_c('span',[_vm._v("Visualización")]),_c('i',{staticClass:"fas fa-arrow-turn-down ml-2 text-muted"})]),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"sm":"auto"}},[_c('SitePerPage',{attrs:{"loading":_vm.resources.loading,"limit":_vm.query.limit,"options":_vm.paginationOptions},on:{"clicked":_vm.perPageChanged}})],1)],1)],1),_c('b-col',{attrs:{"md":"12","lg":"auto","xl":"auto"}},[_c('p',{staticClass:"mb-2 text-dark",class:{
					'fs-09' : _vm.ui_size == 'sm',
					'fs-10' : _vm.ui_size == 'md',
					'fs-13' : _vm.ui_size == 'lg',
				}},[_c('span',[_vm._v("Página Actual")]),_c('i',{staticClass:"fas fa-arrow-turn-down ml-2 text-muted"})]),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"sm":"auto"}},[_c('SitePagination',{attrs:{"loading":_vm.resources.loading,"page":_vm.query.page,"total":_vm.resources.data.total,"limit":_vm.query.limit},on:{"clicked":_vm.paginationChanged}})],1)],1)],1)],1),_c('b-skeleton-wrapper',{attrs:{"loading":_vm.resources.loading && !_vm.reloading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-card',{attrs:{"no-body":""}},[_c('b-skeleton-table',{attrs:{"animation":"fade","rows":10,"columns":7}})],1)]},proxy:true}])},[_c('b-table',{staticClass:"mt-0 mb-0 rounded",class:{
				'fs-09' : _vm.ui_size == 'sm',
				'fs-10' : _vm.ui_size == 'md',
				'fs-13' : _vm.ui_size == 'lg',
				'table-dark' : _vm.ui_theme == 'dark',
			},attrs:{"items":_vm.resources.data.data,"busy":_vm.resources.loading,"fields":_vm.fields,"no-local-sorting":true,"show-empty":"","stacked":_vm.mobile,"hover":"","bordered":"","small":_vm.ui_size == 'sm' || false,"responsive":"","sort-icon-left":"","head-variant":_vm.ui_theme == 'light' ? 'light' : 'dark',"table-variant":_vm.ui_theme == 'light' ? 'light' : 'dark'},on:{"sort-changed":_vm.sortingChanged},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"p-3"},[_c('span',{staticClass:"text-muted"},[_vm._v("No hay Ventas que coincidan con los filtros aplicados.")])])]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(online_state)",fn:function(data){return [_c('div',[(data.value == 'Offline')?_c('div',[_c('i',{staticClass:"fas fa-circle-small text-danger mr-1"}),_c('span',[_vm._v(_vm._s(data.value))])]):(data.value == 'Online')?_c('div',[_c('i',{staticClass:"fas fa-circle-small text-success mr-1"}),_c('span',[_vm._v(_vm._s(data.value))])]):_vm._e()])]}},{key:"cell(user)",fn:function(data){return [_c('div',[(data.value)?_c('div'):_c('div',[_c('span',{staticClass:"text-muted"},[_vm._v("No Asignado")])])])]}}])})],1),_c('SitePagination',{staticClass:"mt-2",attrs:{"loading":_vm.resources.loading,"page":_vm.query.page,"total":_vm.resources.data.total,"limit":_vm.query.limit},on:{"clicked":_vm.paginationChanged}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }