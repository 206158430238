<template>
	<div class="mt-3">

		<SiteModuleHeader
			:showBack="true"
			:showHome="true"
			:showSize="true"
			:showTheme="true"
			:showFluid="true"
			:showTitle="true"
		></SiteModuleHeader>

		<p class="text-dark fs-15 mt-0 mb-4">Las carpetas compartidas de la empresa funcionan gracias a Microsoft OneDrive, un software para compartir archivos y carpetas de forma colaborativa.</p>
		
		<b-row>
			<b-col lg="3">
				<b-card no-body class="mb-5">
					<b-card-header>
						<p class="mb-0 fw-bold fs-12">Versión Web</p>
					</b-card-header>
					<b-card-body class="pb-0">
						<p class="fs-11 mb-3">Esta versión funciona desde cualquier navegador web con Internet y no necesita instalación.</p>
						<b-alert show variant="light" class="mb-2 py-4">
							<b-row no-gutters>
								<b-col sm="3" class="pr-3">
									<img src="/assets/custom_icons/onedrive.png" style="width: 100%" />
								</b-col>
								<b-col>
									<p class="mb-2 fs-13">OneDrive Web</p>
									<a
										href="https://electrofriocl-my.sharepoint.com/personal/administrador_electrofrio_cl1/_layouts/15/onedrive.aspx?view=0"
										target="_blank"
										class="btn btn-dark text-white"
										role="button"
									>Abrir OneDrive Web</a>
								</b-col>
							</b-row>
						</b-alert>
					</b-card-body>
					<b-card-body class="text-center pb-2">
						<p class="text-left text-muted">Navegadores Compatibles</p>
						<b-row>
							<b-col>
								<img src="/assets/custom_icons/chrome.png" style="width: 75%" />
								<p class="fs-08 mt-1 text-muted">Google<br>Chrome</p>
							</b-col>
							<b-col>
								<img src="/assets/custom_icons/edge.png" style="width: 75%" />
								<p class="fs-08 mt-1 text-muted">Microsoft<br>Edge</p>
							</b-col>
							<b-col>
								<img src="/assets/custom_icons/safari.png" style="width: 75%" />
								<p class="fs-08 mt-1 text-muted">Apple<br>Safari</p>
							</b-col>
							<b-col>
								<img src="/assets/custom_icons/firefox.png" style="width: 75%" />
								<p class="fs-08 mt-1 text-muted">Mozilla<br>Firefox</p>
							</b-col>
							<b-col>
								<img src="/assets/custom_icons/opera.png" style="width: 75%" />
								<p class="fs-08 mt-1 text-muted">Opera<br>Browser</p>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</b-col>
			<b-col lg="3">
				<b-card no-body class="mb-5">
					<b-card-header>
						<p class="mb-0 fw-bold fs-12">Versión Escritorio</p>
					</b-card-header>
					<b-card-body class="pb-0">
						<p class="fs-11 mb-3">Esta versión se instala en su computador y le permite sincronizar carpetas automáticamente.</p>
						<b-alert show variant="light" class="mb-2 py-4">
							<b-row no-gutters>
								<b-col sm="3" class="pr-3">
									<img src="/assets/custom_icons/folder.png" style="width: 100%" />
								</b-col>
								<b-col>
									<p class="mb-2 fs-13">OneDrive Escritorio</p>
									<a
										href="https://www.microsoft.com/es-cl/microsoft-365/onedrive/download"
										target="_blank"
										class="btn btn-dark text-white"
										role="button"
									>Descargar OneDrive</a>
								</b-col>
							</b-row>
						</b-alert>
					</b-card-body>
					<b-card-body class="text-center pb-2">
						<p class="text-left text-muted">Sistemas Operativos Compatibles</p>
						<b-row>
							<b-col sm="3">
								<img src="/assets/custom_icons/windows.png" style="width: 70%" />
								<p class="fs-08 mt-1 text-muted">Microsoft<br>Windows</p>
							</b-col>
							<b-col sm="3">
								<img src="/assets/custom_icons/apple.png" style="width: 70%" />
								<p class="fs-08 mt-1 text-muted">Apple<br>macOS</p>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>

	</div>
</template>

<script>
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
export default {
	name: 'OneDrive',
	components: {
		SiteModuleHeader,
	},
	data: function() {
		return {
		}
	},
	mounted() {
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		getters() { return this.$store.getters },
		//////-----------------------------------------------------------
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
	}
}
</script>
