<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="create-model"
			title="Nuevo Modelo"
			:size="ui_size == 'sm' ? 'lg' : 'lg'"
			body-class="p-0"
			:title-class="{
				'fs-11' : ui_size == 'sm',
				'fs-13' : ui_size == 'md',
				'fs-15' : ui_size == 'lg',
			}"
			:header-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:header-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:body-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:body-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:footer-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:footer-text-variant="ui_theme == 'dark' ? 'light' : ''"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-4" :variant="ui_theme == 'dark' ? 'dark': 'light'">

				<b-row>



					<!-- MARCA -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								<b-row>
									<b-col>
										Marca
									</b-col>
									<b-col sm="auto">
										<b-button
											@click="$bvModal.show('create-brand')"
											block
											size="xs"
											class="fs-08"
											:variant="ui_theme == 'dark' ? 'outline-success' : 'outline-success'"
										>
											<i class="fas fa-plus mr-1"></i>
											<span>Nueva Marca</span>
										</b-button>
									</b-col>
								</b-row>
							</template>
							<b-select
								v-model="model.brand_id"
								:disabled="brands.loading"
								:state="val_brand"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
								@change="reloadBrandModels()"
							>
								<b-select-option :value="null" disabled>Presione aquí</b-select-option>
								<b-select-option v-for="(item, i) in brands.data" :key="i" :value="item.id">{{ item.name }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

					<!-- TYPE -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								<b-row>
									<b-col>
										Tipo
									</b-col>
								</b-row>
							</template>
							<b-select
								v-model="model.type_id"
								:disabled="model_types.loading"
								:state="val_type"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							>
								<b-select-option :value="null" disabled>Presione aquí</b-select-option>
								<b-select-option v-for="(item, i) in model_types.data" :key="i" :value="item.id">{{ item.name }}</b-select-option>
							</b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

					<!-- PART NUMBER -->
					<b-col sm="4">
						<b-form-group
							label-class="fw-bold"
							:class="{
								'fs-09 pb-0' : ui_size == 'sm',
								'fs-10 pb-1' : ui_size == 'md',
								'fs-12 pb-2' : ui_size == 'lg',
							}"
						>
							<template #label>
								Nombre
							</template>
							<b-input
								v-model="model.part_number"
								:state="val_part_number"
								:size="ui_size"
								:class="{
									'bg-primary text-white' : ui_theme == 'dark',
								}"
							></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

					<b-col sm="12" class="mb-4">
						<b-card no-body>
							<b-table
								:items="brand_models.data"
								:fields="fields"
								:per-page="5"
								small
								class="mb-0"
							>
							</b-table>
						</b-card>
					</b-col>

				</b-row>

				<!-- VALIDATION -->
				<b-alert
					show
					:variant="val_full ? 'success' : 'danger'"
					class="mb-0"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-12' : ui_size == 'lg',
					}"
				>
					<p class="mb-0 text-size-10">
						<i class="fas fa-exclamation-circle mr-2"></i>
						<span>{{ val_full ?
									'Para continuar, presione el botón CREAR.' :
									'Complete los campos requeridos para continuar.'}}</span>
					</p>
				</b-alert>

				<!-- LOADING -->
				<template #overlay>
					<div class="text-center py-5">
						<b-spinner type="grow" variant="primary"></b-spinner>
					</div>
				</template>

			</b-overlay>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :disabled="loading" :size="ui_size">Cancelar</b-button>
				<b-button @click="create()" variant="success" :disabled="loading||!val_full" :size="ui_size">CREAR</b-button>
			</template>

		</b-modal>
	</div>
</template>

<script>
export default {
	name: 'CreateModel',
	components: {
	},
	data() {
		return {
			loading: false,
			model: {
				type_id: null,
				brand_id: null,
				part_number: '',
			},
			fields: [
				{ key: 'part_number', label: 'N° Parte / Modelo', sortable: true },
				{ key: 'brand.name', label: 'Marca', sortable: true },
				{ key: 'type.name', label: 'Tipo', sortable: true }
			]
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'create-model') { this.reset() }
		})
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		brand_models() { return this.$store.state.brand_models },
		model_types() { return this.$store.state.model_types },
		brands() { return this.$store.state.brands },
		val_part_number() {
			return this.model.part_number && this.model.part_number.length > 0 ? true : false
		},
		val_brand() {
			return this.model.brand_id != null ? true : false
		},
		val_type() {
			return this.model.type_id != null ? true : false
		},
		val_full() {
			return this.val_part_number ? true : false
		},
	},
	methods: {
		reset() {
			this.model = {
				type_id: null,
				brand_id: null,
				part_number: '',
			}
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		create() {
			this.loading = true
			this.$api.post('models', this.model)
			.then(response => {
				this.loading = false
				this.$bvModal.hide('create-model')
				this.$store.dispatch('models')
				this.$store.dispatch('brand_models', response.data.brand_id)
				this.toast(true, 'Se creó el Modelo "'+response.data.part_number+'" correctamente.')
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		reloadBrandModels() {
			this.$store.dispatch('brand_models', this.model.brand_id)
		},
	}
}
</script>