<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="create-purchase-request"
			title="Nueva Requisición"
			:size="ui_size == 'sm' ? 'xl' : 'xl'"
			body-class="p-0"
			:title-class="{
				'fs-11' : ui_size == 'sm',
				'fs-13' : ui_size == 'md',
				'fs-15' : ui_size == 'lg',
			}"
			:header-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:header-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:body-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:body-text-variant="ui_theme == 'dark' ? 'light' : ''"
			:footer-bg-variant="ui_theme == 'dark' ? 'primary' : ''"
			:footer-text-variant="ui_theme == 'dark' ? 'light' : ''"
		>
		
			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-4" :variant="ui_theme == 'dark' ? 'dark': 'light'">

				<div v-if="result.screen == 'main'">
					
					<b-row>

						<!-- GLOSS -->
						<b-col sm="4">
							<b-form-group
								label-class="fw-bold"
								:class="{
									'fs-09 pb-0' : ui_size == 'sm',
									'fs-10 pb-1' : ui_size == 'md',
									'fs-12 pb-2' : ui_size == 'lg',
								}"
							>
								<template #label>
									<b-row no-gutters>
										<b-col>
											<i class="fas fa-crosshairs mr-1 text-primary"></i>
											<span>Descripción</span>
										</b-col>
									</b-row>
								</template>
								<b-input
									v-model="new_purchase_request.title"
									:state="val_title"
									:size="ui_size"
									:class="{
										'bg-primary text-white' : ui_theme == 'dark',
									}"
								></b-input>
								<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
								<b-form-valid-feedback>Correcto</b-form-valid-feedback>
							</b-form-group>
						</b-col>

						<!-- BRANCH -->
						<b-col sm="4">
							<b-form-group
								label-class="fw-bold"
								:class="{
									'fs-09 pb-0' : ui_size == 'sm',
									'fs-10 pb-1' : ui_size == 'md',
									'fs-12 pb-2' : ui_size == 'lg',
								}"
							>
								<template #label>
									<b-row no-gutters>
										<b-col>
											<i class="fas fa-location-dot mr-1 text-primary"></i>
											<span>Sucursal</span>
										</b-col>
									</b-row>
								</template>
								<b-select
									v-model="new_purchase_request.branch_id"
									:state="val_branch"
									:disabled="branches.loading"
									:size="ui_size"
									:class="{
										'bg-primary text-white' : ui_theme == 'dark',
									}"
								>
									<b-select-option :value="null" disabled>Presione aquí</b-select-option>
									<b-select-option v-for="(item, i) in branches.data" :key="i" :value="item.id" class="text-uppercase">{{ item.name }}</b-select-option>
								</b-select>
								<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
								<b-form-valid-feedback>Correcto</b-form-valid-feedback>
							</b-form-group>
						</b-col>

						<!-- COST CENTER -->
						<b-col sm="4">
							<b-form-group
								label-class="fw-bold"
								:class="{
									'fs-09 pb-0' : ui_size == 'sm',
									'fs-10 pb-1' : ui_size == 'md',
									'fs-12 pb-2' : ui_size == 'lg',
								}"
							>
								<template #label>
									<b-row no-gutters>
										<b-col>
											<i class="fas fa-location-dot mr-1 text-primary"></i>
											<span>Centro de Negocios</span>
										</b-col>
									</b-row>
								</template>
								<b-select
									v-model="new_purchase_request.cost_center_id"
									:state="val_cost_center"
									:disabled="cost_centers.loading"
									:size="ui_size"
									:class="{
										'bg-primary text-white' : ui_theme == 'dark',
									}"
								>
									<b-select-option :value="null" disabled>Presione aquí</b-select-option>
									<b-select-option v-for="(item, i) in cost_centers.data" :key="i" :value="item.id" class="text-uppercase">{{ item.code }} -- {{ item.description }}</b-select-option>
								</b-select>
								<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
								<b-form-valid-feedback>Correcto</b-form-valid-feedback>
							</b-form-group>
						</b-col>

						<!-- PRIORITY -->
						<b-col sm="4">
							<b-form-group
								label-class="fw-bold"
								:class="{
									'fs-09 pb-0' : ui_size == 'sm',
									'fs-10 pb-1' : ui_size == 'md',
									'fs-12 pb-2' : ui_size == 'lg',
								}"
							>
								<template #label>
									<b-row no-gutters>
										<b-col>
											<i class="fas fa-location-dot mr-1 text-primary"></i>
											<span>Prioridad</span>
										</b-col>
									</b-row>
								</template>
								<b-select
									v-model="new_purchase_request.priority_id"
									:state="val_priority"
									:size="ui_size"
									:class="{
										'bg-primary text-white' : ui_theme == 'dark',
									}"
								>
									<b-select-option :value="null" disabled>Presione aquí</b-select-option>
									<b-select-option v-for="(item, i) in ['Baja','Media','Alta']" :key="i" :value="i+1" class="text-uppercase">{{ item }}</b-select-option>
								</b-select>
								<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
								<b-form-valid-feedback>Correcto</b-form-valid-feedback>
							</b-form-group>
						</b-col>

						<!-- DATE -->
						<b-col sm="4">
							<b-form-group
								label-class="fw-bold"
								:class="{
									'fs-09 pb-0' : ui_size == 'sm',
									'fs-10 pb-1' : ui_size == 'md',
									'fs-12 pb-2' : ui_size == 'lg',
								}"
							>
								<template #label>
									<b-row no-gutters>
										<b-col>
											<i class="fas fa-calendar-days mr-1 text-primary"></i>
											<span>Fecha de Creación</span>
										</b-col>
									</b-row>
								</template>
								<b-form-datepicker
									v-model="new_purchase_request.request_date"
									:state="val_request_date"
									:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
									placeholder="Presione aquí"
									:size="ui_size"
									:class="{
										'bg-primary text-white' : ui_theme == 'dark',
									}"
								></b-form-datepicker>
								<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
								<b-form-valid-feedback>Correcto</b-form-valid-feedback>
							</b-form-group>
						</b-col>

						<!-- DUE DATE -->
						<b-col sm="4">
							<b-form-group
								label-class="fw-bold"
								:class="{
									'fs-09 pb-0' : ui_size == 'sm',
									'fs-10 pb-1' : ui_size == 'md',
									'fs-12 pb-2' : ui_size == 'lg',
								}"
							>
								<template #label>
									<b-row no-gutters>
										<b-col>
											<i class="fas fa-flag-checkered mr-1 text-primary"></i>
											<span>Fecha de Entrega</span>
										</b-col>
									</b-row>
								</template>
								<b-form-datepicker
									v-model="new_purchase_request.delivery_date"
									:min="new_purchase_request.request_date"
									:state="val_delivery_date"
									:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
									placeholder="Presione aquí"
									:size="ui_size"
									:class="{
										'bg-primary text-white' : ui_theme == 'dark',
									}"
								></b-form-datepicker>
								<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
								<b-form-valid-feedback>Correcto</b-form-valid-feedback>
							</b-form-group>
						</b-col>

						</b-row>

						<!-- SEARCH -->
						<b-row class="mb-2 mt-2">
							<b-col>
								<b-button
									class="mr-2"
									variant="primary"
									@click="search()"
								>
									<i class="fas fa-plus mr-1"></i>
									<span v-if="new_purchase_request.items.length > 0">Agregar ítems codificados</span>
									<span v-else>Agregar ítems codificados</span>
								</b-button>
								<b-button
									variant="primary"
									@click="addNonExistentItem()"
								>
									<i class="fas fa-plus mr-1"></i>
									<span v-if="new_purchase_request.items.length > 0">Agregar ítem no codificado</span>
									<span v-else>Agregar ítem no codificado</span>
								</b-button>
							</b-col>
						</b-row>

						<!-- ITEMS -->
						<b-table
							:items="computedItems"
							:fields="[
								{ key: 'discard', label: '', sortable: false },
								{ key: 'meta', label: 'Ítem', sortable: false },
								{ key: 'unit', label: 'U.M.', sortable: false },
								{ key: 'count', label: 'Cantidad', sortable: false },
							]"
							show-empty
							:stacked="mobile"
							hover
							bordered
							:small="ui_size == 'sm' || false"
							sort-icon-left
							:head-variant="ui_theme == 'light' ? 'light' : 'dark'"
							class="mt-0 mb-4 rounded"
							fixed
							:class="{
								'fs-09' : ui_size == 'sm',
								'fs-10' : ui_size == 'md',
								'fs-13' : ui_size == 'lg',
								'table-dark' : ui_theme == 'dark',
							}"
						>
						<template #table-colgroup="scope">
							<col
								v-for="field in scope.fields"
								:key="field.key"
								:style="{
									width: field.key === 'discard' ? '5%' :
											field.key === 'meta' ? '50%' :
											field.key === 'unit' ? '5%' :
											field.key === 'count' ? '10%' :
											field.key === 'price' ? '15%' : '15%'
								}"
							>
						</template>
						<template v-slot:empty>
							<div class="p-3">
								<span class="fs-12 text-danger">Agregue al menos un ítem a la cotización.</span>
							</div>
						</template>
						<template v-slot:head(discard)>
							<div class="text-center">
								<i class="fas fa-trash"></i>
							</div>
						</template>
						<template v-slot:cell(discard)="data">
							<div class="text-center pt-2" @click="discardProduct(data)" role="button" v-b-tooltip.hover title="Descartar Ítem">
								<i class="fas fa-circle-xmark text-danger"></i>
							</div>
						</template>
						<template v-slot:cell(unit)="data">
							<div class="text-center">
								<p class="mb-0" v-b-tooltip.hover title="Unidad" role="button">{{ data.value }}</p>
							</div>
						</template>
						<template v-slot:cell(meta)="data">
							<div v-if="data.item.code == null">
								<p class="mb-0 fs-07 text-dark text-uppercase fw-bold">
									SIN CÓDIGO
								</p>
								<b-form-input
									:state="validateDescription(computedItems[data.index].description)"
									v-model="computedItems[data.index].description"
									:maxlength="100"
									size="sm"
								></b-form-input>
							</div>
							<div v-else>
								<p class="mb-0 fs-07 text-dark text-uppercase fw-bold">
									CÓDIGO {{ data.item.type == 'S' ? 'SERVICIO' : 'ARTÍCULO' }} {{ data.item.code }}
								</p>
								<p class="mb-0 fs-09">{{ data.item.name ? data.item.name : data.item.description }}</p>
							</div>
						</template>
						<template v-slot:cell(count)="data">
							<div>
								<b-input-group size="sm" class="w-space">
									<b-form-input
										class="text-center"
										:state="validateCount(computedItems[data.index].count)"
										v-model="computedItems[data.index].count"
										:formatter="onlyNumbers"
										:maxlength="20"
									></b-form-input>
								</b-input-group>
							</div>
						</template>
					</b-table>

					<b-row class="mb-4">
						<b-col>
							<b-form-group
								label-class="fw-bold"
								:class="{
									'fs-09 pb-0' : ui_size == 'sm',
									'fs-10 pb-1' : ui_size == 'md',
									'fs-12 pb-2' : ui_size == 'lg',
								}"
							>
								<template #label>
									<b-row no-gutters>
										<b-col>
											<span>Observaciones</span>
										</b-col>
									</b-row>
								</template>
								<b-textarea
									v-model="new_purchase_request.comment"
									:state="val_comment"
									:size="ui_size"
									:class="{
										'bg-primary text-white' : ui_theme == 'dark',
									}"
								></b-textarea>
								<b-form-text v-if="val_comment == null">Opcional</b-form-text>
								<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
								<b-form-valid-feedback>Correcto</b-form-valid-feedback>
							</b-form-group>
						</b-col>
					</b-row>

					<!-- VALIDATION -->
					<b-alert
						show
						:variant="val_full ? 'success' : 'danger'"
						class="mb-0"
						:class="{
							'fs-09' : ui_size == 'sm',
							'fs-10' : ui_size == 'md',
							'fs-12' : ui_size == 'lg',
						}"
					>
						<p class="mb-0 text-size-10">
							<i class="fas fa-exclamation-circle mr-2"></i>
							<span>{{ val_full ?
										'Para continuar, presione el botón CREAR.' :
										'Complete los campos requeridos para continuar.'}}</span>
						</p>
					</b-alert>
				</div>

				<div v-else-if="result.screen == 'success'">
					<div class="text-center py-3">
						<i class="fas fa-check-circle text-success fs-50"></i>
						<p class="mb-2 mt-3 fw-bold fs-15">Requisición N°{{ result.number }} Registrada</p>
						<p class="mb-1 px-3 fs-13 text-muted">La requisición fue registrada exitosamente.</p>
					</div>
				</div>

				<div v-else-if="result.screen == 'error'">
					<div class="text-center py-3">
						<i class="fas fa-xmark-circle text-danger fs-50"></i>
						<p class="mb-2 mt-3 fw-bold fs-15">Error Interno</p>
						<p class="mb-1 px-3 fs-13 text-muted">Ocurrió un problema al registrar la requisición.</p>
						<p class="mb-0 px-3 fs-13 text-muted">Por favor, verifique la información e inténtelo nuevamente.</p>
					</div>
				</div>

				<div v-else>
					<div class="text-center py-3">
						<i class="fas fa-xmark-circle text-danger fs-50"></i>
						<p class="mb-2 mt-3 fw-bold fs-15">Error Desconocido</p>
						<p class="mb-1 px-3 fs-13 text-muted">Ocurrió un problema inesperado. Por favor, contacte al Administrador del Sistema.</p>
					</div>
				</div>


				<!-- LOADING -->
				<template #overlay>
					<div class="text-center py-5">
						<b-spinner type="grow" variant="primary"></b-spinner>
					</div>
				</template>

			</b-overlay>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :disabled="loading" :size="ui_size">
					<span v-if="result.screen == 'main'">Cancelar</span>
					<span v-else>Cerrar</span>
				</b-button>
				<b-button @click="create()" variant="success" :disabled="loading||!val_full" :size="ui_size" v-if="result.screen == 'main'">CREAR</b-button>
			</template>

		</b-modal>
	</div>
</template>

<script>
import Helpers from '@/helpers';
export default {
	name: 'CreatePurchaseRequest',
	components: {
	},
	data() {
		return {
			reloading: false,
			totals: {
				subtotal: 0,
			},
			result: {
				screen: 'main',
				message: '',
				number: '',
			},
			loading: false,
			query: {
				limit: 10,
				page: 1,
				sortDesc: false,
				sortBy: 'id',
				search: null,
				type: '',
			},
			paginationOptions: [
				{ value: 5, text: '5 por página' },
				{ value: 10, text: '10 por página' },
				{ value: 15, text: '15 por página' },
				{ value: 20, text: '20 por página' },
				{ value: 50, text: '50 por página' },
				{ value: 100, text: '100 por página' },
				{ value: 9999, text: 'Mostrar todo' },
			],
			fields: [
				{ key: 'code', label: 'Código', sortable: true },
				{ key: 'name', label: 'Descripción', sortable: true },
				{ key: 'stock', label: 'Stock Disponible', sortable: true },
				{ key: 'active', label: 'Activo', sortable: true },
			],
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'create-purchase-request') { this.reset() }
		})
	},
	computed: {
		new_purchase_request: {
			get() { return this.$store.getters.new_purchase_request; },
			set(data) { this.$store.commit('new_purchase_request', data); }
		},
		computedItems() {
			return this.new_purchase_request.items && this.new_purchase_request.items.length ? this.new_purchase_request.items : []
		},
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		clients() { return this.$store.state.helpers.defontana_clients },
		branches() { return this.$store.state.helpers.branches },
		sellers() { return this.$store.state.helpers.defontana_sellers },
		payment_conditions() { return this.$store.state.helpers.defontana_payment_conditions },
		document_types() { return this.$store.state.helpers.defontana_document_types },
		products() { return this.$store.state.helpers.defontana_products },
		services() { return this.$store.state.helpers.defontana_services },
		cost_centers() { return this.$store.state.helpers.defontana_cost_centers },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		val_title() {
			return this.new_purchase_request.title && this.new_purchase_request.title.length > 0 ? true : false
		},
		val_branch() {
			return this.new_purchase_request.branch_id != null ? true : false
		},
		val_request_date() {
			return this.new_purchase_request.request_date && this.new_purchase_request.request_date.length > 0 ? true : false
		},
		val_delivery_date() {
			return this.new_purchase_request.delivery_date && this.new_purchase_request.delivery_date.length > 0 ? true : false
		},
		val_priority() {
			return this.new_purchase_request.priority_id != null ? true : false
		},
		val_cost_center() {
			return this.new_purchase_request.cost_center_id != null ? true : false
		},
		val_items() {
			if (this.new_purchase_request.items && this.new_purchase_request.items.length > 0) {
				var result = true
				this.new_purchase_request.items.forEach((item, index) => {
					if (typeof item.count == 'undefined' || item.count == null || item.count == '' || item.count == 0) result = false
				})
				return result
			} else {
				return false
			}
		},
		val_non_existent_items() {
			if (this.new_purchase_request.items && this.new_purchase_request.items.length > 0) {
				var result = true
				this.new_purchase_request.items.forEach((item, index) => {
					if ((typeof item.code == 'undefined' ||
								item.code == null ||
								item.code == '' ||
								item.code == 0)
						&& (item.description == null ||
								item.description == '')) result = false
				})
				return result
			} else {
				return false
			}
		},
		val_full() {
			return this.val_title && this.val_branch && this.val_request_date && this.val_cost_center &&
					this.val_delivery_date && this.val_priority && this.val_items && 
					this.val_non_existent_items ? true : false
		},
		val_comment() {
			return this.new_purchase_request.comment == null || this.new_purchase_request.comment.length == 0 ? null : true
		},
	},
	methods: {
		reset() {
			this.totals = {
				subtotal: 0,
			},
			this.result = {
				screen: 'main',
				message: '',
				number: '',
			}
			this.$store.commit('clear_new_purchase_request')
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		create() {
			this.loading = true
			this.$api.post('purchase_requests', this.new_purchase_request)
			.then(response => {
				this.loading = false
				//this.$bvModal.hide('create-purchase-request')
				this.$emit('update')
				this.result = {
					screen: 'success',
					message: '',
					number: response.data.number
				}
			})
			.catch(() => {
				this.loading = false
				this.result = {
					screen: 'error',
					message: ''
				}
			})
		},
		onlyNumbers(data) {
			return Helpers.onlyNumbers(data, true)
		},
		search() {
			this.load()
			this.$bvModal.show('search-products')
		},
		load() {
			this.$store.dispatch('defontana_products', this.query).then(() => { this.reloading = false })
		},
		reload() {
			this.reloading = true
			this.load()
		},
		perPageChanged(limit) {
			this.query.limit = limit
			this.reload()
		},
		paginationChanged(page) {
			this.query.page = page
			this.reload()
		},
		sortingChanged(ctx) {
			this.query.sortDesc = ctx.sortDesc
			this.query.sortBy = ctx.sortBy
			this.reload()
		},
		discardProduct(data) {
			this.new_purchase_request.items.splice(data.index, 1)
		},
		validateCount(count) {
			return count > 0 ? true : false
		},
		validateDescription(description) {
			return description && description.length > 0 ? true : false
		},
		addNonExistentItem() {
			var item = {
				line: null,
				description: null,
				code: null,
				type: null,
				count: null,
				measurement_unit_id: 1,
				unit: 'UN',
				comment: null,
			}
			this.new_purchase_request.items.push(item)
		}
	}
}
</script>