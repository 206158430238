<template>

	<!-- MODAL -->
	<b-modal
		id="secure-download"
		size="md"
		body-class="p-4"
		hide-header
	>

		<!-- CONTENT -->
		<p class="fs-13">{{ download_token.loading ? 'Generando Enlace' : 'Descarga Segura' }}</p>

		<div v-if="download_token.loading">
			<div class="text-center py-3">
				<b-spinner type="grow" large variant="primary"></b-spinner>
			</div>
		</div>
		<div v-else>
			<b-alert :show="!download_token.loading" variant="light">
				<p class="mb-0">Su enlace de descarga expirará dentro de 15 minutos.</p>
			</b-alert>

			<b-button
				v-if="download_token.data.uuid"
				variant="success"
				block
				size="lg"
				:href="file_uri"
				@click="close()"
			>
				<span>Descargar</span>
			</b-button>
		</div>

		<!-- FOOTER -->
		<template v-slot:modal-footer="{ close }">
			<div>
				<b-button variant="outline-dark" @click="close()">
					<span>Cerrar</span>
				</b-button>
			</div>
		</template>

	</b-modal>
	<!-- /MODAL -->
</template>

<script>
export default {
	name: 'SecureDownload',
	data() {
		return {
			loading: false,
		}
	},
	prop: {
		download: Object
	},
	computed: {
		download_token() {
			return this.$store.state.download_token
		},
		file_uri() {
			return !this.download_token.loading && this.download_token.data.uuid && this.download_token.data.token ?
						this.$store.state.api_path +'files/'+ this.download_token.data.uuid +'/token/'+ this.download_token.data.token :
						'#'
		}
	},
	methods: {
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		close() {
			this.$bvModal.hide('secure-download')
		}
	}
}
</script>

<style scoped>
</style>
