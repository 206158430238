<template>
	<div class="mt-3">

		<SiteModuleHeader></SiteModuleHeader>

		<p class="text-dark fs-15 mt-0 mb-4">Si necesita apoyo remoto con inconvenientes, descargue el software de soporte e inícielo.</p>
		<b-row>
			<b-col lg="4">
				<b-card no-body class="mb-5">
					<b-card-header>
						<p class="mb-0 fw-bold fs-12">Software de Soporte Recomendado</p>
					</b-card-header>
					<b-card-body class="pt-5 pb-0 text-center">
						<img src="@/assets/logos/teamviewer.svg" style="width: 80%" />
						<p class="mt-4 fs-13 px-5 text-dark">Confirme cualquier diálogo que solicite permisos al descargar y abrir el software.</p>
						<p class="mt-3 fs-13 px-5 text-dark">Espere unos segundos y comparta los códigos de acceso con el técnico.</p>
					</b-card-body>
					<b-card-body>
						<a class="btn btn-primary btn-block" href="https://dl.teamviewer.com/download/TeamViewerQS.exe" target="_new">
							<p class="mb-0 py-2 fs-13 text-center">
								<i class="fas fa-download mr-2"></i>
								<span>Presione para iniciar descarga</span>
							</p>
						</a>
					</b-card-body>
				</b-card>
			</b-col>
			<b-col lg="4">
				<b-card no-body class="mb-5">
					<b-card-header>
						<p class="mb-0 fs-12">Software de Soporte Alternativo</p>
					</b-card-header>
					<b-card-body class="pt-5 pb-0 text-center">
						<img src="@/assets/logos/anydesk.svg" style="width: 60%" />
						<p class="mt-4 fs-13 px-5 text-dark">Escoja esta opción sólo si tiene problemas con TeamViewer.</p>
					</b-card-body>
					<b-card-body>
						<a class="btn btn-outline-secondary btn-block" href="https://download.anydesk.com/AnyDesk.exe" target="_new">
							<p class="mb-0 py-2 fs-13 text-center">
								<i class="fas fa-download mr-2"></i>
								<span>Presione para iniciar descarga</span>
							</p>
						</a>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>

	</div>
</template>

<script>
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
export default {
	name: 'RemoteSupport',
	components: {
		SiteModuleHeader,
	},
	data: function() {
		return {
		}
	},
	mounted() {
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		getters() { return this.$store.getters },
		//////-----------------------------------------------------------
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
	}
}
</script>
