<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="manage-user"
			title="Administrar Acceso"
			size="lg"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="privileges.loading || updating" rounded="sm" class="p-3">

				<b-card class="text-size-11 px-3 py-2" no-body>
					<b-row>
						<b-col>
							<p class="mb-0">
								<b-skeleton v-if="privileges.loading"></b-skeleton>
								<span v-else>
									<strong>{{ privileges.data.user.name }} {{ privileges.data.user.lastname }}</strong>
								</span>
							</p>
							<p class="mb-0">
								<b-skeleton v-if="privileges.loading"></b-skeleton>
								<span v-else class="text-muted">{{privileges.data.user.email}}</span>
							</p>
							<b-row class="mt-3">
								<b-col sm="auto">
									<div class="mb-0 text-uppercase fs-07 fw-bold text-dark">Perfil de Acceso</div>
									<b-skeleton v-if="privileges.loading"></b-skeleton>
									<span v-else>
										<i :class="'mr-2 fas fa-'+privileges.data.user.profile.icon"></i>
										<span>{{ privileges.data.user.profile.description }}</span>
									</span>
								</b-col>
								<b-col sm="auto">
									<div class="mb-0 text-uppercase fs-07 fw-bold text-dark">Fecha Creación</div>
									<b-skeleton v-if="privileges.loading"></b-skeleton>
									<span v-else>
										<span>{{ privileges.data.user.created }}</span>
									</span>
								</b-col>
							</b-row>
						</b-col>
						<b-col sm="auto" class="text-right">
							<p class="mb-0 mt-2">
								<b-skeleton v-if="privileges.loading"></b-skeleton>
								<span v-else>
									<b-button
										variant="outline-primary"
										@click="resetPassword(privileges.data.user)"
										class="mr-2"
										title="Reiniciar Contraseña"
										v-b-tooltip.hover
									>
										<i class="fas fa-key mr-2"></i>
										<span>Contraseña</span>
									</b-button>
									<b-button
										v-if="privileges.data.user.enabled"
										variant="outline-danger"
										@click="disableConfirmation(privileges.data.user)"
										title="Bloquear Acceso"
										v-b-tooltip.hover
									>
										<i class="fas fa-lock mr-2"></i>
										<span>Bloquear</span>
									</b-button>
									<b-button
										v-else
										variant="outline-success"
										@click="enableConfirmation(privileges.data.user)"
										title="Desbloquear Acceso"
										v-b-tooltip.hover
									>
										<i class="fas fa-unlock mr-2"></i>
										<span>Desbloquear</span>
									</b-button>
								</span>
							</p>
						</b-col>
					</b-row>
				</b-card>

				<b-alert class="mt-3" :show="!privileges.loading && privileges.data && privileges.data.user && !privileges.data.user.enabled" variant="danger">
					<b-row no-gutters>
						<b-col sm="auto" class="pr-2">
							<i class="fas fa-exclamation-triangle"></i>
						</b-col>
						<b-col>
							<p class="mb-0 fs-11">
								<strong>Usuario Bloqueado</strong>
							</p>
							<p class="mb-0 fs-11">Puede modificar los privilegios, pero el usuario no podrá ingresar al sistema hasta que desbloquee su acceso.</p>
						</b-col>
					</b-row>
				</b-alert>
				<b-card no-body v-if="!privileges.loading || updating" class="mt-3">
					<b-tabs pills card vertical>
						<template #tabs-start>
							<p class="fw-bold fs-12 mb-2">Módulos</p>
						</template>
						<b-tab v-for="(item, index) in privileges.data.available" :key="index" no-body>
							<template #title>
								{{item.name}}
							</template>
							<h4 class="px-3 pt-3 mb-1">{{item.description}}</h4>
							<p class="px-3 text-muted text-size-11">Active y desactive privilegios para este usuario.</p>
							<b-table
								:items="item.privileges"
								:fields="privilege_fields"
								small
								hover
								class="mb-0"
							>
								<template v-slot:head(enabled)="data">
									<div class="text-left ml-2">
										<span>{{data.label}}</span>
									</div>
								</template>
								<template v-slot:cell(enabled)="data">
									<div class="text-left ml-2">
										<b-form-checkbox v-model="privileges.data.enabled" :value="data.item.id">
											<span>{{data.item.description}}</span>
										</b-form-checkbox>
									</div>
								</template>
							</b-table>
						</b-tab>
					</b-tabs>
				</b-card>

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center py-5">
						<b-spinner type="grow" variant="primary"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="outline-secondary" :disabled="privileges.loading || updating">Cancelar</b-button>
				<b-button @click="update()" variant="success" :disabled="privileges.loading || updating">ACTUALIZAR</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->

		<DisableUser :user="user" @update="emitUpdate"></DisableUser>
		<EnableUser :user="user" @update="emitUpdate"></EnableUser>
		<AdminResetPassword :user="user" @update="emitUpdate"></AdminResetPassword>

	</div>
</template>

<script>
import DisableUser from '@/components/DisableUser.vue'
import EnableUser from '@/components/EnableUser.vue'
import AdminResetPassword from '@/components/AdminResetPassword.vue'
export default {
	name: 'ManageUser',
	components: {
		DisableUser,
		EnableUser,
		AdminResetPassword,
	},
	data() {
		return {
			updating: false,
			selected: [],
            privilege_fields: [
                { key: 'enabled', label: 'Privilegio', sortable: true }
            ],
		}
	},
	props: {
		user: Object
	},
	computed: {
		loading() {
			return false
		},
		privileges() {
			return this.$store.state.privileges
		},
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'manage-user') { this.reset() }
		})
	},
	methods: {
		reset() {
			//this.$store.commit('reset_worker')
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		update() {
			this.updating = true
			this.$api.put('users/'+this.privileges.data.user.id+'/privileges', { privileges: this.privileges.data.enabled })
			.then(() => {
				this.updating = false
				this.$bvModal.hide('manage-user')
				this.toast(true, 'Se actualizaron los privilegios correctamente y serán aplicados al usuario la próxima vez que ingrese al sistema.', 10000)
			})
			.catch(error => {
				this.updating = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		emitUpdate() {
			this.$emit('update')
		},
        parseIdentifier(i) {
            return i ? i.length <= 10 ? i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1) : 'INVÁLIDO' : i
        },
		disableConfirmation() {
			this.$bvModal.show('disable-user')
		},
		enableConfirmation() {
			this.$bvModal.show('enable-user')
		},
		resetPassword() {
			this.$bvModal.show('admin-reset-password')
		},
	}
}
</script>

<style scoped>
</style>
