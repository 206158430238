<template>
	<div class="mt-3">

		<SiteModuleHeader
			:showBack="true"
			:showHome="true"
			:showSize="true"
			:showTheme="true"
			:showFluid="true"
			:showTitle="true"
			@reload="reload"
			:reloading="reloading || resources.loading"
		></SiteModuleHeader>

		<CreateCorporateTemplate></CreateCorporateTemplate>
		<CreateCorporateTemplateGroup></CreateCorporateTemplateGroup>

		<!-- TOOLBAR -->
		<b-row no-gutters>

			<!-- CREATE -->
			<b-col md="sm" lg="auto" xl="auto">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Acciones</span>
					<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto">
						<!-- CREATE -->
						<SitePrimaryButton
							:loading="resources.loading"
							text="Nueva Plantilla"
							title="Presione para crear"
							icon="plus"
							@clicked="createTemplate()"
						></SitePrimaryButton>
					</b-col>
					<b-col sm="auto">
						<!-- CREATE -->
						<SitePrimaryButton
							:loading="resources.loading"
							text="Nuevo Grupo"
							title="Presione para crear"
							icon="plus"
							@clicked="createGroup()"
						></SitePrimaryButton>
					</b-col>
				</b-row>
			</b-col>

			<!-- SPACER -->
			<b-col>
			</b-col>

		</b-row>
		<!-- /TOOLBAR -->

		<!-- DATA -->
		<b-skeleton-wrapper :loading="resources.loading && !reloading">

			<!-- LOADING -->
			<template #loading>
				<b-row>
					<b-col>
						<b-skeleton height="300px" class="m-0 p-0"></b-skeleton>
					</b-col>
					<b-col>
						<b-skeleton height="300px" class="m-0 p-0"></b-skeleton>
					</b-col>
					<b-col>
						<b-skeleton height="300px" class="m-0 p-0"></b-skeleton>
					</b-col>
				</b-row>
			</template>

			<b-row>
				<b-col lg="4" v-for="(group, g_index) in resources.data" :key="g_index">
					<b-card no-body class="mb-5">
						<b-card-header>
							<p class="mb-0 fw-bold fs-12">{{ group.name }}</p>
						</b-card-header>
						<b-card-body class="py-5 text-center">
							<b-img :src="imageSource(group)" width="100%"></b-img>
						</b-card-body>
						<b-list-group flush v-if="group.templates && group.templates.length > 0">
							<b-list-group-item v-for="(template, t_index) in group.templates" :key="t_index">
								<b-row no-gutters>
									<b-col sm="auto" class="pr-3">
										<b-button
											variant="light"
											size="xs"
											class="px-2"
											@click="downloadFile(template)"
											title="Descargar"
										>
											<i class="fas fa-download"></i>
										</b-button>
									</b-col>
									<b-col>
										<p class="mb-0" :title="template.description ? template.description : null">{{ template.name }}</p>
									</b-col>
								</b-row>
							</b-list-group-item>
						</b-list-group>
					</b-card>
				</b-col>
			</b-row>

		</b-skeleton-wrapper>
		<SecureDownload></SecureDownload>

	</div>
</template>

<script>
import CreateCorporateTemplate from '@/components/CreateCorporateTemplate.vue'
import CreateCorporateTemplateGroup from '@/components/CreateCorporateTemplateGroup.vue'
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
import SitePrimaryButton from '@/components/SitePrimaryButton.vue'
import SecureDownload from '@/components/SecureDownload.vue'

export default {
	name: 'TemplatesView',
	components: {
		SiteModuleHeader,
		SitePrimaryButton,
		CreateCorporateTemplate,
		CreateCorporateTemplateGroup,
		SecureDownload,
	},
	data() {
		return {
			reloading: false,
		}
	},
	computed: {
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		resources() { return this.$store.state.corporate_template_groups },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
		corporate_templates() { return this.$store.state.corporate_templates },
		corporate_template_groups() { return this.$store.state.corporate_template_groups },
	},
	created() {
		this.load()
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
		load() {
			this.$store.dispatch('corporate_template_groups').then(() => { this.reloading = false })
		},
		reload() {
			this.reloading = true
			this.load()
		},
		createTemplate() {
			this.$bvModal.show('create-corporate-template')
		},
		createGroup() {
			this.$bvModal.show('create-corporate-template-group')
		},
		imageSource(group) {
			if (group == 'undefined' || group.icon == null || group.icon.length == 0) return '#'
			return 'assets/custom_icons/'+group.icon+'.png'
		},
		downloadFile(template) {
			if (template == 'undefined' || template == null) return false
			this.$store.state.download_token.loading = true
			this.$bvModal.show('secure-download')
			this.$api.get('files/'+template.file.uuid+'/token')
			.then(response => {
				this.$store.dispatch('download_token', response.data.uuid)
			})
			.catch(error => {
				this.toast(false, error.response.data.message, 10000)
			})
		},
	}
}
</script>

