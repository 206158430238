<template>
	<div class="mt-3">

		<SiteModuleHeader
			:showBack="true"
			:showHome="true"
			:showSize="true"
			:showTheme="true"
			:showFluid="true"
			:showTitle="true"
			@reload="reload"
			:reloading="reloading || resources.loading"
		></SiteModuleHeader>

		<!-- TOOLBAR -->
		<b-row no-gutters>

			<!-- CREATE -->
			<b-col md="sm" lg="auto" xl="auto">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Acciones</span>
					<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
				</p>
				<b-row no-gutters>

					<!-- SEARCH INPUT -->
					<b-col sm="auto">
						<b-overlay
							:show="resources.loading"
							rounded
							opacity="0.6"
							class="mr-2"
						>
							<template #overlay>
								<span>&nbsp;</span>
							</template>
							<b-input-group :size="ui_size">
								<b-input
									v-model="query.search"
									placeholder="RUT, nombre o apellido"
									@changed="searchChanged"
									@blur="searchBlur()"
									@keyup.delete="searchClear()"
									@keydown.enter="search()"
									v-bind:class="{
										'bg-white text-primary' : true,
										'border-primary' : search != null,
										'border-0' : search == null,
									}"
								></b-input>
								<template #append>
									<b-button
										title="Presione para buscar"
										:disabled="query.search == null || query.search.length == 0"
										:variant="query.search != null && query.search.length > 0 ? 'primary' : 'outline-primary'"
										@click="search()"
									>
										<i class="fas fa-search mr-1"></i>
										<span>Buscar</span>
									</b-button>
								</template>
							</b-input-group>
						</b-overlay>
					</b-col>

					<!-- SYNC -->
					<b-col md="12" lg="auto" xl="auto" v-if="false">
						<SitePrimaryButton
							:loading="resources.loading"
							text="Sincronizar"
							title="Descargar nueva información desde Defontana"
							icon="refresh"
							@clicked="openSync()"
						></SitePrimaryButton>
					</b-col>

				</b-row>
			</b-col>

			<!-- SPACER -->
			<b-col>
			</b-col>

			<!-- PER PAGE -->
			<b-col md="12" lg="auto" xl="auto">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Visualización</span>
					<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto">
						<SitePerPage
							:loading="resources.loading"
							:limit="query.limit"
							:options="paginationOptions"
							@clicked="perPageChanged"
						></SitePerPage>
					</b-col>
				</b-row>
			</b-col>

			<!-- PER PAGE -->
			<b-col md="12" lg="auto" xl="auto">
				<p class="mb-2 text-dark"
					:class="{
						'fs-09' : ui_size == 'sm',
						'fs-10' : ui_size == 'md',
						'fs-13' : ui_size == 'lg',
					}">
					<span>Página Actual</span>
					<i class="fas fa-arrow-turn-down ml-2 text-muted"></i>
				</p>
				<b-row no-gutters>
					<b-col sm="auto">
						<SitePagination
							:loading="resources.loading"
							:page="query.page"
							:total="resources.data.total"
							:limit="query.limit"
							@clicked="paginationChanged"
						></SitePagination>
					</b-col>
				</b-row>
			</b-col>

		</b-row>
		<!-- /TOOLBAR -->

		<!-- DATA -->
		<b-skeleton-wrapper :loading="resources.loading && !reloading">

			<!-- LOADING -->
			<template #loading>
				<b-card no-body>
					<b-skeleton-table
						animation="fade"
						:rows="10"
						:columns="7"
					></b-skeleton-table>
				</b-card>
			</template>


			<!-- TABLE :fields="visibleTableFields" -->
			<b-table
				:items="resources.data.data"
				:busy="resources.loading"
				:fields="fields"
				:no-local-sorting="true"
				@sort-changed="sortingChanged"
				show-empty
				:stacked="mobile"
				hover
				bordered
				:small="ui_size == 'sm' || false"
				responsive
				sort-icon-left
				:head-variant="ui_theme == 'light' ? 'light' : 'dark'"
				:table-variant="ui_theme == 'light' ? 'light' : 'dark'"
				class="mt-0 mb-0 rounded"
				:class="{
					'fs-09' : ui_size == 'sm',
					'fs-10' : ui_size == 'md',
					'fs-13' : ui_size == 'lg',
					'table-dark' : ui_theme == 'dark',
				}"
			>
				<template v-slot:empty>
					<div class="p-3">
						<span class="text-muted" >No hay trabajadores que coincidan con la búsqueda.</span>
					</div>
				</template>
				<template v-slot:table-busy>
					<div class="text-center my-2">
						<b-spinner class="align-middle"></b-spinner>
					</div>
				</template>
				<template v-slot:cell(sync)="data">
					<div class="text-center">
						<b-button
							size="xs"
							variant="light"
							title="Volver a sincronizar"
							@click="resyncProduct(data)"
							:disabled="loaders.resync.active && loaders.resync.index == data.index"
						>
							<i class="fas fa-refresh"></i>
						</b-button>
					</div>
				</template>
				<template v-slot:cell(rut)="data">
					<b-skeleton v-if="loaders.resync.active && loaders.resync.index == data.index" class="mt-1"></b-skeleton>
					<div v-else class="text-left text-uppercase">
						{{ data.value }}
					</div>
				</template>
				<template v-slot:cell(nombre)="data">
					<b-skeleton v-if="loaders.resync.active && loaders.resync.index == data.index" class="mt-1"></b-skeleton>
					<div v-else class="text-left text-uppercase">
						{{ data.value }}
					</div>
				</template>
				<template v-slot:cell(apellidoPaterno)="data">
					<b-skeleton v-if="loaders.resync.active && loaders.resync.index == data.index" class="mt-1"></b-skeleton>
					<div v-else class="text-left text-uppercase">
						{{ data.value }}
					</div>
				</template>
				<template v-slot:cell(apellidoMaterno)="data">
					<b-skeleton v-if="loaders.resync.active && loaders.resync.index == data.index" class="mt-1"></b-skeleton>
					<div v-else class="text-left text-uppercase">
						{{ data.value }}
					</div>
				</template>
				<template v-slot:cell(last_sync)="data">
					<b-skeleton v-if="loaders.resync.active && loaders.resync.index == data.index" class="mt-1"></b-skeleton>
					<div v-else class="text-left text-uppercase">
						{{ data.value }}
					</div>
				</template>
			</b-table>
		</b-skeleton-wrapper>
		
		<!-- PAGINATION -->
		<SitePagination
			:loading="resources.loading"
			:page="query.page"
			:total="resources.data.total"
			:limit="query.limit"
			@clicked="paginationChanged"
			class="mt-2"
		></SitePagination>

		<DefontanaSync></DefontanaSync>

	</div>
</template>

<script>
import Helpers from '@/helpers'
import SiteModuleHeader from '@/components/SiteModuleHeader.vue'
import SitePerPage from '@/components/SitePerPage.vue'
import SitePagination from '@/components/SitePagination.vue'
import SitePrimaryButton from '@/components/SitePrimaryButton.vue'
import DefontanaSync from '@/components/DefontanaSync.vue'

export default {
	name: 'DefontanaEmployees',
	components: {
		SiteModuleHeader,
		SitePerPage,
		SitePagination,
		SitePrimaryButton,
		DefontanaSync,
	},
	data() {
		return {
			reloading: false,
			query: {
				limit: 10,
				page: 1,
				sortDesc: true,
				sortBy: 'rut',
				search: null,
			},
			paginationOptions: [
				{ value: 5, text: '5 por página' },
				{ value: 10, text: '10 por página' },
				{ value: 15, text: '15 por página' },
				{ value: 20, text: '20 por página' },
				{ value: 50, text: '50 por página' },
				{ value: 100, text: '100 por página' },
				{ value: 9999, text: 'Mostrar todo' },
			],
			fields: [
				{ key: 'sync', label: '', sortable: false },
				{ key: 'rut', label: 'RUT', sortable: true },
				{ key: 'apellidoPaterno', label: 'Primer Apellido', sortable: true },
				{ key: 'apellidoMaterno', label: 'Segundo Apellido', sortable: true },
				{ key: 'nombre', label: 'Nombres', sortable: true },
				{ key: 'last_sync', label: 'Última Actualización', sortable: true },
			],
			loaders: {
				resync: {
					active: false,
					index: null,
				}
			},
		}
	},
	computed: {
		client() { return this.$store.state.core.account.client },
		profile() { return this.$store.state.core.account.profile },
		core() { return this.$store.state.core },
		local() { return this.$store.state.local },
		session() { return this.$store.getters.session },
		title() { return this.$route.meta.title },
		ui_size() { return this.$store.getters.ui_size },
		ui_theme() { return this.$store.getters.ui_theme },
		mobile() { return this.screen_size.width >= 992 ? false : true },
		screen_size() { return this.$store.getters.screen_size },
		resources() { return this.$store.state.defontana_employees },
		visibleTableFields() { return this.mobile ? this.tableFields.filter(field => field.mobile) : this.tableFields },
		//////
	},
	mounted() {
		this.load()
	},
	created() {
	},
	methods: {
		goTo(route_name) {
			if (this.$route.name != route_name) this.$router.push({ name: route_name })
		},
		load() {
			this.$store.dispatch('defontana_employees', this.query).then(() => { this.reloading = false })
		},
		reload() {
			this.reloading = true
			this.load()
		},
		perPageChanged(limit) {
			this.query.limit = limit
			this.reload()
		},
		paginationChanged(page) {
			this.query.page = page
			this.reload()
		},
		sortingChanged(ctx) {
			this.query.sortDesc = ctx.sortDesc
			this.query.sortBy = ctx.sortBy
			this.reload()
		},
		search() {
			if (this.query.search == null) {
				this.query.search = ''
				//this.$refs.search.focus()
			}
			else if (this.query.search == '') {
				this.query.search = null
			}
			else if (this.query.search.length > 0) {
				this.reload()
			}
		},
		searchBlur() {
			if (this.query.search == '') {
				this.query.search = null
			}
		},
		searchClear() {
			if (this.query.search != null && this.query.search.length == 0) {
				this.query.page = 1
				this.reload()
			}
		},
		searchChanged(search) {
			this.query.search = search
		},
		parseNumber(number) {
			return Helpers.parseNumber(number)
		},
		viewEmployee(employee) {
			this.$bvModal.show('view-defontana-employee')
			this.$store.dispatch('defontana_employee', employee.id)
		},
		resyncProduct(data) {
			this.loaders.resync.active = true
			this.loaders.resync.index = data.index
			setTimeout(() => {
				this.loaders.resync.active = false
				this.loaders.resync.index = null
			}, 1000)
		},
		openSync() {
			this.$bvModal.show('defontana-sync')
		},
	}
}
</script>

